import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Modal,
    Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { useGetDissertationListForReviewerByPaginateQuery } from 'src/rtk/features/disertation-thesis/dissertationListForReviewerApi';
import { ThesisDissertationSummaryReport } from '../ThesisDissertationSummaryView/ThesisDissertationSummaryReport';
import AddDissertationReviewerComment from './DissertationReviewerComment';
import loader from '../../../assets/images/loader.gif';

interface SearchFields {
    from_date?: string;
    to_date?: string;
    bmdc_registration_no?: string;
}

const initialValues: SearchFields = {
    from_date: '',
    to_date: '',
    bmdc_registration_no: '',
};

const DissertationList = () => {
    document.title = 'Dissertation/Thesis List | BCPS';

    const [commentModalShow, setCommentModalShow] = useState<boolean>(false);
    const [viewModalShow, setViewModalShow] = useState<boolean>(false);
    const [commentThesisId, setCommentThesisId] = useState<string>('');
    const [dissertationForComment, setDissertationForComment] = useState<any>({});
    const [dissertationToView, setDissertationToView] = useState<DissertationListForReviewer>({} as DissertationListForReviewer);
    const [dissertationToBeExported, setDissertationToBeExported] = useState<any>({} as any);
    const [page, setPage] = useState<string | number>('1');
    const [paginate_per_page, set_paginate_per_page] = useState(15);
    const [search, setSearch] = useState('');
    const [showReport, setShowReport] = useState<boolean>(false);
    let referenceUrl = new URLSearchParams(location.search).get('ref');

    const {
        data: dissertationThesisListData,
        error: dissertationThesisListDataError,
        isLoading: isDissertationThesisListDataLoading,
        isError: isDissertationThesisListDataError,
        isSuccess: isDissertationThesisListDataSuccess,
        refetch,
        isFetching,
    } = useGetDissertationListForReviewerByPaginateQuery({
        page,
        paginate_per_page,
        search,
    });

    useEffect(() => {
        refetch();
    }, [referenceUrl]);

    const allDissertationThesis = dissertationThesisListData?.data;

    const commentModalShowHandler = (modalState: boolean, dissertation: any) => {
        setCommentModalShow(modalState);
        setCommentThesisId(dissertation?.id);
        setDissertationForComment(dissertation);
    };

    const viewModalShowHandler = (modalState: boolean, thesis: DissertationListForReviewer) => {
        setViewModalShow(modalState);
        setDissertationToView(thesis);
    };

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        set_paginate_per_page(newPerPage);
        setPage(page);
    };

    const exportDissertationHandler = (reportState: boolean, dissertation: any) => {
        setShowReport(reportState);
        setDissertationToBeExported(dissertation);
    };

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            width: '100px',
            cell: (row, index) => index + 1,
        },

        {
            name: <div>Trainee Name</div>,
            selector: (row) =>
                row.trainee_name ?? '-',
            sortable: false,
            wrap: true,
            width: '150px',
        },

        {
            name: <div>BMDC Reg. No.</div>,
            selector: (row) => row.bmdc_registration_no ?? '-',
            sortable: false,
            wrap: true,
            width: '150px',
        },
        {
            name: <div>Discipline</div>,
            selector: (row) => row.subject_name ?? '-',
            sortable: false,
            wrap: true,
            width: '150px',
        },

        {
            name: <div>Type</div>,
            selector: (row) => row.document_type ?? '-',
            sortable: false,
            wrap: true,
            width: '150px',
        },

        // {
        //     name: <div>ID</div>,
        //     selector: (row) =>
        //         '-',
        //     sortable: false,
        //     wrap: true,
        //     center: true,
        //     width: '150px',
        // },

        // {
        //     name: <div>Title</div>,
        //     selector: (row) =>
        //         '-',
        //     sortable: false,
        //     wrap: true,
        //     center: true,
        //     width: '150px',
        // },

        {
            name: <div>Date of Submission</div>,
            selector: (row) =>
                row.submission_date ?? '-',
            sortable: false,
            wrap: true,
            width: '200px',
        },

        {
            name: <div>Guide</div>,
            selector: (row) =>
                row?.title?.guide?.personal_info?.full_name ?? '-',
            sortable: false,
            wrap: true,
            width: '150px',
        },

        {
            name: <div>Co-Guide 1</div>,
            selector: (row) =>
                row?.title?.co_guide_1?.personal_info?.full_name ?? '-',
            sortable: false,
            wrap: true,
            width: '150px',
        },

        {
            name: <div>Co-Guide 2</div>,
            selector: (row) =>
                row?.title?.co_guide_2?.personal_info?.full_name ?? '-',
            sortable: false,
            wrap: true,
            width: '150px',
        },

        {
            name: <div>Co-Guide 3</div>,
            selector: (row) =>
                row?.title?.co_guide_3?.personal_info?.full_name ?? '-',
            sortable: false,
            wrap: true,
            width: '150px',
        },

        {
            name: <div>Status</div>,
            selector: (row) =>
                row.admin_approval_status ?? '-',
            sortable: false,
            wrap: true,
            width: '150px',
        },

        {
            name: <div>Action</div>,
            width: '280px',
            cell: (row) => (
                <>
                    {/* btn-group-example */}
                    <div className="d-flex gap-1 my-2">
                        <Button
                            size="sm"
                            color="info"
                            type="button"
                            text={`<span class="fas fa-eye" ></span> View`}
                            onClick={() => {
                                // viewModalShowHandler(true, row);
                                window.open(`/view-thesis-entry-details/${row?.id}`, '_blank');
                            }}
                        />

                        <Button
                            size="sm"
                            color="success"
                            type="button"
                            text={`<span class="fas fa-edit" ></span> Comment`}
                            onClick={() => {
                                commentModalShowHandler(true, row);
                            }}
                        />

                        <Button
                            size="sm"
                            color="secondary"
                            type="button"
                            text={`<span class="fas fa-upload" ></span> Export`}
                            onClick={() => {
                                exportDissertationHandler(true, row);
                            }}
                        />
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
        },
    ];

    const {
        handleSubmit,
        getFieldProps,
        values,
        touched,
        errors,
        resetForm,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values) => {
            let s: string = '';

            if (values.from_date) {
                s = s + `&from_date=${values.from_date}`;
            }

            if (values.to_date) {
                s = s + `&to_date=${values.to_date}`;
            }

            if (values.bmdc_registration_no) {
                s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
            }

            setSearch(s);
            setPage('1');
            set_paginate_per_page(15);
        },
    });

    // useEffect(() => {
    //     if (isDissertationThesisListDataLoading || !isDissertationThesisListDataSuccess) {
    //         toast.dismiss();
    //         toast.loading('Data Loading...');
    //     } else {
    //         toast.dismiss();
    //         toast.success('Data Load Successfully');
    //     }
    // }, [isDissertationThesisListDataLoading, isDissertationThesisListDataSuccess]);

    return (
        <div className="page-content">
            {isDissertationThesisListDataLoading || isFetching ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <Breadcrumb title="Dashboard" breadcrumbItem="Dissertation/Thesis List" />
                <Card className="mb-5">

                    <CardBody>
                        <Row>
                            <Col>

                                <Form
                                    className="custom-form"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        //   handleSubmit();
                                    }}
                                >
                                    <div className="filter-wrapper">
                                        <FormGroup>
                                            <Label for="from_date">From Date</Label>
                                            <InputField
                                                type="date"
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('from_date')}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="to_date">To Date</Label>
                                            <InputField
                                                type="date"
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('to_date')}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label for="id">BMDC Reg. No.</Label>
                                            <InputField
                                                type="text"
                                                invalid={{ errors, touched }}
                                                {...getFieldProps('bmdc_registration_no')}
                                            />
                                        </FormGroup>

                                        <FormGroup className="btn-column">
                                            <Button
                                                color="danger"
                                                type="reset"
                                                text="Reset"
                                                onClick={() => {
                                                    resetForm();
                                                    handleSubmit();
                                                }}
                                            />
                                            <Button
                                                type="button"
                                                text="Search"
                                                onClick={() => {
                                                    handleSubmit();
                                                }}
                                            />
                                        </FormGroup>
                                    </div>
                                </Form>

                            </Col>
                        </Row>
                        <DataTable
                            columns={columns}
                            data={allDissertationThesis ? allDissertationThesis : []}
                            pagination
                            paginationServer
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={isDissertationThesisListDataLoading}
                            paginationTotalRows={dissertationThesisListData?.total}
                            expandOnRowClicked
                            highlightOnHover
                            paginationRowsPerPageOptions={[2, 5, 10, 15, 20, 50, 100, 200]}
                            persistTableHead
                        />

                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={commentModalShow}
                            scrollable={true}
                            size="lg"
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Dissertation Reviewer Comment Section
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setCommentModalShow(!commentModalShow);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <Row>
                                    <Col>
                                        <AddDissertationReviewerComment thesisFileId={commentThesisId} dissertation={dissertationForComment} setModalShow={setCommentModalShow} />
                                    </Col>
                                </Row>
                            </div>
                        </Modal>

                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={viewModalShow}
                            scrollable={true}
                            size="xl"
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Dissertation/Thesis Details
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setViewModalShow(!viewModalShow);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <Row>
                                    <Col>
                                        <div>
                                            <div className="row justify-content-center">
                                                <div className="col-md-9">
                                                    <div className="row justify-content-between">
                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Trainee Name: </span>
                                                            <span>{dissertationToView.trainee_name ?? 'N/A'}</span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">BMDC Reg. No.: </span>
                                                            <span>{dissertationToView.bmdc_registration_no ?? 'N/A'}</span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Submission Date: </span>
                                                            <span>{dissertationToView.submission_date ?? 'N/A'}</span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Submission Status: </span>
                                                            <span>{dissertationToView.submission_status ?? 'N/A'}</span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Type: </span>
                                                            <span>{dissertationToView.document_type ?? 'N/A'}</span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Guide: </span>
                                                            <span>{dissertationToView?.title?.guide?.personal_info?.full_name ?? 'N/A'}</span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Guide Approval Status:</span>
                                                            <span>{dissertationToView.guide_approval_status ?? 'N/A'}</span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Guide Approval Note: </span>
                                                            <span>
                                                                {dissertationToView.guide_approval_note ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Co-Guide 1: </span>
                                                            <span>
                                                                {dissertationToView?.title?.co_guide_1?.personal_info?.full_name ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Co-Guide 1 Approval Status: </span>
                                                            <span>
                                                                {dissertationToView.co_guide1_approval_status ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Co-Guide 1 Approval Note: </span>
                                                            <span>
                                                                {dissertationToView.co_guide1_approval_note ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Co-Guide 2: </span>
                                                            <span>
                                                                {dissertationToView?.title?.co_guide_2?.personal_info?.full_name ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Co-Guide 2 Approval Status: </span>
                                                            <span>
                                                                {dissertationToView.co_guide2_approval_status ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Co-Guide 2 Approval Note: </span>
                                                            <span>
                                                                {dissertationToView.co_guide2_approval_note ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Co-Guide 3: </span>
                                                            <span>
                                                                {dissertationToView?.title?.co_guide_3?.personal_info?.full_name ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Co-Guide 3 Approval Status: </span>
                                                            <span>
                                                                {dissertationToView.co_guide3_approval_status ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Co-Guide 3 Approval Note: </span>
                                                            <span>
                                                                {dissertationToView.co_guide3_approval_note ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Discipline: </span>
                                                            <span>
                                                                {dissertationToView.subject_name ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Admin Approval Status: </span>
                                                            <span>
                                                                {dissertationToView.admin_approval_status ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Average Writing Mark: </span>
                                                            <span>
                                                                {dissertationToView.average_writing_mark ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Average Defence Mark: </span>
                                                            <span>
                                                                {dissertationToView.average_defence_mark ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Exam Date: </span>
                                                            <span>
                                                                {dissertationToView.exam_date ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Result: </span>
                                                            <span>
                                                                {dissertationToView.result ?? 'N/A'}
                                                            </span>
                                                        </div>

                                                        <div className="col-md-6 col-sm-12 mb-2">
                                                            <span className="fw-semibold">Instruction: </span>
                                                            <span>
                                                                {dissertationToView.instruction ?? 'N/A'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Modal>
                    </CardBody>
                </Card>

                {
                    showReport && dissertationToBeExported?.id ?
                        <ThesisDissertationSummaryReport
                            thesisEntry={dissertationToBeExported}
                            showReport={showReport}
                            setShowReport={setShowReport}
                        />
                        :
                        null
                }
            </Container>
        </div>
    );
};

export default DissertationList;
