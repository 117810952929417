import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetGuideCoGuideApprovalListQuery } from 'src/rtk/features/guide/guideCoguideApi';
import { useGetDepartmentsByInstituteQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import loader from '../../assets/images/loader.gif';
import ApprovalViewModal from './ApprovalViewModal';

const GuideCoGuideApproval = () => {
  const [guideCoGuideId, setGuideCoGuideId] = useState('');

  const [showAddForm, setShowAddForm] = useState<boolean>(true);
  const [approvalModalShow, setApprovalModalShow] = useState(false);

  const [instituteName, setInstituteName] = useState('');
  const [statusCheck, setStatusCheck] = useState<boolean>(true);

  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [selectedDepartment, setSelectedDepartment] = useState<any>('');
  let referenceUrl = new URLSearchParams(location.search).get('ref');

  const instituteList: SelectOption[] =
    useGetOrganizationsQuery()
      .data?.data?.map((item) => ({
        value: item.organization_name!,
        label: item.organization_name!,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  const { data: departmentLookupData } = useGetDepartmentsByInstituteQuery(instituteName);

  const departmentList: SelectOption[] = departmentLookupData?.data?.map((item) => ({
    value: item.department_name!,
    label: item.department_name!,
  })) || [];

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    from_date?: string;
    to_date?: string;
    bmdc_registration_no?: string;
    institute_name?: string;
    department_name?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    from_date: '',
    to_date: '',
    bmdc_registration_no: '',
    institute_name: '',
    department_name: '',
  };

  const {
    data: guideList,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useGetGuideCoGuideApprovalListQuery(generateQueryUrl());

  useEffect(() => {
    refetch();
  }, [referenceUrl]);

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.bmdc_registration_no !== '') {
        s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
      }

      if (values.institute_name !== '') {
        s = s + `&institute_name=${values.institute_name}`;
      }

      if (values.department_name !== '') {
        s = s + `&department_name=${values.department_name}`;
      }

      setSearch(s);
      setPage('1');
    },
  });

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
      width: '80px',
      wrap: true,
    },

    {
      name: <div>BMDC Reg No</div>,
      selector: (row) => row?.registration?.bmdc_registration_no,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Name</div>,
      selector: (row) => row?.registration?.personal_info?.full_name,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Institute</div>,
      width: '200px',
      selector: (row) =>
        row?.registration?.current_employment_info?.institute_name ?? 'N/A',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Institute Location</div>,
      width: '150px',
      selector: (row) =>
        row?.registration?.current_employment_info?.working_address ?? 'N/A',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Department</div>,
      selector: (row) =>
        row?.registration?.current_employment_info?.department_name ?? 'N/A',
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Designation</div>,
      width: '150px',
      selector: (row) =>
        row?.registration?.current_employment_info?.designation ?? 'N/A',
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Applied For</div>,
      selector: (row) => row?.payment_for,
      sortable: false,
    },
    {
      name: <div>Status</div>,
      selector: (row) => row?.payment_verification_status,
      cell: (row) => {
        switch (row?.payment_verification_status) {
          case 'Reject':
            return (
              <>
                <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                  {'Rejected'}
                </div>
              </>
            );

          case 'Approve':
            return (
              <>
                <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                  {'Approved'}
                </div>
              </>
            );

          case 'Approved':
            return (
              <>
                <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                  {'Approved'}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                  {row?.payment_verification_status}
                </div>
              </>
            );
        }
      },
    },

    {
      name: <div>Action</div>,
      cell: (row) => (
        <div className='my-2'>
          <Button
            className="btn btn-sm btn-info"
            text="View"
            onClick={() => {
              setApprovalModalShow(true);
              setGuideCoGuideId(row?.id);
            }}
          />
          {approvalModalShow && row?.id === guideCoGuideId ? (
            <ApprovalViewModal
              isOpen={approvalModalShow}
              toggle={() => setApprovalModalShow(!approvalModalShow)}
              id={row?.registration_no}
              approvalId={row.id}
              type={row.payment_for}
              verficationStatus={row?.payment_verification_status}
            />
          ) : null}
        </div>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const handleInstitute = (e: any) => {
    if (e.target.value) {
      setInstituteName(e.target.value);
      setStatusCheck(false);
    }
  };

  useEffect(() => {
    if (selectedInstitue !== '') {
      setFieldValue('institute_name', selectedInstitue?.value);
      setInstituteName(selectedInstitue?.value);
    }
  }, [selectedInstitue]);

  useEffect(() => {
    if (selectedDepartment !== '') {
      setFieldValue('department_name', selectedDepartment?.value);
    }
  }, [selectedDepartment]);

  return (
    <div className="page-content">
      {isLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '/', name: 'Dashboard' }]}
          title={'Guide/Co-Guide Registration Approval'}
        />
        {/* <div className="row align-items-center justify-content-center"> */}
        <Card>
          <CardBody>
            <Row>
              <Col>
                <div className="gap-2 w-100 ">
                  <Form
                    className="custom-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                    onReset={(e) => {
                      e.preventDefault();
                      setSelectedInstitute('');
                      setSelectedDepartment('');
                      resetForm();
                      handleSubmit();
                      // setStatusCheck(true);
                    }}
                  >
                    <div className="filter-wrapper">
                      <FormGroup>
                        <Label for="bmdc_registration_no">BMDC Reg. No.</Label>
                        <InputField
                          type="text"
                          placeholder="BMDC Reg. No."
                          {...getFieldProps('bmdc_registration_no')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="from_date">From Date</Label>
                        <InputField
                          type="date"
                          placeholder=""
                          invalid={{ errors, touched }}
                          {...getFieldProps('from_date')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="to_date">To Date</Label>
                        <InputField
                          type="date"
                          placeholder=""
                          invalid={{ errors, touched }}
                          {...getFieldProps('to_date')}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="institute_name">Institute</Label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          value={selectedInstitue}
                          onChange={setSelectedInstitute}
                          options={instituteList}
                          placeholder='Institute'
                          name="institute_name"
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label for="department_name">Department</Label>
                        <Select
                          isClearable={true}
                          isSearchable={true}
                          value={selectedDepartment}
                          onChange={setSelectedDepartment}
                          options={departmentList}
                          placeholder='Department'
                          name="department_name"
                        />
                      </FormGroup>

                      <FormGroup className="btn-column mb-3">
                        <Button color="danger" type="reset" text="Reset" />
                        <Button type="submit" text="Search" />
                      </FormGroup>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
            {/* <div className="card-title rounded text-center bg-secondary text-white py-2 my-2">
              Guide/Co-Guide List
            </div> */}
            <DataTable
              columns={columns}
              data={guideList?.data!}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              progressPending={isLoading}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              responsive
              persistTableHead={true}
              paginationTotalRows={guideList?.total}
            />
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default GuideCoGuideApproval;
