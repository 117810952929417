import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Modal, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useCreateFeedBackForAssesmentMutation, useCreateMiniCexEntryByTraineeMutation } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

const UpdatedMiniCexFormEntry = (props: any) => {

    const { view, miniCEXTraineeInfo, setMiniCEXEntryModalShow } = props;

    const history = useHistory();
    const { data: personalProfile, isError: isDeptFetchError, isLoading: isProfileLoading } =
        useGetPersonalProfileQuery();

    const [traineeSatisfication, setTraineeSatisfication] = useState<number>();
    const [modalShow, setModalShow] = useState(false);

    const [createMiniCexEntryByTrainee, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateMiniCexEntryByTraineeMutation();
    const [createFeedBackForAssesment, { isSuccess: isFeedBackSuccess, isError: isFeedBackError, error: feedBackError, isLoading: isFeedBackLoading }] = useCreateFeedBackForAssesmentMutation();

    const err: CustomError = error as CustomError;
    const err1: CustomError = feedBackError as CustomError;

    const initialValues = {
        registration_id: '',
        supervisor_id: '',
        logbook_entry_session_id: '',
        date_of_assessment: new Date(Date.now()).toISOString().slice(0, 10),
        complexity_level: '',
        diagnosis_of_the_case: '',
        competency_setting: '',
        patient_address: '',
        patient_name: '',
        patient_reg_no: '',
        patient_age: '',
        patient_gender: '',
        case_description: '',
        file_upload: '',
    }

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        setValues,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            complexity_level: Yup.string().required('This is Required!'),
            diagnosis_of_the_case: Yup.string().required('This is Required!'),
            competency_setting: Yup.string().required('This is Required!'),
            patient_address: Yup.string().required('This is Required!'),
            patient_name: Yup.string().required('This is Required!'),
            patient_reg_no: Yup.string().required('This is Required!'),
            patient_age: Yup.string().required('This is Required!'),
            patient_gender: Yup.string().required('This is Required!'),
            case_description: Yup.string().required('This is Required!'),
            // file_upload: Yup.string().required('This is Required!'),
            date_of_assessment: Yup.date().max(new Date(Date.now()), 'Payment date can\'t be a future date').required(),
        }),

        onSubmit: (values: any) => {
            const formData = new FormData();

            values.registration_id = personalProfile?.data?.registration_no;
            values.supervisor_id = personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.id;
            values.logbook_entry_session_id = personalProfile?.data?.details?.current_session_and_supervisor_info?.id;
            console.log(values);

            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });
            createMiniCexEntryByTrainee(formData);
        },
    });


    useEffect(() => {
        if (isSuccess) {
            toast.success('Mini-Cex Entry Stored Successfully!');
            setModalShow(true);
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);


    const performanceNumber = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    const feedBackData = {
        supervisor_id: personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.id,
        logbook_session_id: personalProfile?.data?.details?.current_session_and_supervisor_info?.id,
        user_id: personalProfile?.data?.id,
        satisfactory_label: traineeSatisfication,
        type: 'mini-cex-trainee'
    };

    useEffect(() => {
        if (miniCEXTraineeInfo?.id) {
            setValues({
                registration_id: personalProfile?.data?.registration_no ?? '',
                supervisor_id: personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.id?.toString() ?? '',
                logbook_entry_session_id: personalProfile?.data?.details?.current_session_and_supervisor_info?.id ?? '',
                date_of_assessment: miniCEXTraineeInfo?.date_of_assessment ? new Date(miniCEXTraineeInfo?.date_of_assessment).toISOString().slice(0, 10) : new Date(Date.now()).toISOString().slice(0, 10),
                complexity_level: miniCEXTraineeInfo?.complexity_level ?? '',
                diagnosis_of_the_case: miniCEXTraineeInfo?.diagnosis_of_the_case ?? '',
                competency_setting: miniCEXTraineeInfo?.competency_setting ?? '',
                patient_address: miniCEXTraineeInfo?.patient_address ?? '',
                patient_name: miniCEXTraineeInfo?.patient_name ?? '',
                patient_reg_no: miniCEXTraineeInfo?.patient_reg_no ?? '',
                patient_age: miniCEXTraineeInfo?.patient_age ?? '',
                patient_gender: miniCEXTraineeInfo?.patient_gender ?? '',
                case_description: miniCEXTraineeInfo?.case_description ?? '',
                file_upload: miniCEXTraineeInfo?.file_upload ?? '',
            });
        }
    }, [miniCEXTraineeInfo]);

    useEffect(() => {
        if (isFeedBackSuccess) {
            toast.success('Feedback Given Successfully!');
            setModalShow(false);
            setMiniCEXEntryModalShow(false);
        }
        if (isFeedBackError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err?.data, setErrors);
        }
    }, [isFeedBackSuccess, isFeedBackError]);

    return (
        <>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Form
                style={view === true ? { pointerEvents: 'none' } : {}}
                className="custom-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                }}
                onReset={(e) => {
                    // e.preventDefault();
                    resetForm();
                }}
            >
                <Card className='mt-2'>
                    <CardHeader>
                        <h4 className='text-center'><u>Mini Clinical Evaluation Exercise (MINI-CEX)</u></h4>
                        <Row>
                            <Col>
                                <div>
                                    <span>
                                        <strong>Name of the Institution: </strong>
                                    </span>
                                    <span>
                                        {personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.institute_name}
                                    </span>
                                </div>
                            </Col>
                            <Col>
                                <div className='row justify-content-end'>
                                    <div className='col-sm-4'>
                                        <strong>Date of Examination : </strong>
                                    </div>
                                    <div className='col-sm-4'>
                                        <InputField
                                            type="date"
                                            placeholder=""
                                            invalid={{ errors, touched }}
                                            name='date_of_assessment'
                                            value={values.date_of_assessment}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-6 col-sm-12 mb-2">
                                <h5 className="block-heading">1. Trainee Information</h5>
                                <table className="table table-bordered table-striped table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>Name Of the Trainee: </strong>
                                                {personalProfile?.data?.details?.personal_info?.full_name ?? 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>BMDC Reg No.: </strong>
                                                {personalProfile?.data?.details?.bmdc_registration_no ?? 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Speciality: </strong>
                                                {personalProfile?.data?.details?.subject?.subject_name ?? 'N/A'}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <strong>Program: </strong>
                                                {'FCPS Part-2'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Year of Training: </strong>
                                                {personalProfile?.data?.details?.current_session_and_supervisor_info?.exam_training_info?.training_type === 'Core' &&
                                                    <div>
                                                        <span>
                                                            <strong><u>FCPS Core: </u></strong>
                                                        </span>
                                                        <span>
                                                            {personalProfile?.data?.details?.current_session_and_supervisor_info?.exam_training_info?.name ?? 'N/A'}
                                                        </span>
                                                    </div>
                                                }
                                                {personalProfile?.data?.details?.current_session_and_supervisor_info?.exam_training_info?.training_type === 'Advance' &&
                                                    <div>
                                                        <span>
                                                            <strong><u>FCPS Speciality: </u></strong>
                                                        </span>
                                                        <span>
                                                            {personalProfile?.data?.details?.current_session_and_supervisor_info?.exam_training_info?.name ?? 'N/A'}
                                                        </span>
                                                    </div>
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-md-6 col-sm-12 mb-2">
                                <h5 className="block-heading">2. Assessor's Information</h5>
                                <table className="table table-bordered table-striped table-sm">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <strong>Name of the assessor: </strong>
                                                {personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.name ?? 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Assessor’s specialty: </strong>
                                                {personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.department_name ?? 'N/A'}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <strong>Assessor’s position: </strong>
                                                {personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.designation ?? 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Assessor’s phone number: </strong>
                                                {personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.mobile ?? 'N/A'}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <strong>Assessor’s email: </strong>
                                                {personalProfile?.data?.details?.current_session_and_supervisor_info?.supervisor?.email ?? 'N/A'}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <Row>
                            <h5 className='block-heading'>3. Patient Information</h5>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_name" className='form-label required-field'>Patient Name</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Patient Name"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('patient_name')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_address" className='form-label required-field'>Address of the Patient</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Address of the Patient"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('patient_address')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_reg_no" className='form-label required-field'>Reg. No. of the Patient</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Reg. No. of the Patient"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('patient_reg_no')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_age" className='form-label required-field'>Age</Label>
                                    <InputField
                                        type="number"
                                        placeholder="Age"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('patient_age')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup className='mt-4'>
                                    <Label className='form-label me-1 required-field'>Gender : </Label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="patient_gender"
                                            value="Male"
                                            checked={values.patient_gender === 'Male'}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('patient_gender', 'Male');
                                            }}
                                            id="gender_male"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="gender_male"
                                        >
                                            Male
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="patient_gender"
                                            value="Female"
                                            checked={values.patient_gender === 'Female'}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('patient_gender', 'Female');
                                            }}
                                            id="gender_female"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="gender_female"
                                        >
                                            Female
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="patient_gender"
                                            value="Others"
                                            checked={values.patient_gender === 'Others'}
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('patient_gender', 'Others');
                                            }}
                                            id="gender_others"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="gender_others"
                                        >
                                            Others
                                        </label>
                                    </div>
                                    {errors.patient_gender && touched.patient_gender ? (
                                        <div className="text-danger error-size">
                                            {errors.patient_gender as string}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col sm={8}>
                                <FormGroup>
                                    <Label for="diagnosis_of_the_case" className='form-label required-field'>Diagnosis/Problem</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Diagnosis/Problem"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('diagnosis_of_the_case')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup>
                                    <Label className='form-label me-1 required-field'>Complexity : </Label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="complexity_level"
                                            value="Low"
                                            checked={values.complexity_level === 'Low'}
                                            onChange={(e) => {
                                                setFieldValue("complexity_level", 'Low');
                                            }}
                                            id="complexity_low"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="complexity_low"
                                        >
                                            Low
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="complexity_level"
                                            value="Medium"
                                            checked={values.complexity_level === 'Medium'}
                                            onChange={(e) => {
                                                setFieldValue("complexity_level", 'Medium');
                                            }}
                                            id="complexity_medium"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="complexity_medium"
                                        >
                                            Medium
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="complexity_level"
                                            value="High"
                                            checked={values.complexity_level === 'High'}
                                            onChange={(e) => {
                                                setFieldValue("complexity_level", 'High');
                                            }}
                                            id="complexity_high"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="complexity_high"
                                        >
                                            High
                                        </label>
                                    </div>
                                    {errors.complexity_level && touched.complexity_level ? (
                                        <div className="text-danger error-size">
                                            {errors.complexity_level as string}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label className='form-label me-1  required-field'>Setting : </Label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="In-Patient"
                                            checked={values.competency_setting === 'In-Patient'}
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'In-Patient');
                                            }}
                                            id="in_patient"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="in_patient"
                                        >
                                            In-Patient
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="Out-Patient"
                                            checked={values.competency_setting === 'Out-Patient'}
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'Out-Patient');
                                            }}
                                            id="out_patient"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="out_patient"
                                        >
                                            Out-Patient
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="Emergency"
                                            checked={values.competency_setting === 'Emergency'}
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'Emergency');
                                            }}
                                            id="emergency"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="emergency"
                                        >
                                            Emergency
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="Others"
                                            checked={values.competency_setting === 'Others'}
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'Others');
                                            }}
                                            id="others"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="others"
                                        >
                                            Others
                                        </label>
                                    </div>
                                    {errors.competency_setting && touched.competency_setting ? (
                                        <div className="text-danger error-size">
                                            {errors.competency_setting as string}
                                        </div>
                                    ) : null}
                                </FormGroup>
                            </Col>
                            {values.competency_setting === 'Others' &&
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label className='form-label'>Please Specify</Label>
                                        <InputField
                                            type="text"
                                            placeholder="Please Specify"
                                            invalid={{ errors, touched }}
                                            name='competency_setting'
                                            value={values.competency_setting}
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", e.target.value);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>}
                        </Row>
                        <Row>
                            <Col>
                                <h5 className='block-heading required-field'>4. Brief case description/Case summary/Salient feature:</h5>
                                <InputField
                                    type="textarea"
                                    placeholder="Brief case description"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('case_description')}
                                />
                                <p className='text-muted'>(For example-A pediatric second  year  trainee has presented a case of 12-year-old boy presenting with history of    three weeks of fever and generalized lymphadenopathy. He covered history taking, physical examination, investigations and treatment plan, follow up and future plan of this patient.)</p>
                            </Col>
                            <Col>
                                <Label>Upload by Trainee</Label>
                                {typeof values.file_upload === 'string' && values.file_upload !== '' ? (
                                    <a
                                        className="me-2 p-0 center"
                                        href={`${config?.APP_URL}${values.file_upload}`}
                                        rel="noreferrer"
                                        target="_blank"
                                        style={view === true ? { pointerEvents: 'auto' } : {}}
                                    >
                                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                    </a>
                                ) : (
                                    typeof values.file_upload !== 'string' && values.file_upload &&
                                    <a
                                        className="me-2 p-0 center"
                                        href={URL.createObjectURL(values.file_upload)}
                                        rel="noreferrer"
                                        target="_blank"
                                        style={view === true ? { pointerEvents: 'auto' } : {}}
                                    >
                                        <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                    </a>
                                )}
                                <input
                                    name="file_upload"
                                    type="file"
                                    className="form-control"
                                    placeholder="Upload Certificate"
                                    onChange={(e: any) => {
                                        setFieldValue('file_upload', e.target.files[0]);
                                    }}
                                ></input>
                                {errors.file_upload && touched.file_upload ? (
                                    <div className="text-danger error-size">{errors.file_upload as string}</div>
                                ) : null}
                            </Col>
                        </Row>


                        {view !== true ?
                            <FormGroup className="mb-3 text-center mt-3">
                                <Button
                                    type="reset"
                                    className="btn btn-secondary me-2"
                                >
                                    Reset
                                </Button>

                                <button
                                    type="submit"
                                    className="btn btn-success"
                                >
                                    Submit
                                </button>
                            </FormGroup>
                            :
                            null}
                    </CardBody>
                </Card>
            </Form>
            <Modal
                onOpened={function () {
                    toast.dismiss();
                }}
                isOpen={modalShow}
                toggle={() => {
                    document.body.classList.add('no_padding');
                }}
                backdrop={'static'}
                modalTransition={{ timeout: 0 }}
                backdropTransition={{ timeout: 0 }}
                size='xl'
            >
                <div className="modal-header">
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => {
                            setModalShow(!modalShow);
                        }}
                    ></button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col sm={3}>
                            <Label>Satisfaction using Mini-CEX</Label>
                        </Col>
                        <Col sm={8}>
                            <span className='fw-bold'>LOW</span>
                            {performanceNumber?.map((item) => (
                                <div key={item} className='form-check form-check-inline'>
                                    <button
                                        type='button'
                                        className={traineeSatisfication === item ? "btn btn-primary rounded-pill" : "btn btn-outline-primary rounded-pill"}
                                        onClick={() => {
                                            setTraineeSatisfication(item);
                                        }}
                                    >
                                        {item}
                                    </button>
                                </div>
                            ))}
                            <span className='fw-bold'>HIGH</span>
                        </Col>
                    </Row>
                    <FormGroup className="text-end mt-3">
                        <button
                            type="button"
                            className="btn btn-danger me-1"
                            onClick={() => {
                                setModalShow(!modalShow);
                            }}
                        >
                            Skip
                        </button>
                        <button
                            type="button"
                            className="btn btn-success"
                            onClick={() => {
                                createFeedBackForAssesment(feedBackData);
                            }}
                            disabled={traineeSatisfication === undefined}
                        >
                            Submit
                        </button>
                    </FormGroup>
                </div>
            </Modal>
        </>
    );
};

export default UpdatedMiniCexFormEntry;
