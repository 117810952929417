import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useCreateThesisDissertationFileEntryMutation } from 'src/rtk/features/fileUploadForThesis/thesisDissertationFileEntryApi';
import * as Yup from 'yup';

const ThesisDissertationFileUpload = (props: any) => {
    const {
        list,
        isSuccess,
        isLoading,
        thesisDissertationTitle,
        thesisEntryListForTrainee,
        handleFormSubmit,
        submitStatus,
        modalShow,
        setModalShow,
    } = props;
    const initialValues = {
        document: '',
        submission_status: '',
    };
    const [btnClicked, setBtnClicked] = useState<string>('');

    const FILE_SIZE = 500 * 1024 * 1024;
    const [createThesisDissertationFileEntry, { isSuccess: isCreateSuccess, isError, error }] = useCreateThesisDissertationFileEntryMutation();


    const fileInputRef = useRef<any>();
    const buttonRef = useRef<any>();

    const fullList = { ...list?.data };
    // console.log(fullList);
    const err: CustomError = error as CustomError;
    const {
        handleSubmit,
        getFieldProps,
        touched,
        errors,
        values,
        setValues,
        handleChange,
        setFieldValue,
        resetForm,
        setErrors,
    } = useFormik({
        initialValues,
        validationSchema: Yup.object({
            document: Yup.mixed()
                .required('File is required')
                .test('is-pdf', 'Must be a File', (value) => {
                    if(typeof value === 'string') {
                        return true;
                    }
                    if (value) {
                        return [
                            'application/pdf',
                            'image/jpeg',
                            'image/png',
                            'image/jpg',
                            'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
                            'image/gif'
                        ].includes(value.type);
                    }
                    return true;
                })
                .test('file-size', 'File too large', (value) => {
                    if(typeof value === 'string') {
                        return true;
                    }
                    if (value) {
                        return value.size <= FILE_SIZE;
                    }
                    return true;
                }),
        }),
        onSubmit: (values: any) => {
            const formData = new FormData();
            Object.keys(values).forEach((key) => {
                if (key === 'submission_status') {
                    if (btnClicked === 'submit') {
                        values[key] = 'Submit';
                    } else if (btnClicked === 'save_as_draft') {
                        values[key] = 'Draft';
                    }
                }
                formData.append(key, values[key]);
            });
            // buttonRef.current.disabled = true;
            // console.log(values);
            createThesisDissertationFileEntry(formData);
            handleFormSubmit(submitStatus);
        },
    });

    useEffect(() => {
        if (isCreateSuccess) {
            resetForm();
            setModalShow(false);
            toast.success('File Uploaded Successfully!');
            fileInputRef.current.value = '';
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isCreateSuccess, isError]);

    useEffect(() => {
        if (isSuccess && !isLoading) {
            setFieldValue('document', list?.data?.document_name);
        }
    }, [isSuccess, isLoading]);

    let index = 0;

    return (
        <React.Fragment>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardBody>
                            <Form
                                onSubmit={handleSubmit}
                                onReset={(e) => {
                                    resetForm();
                                    fileInputRef.current.value = '';
                                }}
                                encType="multipart/form-data"
                                id="reset-file-input"
                            >
                                <FormGroup>
                                    <div>
                                        <Label className="form-label required-field">{thesisDissertationTitle?.category} Upload</Label>{' '}
                                        {typeof values.document === 'string' && values.document !== '' ? (
                                            <a
                                                className="me-2 p-0 center"
                                                href={`${config?.APP_URL}${values.document}`}
                                                rel="noreferrer"
                                                target="_blank"
                                            >
                                                <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                            </a>
                                        ) : (
                                            values.document ?
                                                <a
                                                    className="me-2 p-0 center"
                                                    href={URL.createObjectURL(values.document)}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                                </a>
                                                :
                                                null
                                        )}
                                        <input
                                            ref={fileInputRef}
                                            name="document"
                                            type="file"
                                            className="form-control"
                                            placeholder="Upload Document"
                                            onChange={(e: any) => {
                                                setFieldValue('document', e.target.files[0]);
                                            }}
                                        ></input>
                                        <div className="form-text">Max file size 500 MB and  .jpg, .png, .gif, .pdf, .docx only</div>
                                        {errors.document && touched.document ? (
                                            <div className="text-danger">{errors.document as string}</div>
                                        ) : null}
                                    </div>
                                </FormGroup>
                                <FormGroup className="mb-3 text-center">
                                    {/* <Button
                                        type="reset"
                                        className="btn btn-secondary me-2"
                                        disabled={list?.data?.submission_status?.toLowerCase() === 'submit'}
                                    >
                                        Reset
                                    </Button>
                                    <button
                                        type="submit"
                                        className="btn btn-info me-2"
                                        onClick={(e) => {
                                            setBtnClicked('save_as_draft');
                                        }}
                                        disabled={list?.data?.submission_status?.toLowerCase() === 'submit'}
                                    >
                                        Save as Draft
                                    </button> */}
                                    <button
                                        type="submit"
                                        ref={buttonRef}
                                        className="btn btn-success"
                                        onClick={(e) => {
                                            setBtnClicked(submitStatus === 'Draft' ? 'save_as_draft' : submitStatus === 'Submit' ? 'submit' : '');
                                        }}
                                        disabled={
                                            list?.data?.submission_status?.toLowerCase() === 'submit' ||
                                            thesisEntryListForTrainee?.data?.approval_status?.toLowerCase() === 'approve' ||
                                            thesisEntryListForTrainee?.data?.approval_status?.toLowerCase() === 'reject'
                                        }
                                    >
                                        Upload
                                    </button>
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>

    );
};

export default ThesisDissertationFileUpload;
