import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/components';
import { useDeleteLogBookMasterSetupMutation } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';

interface Props {
  logBookSetupMaster: LogBookSetupMasters;
  index: number;
}

const LogBookEntrySessionTableRow = ({ logBookSetupMaster, index }: Props) => {
  const history = useHistory();
  const [deleteApproval, { isSuccess, isLoading, isError }] =
    useDeleteLogBookMasterSetupMutation();

  const deleteHandler = (id: string) => {
    const confirm = window.confirm('Are you sure you want to delete?');
    if (confirm) deleteApproval(id);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Log Book Master deleted successfully');
    }
    if (isError) {
      toast.error('Error deleting Log Book Master');
    }
  }, [isSuccess, isError]);

  return (
    <tr key={logBookSetupMaster.id}>
      <th scope="row">{index + 1}</th>
      <td>{logBookSetupMaster.logbook_heading}</td>
      <td>{logBookSetupMaster.exam_name}</td>
      <td>{logBookSetupMaster.subject_name}</td>
      <td>{logBookSetupMaster.logbook_type}</td>

      <td>{logBookSetupMaster.status ? 'Active' : 'Inactive'}</td>

      <td>
        <div className="d-flex gap-2">
          <Button
            size="sm"
            text="Edit"
            color="warning"
            onClick={() =>
              history.push(`/log/book/entry/session/${logBookSetupMaster.id}`)
            }
          />

          <Button
            size="sm"
            text="Delete"
            color="danger"
            disabled={isLoading}
            isLoading={isLoading}
            onClick={() => deleteHandler(logBookSetupMaster.id!)}
          />
        </div>
      </td>
    </tr>
  );
};

export default LogBookEntrySessionTableRow;
