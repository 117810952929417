import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col, Container, Form,
  FormGroup,
  Label,
  Modal,
  Row
} from 'reactstrap';
import {
  Button,
  DaynamicInputField,
  DaynamicSearchInputField,
  DynamicView,
  InputField
} from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { putUrlString } from 'src/helpers';
import store from 'src/rtk/app/store';
import {
  useCreateByPostMutation,
  useGetDataByMutation,

  useGetDataQuery
} from 'src/rtk/features/common/crudApi';
import * as Yup from 'yup';
import loader from '../../../assets/images/loader.gif';
import { ReportPDF } from './ReportPDF';
import formObj from './formObj';


const ExaminerMarkingForThesis = () => {
  if (formObj?.title) {
    document.title = formObj?.title + ' | BCPS';
  }
  let referenceUrl = new URLSearchParams(location.search).get('ref');

  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [downloadReportPdf, setDownloadReportPdf] = useState<boolean>(false);
  const [pdfHtmlData, setPdfHtmlData] = useState<any>("");
  const [showForm, setShowForm] = useState<boolean>(true);
  const [viewData, setViewData] = useState<any>({});
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');
  const [submitFormType, setSubmitFormType] = useState('Examinner');
  const [marksDisabled, setMarksDisabled] = useState<boolean>(false);
  const [result, setResult] = useState<string>('');
  const generateQueryUrl = () => {
    return `${formObj.listAPIUrl}?page=${page}&paginate_per_page=${limit}${search}`;
  };

  const {
    data: dataList,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
    refetch,
    isFetching
  } = useGetDataQuery(generateQueryUrl());
  const data: any = dataList?.data ? dataList.data : [];

  useEffect(() => {
    refetch();
  }, [referenceUrl]);

  useEffect(() => {
    if (dataListLoadingError) {
      toast.dismiss();
      toast.error('Data Loading error. Please try again later.');
    } else if (dataListLoading || !dataListLoadingSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);


  let initialValues: any = {};
  let validationObj: any = {};
  let inputFields: any = [];
  let formFilds = formObj?.formFields || {};
  Object.keys(formFilds)
    .sort(function (a: any, b: any) {
      return parseInt(a.positionInForm) - parseInt(b.positionInForm);
    })
    .map((key) => {
      if (formFilds[key]['showInForm']) {
        let field = formFilds[key];
        inputFields.push(formFilds[key]);

        if (
          field['type'] === 'checkbox' &&
          typeof field['default'] === 'string'
        ) {
          field['default'] = [field['default'].trim()];
        } else if (
          field['type'] === 'radio' &&
          typeof field['default'] === 'object'
        ) {
          field['default'] = field['default'].join(' ').trim();
        } else if (field['type'] === 'date') {
          field['default'] = field?.options?.defaultDate || '';
        }

        if (field['isRequired']) {
          validationObj[field['name']] = field['validation'];
        }

        initialValues[field['name']] = field['default'];
      }
    });

  const [deleteByID, { isSuccess, isLoading, isError, error }] =
    useCreateByPostMutation();
  const err: CustomError = error as CustomError;

  useEffect(() => {
    if (isSuccess) {
      toast.success('Thesis/Dissertation successfully sent to library');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);




  let initialSearchValues: any = {};
  let inputSearchFields: any = [];
  let tableColumn: any = [];
  let viewColumn: any = [];
  let searchFormFilds = formObj?.searchFormFields || {};



  Object.keys(searchFormFilds)
    .sort(function (a: any, b: any) {
      return (
        parseInt(searchFormFilds[a].positionInForm) -
        parseInt(searchFormFilds[b].positionInForm)
      );
    })
    .map((key) => {
      let searchFormField = searchFormFilds[key];

      if (searchFormField['viewCell']) {
        viewColumn.push({
          name: searchFormField['label'],
          viewCell: searchFormField['viewCell'],
        });
      }

      if (searchFormField['cell']) {
        tableColumn.push({
          name: <div>{searchFormField['label']}</div>,
          cell: searchFormField['cell'],
          width: searchFormField['cellWidth'] ?? "110px",
        });
      }

      if (formObj?.showSearchForm && searchFormFilds[key]['showInSearch']) {
        inputSearchFields.push(searchFormFilds[key]);

        if (
          searchFormField['type'] === 'checkbox' &&
          typeof searchFormField['default'] === 'string'
        ) {
          searchFormField['default'] = [searchFormField['default'].trim()];
        } else if (
          searchFormField['type'] === 'radio' &&
          typeof searchFormField['default'] === 'object'
        ) {
          searchFormField['default'] = searchFormField['default']
            .join(' ')
            .trim();
        } else if (searchFormField['type'] === 'date') {
          searchFormField['default'] =
            searchFormField?.options?.defaultDate || '';
        }

        // initialSearchValues[searchFormField['name']] = '';
      }
    });

  let columns: TableColumn<any>[] = [
    {
      name: '#',
      width: '50px',
      cell: (row, index: any) => (data.from ? data.from + index : index + 1),
    },
    ...tableColumn,
  ];

  if (formObj?.showTableActionColumn) {
    columns.push({
      name: <div>Action</div>,
      width: '275px',
      selector: (row: any) => row.id,
      cell: (row: any, index: any) => (
        <div className="d-flex flex-wrap gap-2 py-1">
          <Button
            size="sm"
            className=""
            text={`<span class="fas fa-edit" ></span> Details`}
            color="warning"
            onClick={() => {
              window.open(`/thesis-entry-details-for-supervisor/${row?.id}`, '_blank');
            }}
          />
          {formObj?.showViewBtn && (
            <Button
              size="sm"
              className=""
              text={`<span class="fas fa-eye" ></span> ${formObj.viewBtnTitle}`}
              color="primary"
              onClick={() => {
                approvalResetForm();
                setShowModal(true);
                setSubmitFormType('View');
                setViewData(row);
              }}
            />
          )}

          {/* examiner  */}

          {row["marking_status"] === 'Marking Done' && <Button
            size="sm"
            className=""
            text={`<span class="fas fa-certificate" ></span> Mark Sheet`}
            color="dark"
            onClick={() => {
              approvalResetForm();
              setShowReportModal(true);
              setSubmitFormType('Mark Sheet');
              setViewData(row);
              console.log(row);
            }}
          />}

          {row["marking_status"] !== 'Marking Done' && <Button
            size="sm"
            className=""
            text={`<span class="fas fa-edit" ></span> Marking`}
            color="success"
            onClick={() => {
              // approvalResetForm();
              setShowModal(true);
              setSubmitFormType('Examinner');
              // setViewData(row);
              setViewData(row);
            }}
          />}


          {/* <Button
            size="sm"
            className=""
            text={`<span class="fas fa-eye" ></span> Examinner`}
            color="success"
            onClick={() => {
              approvalResetForm();
              setShowModal(true);
              setSubmitFormType('Examinner');
              // setViewData(row);
              setViewData(row);
            }}
          /> */}

          {formObj?.showUpdateBtn && (
            <Button
              size="sm"
              className=""
              text={`<span class="fas fa-edit" ></span> ${formObj.updateBtnTitle}`}
              color="info"
              onClick={() => {
                window.open(`/thesis-entry-details-for-supervisor/${row?.id}`, '_blank');
              }}
            />
          )}

          {/* {formObj?.showDeleteBtn && (row?.library_status === "" || row?.library_status === null) && (
            <ConfirmToast
              asModal={true}
              childrenClassName="margin-top-10"
              customCancel="No"
              customConfirm="Yes"
              customFunction={() => {

                let postData = {
                  queryUrl: `${formObj.deleteAPIUrl}${row.id}`,
                  formData: {},
                };


                deleteByID(postData)

              }
              }
              message={formObj.deletePromtMessage}
              position="top-right" //will be ignored cause asModal=true
              showCloseIcon={true}
              theme="lilac"
            >
              <Button
                size="sm"
                className=""
                text={`<span class="fas fa-send" ></span> ${formObj.deletBtnTitle}`}
                color="success"
              />
            </ConfirmToast>
          )} */}
        </div>
      ),
    });
  }

  const {
    handleSubmit: handleSearchSubmit,
    getFieldProps,
    handleChange,
    handleBlur,
    values,
    setValues,
    setFieldValue,
    touched,
    errors,
    resetForm: resetSearchForm,
    setErrors,
  } = useFormik({
    initialValues: initialSearchValues,
    onSubmit: (searchValues) => {
      let s: string = '';

      Object.keys(searchValues).map((i: any) => {
        if (searchValues[i] === '') {
          delete searchValues[i];
        }
      });

      if (searchValues?.name) {
        s = s + `&search=${searchValues.name}`;
        delete searchValues.name;
      }

      if (Object.keys(searchValues).length > 0) {
        s = s + `&` + putUrlString('', searchValues).replaceAll('?', '');
      }

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });

  /******* Examinner Form Start ****** */

  const [
    updateApprovalStatus,
    {
      isSuccess: isApproveSuccess,
      isLoading: isApproveLoading,
      isError: isApproveError,
    },
  ] = useGetDataByMutation();
  const approvErr: CustomError = error as CustomError;


  useEffect(() => {
    if (isApproveSuccess) {
      toast.dismiss();
      setShowModal(false);
      toast.success('Examinner save successfully');
    }
    if (isApproveError) {
      if (approvErr?.data?.message) {
        toast.error(approvErr?.data?.message || 'Something went wrong');
      } else {
        toast.error(approvErr?.message || 'Something went wrong');
      }
    }
  }, [isApproveSuccess, isApproveError]);


  const [createByPost, { isSuccess: formSubmitSuccess, isError: formSubmitError, error: formError }] =
    useCreateByPostMutation();
  const formSubError: CustomError = formError as CustomError;


  useEffect(() => {
    if (formSubmitSuccess) {
      toast.dismiss();
      setShowModal(false);
      toast.success('Examinner save successfully');
    }
    if (formSubmitError) {
      if (formSubError?.data?.message) {
        toast.error(formSubError?.data?.message || 'Something went wrong');
      } else {
        toast.error(formSubError?.message || 'Something went wrong');
      }
    }
  }, [formSubmitSuccess, formSubmitError]);



  const {
    values: approvalValues,
    handleSubmit: approvalHandleSubmit,
    setFieldValue: approvalSetFieldValue,
    getFieldProps: approvalGetFieldProps,
    touched: approvalTouched,
    errors: approvalErrors,
    resetForm: approvalResetForm,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({ ...validationObj }),
    onSubmit: (approvalValues) => {

      // toast.loading(submitFormType + ' Submited...');

      approvalValues['thesis_file_id'] = viewData['id']
      approvalValues['attendance'] = approvalValues['attendance'].toString();

      let postData = {
        queryUrl: formObj['reviewerAssignAPIUrl'],
        formData: approvalValues,
      };

      createByPost(postData);

    },
  });

  const initialValuesForApprovalForm: any = {
    id: '',
    status: '',
    correction_days: 0,
    message: '',
  };

  const {
    values: acceptedValues,
    handleSubmit: acceptedHandleSubmit,
    setFieldValue: acceptedSetFieldValue,
    getFieldProps: acceptedGetFieldProps,
    resetForm: acceptedFormReset,
    touched: acceptedTouched,
    errors: acceptedErrors,
  } = useFormik({
    initialValues: initialValuesForApprovalForm,
    validationSchema: Yup.object({
      status: Yup.string().required('Status is required'),
      message: Yup.string().when('status', (status, schema) => {
        if (status && status !== 'Approve') {
          return schema.required(status + ' note is required!');
        }
        return schema;
      }),
    }),
    onSubmit: (approvalValues) => {

    },
  });

  const setFormValue = (key: any, value: any = '') => {
    approvalSetFieldValue(key, value);
  };

  useEffect(() => {
    if (marksDisabled) {
      approvalSetFieldValue('thesis_writing_mark', 0);
      approvalSetFieldValue('thesis_defence_mark', 0);
    }
  }, [marksDisabled]);

  useEffect(() => {
    if (approvalValues.thesis_writing_mark > 50) {
      approvalSetFieldValue('thesis_writing_mark', 0);
    }
    if (approvalValues.thesis_defence_mark > 50) {
      approvalSetFieldValue('thesis_defence_mark', 0);
    }
  }, [approvalValues.thesis_writing_mark, approvalValues.thesis_defence_mark]);

  useEffect(() => {
    if (marksDisabled) {
      setResult('Absent');
    } else {
      const totalMark: number = (+approvalValues.thesis_writing_mark || 0) + (+approvalValues.thesis_defence_mark || 0);
      if (!totalMark) {
        setResult('');
      } else {
        if (totalMark / 2 >= 30) {
          setResult('Passed');
        } else {
          setResult('Failed');
        }
      }
    }
  }, [marksDisabled, approvalValues.thesis_writing_mark, approvalValues.thesis_defence_mark]);

  /******* Examinner Form End ****** */

  return (
    <>
      <div className="page-content">
        {dataListLoading || isFetching ? (
          <div className="overlay">
            <img src={loader} alt="Loading" height={100} width={300} />
          </div>
        ) : null}
        <Container fluid>
          {formObj.breadcrumbItemForList && (
            <BetterBreadcrumb
              title={formObj?.title}
              breadcrumbItem={formObj.breadcrumbItemForList}
            />
          )}

          <Card>
            <CardBody>
              <Row>
                {formObj?.showSearchForm && inputSearchFields?.length > 0 && (
                  <>
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      handleSearchSubmit();
                    }}
                    >
                      <div className="filter-wrapper">
                        {inputSearchFields?.length > 0 &&
                          inputSearchFields?.map(
                            (searchField: any, key: any) => {
                              if (
                                !['hidden', 'submit'].includes(
                                  searchField['type'],
                                )
                              ) {
                                searchField['isRequired'] = false;
                                searchField['default'] = '';

                                if (
                                  ['radio', 'checkbox'].includes(
                                    searchField['type'],
                                  )
                                ) {
                                  searchField['type'] = 'select';
                                }

                                if (
                                  searchField['dependField'] &&
                                  searchField['dependValue'] &&
                                  searchField['condition']
                                ) {
                                  let isMatch = true;
                                  switch (searchField['condition']) {
                                    case '===':
                                      isMatch =
                                        values[searchField['dependField']] ===
                                        searchField['dependValue'];
                                      break;

                                    case '!==':
                                      isMatch =
                                        values[searchField['dependField']] !==
                                        searchField['dependValue'];
                                      break;

                                    case 'includes':
                                      isMatch = searchField[
                                        'dependValue'
                                      ].includes(
                                        values[searchField['dependField']],
                                      );
                                      break;

                                    default:
                                      break;
                                  }
                                  if (!isMatch) {
                                    if (
                                      values[searchField['name']] !==
                                      searchField['default']
                                    ) {
                                      setFieldValue(
                                        searchField['name'],
                                        searchField['default'],
                                      );
                                    }
                                    return <></>;
                                  }
                                }

                                return (
                                  <FormGroup
                                    key={searchField['name'] + key}
                                    className="mb-3"
                                  >
                                    <>
                                      {['select'].includes(
                                        searchField['type'],
                                      ) && (
                                          <DaynamicSearchInputField
                                            label={searchField['label']}
                                            name={searchField['name']}
                                            type={searchField['type']}
                                            placeholder={'Select'}
                                            options={searchField['data']}
                                            dataApi={searchField['dataApi']}
                                            value={
                                              values[searchField['name']] !==
                                                undefined
                                                ? values[searchField['name']]
                                                : searchField['default']
                                            }
                                            isMulti={searchField['isMulti']}
                                            required={
                                              searchField['isRequired']
                                            }
                                            invalid={{ errors, touched }}
                                            onChange={(data: any) => {
                                              if (data === null) {
                                                setFieldValue(
                                                  searchField['name'],
                                                  '',
                                                );
                                              } else if (
                                                data &&
                                                data?.length === undefined &&
                                                data.value !== undefined
                                              ) {
                                                setFieldValue(
                                                  searchField['name'],
                                                  data.value,
                                                );
                                              } else if (
                                                data &&
                                                data?.length !== undefined
                                              ) {
                                                setFieldValue(
                                                  searchField['name'],
                                                  data.map(
                                                    (item: any) => item.value,
                                                  ),
                                                );
                                              }
                                            }}
                                          />
                                        )}

                                      {['date'].includes(
                                        searchField['type'],
                                      ) && (
                                          <>
                                            <DaynamicSearchInputField
                                              label={searchField['label']}
                                              name={searchField['name']}
                                              type={searchField['type']}
                                              placeholder={''}
                                              options={searchField['options']}
                                              invalid={{ errors, touched }}
                                              value={
                                                values[
                                                  searchField['name']
                                                ] !== undefined
                                                  ? values[
                                                  searchField['name']
                                                  ]
                                                  : searchField?.options
                                                    ?.defaultDate
                                              }
                                              required={
                                                searchField['isRequired']
                                              }
                                              setData={(data: any) => {
                                                if (
                                                  searchField?.options
                                                    ?.mode === 'range'
                                                ) {
                                                  setFieldValue(
                                                    searchField['name'],
                                                    data[0],
                                                  );
                                                  setFieldValue(
                                                    searchField['name2'],
                                                    data[1],
                                                  );
                                                } else {
                                                  setFieldValue(
                                                    searchField['name'],
                                                    data.join(',').trim(),
                                                  );
                                                }
                                              }}
                                            />
                                          </>
                                        )}

                                      {['radio', 'checkbox'].includes(
                                        searchField['type'],
                                      ) && (
                                          <DaynamicSearchInputField
                                            label={searchField['label']}
                                            name={searchField['name']}
                                            type={searchField['type']}
                                            placeholder={''}
                                            options={searchField['data']}
                                            dataApi={searchField['dataApi']}
                                            value={
                                              values[searchField['name']] !==
                                                undefined
                                                ? values[searchField['name']]
                                                : searchField['default']
                                            }
                                            isMulti={searchField['isMulti']}
                                            required={
                                              searchField['isRequired']
                                            }
                                            invalid={{ errors, touched }}
                                            onChange={(data: any) => {
                                              let selectedValue =
                                                data.target.value.toString();
                                              if (
                                                searchField['type'] ===
                                                'checkbox'
                                              ) {
                                                let isChecked =
                                                  data.target.checked;
                                                let oldValue =
                                                  values[searchField['name']];

                                                let getIndex =
                                                  oldValue.indexOf(
                                                    selectedValue,
                                                  );
                                                if (
                                                  isChecked &&
                                                  getIndex === -1
                                                ) {
                                                  oldValue.push(
                                                    selectedValue,
                                                  );
                                                } else if (
                                                  !isChecked &&
                                                  getIndex > -1
                                                ) {
                                                  oldValue.splice(
                                                    getIndex,
                                                    1,
                                                  );
                                                }

                                                setFieldValue(
                                                  searchField['name'],
                                                  oldValue,
                                                );
                                              } else {
                                                setFieldValue(
                                                  searchField['name'],
                                                  selectedValue,
                                                );
                                              }
                                            }}
                                          />
                                        )}

                                      {![
                                        'select',
                                        'radio',
                                        'checkbox',
                                        'date',
                                      ].includes(searchField['type']) && (
                                          <DaynamicSearchInputField
                                            label={searchField['label']}
                                            type={searchField['type']}
                                            placeholder={searchField['label']}
                                            required={
                                              searchField['isRequired']
                                            }
                                            invalid={{ errors, touched }}
                                            {...getFieldProps(
                                              searchField['name'],
                                            )}
                                          />
                                        )}
                                    </>
                                  </FormGroup>
                                );
                              }
                            },
                          )}
                        <FormGroup className="btn-column">
                          <Button
                            color="danger"
                            type="reset"
                            text="Reset"
                            onClick={() => {
                              resetSearchForm();
                              handleSearchSubmit();
                            }}
                          />
                          <Button
                            type="button"
                            text="Search"
                            onClick={() => {
                              handleSearchSubmit();
                            }}
                          />
                          {formObj?.showCreateNewBtn && (
                            <Button
                              color="success"
                              type="button"
                              text={formObj.createNewBtnTitle || 'Create New'}
                              onClick={() => {
                                history.push(`${formObj.addUrl}`);
                                // handleSearchSubmit();
                              }}
                            />
                          )}

                        </FormGroup>
                      </div>
                    </Form>
                  </>
                )}
                {formObj?.addUrl &&
                  formObj?.showCreateNewBtn &&
                  inputSearchFields.length === 0 && (
                    <Col
                      md={
                        formObj?.showSearchForm && inputSearchFields.length > 0
                          ? ''
                          : 12
                      }
                      sm={12}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <div className="mt-2">
                        <Link
                          to={formObj.addUrl}
                          className="btn btn-success waves-effect waves-light"
                        >
                          <i className="fa fa-plus me-2" />{' '}
                          {formObj.createNewBtnTitle || 'Create New'}
                        </Link>
                      </div>
                    </Col>
                  )}
              </Row>
              {data?.total !== undefined && columns.length > 0 && (
                <DataTable
                  columns={columns}
                  data={data?.data ? data.data : []}
                  pagination
                  paginationServer
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(newPerPage: number, page: number) => {
                    setLimit(newPerPage);
                    setPage(page);
                  }}
                  progressPending={dataListLoading}
                  paginationTotalRows={data?.total}
                  expandOnRowClicked
                  highlightOnHover
                  paginationRowsPerPageOptions={
                    config.paginationRowsPerPageOptions
                  }
                  persistTableHead
                />
              )}

              {/* {data?.total === undefined && columns.length > 0 && (
                <DataTable
                  columns={columns}
                  data={data.data}
                  pagination
                  progressPending={dataListLoading}
                  paginationTotalRows={data.data?.length}
                  highlightOnHover
                  paginationRowsPerPageOptions={config.paginationRowsPerPageOptions}
                />
              )} */}
            </CardBody>
          </Card>
          <Modal
            onOpened={function () { }}
            isOpen={showModal}
            scrollable={false}
            size="lg"
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                approvalHandleSubmit();
              }}
              onReset={(e) => {
                e.preventDefault();
                approvalResetForm();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {submitFormType} Details Info
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShowModal(false);
                  }}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div style={{ maxHeight: submitFormType.toLowerCase() !== 'view' ? '50vh' : '', overflow: 'hidden', overflowY: 'auto', }} >
                  <DynamicView viewColumn={viewColumn} viewData={viewData} />
                </div>
                {submitFormType.toLowerCase().trim() === 'examinner' && (<>
                  <div className="align-items-center justify-content-center mt-3">

                    <div className="card ">
                      <div className="card-header text-center fw-bold text-dark bg-light py-2">Marking For Thesis</div>
                      <div className="card-body">
                        <Row>
                          {inputFields.length > 0 &&
                            inputFields.map((field: any, key: any) => {
                              if (!['hidden', 'submit'].includes(field['type'])) {
                                field['type'] = field['type'];

                                if (
                                  field['dependField'] &&
                                  field['dependValue'] &&
                                  field['condition']
                                ) {
                                  let isMatch = true;
                                  switch (field['condition']) {
                                    case '===':
                                      isMatch =
                                        approvalValues[field['dependField']] ===
                                        field['dependValue'];
                                      break;

                                    case '!==':
                                      isMatch =
                                        approvalValues[field['dependField']] !==
                                        field['dependValue'];
                                      break;

                                    case 'includes':
                                      isMatch = field['dependValue'].includes(
                                        approvalValues[field['dependField']],
                                      );
                                      break;

                                    default:
                                      break;
                                  }
                                  if (!isMatch) {
                                    if (approvalValues[field['name']] !== field['default']) {
                                      setFormValue(field['name'], field['default']);
                                    }
                                    return <></>;
                                  }
                                }

                                return (
                                  <>
                                    <Col
                                      key={field['name'] + key}
                                      md={field['colSize']}
                                      sm={12}
                                    >
                                      <FormGroup className="mb-3">
                                        <>
                                          {['richtext'].includes(
                                            field['type'],
                                          ) && (
                                              <div className="accordion-item">
                                                <h2
                                                  className="accordion-header"
                                                  id={'heading-' + field['name']}
                                                >
                                                  <button
                                                    className="accordion-button text-capitalize"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={
                                                      '#collapse-' + field['name']
                                                    }
                                                    aria-expanded="true"
                                                    aria-controls="collapseOne"
                                                    onClick={(e: any) => {
                                                      let bsTarget =
                                                        e.target?.dataset
                                                          ?.bsTarget || '';

                                                      let selectorTarget =
                                                        document.querySelector(
                                                          bsTarget,
                                                        );

                                                      if (
                                                        selectorTarget.classList.contains(
                                                          'show',
                                                        )
                                                      ) {
                                                        selectorTarget.classList.remove(
                                                          'show',
                                                        );
                                                      } else {
                                                        selectorTarget.className +=
                                                          ' show';
                                                      }
                                                    }}
                                                  >
                                                    <Label
                                                      className={
                                                        field['isRequired']
                                                          ? 'form-label me-2 required-field text-capitalize'
                                                          : 'form-label me-2 text-capitalize'
                                                      }
                                                    >
                                                      {field['label']}
                                                    </Label>
                                                  </button>
                                                </h2>
                                                <div
                                                  id={'collapse-' + field['name']}
                                                  className="accordion-collapse collapse "
                                                  aria-labelledby={
                                                    'heading-' + field['name']
                                                  }
                                                  data-bs-parent="#accordionExample"
                                                >
                                                  <div className="accordion-body">
                                                    <DaynamicInputField
                                                      label={field['label']}
                                                      type={field['type']}
                                                      placeholder={
                                                        field['label']
                                                      }
                                                      required={
                                                        field['isRequired']
                                                      }
                                                      invalid={{
                                                        errors,
                                                        touched,
                                                      }}
                                                      setData={(
                                                        data: any,
                                                      ) => {
                                                        setFormValue(
                                                          field['name'],
                                                          data,
                                                        );
                                                      }}
                                                      {...getFieldProps(
                                                        field['name'],
                                                      )}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}

                                          {['modal'].includes(field['type']) && (
                                            <DaynamicInputField
                                              label={field['label']}
                                              name={field['name']}
                                              type={field['type']}
                                              placeholder={field['label']}
                                              options={field['data']}
                                              dataApi={field['dataApi']}
                                              value={
                                                approvalValues[field['name']] !== undefined
                                                  ? approvalValues[field['name']]
                                                  : field['default']
                                              }
                                              isMulti={field['isMulti']}
                                              required={field['isRequired']}
                                              invalid={{ errors, touched }}
                                              onChange={(
                                                data: any,
                                                isSelected: boolean = false,
                                              ) => {
                                                if (data === null) {
                                                  setFormValue(field['name'], '');
                                                  field['data'] = [];
                                                } else if (
                                                  data &&
                                                  typeof data === 'string'
                                                ) {
                                                  let isSelected =
                                                    approvalValues[field['name']].includes(
                                                      data,
                                                    );

                                                  if (isSelected) {
                                                    setFormValue(field['name'], '');
                                                    field['data'] = [];
                                                  } else {
                                                    setFormValue(field['name'], data);
                                                    field['data'] = [data];
                                                  }
                                                } else if (
                                                  data &&
                                                  typeof data === 'object' &&
                                                  data?.length === undefined
                                                ) {
                                                  let isSelected =
                                                    approvalValues[field['name']] ===
                                                    data?.value;

                                                  if (isSelected) {
                                                    setFormValue(field['name'], '');
                                                    field['data'] = [];
                                                  } else {
                                                    setFormValue(
                                                      field['name'],
                                                      data?.value || '',
                                                    );
                                                    field['data'] = data;
                                                  }
                                                } else if (
                                                  data &&
                                                  typeof data === 'object' &&
                                                  data?.length > 0
                                                ) {
                                                  if (!isSelected) {
                                                    let filterData = field[
                                                      'data'
                                                    ].filter((item: any) => {
                                                      return (
                                                        item.value !== data?.[0].value
                                                      );
                                                    });

                                                    setFormValue(
                                                      field['name'],
                                                      filterData.map(
                                                        (item: any) => item.value,
                                                      ),
                                                    );
                                                    field['data'] = filterData;
                                                  } else {
                                                    setFormValue(
                                                      field['name'],
                                                      data.map(
                                                        (item: any) => item.value,
                                                      ),
                                                    );
                                                    field['data'] = data;
                                                  }
                                                }
                                              }}
                                            />
                                          )}

                                          {['select'].includes(field['type']) && (
                                            <DaynamicInputField
                                              label={field['label']}
                                              name={field['name']}
                                              type={field['type']}
                                              placeholder={field['label']}
                                              options={field['data']}
                                              dataApi={field['dataApi']}
                                              value={
                                                approvalValues[field['name']] !== undefined
                                                  ? approvalValues[field['name']]
                                                  : field['default']
                                              }
                                              isMulti={field['isMulti']}
                                              required={field['isRequired']}
                                              invalid={{ errors, touched }}
                                              onChange={(data: any) => {
                                                if (data === null) {
                                                  setFormValue(field['name'], '');
                                                } else if (
                                                  data &&
                                                  data?.length === undefined &&
                                                  data.value !== undefined
                                                ) {
                                                  setFormValue(
                                                    field['name'],
                                                    data.value,
                                                  );
                                                } else if (
                                                  data &&
                                                  data?.length !== undefined
                                                ) {
                                                  setFormValue(
                                                    field['name'],
                                                    data.map((item: any) => item.value),
                                                  );
                                                }
                                              }}
                                            />
                                          )}

                                          {['date'].includes(field['type']) && (
                                            <>
                                              <DaynamicInputField
                                                label={field['label']}
                                                name={field['name']}
                                                type={field['type']}
                                                placeholder={field['label']}
                                                options={field['options']}
                                                invalid={{ errors, touched }}
                                                value={
                                                  approvalValues[field['name']] !== undefined
                                                    ? approvalValues[field['name']]
                                                    : field?.options?.defaultDate
                                                }
                                                required={field['isRequired']}
                                                setData={(data: any) => {
                                                  if (
                                                    field?.options?.mode === 'range'
                                                  ) {
                                                    setFormValue(
                                                      field['name'],
                                                      data[0],
                                                    );
                                                    setFormValue(
                                                      field['name2'],
                                                      data[1],
                                                    );
                                                  } else {
                                                    setFormValue(
                                                      field['name'],
                                                      data.join(',').trim(),
                                                    );
                                                  }
                                                }}
                                              />
                                            </>
                                          )}

                                          {['radio', 'checkbox'].includes(
                                            field['type'],
                                          ) && (
                                              <div className='mt-4'>
                                                <DaynamicInputField
                                                  label={field['label']}
                                                  name={field['name']}
                                                  type={field['type']}
                                                  placeholder={field['label']}
                                                  options={field['data']}
                                                  dataApi={field['dataApi']}
                                                  value={
                                                    approvalValues[field['name']] !== undefined
                                                      ? approvalValues[field['name']]
                                                      : field['default']
                                                  }
                                                  isMulti={field['isMulti']}
                                                  required={field['isRequired']}
                                                  invalid={{ errors, touched }}
                                                  onChange={(data: any) => {
                                                    let selectedValue =
                                                      data.target.value.toString();
                                                    if (field['type'] === 'checkbox') {
                                                      let isChecked = data.target.checked;
                                                      let oldValue = approvalValues[field['name']];

                                                      let getIndex =
                                                        oldValue.indexOf(selectedValue);
                                                      if (isChecked && getIndex === -1) {
                                                        oldValue.push(selectedValue);

                                                        if (field['name'] === 'attendance') {
                                                          setMarksDisabled(false);
                                                        }
                                                      } else if (
                                                        !isChecked &&
                                                        getIndex > -1
                                                      ) {
                                                        oldValue.splice(getIndex, 1);

                                                        if (field['name'] === 'attendance') {
                                                          setMarksDisabled(true);
                                                        }
                                                      }

                                                      setFormValue(
                                                        field['name'],
                                                        oldValue,
                                                      );
                                                    } else {
                                                      setFormValue(
                                                        field['name'],
                                                        selectedValue,
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            )}

                                          {![
                                            'richtext',
                                            'modal',
                                            'select',
                                            'radio',
                                            'checkbox',
                                            'date',
                                          ].includes(field['type']) && (
                                              <DaynamicInputField
                                                label={field['label']}
                                                type={field['type']}
                                                placeholder={field['label']}
                                                required={field['isRequired']}
                                                readOnly={field['name'] === 'thesis_writing_mark' || field['name'] === 'thesis_defence_mark' ? marksDisabled : false}
                                                invalid={{ errors, touched }}
                                                {...approvalGetFieldProps(field['name'])}
                                              />
                                            )}
                                        </>
                                      </FormGroup>
                                    </Col>
                                  </>
                                );
                              }
                            })}
                          {result ?
                            <Col
                              md={4}
                              sm={12}
                              className='mt-5'
                            >
                              <p className='font-size-14 border border-2 p-2 text-center'>
                                {result === 'Passed' && <b className='text-success'>{result}</b>}
                                {result === 'Failed' && <b className='text-danger'>{result}</b>}
                                {result === 'Absent' && <b className='text-danger'>{result}</b>}
                              </p>
                            </Col>
                            :
                            null}
                        </Row>
                      </div>
                    </div>

                  </div>
                </>)}

                {/* <pre>{JSON.stringify(approvalValues, null, 2)}</pre> */}
              </div>
              <div className="modal-footer">
                {showForm && submitFormType.toLowerCase() !== 'view' && (
                  <Button
                    type="submit"
                    text="Save"
                    className="btn btn-success"

                  ></Button>
                )}
                <Button
                  type="button"
                  text="Close"
                  className="btn btn-danger"
                  onClick={() => {
                    setShowModal(!showModal);
                  }}
                ></Button>
              </div>
            </Form>
          </Modal>

          <Modal
            onOpened={function () { }}
            isOpen={showReportModal}
            scrollable={false}
            size="xl"
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                approvalHandleSubmit();
              }}
              onReset={(e) => {
                e.preventDefault();
                approvalResetForm();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {submitFormType} Details Info
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setDownloadReportPdf(false);
                    setShowReportModal(false);
                  }}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">

                <div className='p-3' style={{ maxHeight: submitFormType.toLowerCase() !== 'view' ? '50vh' : '', overflow: 'hidden', overflowY: 'auto', }} >
                  <div className='row'>
                    <div id="pdfHtmlData">
                      <div className='col-md-12' style={{ textAlign: 'center' }}>
                        <h4>FCPS Examination, {moment(viewData?.exam_date).format("MMMM YYYY")}</h4>
                      </div>
                      <style>
                        {`
                        .text-center {
                          text-align: center;
                        }
                        strong {
                          font-weight: bold;
                        }
                      `}
                      </style>
                      <div className='col-md-12 text-center'>
                        {/* <b>Mark Sheet {viewData?.title?.category ? "for " + viewData?.title?.category : ""}  {viewData?.title?.title_of_thesis ? "for " + viewData?.title?.title_of_thesis : ""}</b> */}
                        <h4>Mark Sheet for Thesis Defense for Specialized Subjects</h4>
                        <hr />
                      </div>

                      {/* <div className='col-md-12'>
                      <strong>Title of thesis :</strong> {viewData?.title?.title_of_thesis || ""}
                    </div> */}
                      <div className='col-md-12' style={{ marginTop: '25px' }}>
                        <strong>Trainee name :</strong> {viewData?.trainee_name || ""}
                      </div>
                      <div className='col-md-12'>
                        <strong>BMDC Reg. :</strong> {viewData?.bmdc_registration_no || ""}
                      </div>
                      <div className='col-md-12'>
                        <strong>Subject name :</strong> {viewData?.subject_name || ""}
                      </div>
                      <div className='col-md-12'>
                        <strong>Centre :</strong> BCPS
                        {/* {viewData?.title?.place_of_study || ""} */}
                      </div>
                      <div className='col-md-12'>
                        <strong>Exam Date :</strong> {moment(viewData?.exam_date).format("DD, MMMM YYYY")}
                      </div>
                      <div className='col-md-12'>
                        <strong>Thesis Title :</strong> {viewData?.title?.title_of_thesis || ""}
                      </div>



                      {viewData?.examiners?.length > 0 && (
                        <div className='col-md-12 mt-3' style={{ marginTop: '25px' }}>
                          <table className='table table-sm table-bordered border-dark'>
                            <thead className='text-center'>
                              {/* <tr>
                              <th>Thesis Title</th>
                              <td colSpan={4} style={{ textAlign: 'left' }}>:&nbsp;{viewData?.title?.title_of_thesis || ""}</td>
                            </tr> */}
                              <tr>
                                <th>Trainee name</th>
                                <th>Thesis Writing=50<br />(Pass Mark=30)</th>
                                <th>Thesis Defence=50<br />(Pass Mark=30)</th>
                                <th>Total Marks=100<br />(Pass Mark=60)</th>
                                {/* <th>Result</th> */}
                              </tr>
                            </thead>
                            <tbody className='text-center'>
                              <tr>
                                <th>{viewData?.trainee_name || ""}</th>
                                <th>{viewData?.examiners?.find((examiner: any) => examiner.examiner_id === store.getState().auth.user.id)?.thesis_writing_mark || 0}</th>
                                <th>{viewData?.examiners?.find((examiner: any) => examiner.examiner_id === store.getState().auth.user.id)?.thesis_defence_mark || 0}</th>
                                <th>{parseInt(viewData?.examiners?.find((examiner: any) => examiner.examiner_id === store.getState().auth.user.id)?.thesis_writing_mark || 0) + parseInt(viewData?.examiners?.find((examiner: any) => examiner.examiner_id === store.getState().auth.user.id)?.thesis_defence_mark || 0)}</th>
                                {/* <th>{viewData?.result || ""}</th> */}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      <div className="col-md-12">
                        <div className="row">
                          {viewData?.examiners && viewData?.examiners?.filter((examiner: any) => examiner.examiner_id === store.getState().auth.user.id)?.map((mark: any) => {
                            return (
                              <div key={"signature-" + mark.id} style={{ width: '200px' }} className="m-1 p-2  text-center">
                                <div style={{ marginTop: '50px', }}><hr style={{ border: '1px solid #222', }} /></div>
                                <div>{mark?.examiner_name || ""}</div>
                                <div>Examiner</div>
                              </div>
                            )
                          })
                          }

                        </div>
                      </div>
                    </div>

                    {viewData?.result === 'Passed' ?
                      <div className="col-sm-12">
                        <>
                          {/* <h6 className="block-heading">Approval Status</h6> */}

                          <table className="table table-bordered table-sm mt-2">
                            <tbody>
                              <tr>
                                <th>Status</th>
                                <td>
                                  <div>
                                    <FormGroup check inline className="mb-3 ">
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="status"
                                        value={acceptedValues.status}
                                        checked={acceptedValues.status === 'Approve'}
                                        id="approve"
                                        onChange={() => {
                                          acceptedSetFieldValue('status', 'Approve');
                                        }}
                                      />
                                      <label
                                        className="form-check-label text-success"
                                        htmlFor="approve"
                                      >
                                        Accepted
                                      </label>
                                    </FormGroup>

                                    <FormGroup check inline className="mb-3">
                                      <input
                                        className="form-check-input mt-2"
                                        type="radio"
                                        name="status"
                                        value={acceptedValues.status}
                                        checked={acceptedValues.status === 'Correction'}
                                        id="reject"
                                        onChange={() => {
                                          acceptedSetFieldValue('status', 'Correction');
                                        }}
                                      />
                                      <label
                                        className="form-check-label text-danger"
                                        htmlFor="reject"
                                      >
                                        Accepted after correction within
                                        <input
                                          className="form-control-sm mx-2"
                                          type="number"
                                          name="numberOfDays"
                                          id="numberOfDays"
                                          onChange={() => {
                                            // approvalSetFieldValue('status', 'Reject');
                                          }}
                                        />
                                        days
                                      </label>
                                    </FormGroup>
                                  </div>
                                </td>
                              </tr>

                              <tr>
                                <th style={{ width: '30%' }}>
                                  {acceptedValues.status} Note
                                </th>
                                <td>
                                  <InputField
                                    type="textarea"
                                    placeholder={'Enter ' + acceptedValues.status + ' Note'}
                                    {...acceptedGetFieldProps('message')}
                                  />
                                  {acceptedErrors.message && acceptedErrors.message ? (
                                    <div className="text-danger error-size">{acceptedErrors.message as string}</div>
                                  ) : null}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </>
                      </div>
                      :
                      <>
                      </>}
                  </div>

                  {/* <pre>{JSON.stringify(viewData, null, 2)}</pre> */}
                </div>

              </div>
              <div className="modal-footer">
                {/* ReportPDF */}
                {downloadReportPdf ? (
                  <ReportPDF htmlData={pdfHtmlData} setDownloadReportPdf={setDownloadReportPdf} />
                ) : ""}
                <Button
                  type="button"
                  text="Download"
                  className="btn btn-success"
                  onClick={() => {
                    setDownloadReportPdf(true);
                    let pdfHtmlData = document.getElementById("pdfHtmlData")?.innerHTML;
                    setPdfHtmlData(pdfHtmlData);
                  }}
                ></Button>
                <Button
                  type="button"
                  text="Close"
                  className="btn btn-danger"
                  onClick={() => {
                    setDownloadReportPdf(false);
                    setShowReportModal(false);
                  }}
                ></Button>
              </div>
            </Form>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default ExaminerMarkingForThesis;
