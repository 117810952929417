import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useAppSelector } from 'src/rtk/app/hook';
import { useCreateInspectionRecomendationMutation, useGetMemberRecomendationCheckMutation, useLazyGetAssignedDepartmentForViewQuery } from 'src/rtk/features/instituteInspection/institutueInspectionApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetInstituteNameQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import MembersRecomendedDataModal from './MembersRecomendedDataModal';


const RecomendationForm = () => {

    const authUser = useAppSelector((state) => state.auth.user);
    // console.log(authUser);
    const history = useHistory();

    const [selectedDepartment, setSelectedDepartment] = useState<any>('');

    const [trainingYears, setTrainingYears] = useState<any>('');

    const [status, setStatus] = useState<boolean>(false);
    const [isMemberFound, setIsMemberFound] = useState<boolean>(true);
    const [modalShow, setModalShow] = useState<boolean>(false);

    const { id: teamId, insId: instituteId, insLocation: instituteLocation, date: inspectionDate, chairman: chairmanId } = useParams<{
        id: string;
        insId: string;
        insLocation: string;        
        date: string;
        chairman: string;
    }>();

    const [getAssignedDepartmentForView, { data: list, isSuccess: isListSuccess }] = useLazyGetAssignedDepartmentForViewQuery();

    useEffect(() => {
        if (instituteId !== '' && teamId !== '') {
            const url = {
                id: teamId,
                institute_id: instituteId,
                inspection_date: inspectionDate
            };
            getAssignedDepartmentForView(url);
        }
    }, [teamId, instituteId, inspectionDate]);


    const { data: justification } = useGetLookupDataQuery('justification_of_recommendation');
    const { data: years } = useGetLookupDataQuery('period_of_training_in_year');
    const { data: months } = useGetLookupDataQuery('period_of_training_in_month');

    const { data: instituteLookupData } = useGetInstituteNameQuery();

    const instituteName = instituteLookupData?.find((item: any) => item.id === instituteId);

    const allJustification: Lookup[] =
        (justification as any)?.data[0]?.childs || [];

    const yearsLookup: Lookup[] =
        (years as any)?.data[0]?.childs || [];

    const allYears: SelectOption[] = yearsLookup?.map((year) => ({
        value: year.name,
        label: year.name + ' Years',
    })) || [];

    const monthsLookup: Lookup[] =
        (months as any)?.data[0]?.childs || [];

    const allMonths: SelectOption[] = monthsLookup?.map((month) => ({
        value: month.name,
        label: month.name + ' Months',
    })) || [];

    const [createInspectionRecomendation, { isSuccess, isError, error, isLoading }] = useCreateInspectionRecomendationMutation();

    const [getMemberRecomendationCheck, { data: checkData, isSuccess: isMemberSuccess, isError: isMemberError, error: isMessageError, isLoading: isMemberLoading }] = useGetMemberRecomendationCheckMutation();

    const allDepartment: SelectOption[] =
        list?.data[0]?.assigned_departments?.map((department: any) => ({
            value: department?.department_id!,
            label: department?.department?.department_name!,
        })) || [];

    const err: CustomError = error as CustomError;
    const err1: CustomError = isMessageError as CustomError;

    const initialValues: RecomendationForm = {
        team_creation_id: '',
        institute_id: '',
        form_b_department_id: '',
        previous_recognition_status: '',
        period_of_accepted_training_in_year: '',
        period_of_accepted_training_in_month: '',
        period_of_recognition: '',
        starting_date_of_recognition: '',
        validity_date_of_recognition: '',
        overall_patient_load: '',
        in_patient: '',
        out_patient: '',
        practical_workload: '',
        availibility_of_investigative_service: '',
        availibility_of_other_support_service: '',
        number_and_status_of_trainer: '',
    }

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            institute_id: Yup.string().required('Institute is Required!'),
            // form_b_department_id: Yup.string().required('Department is Required!'),
            previous_recognition_status: Yup.string().required('Recognition Status is Required!'),
            period_of_accepted_training_in_year: Yup.string().required('Period of Training is Required!'),
            starting_date_of_recognition: Yup.string().required('Starting Date is Required!'),
        }),

        onSubmit: (values: any) => {
            values.team_creation_id = teamId;
            values.period_of_recognition = '5';
            createInspectionRecomendation(values);
            console.log(values);
        },
    });


    const handleFirstPerformance = (e: any, index: number) => {
        if (e.target.checked) {
            if (index === 0) {
                setFieldValue('overall_patient_load', 'Adequate');
            }
            if (index === 1) {
                setFieldValue('in_patient', 'Adequate');
            }
            if (index === 2) {
                setFieldValue('out_patient', 'Adequate');
            }
            if (index === 3) {
                setFieldValue('practical_workload', 'Adequate');
            }
            if (index === 4) {
                setFieldValue('availibility_of_investigative_service', 'Satisfactory');
            }
            if (index === 5) {
                setFieldValue('availibility_of_other_support_service', 'Satisfactory');
            }
            if (index === 6) {
                setFieldValue('number_and_status_of_trainer', 'Adequate');
            }
        }
    }

    const handleSecondPerformance = (e: any, index: number) => {
        if (e.target.checked) {
            if (index === 0) {
                setFieldValue('overall_patient_load', 'Inadequate');
            }
            if (index === 1) {
                setFieldValue('in_patient', 'Inadequate');
            }
            if (index === 2) {
                setFieldValue('out_patient', 'Inadequate');
            }
            if (index === 3) {
                setFieldValue('practical_workload', 'Inadequate');
            }
            if (index === 4) {
                setFieldValue('availibility_of_investigative_service', 'Unsatisfactory');
            }
            if (index === 5) {
                setFieldValue('availibility_of_other_support_service', 'Unsatisfactory');
            }
            if (index === 6) {
                setFieldValue('number_and_status_of_trainer', 'Inadequate');
            }
        }
    }

    const handleFirstPerformanceChecked = (index: number) => {
        if (index === 0) {
            return values.overall_patient_load === 'Adequate';
        }
        if (index === 1) {
            return values.in_patient === 'Adequate';
        }
        if (index === 2) {
            return values.out_patient === 'Adequate';
        }
        if (index === 3) {
            return values.practical_workload === 'Adequate';
        }
        if (index === 4) {
            return values.availibility_of_investigative_service === 'Satisfactory';
        }
        if (index === 5) {
            return values.availibility_of_other_support_service === 'Satisfactory';
        }
        if (index === 6) {
            return values.number_and_status_of_trainer === 'Adequate';
        }

    }

    const handleSecondPerformanceChecked = (index: number) => {
        if (index === 0) {
            return values.overall_patient_load === 'Inadequate';
        }
        if (index === 1) {
            return values.in_patient === 'Inadequate';
        }
        if (index === 2) {
            return values.out_patient === 'Inadequate';
        }
        if (index === 3) {
            return values.practical_workload === 'Inadequate';
        }
        if (index === 4) {
            return values.availibility_of_investigative_service === 'Unsatisfactory';
        }
        if (index === 5) {
            return values.availibility_of_other_support_service === 'Unsatisfactory';
        }
        if (index === 6) {
            return values.number_and_status_of_trainer === 'Inadequate';
        }

    }


    useEffect(() => {
        // console.log(selectedDepartment?.value);
        if (selectedDepartment !== '') {
            setFieldValue('form_b_department_id', selectedDepartment?.value);
        }
    }, [selectedDepartment]);

    useEffect(() => {
        setFieldValue('form_b_department_id', '');
    }, []);

    useEffect(() => {
        if (values.institute_id !== undefined && values.institute_id !== '' && values.form_b_department_id !== undefined && values.form_b_department_id !== '' && teamId !== '') {
            console.log(list?.data?.length);
            getMemberRecomendationCheck(`team_creation_id=${teamId}&institute_id=${values.institute_id}&form_b_department_id=${values.form_b_department_id}`)
        }
        else if (values.institute_id !== undefined && values.form_b_department_id !== undefined && teamId !== '' && list?.data[0]?.total_department === 0) {
            console.log(list?.data?.length);
            getMemberRecomendationCheck(`team_creation_id=${teamId}&institute_id=${values.institute_id}&form_b_department_id=${values.form_b_department_id}`)
        }
    }, [values.institute_id, values.form_b_department_id, teamId]);


    useEffect(() => {
        if (isMemberSuccess) {
            setIsMemberFound(true);
            setModalShow(true);
        }
        if (isMemberError) {
            toast.error(err1?.message);
            setIsMemberFound(false);
        }
    }, [isMemberSuccess, isMemberError, checkData]);


    useEffect(() => {
        if (isSuccess) {
            resetForm();
            setSelectedDepartment('');
            toast.success('Information Stored Successfully!');
            history.push('/inspection-recomendation-list');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    const handleReset = (e: any) => {
        resetForm({
            values: {
                institute_id: '',
                form_b_department_id: '',
                previous_recognition_status: '',
                period_of_accepted_training_in_year: '',
                period_of_accepted_training_in_month: '',
                period_of_recognition: '',
                starting_date_of_recognition: '',
                validity_date_of_recognition: '',
                overall_patient_load: '',
                in_patient: '',
                out_patient: '',
                practical_workload: '',
                availibility_of_investigative_service: '',
                availibility_of_other_support_service: '',
                number_and_status_of_trainer: '',
            }
        });
        setSelectedDepartment('');
        setStatus(false);
    }

    useEffect(() => {
        setFieldValue('overall_patient_load', 'Adequate');
        setFieldValue('in_patient', 'Adequate');
        setFieldValue('out_patient', 'Adequate');
        setFieldValue('practical_workload', 'Adequate');
        setFieldValue('availibility_of_investigative_service', 'Satisfactory');
        setFieldValue('availibility_of_other_support_service', 'Satisfactory');
        setFieldValue('number_and_status_of_trainer', 'Adequate');
    }, []);

    const [toDate, setToDate] = useState<Date | null>(null);

    const handleFromDateChange = (event: any) => {
        const fromDate = new Date(event.target.value);
        // const newToDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 6, fromDate.getDate());
        const newToDate = new Date(fromDate);
        newToDate.setFullYear(fromDate.getFullYear() + 5);
        setToDate(newToDate);
    }

    const handleTrainingYear = (e: any) => {
        // e.target.value === '0' ? setTrainingYears('6') : setTrainingYears(e.target.value);
        if (e.target.value === '0') {
            values.period_of_accepted_training_in_month = '6';
            setStatus(true);
        }
        else if (e.target.value === '5') {
            values.period_of_accepted_training_in_month = '0';
            setStatus(true);
        }
        else {
            values.period_of_accepted_training_in_month = '';
            setStatus(false);
        }
    }

    return (
        <div className='page-content'>
            {isLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    title="Recomendation Form"
                    breadcrumbItem={[
                        { link: '#', name: 'Recomendation' },
                        { link: '/inspection-recomendation-list', name: 'Recomendation List' },
                    ]}
                />
                <Form
                    className="custom-form"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                    }}
                    onReset={(e) => {
                        e.preventDefault();
                        handleReset(e);
                        console.log(values);
                    }}
                >
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="institute_id" className='form-label required-field'>Name Of Institute</Label>
                                        <Input
                                            type="select"
                                            name='institute_id'
                                            value={values.institute_id = instituteName?.id}
                                            readOnly
                                            disabled
                                        >
                                            {instituteLookupData?.map((item) => (
                                                <option key={item.id} value={item.id}>
                                                    {item.institute_name}
                                                </option>
                                            ))}
                                        </Input>
                                        {errors.institute_id && touched.institute_id ? (
                                            <div className="text-danger">{errors.institute_id as string}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="form_b_department_id">Name Of Department</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedDepartment}
                                            onChange={setSelectedDepartment}
                                            options={allDepartment}
                                            placeholder='Select Department'
                                            name="form_b_department_id"
                                        />
                                        {errors.form_b_department_id && touched.form_b_department_id ? (
                                            <div className="text-danger">{errors.form_b_department_id as string}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="previous_recognition_status" className='form-label required-field'>Previous Status Of Recognition</Label>
                                        <InputField
                                            type="text"
                                            placeholder="Recognition Status"
                                            {...getFieldProps('previous_recognition_status')}
                                        />
                                        {errors.previous_recognition_status && touched.previous_recognition_status ? (
                                            <div className="text-danger">{errors.previous_recognition_status as string}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Table className='mt-2'>
                                <tr>
                                    <td className='col-lg-3 col-md-3 col-sm-2'><Label className='form-label required-field'>Period of training to be accepted</Label></td>
                                    <td className='col-lg-2 col-md-2 col-sm-1'>
                                        <div className='m-0 p-0 text-center'><strong>Year</strong></div>
                                        <InputField
                                            type="select"
                                            placeholder="Period of training"
                                            name='period_of_accepted_training_in_year'
                                            value={values.period_of_accepted_training_in_year}
                                            onChange={(e) => {
                                                handleChange(e);
                                                handleTrainingYear(e);
                                            }}
                                            options={allYears}
                                        />
                                    </td>
                                    <td className='col-lg-2 col-md-2 col-sm-1'>
                                        <div className='m-0 p-0 text-center'><strong>Month</strong></div>
                                        <InputField
                                            type="select"
                                            placeholder="Period of training"
                                            name='period_of_accepted_training_in_month'
                                            // value={trainingYears === '6' ? values.period_of_accepted_training_in_month = trainingYears : values.period_of_accepted_training_in_month}
                                            value={values.period_of_accepted_training_in_month}
                                            // disabled={values.period_of_accepted_training_in_month === '0'}
                                            disabled={status}
                                            onChange={(e) => handleChange(e)}
                                            options={allMonths}
                                        />
                                    </td>
                                    <td>{errors.period_of_accepted_training_in_year && touched.period_of_accepted_training_in_year ? (
                                        <div className="text-danger">{errors.period_of_accepted_training_in_year as string}</div>
                                    ) : null}</td>
                                </tr>
                                <tr className='mt-2'>
                                    <td><Label className='form-label required-field'>Period of Recognition</Label></td>
                                    <td>
                                        <InputField
                                            type="text"
                                            placeholder="Period of Recognition"
                                            value={'5 Years'}
                                            name='period_of_recognition'
                                            readOnly
                                        />
                                    </td>
                                    <td></td>
                                </tr>
                                <tr className='mt-2'>
                                    <td><Label className='form-label required-field'>Strating Date of Recognition</Label></td>
                                    <td>
                                        <div></div>
                                        <InputField
                                            type="date"
                                            name="starting_date_of_recognition"
                                            onChange={(e) => {
                                                handleFromDateChange(e);
                                                handleChange(e);
                                            }}
                                            value={values.starting_date_of_recognition}
                                        />
                                    </td>
                                    <td>
                                        <div className='m-0 p-0 text-center'><strong>Valid Up to</strong></div>
                                        <InputField
                                            type="date"
                                            name="validity_date_of_recognition"
                                            onChange={(e) => {
                                                handleChange(e);
                                            }}
                                            value={values.validity_date_of_recognition = toDate?.toISOString().slice(0, 10) || ""}
                                            disabled
                                        />
                                    </td>
                                    <td>{errors.starting_date_of_recognition && touched.starting_date_of_recognition ? (
                                        <div className="text-danger">{errors.starting_date_of_recognition as string}</div>
                                    ) : null}</td>
                                </tr>
                            </Table>

                            <Table className='table-bordered mt-2'>
                                {allJustification.map((item: any, index: number) => (
                                    <tr key={item.id}>
                                        <td className='col-lg-2 col-md-2 col-sm-2'>
                                            <div>
                                                {item.name}
                                            </div>
                                        </td>
                                        <td className='col-lg-2 col-md-2 col-sm-2'>
                                            <div className='form-check form-check-inline m-1 p-1'>
                                                <Input
                                                    className='form-check-input'
                                                    type="radio"
                                                    placeholder=""
                                                    id={`adequate-${index}`}
                                                    name={`adequate-${index}`}
                                                    onChange={(e: any) => {
                                                        handleFirstPerformance(e, index);
                                                        handleChange(e);
                                                    }}
                                                    checked={handleFirstPerformanceChecked(index)}
                                                />
                                                <label className="form-check-label" htmlFor={`adequate-${index}`}>
                                                    {item.name === 'Availability of investigative services' || item.name === 'Availability of other support services'
                                                        ? 'Satisfactory' : 'Adequate'}
                                                </label>
                                            </div>
                                        </td>
                                        <td className='col-lg-2 col-md-2 col-sm-2'>
                                            <div className='form-check form-check-inline m-1 p-1'>
                                                <Input
                                                    className='form-check-input'
                                                    type="radio"
                                                    placeholder=""
                                                    id={`inadequate-${index}`}
                                                    name={`adequate-${index}`}
                                                    onChange={(e: any) => {
                                                        handleSecondPerformance(e, index);
                                                        handleChange(e);
                                                    }}
                                                    checked={handleSecondPerformanceChecked(index)}
                                                />
                                                <label className="form-check-label" htmlFor={`inadequate-${index}`}>
                                                    {item.name === 'Availability of investigative services' || item.name === 'Availability of other support services'
                                                        ? 'Unsatisfactory' : 'Inadequate'}
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </Table>

                            <FormGroup className="mb-3 text-center mt-2">
                                <Button
                                    type="reset"
                                    className="btn btn-secondary me-2"
                                >
                                    Reset
                                </Button>

                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={!isMemberFound}
                                >
                                    Submit
                                </button>
                            </FormGroup>
                        </CardBody>
                        {modalShow && isMemberFound && authUser?.id.toString() === chairmanId ?
                            <MembersRecomendedDataModal
                                isOpen={modalShow}
                                toggle={() => setModalShow(!modalShow)}
                                checkData={checkData?.data}
                                instituteName={instituteName?.institute_name!}
                                deparmentName={selectedDepartment?.label}
                            />
                            : null}
                    </Card>
                </Form>
            </Container>
        </div >
    );
};

export default RecomendationForm;
