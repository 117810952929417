import { useFormik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useGetAllExamInfosWithoutPaginationQuery, useGetExamInfosQuery } from 'src/rtk/features/setup/examInfo/examInfoSetupApi';
import { useCreateLogBookMasterSetupMutation } from 'src/rtk/features/setup/logBookMasterSetup/logBookMasterSetupApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';

import * as Yup from 'yup';

const initialValues: LogBookSetupMasters = {
  exam_training_id: '',
  logbook_heading: '',
  exam_name: '',
  subject_id: '',
  subject_name: '',
  logbook_type: '',
  status: false,
};

export const AddLogBookMasterSetup = () => {
  const history = useHistory();
  const [createLogBookMaster, { isSuccess, isError, error }] =
    useCreateLogBookMasterSetupMutation();

  const { data: getExamInfo } = useGetAllExamInfosWithoutPaginationQuery({
    page: 'all'
  });
  const ExamInfoList = getExamInfo?.data;

  const { data: getSubjectInfolist } = useGetSubjectInfosQuery();
  const SubjectList = getSubjectInfolist?.data;

  const err: CustomError = error as CustomError;
  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    resetForm,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      exam_training_id: Yup.string().required('Exam training is required'),
      logbook_heading: Yup.string().required('Log book heading is required'),
      exam_name: Yup.string().required('Exam name is required'),
      subject_id: Yup.string(),
      subject_name: Yup.string().required('Subject name is required'),
      logbook_type: Yup.string().required('Lookup type is required'),
      status: Yup.boolean(),
    }),

    onSubmit: (values) => {
      createLogBookMaster(values);
      console.log(values);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Log Book Master created successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const handleSubject = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    // console.log(options[selectedIndex].innerHTML, value);
    setFieldValue('subject_id', value);
    setFieldValue('subject_name', options[selectedIndex].innerHTML);
  };
  const handleExamTraining = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    // console.log(options[selectedIndex].innerHTML, value);
    setFieldValue('exam_training_id', value);
    //setFieldValue('subject_name', options[selectedIndex].innerHTML);
  };
  return (
    <div className="page-content">
      <Container fluid>
        <BetterBreadcrumb
          title="Log Book Master Setup"
          breadcrumbItem={[
            {
              link: '/Log/Book/master/Setup',
              name: 'Log Book Master Setup List',
            },
          ]}
        />
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8 col-sm-12 ">
            <div className="card mt-3">
              <h5 className="card-header">
                <i className="fa fa-plus me-2" />
                Add New
              </h5>
              <div className="card-body">
                <Form
                  autoComplete="off"
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <Row>
                    <FormGroup className="mb-3">
                      <Label for="exam_training_id">Exam Training</Label>
                      <Input
                        id="exam_training_id"
                        name="exam_training_id"
                        type="select"
                        onChange={(e) => handleExamTraining(e)}
                      >
                        <option value="">Select..</option>
                        {ExamInfoList?.map((sub) => (
                          <option key={sub.id} value={sub.id}>
                            {sub.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="subject_id">Subject</Label>
                      <Input
                        id="subject_id"
                        name="subject_id"
                        type="select"
                        onChange={(e) => handleSubject(e)}
                      >
                        <option value="">Select..</option>
                        {SubjectList?.map((sub) => (
                          <option key={sub.id} value={sub.id}>
                            {sub.subject_name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="logbook_heading">Logbook Heading</Label>
                      <InputField
                        type="text"
                        placeholder="Logbook Heading"
                        invalid={{ errors, touched }}
                        {...getFieldProps('logbook_heading')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="exam_name">Exam Name</Label>
                      <InputField
                        type="text"
                        placeholder="Exam Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('exam_name')}
                      />
                    </FormGroup>

                    <FormGroup className="mb-3">
                      <Label for="logbook_type">Log Book Type</Label>
                      <InputField
                        type="text"
                        placeholder="Log Book Type"
                        invalid={{ errors, touched }}
                        {...getFieldProps('logbook_type')}
                      />
                    </FormGroup>

                    <div className="col-md-6 col-sm-6 ">
                      <h6>Active Status</h6>
                      <FormGroup check className="mb-3">
                        <Input
                          type="checkbox"
                          role="check"
                          {...getFieldProps('status')}
                        />
                        <Label check>Active</Label>
                      </FormGroup>
                    </div>

                    <div className="text-center">
                      <Button
                        className="w-25"
                        outline
                        color="secondary"
                        type="button"
                        text="Cancel"
                        onClick={() => {
                          history.push('/Log/Book/master/Setup');
                        }}
                      />
                      <Button
                        className="w-25 ms-3"
                        type="submit"
                        text="Submit"
                      />
                    </div>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
