import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table
} from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useAppSelector } from 'src/rtk/app/hook';
import { useLazyGetCertificatePdfQuery } from 'src/rtk/features/certificate/publishCertificateApi';
import { useCreatePaymentWitoutFeesApplyMutation, useLazyGetMyTrainingQuery } from 'src/rtk/features/myTraining/myTrainingApi';
import { useGetNoticeListQuery, useLazyGetNoticeViewQuery } from 'src/rtk/features/notice/publishNoticeApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import loader from '../../assets/images/loader.gif';
import { ViewCertificatePdf } from '../Certificate/ViewCertificatePdf';
import { UpdatedViewNoticePdf } from '../Notice/UpdatedViewNoticePdf';
import ChangeSupervisorModal from './ChangeSupervisorModal';
import CloseModal from './CloseModal';
import CoreTrainingLogbookModal from './CoreTrainingLogbookModal';
import DescriptionModal from './DescriptionModal';
import DocumentUploadModal from './DocumentUploadModal';
import InstructionDeatailsModal from './InstructionDeatailsModal';
import { TrainingProgressReportPDF } from './TrainingProgressReportPDF';
import TrainingViewDeatailsModal from './TrainingViewDeatailsModal';
import WorkshopListModal from './WorkshopListModal';

const MyTraining = () => {
  document.title = 'My Training | BCPS';

  const [modalShow, setModalShow] = useState(false);
  const [changeSupervisorModalShow, setChangeSupervisorModalShow] = useState(false);
  const [documentUploadModalShow, setDocumentUploadModalShow] = useState(false);
  const [instuctionModalShow, setInstuctionModalShow] = useState(false);
  const [descriptionModalShow, setDescriptionnModalShow] = useState(false);
  const [trainingViewModalShow, setTrainingViewModalShow] = useState(false);
  const [closeModalShow, setCloseModalShow] = useState(false);
  const [courseId, setCourseId] = useState('');
  const [reportId, setReportId] = useState('');
  const [closeId, setCloseId] = useState('');
  const [trainingId, setTrainingId] = useState('');
  const [description, setDescription] = useState('');
  const [selectedWorkshop, setSelectedWorkshop] = useState<any>({});
  const [noticeId, setNoticeId] = useState<string>('');
  const [notice, setNotice] = useState<any>({});
  const [documentId, setDocumentId] = useState('');
  const [certificate, setCertificate] = useState<any>({});
  const [certificateInfoObj, setCertificateInfoObj] = useState<any>({});
  const [trainingTitleForCertificate, setTrainingTitleForCertificate] = useState<string>('');
  const [trainingDuration, setTrainingDuration] = useState<string>('');
  const [trainingDurationUnit, setTrainingDurationUnit] = useState<string>('');
  const [certificateId, setCertificateId] = useState<string>('');
  const [fcpsPartOneInfo, setFcpsPartOneInfo] = useState<any>('');
  const [fcpsPartOneInfoForChange, setFcpsPartOneInfoForChange] = useState<any>('');

  const [workShopListModalShow, setWorkShopListModalShow] = useState(false);

  const [createPaymentWitoutFeesApply, { isError, isSuccess, error, isLoading: isWithoutFeesPaymentLoading }] = useCreatePaymentWitoutFeesApplyMutation();

  const { data: personalProfile, isError: isDeptFetchError, isLoading: isProfileLoading } = useGetPersonalProfileQuery();

  const err: CustomError = error as CustomError;

  const authUser = useAppSelector((state) => state.auth.user);

  let referenceUrl = new URLSearchParams(location.search).get('ref');

  useEffect(() => {
    if (isSuccess) {
      toast.success('Applied successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (certificateId) {
      getCertificatePdf(certificateId);
    }
  }, [certificateId]);

  // const {
  //   data: trainingWorkshops,
  //   error,
  //   isLoading,
  //   isError,
  // } = useGetExamInfosQuery();

  // const trainingWorkshopList = trainingWorkshops?.data;
  // const body = {
  //   name: 'FCPS part -1',
  // }
  // const trainingTypes =
  //   useGetLookupDataQuery('training_type').data?.data[0]?.childs;

  // const [createMyTraining,{data: courseList}] = useCreateMyTrainingMutation();
  const [getMyTraining, { data: courseList, isLoading, isSuccess: isCourseListSuccess, isFetching }] = useLazyGetMyTrainingQuery();

  useEffect(() => {
    getMyTraining({ name: 'FCPS part -1' });
  }, [referenceUrl]);

  const [getCertificatePdf, { data: certificateData, isSuccess: isCertificateDataSuccess, isError: isCertificateDataError, isLoading: isCertificateDataLoading, error: certificateDataError }] = useLazyGetCertificatePdfQuery();

  const modalShowHandler = (modalState: boolean, id: string, row: any) => {
    setModalShow(modalState);
    setCourseId(id);
    setFcpsPartOneInfo(row);
  };

  const modalChangeSupervisorShowHandler = (modalState: boolean, id: string, row: any) => {
    setChangeSupervisorModalShow(modalState);
    setCourseId(id);
    setFcpsPartOneInfoForChange(row);
  };

  const instructionModalShowHandler = (modalState: boolean) => {
    setInstuctionModalShow(modalState);
  };

  const trainingDescriptionModalShowHandler = (modalState: boolean, description: string, trainingId: string) => {
    setDescriptionnModalShow(modalState);
    setDescription(description);
    setTrainingId(trainingId);
  };

  const trainingModalShowHandler = (modalState: boolean, trainingId: string) => {
    setTrainingViewModalShow(modalState);
    setTrainingId(trainingId);
  };

  const closeModalShowHandler = (modalState: boolean, closeId: string) => {
    setCloseModalShow(modalState);
    setCloseId(closeId);
  };

  const modalWorkshopApplyHandler = (modalState: boolean, workshop: any) => {
    setWorkShopListModalShow(modalState);
    setSelectedWorkshop(workshop);
  };

  const modalDocumentUploadShowHandler = (modalState: boolean, id: string) => {
    setDocumentUploadModalShow(modalState);
    setDocumentId(id);
  };

  // useEffect(() => {
  //   createMyTraining(body);
  // }, []);

  // const [getNoticePdf,{ data: noticeListData, isSuccess: isNoticeDataSuccess}] = useLazyGetNoticePdfQuery();

  // useEffect(()=>{
  //   if(noticeId !== ''){
  //     getNoticePdf(noticeId);
  //   }
  // },[noticeId]);

  // useEffect(()=>{
  //   if(isNoticeDataSuccess){
  //     setNotice(noticeListData?.data);
  //   }
  // },[isNoticeDataSuccess]);

  const {
    data: noticeListData,
    isSuccess: isNoticeListDataSuccess,
    isError: isNoticeListDataError,
    isLoading: isNoticeListDataLoading,
    error: noticeListDataError,
  } = useGetNoticeListQuery();

  const [getNoticePdf, {
    data: noticeData,
    isSuccess: isNoticeDataSuccess,
    isError: isNoticeDataError,
    isLoading: isNoticeDataLoading,
    isFetching: isNoticeDataFetching,
    error: noticeDataError,
  }] = useLazyGetNoticeViewQuery();

  useEffect(() => {
    if (isCertificateDataLoading || !isCertificateDataSuccess) {
      setCertificate({});
      setCertificateInfoObj({});
    } else {
      setCertificate(certificateData?.data);
      setCertificateInfoObj({
        name: authUser?.name,
        bmdc_reg_no: authUser?.details?.bmdc_registration_no,
        subject: authUser?.details?.subject?.subject_name,
        institute: authUser?.details?.current_session_and_supervisor_info?.supervisor?.institute_name,
        issue_date: moment(certificateData?.data?.notice_date?.substring(0, 10)).format('DD/MMM/YYYY'),
      });
    }
  }, [isCertificateDataSuccess, isCertificateDataLoading]);

  useEffect(() => {
    if (noticeId) {
      getNoticePdf(noticeId);
    }
  }, [noticeId]);

  useEffect(() => {
    if (noticeData?.id && isNoticeDataSuccess && !isNoticeDataFetching) {
      setNotice(noticeData);
    } else {
      setNotice({});
    }
  }, [noticeData, isNoticeDataSuccess, isNoticeDataFetching]);

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading || isWithoutFeesPaymentLoading || isFetching ? (
          <div className="overlay">
            <img src={loader} alt="Loading" height={100} width={300} />
          </div>
        ) : null}
        <Container fluid>
          <BetterBreadcrumb
            breadcrumbItem={[
              { link: '#', name: 'Trainee' },
            ]}
            title={'My Training'}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h5 className="block-heading mb-3">Logbook List</h5>
                  <div className="table-responsive">
                    <Table className="table table-sm training-table">
                      <thead>
                        <tr>
                          <th>Sl</th>
                          <th>Program Type</th>
                          <th>Slot</th>
                          <th>Duration</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseList?.data?.Course?.map(
                          (elmt, index: number) => {
                            return (
                              <tr key={elmt.id} className={`${elmt?.training_type === 'Advance' ? 'advance-training' : 'specialty-training'}`}>
                                <th scope="row">{index + 1}</th>
                                <td>{elmt?.training_type === 'Advance' ? 'Specialty' : elmt?.training_type}</td>
                                <td>{elmt?.name ? elmt?.name : 'N/A'}</td>


                                <td>
                                  {elmt?.session_start_date && elmt?.session_end_date ? moment(elmt?.session_start_date).format('DD/MMM/YYYY') + ' to ' + moment(elmt?.session_end_date).format('DD/MMM/YYYY') : '---'}
                                </td>
                                {/* <td>
                                  {elmt.completion_ind ? <p className="badge bg-primary">Completed</p> : <p className="badge bg-success">In Progress</p>}
                                </td> */}
                                <td>
                                  {/* {elmt.active_course && <p className="badge bg-info">Applied</p>}
                                  {elmt.completion_ind && <p className="badge bg-warning">In Progress</p>}
                                  {elmt.can_close && <p className="badge bg-primary">Completed</p>}
                                  {!elmt.active_course && !elmt.can_close && !elmt.completion_ind && <p>---</p>} */}
                                  {
                                    elmt.completion_ind && elmt.can_close
                                      ? <p className="badge bg-primary">Completed</p>
                                      : elmt.supervisors_approve_status === 'Approve'
                                        ? <p className="badge bg-warning">In Progress</p>
                                        : elmt.active_course
                                          ? <p className="badge bg-info">Applied</p>
                                          : <p>---</p>
                                  }
                                </td>

                                <td>
                                  <a type="button" rel="noreferrer" target="_blank" className="btn btn-outline-primary btn-sm me-1"
                                    href={
                                      personalProfile?.data?.details?.subject?.subject_name?.toLowerCase() === 'medicine' ?
                                        '/documents/Logbook Essential Information - Medicine.pdf'
                                        : personalProfile?.data?.details?.subject?.subject_name?.toLowerCase() === 'surgery' ?
                                          '/documents/Logbook Essential Information - Surgery.pdf'
                                          : personalProfile?.data?.details?.subject?.subject_name?.toLowerCase() === 'paediatrics' ?
                                            '/documents/Logbook Essential Information - Paediatrics.pdf'
                                            : '/documents/Logbook Essential Information - Medicine.pdf'
                                    }
                                  >
                                    Instruction
                                  </a>
                                  {/* <Button className="me-1 btn-sm" color='primary' outline onClick={() => { instructionModalShowHandler(true) }}>
                                    Instruction
                                  </Button> */}
                                  {
                                    index === 0 ?
                                      <Button
                                        type="button"
                                        color={courseList?.data?.Course[index].active_course ||
                                          courseList?.data?.Course[index].supervisors_approve_status === 'Approve' ? 'secondary' : 'success'}
                                        className="me-1 btn-sm"
                                        disabled={courseList?.data?.Course[index].active_course ||
                                          courseList?.data?.Course[index].supervisors_approve_status === 'Approve'}
                                        onClick={() => modalShowHandler(true, elmt.id!, elmt)}
                                      >
                                        Apply
                                      </Button>
                                      :
                                      <Button
                                        type="button"
                                        color={!(courseList?.data?.Course[index - 1].can_close) || courseList?.data?.Course[index].active_course || courseList?.data?.Course[index].completion_ind ? 'secondary' : 'success'}
                                        className="me-1 btn-sm"
                                        disabled={!(courseList?.data?.Course[index - 1].can_close) || courseList?.data?.Course[index].active_course || courseList?.data?.Course[index].completion_ind}
                                        onClick={() => modalShowHandler(true, elmt.id!, elmt)}
                                      >
                                        Apply
                                      </Button>
                                  }
                                  <Button
                                    type="button"
                                    color={elmt.active_course === false || elmt.completion_ind === true ||
                                      (courseList?.data?.Course[index].supervisors_approve_status !== 'Approve' &&
                                        courseList?.data?.Course[index].supervisors_approve_status == 'Pending') ? 'secondary' : 'success'}
                                    className="me-1 btn-sm"
                                    disabled={elmt.active_course === false || elmt.completion_ind === true ||
                                      (courseList?.data?.Course[index].supervisors_approve_status !== 'Approve' &&
                                        courseList?.data?.Course[index].supervisors_approve_status == 'Pending')}
                                    onClick={() => modalChangeSupervisorShowHandler(true, elmt.id!, elmt)}
                                  >
                                    Supervisor Change Request
                                  </Button>

                                  {elmt?.supervisors_approve_status === 'Approve'  && 
                                  <Button
                                    type="button"
                                    color={elmt.can_close === false && elmt.active_course === false ? 'secondary' : 'warning'}
                                    className="me-1 btn-sm"
                                    disabled={elmt.can_close === false && elmt.active_course === false}
                                    onClick={() => { closeModalShowHandler(true, elmt?.id!) }}
                                  >
                                    Complete
                                  </Button>}

                                  {elmt?.supervisors_approve_status === 'Approve' ?
                                    <Button
                                      className="btn btn-sm btn-danger me-1"
                                      onClick={() => {
                                        modalDocumentUploadShowHandler(true, elmt?.id!);
                                      }}
                                    >
                                      Document Upload
                                    </Button> : null}

                                  {elmt?.progress_report?.submit_status === 'Submit' ?
                                    <Button
                                      className="btn btn-sm btn-info me-1"
                                      disabled={elmt?.progress_report?.submit_status !== 'Submit'}
                                      onClick={() => {
                                        setReportId(elmt?.id!);
                                      }}
                                    >
                                      <span className="fas fa-eye"></span> Progress Report
                                    </Button> : null}

                                  {modalShow && courseId === elmt.id ? (
                                    <CoreTrainingLogbookModal
                                      isOpen={modalShow}
                                      toggle={() => setModalShow(!modalShow)}
                                      id={courseId}
                                      trainingType={elmt?.training_type === 'Advance' ? 'Specialty' : elmt?.training_type}
                                      slotName={elmt?.name}
                                      fcpsPartOneInfo={fcpsPartOneInfo}
                                    />
                                  ) : null}

                                  {changeSupervisorModalShow && courseId === elmt.id ? (
                                    <ChangeSupervisorModal
                                      isOpen={changeSupervisorModalShow}
                                      toggle={() => setChangeSupervisorModalShow(!changeSupervisorModalShow)}
                                      id={courseId}
                                      trainingType={elmt?.training_type === 'Advance' ? 'Specialty' : elmt?.training_type}
                                      slotName={elmt?.name}
                                      fcpsPartOneInfoForChange={fcpsPartOneInfoForChange}
                                    />
                                  ) : null}

                                  {closeModalShow && closeId === elmt.id ? (
                                    <CloseModal
                                      isOpen={closeModalShow}
                                      toggle={() => setCloseModalShow(!closeModalShow)}
                                      element={elmt}
                                      personalProfile={personalProfile}
                                    />
                                  ) : null}
                                </td>
                                {instuctionModalShow ? (
                                  <InstructionDeatailsModal
                                    isOpen={instuctionModalShow}
                                    toggle={() => setInstuctionModalShow(!instuctionModalShow)}
                                  />
                                ) : null}

                                {reportId === elmt?.id ? (
                                  <TrainingProgressReportPDF userInfo={authUser} setReportId={setReportId} />
                                ) : null}

                                {documentUploadModalShow && documentId === elmt.id ? (
                                  <DocumentUploadModal
                                    isOpen={documentUploadModalShow}
                                    toggle={() => setDocumentUploadModalShow(!documentUploadModalShow)}
                                  />
                                ) : null}
                              </tr>
                            );
                          },
                        )}
                      </tbody>

                    </Table>
                  </div>


                </CardBody>
              </Card>
              <Card className='mt-3'>
                <CardBody>
                  <h5 className="block-heading my-3">Workshop/Training List</h5>
                  <div className="table-responsive">
                    <Table className="table table-sm table-striped table-hover">
                      <thead>
                        <tr>
                          <th>Sl</th>
                          <th>Type</th>
                          <th>Workshop/Training</th>
                          <th>Duration</th>
                          <th>Fee</th>
                          <th>Status</th>
                          {/* <th>Certificate</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseList?.data?.WorkshopAndTraining?.map(
                          (elmt, index: number) => {
                            return (
                              <tr key={elmt.id}>
                                <th>{index + 1}</th>
                                <td>{elmt.type}</td>
                                <td>{elmt.name}</td>
                                <td>{elmt?.duration + ' ' + elmt?.duration_unit}</td>
                                <td>{elmt?.exam_tranning_fee?.exam_fee !== '0' ? <span className="">{elmt?.exam_tranning_fee?.exam_fee}</span> : <span className="">Free</span>}</td>
                                <td>
                                  {elmt?.payment_verification_status === 'Approved' ? <span className="badge bg-success me-1">Approved</span> : elmt?.payment_verification_status === 'Pending' ? <span className="badge bg-warning me-1">Pending</span> : null}
                                  {elmt?.active_course ? <span className="badge bg-success">Applied</span> : <span className="badge bg-info">Not Applied</span>}
                                </td>

                                <td>
                                  <button className="me-1 btn btn-sm btn-outline-primary" onClick={() => { trainingDescriptionModalShowHandler(true, elmt?.description!, elmt.id!) }}>
                                    Instruction
                                  </button>

                                  {elmt?.certificate_issued === 1 &&
                                    <button className='btn btn-sm btn-warning me-1' onClick={(e) => {
                                      setCertificateId(elmt?.certificate_ref_no!);
                                      setTrainingTitleForCertificate(elmt?.name!);
                                      setTrainingDuration(elmt?.duration!);
                                      setTrainingDurationUnit(elmt?.duration_unit!);
                                    }}
                                    >
                                      Certificate
                                    </button>}

                                  <button
                                    className="btn btn-sm btn-danger me-1"
                                    onClick={() => {
                                      setNoticeId(elmt?.notice_ref_no!);
                                    }}
                                    disabled={noticeId !== ''}
                                  >
                                    Notice
                                  </button>

                                  {elmt?.workshop_skill_master_id &&
                                    <Button
                                      type="button"
                                      color="info"
                                      className="me-1 btn-sm"
                                      onClick={() => { trainingModalShowHandler(true, elmt.id!) }}
                                    >
                                      View
                                    </Button>}
                                  {elmt.exam_tranning_fee?.exam_fee !== '0' ?
                                    <Button
                                      type="button"
                                      color="success"
                                      className="me-1 btn-sm"
                                      onClick={() => modalWorkshopApplyHandler(true, elmt)}
                                      disabled={elmt.active_course}
                                    >
                                      Apply
                                    </Button>
                                    :
                                    <Button
                                      type="button"
                                      color="success"
                                      className="me-1 btn-sm"
                                      onClick={() => {
                                        const body = {
                                          exam_course_id: elmt.id,
                                        };
                                        createPaymentWitoutFeesApply(body);
                                      }}
                                      disabled={elmt.active_course}
                                    >
                                      Apply
                                    </Button>
                                  }

                                </td>
                                {descriptionModalShow && trainingId === elmt.id ? (
                                  <DescriptionModal
                                    isOpen={descriptionModalShow}
                                    toggle={() => setDescriptionnModalShow(!descriptionModalShow)}
                                    description={description}
                                  />
                                ) : null}
                                {trainingViewModalShow && trainingId === elmt.id ? (
                                  <TrainingViewDeatailsModal
                                    isOpen={trainingViewModalShow}
                                    toggle={() => setTrainingViewModalShow(!trainingViewModalShow)}
                                    trainingId={elmt?.workshop_skill_master_id!}
                                    attachments={elmt.attachment}
                                  />
                                ) : null}
                                {/* {noticeId !== '' && noticeId === elmt?.notice_ref_no && notice.id ? (
                                  <UpdatedViewNoticePdf data={notice} setNoticeId={setNoticeId} />
                                ) : null} */}
                              </tr>
                            );
                          },
                        )}
                      </tbody>
                      {workShopListModalShow ? (
                        <WorkshopListModal
                          isOpen={workShopListModalShow}
                          toggle={() => setWorkShopListModalShow(!workShopListModalShow)}
                          workshopName={selectedWorkshop.name!}
                          type={selectedWorkshop.type!}
                          fees={selectedWorkshop.exam_tranning_fee?.exam_fee!}
                          id={selectedWorkshop.id!}
                        />
                      ) : null}
                      {noticeId && notice?.id && noticeId === notice.id ? (
                        <UpdatedViewNoticePdf
                          data={notice}
                          noticeId={noticeId}
                          setNoticeId={setNoticeId}
                          notice={notice}
                          setNotice={setNotice}
                        />
                      ) : null}
                      {certificateId === certificate?.id && certificateInfoObj?.name && trainingTitleForCertificate ? (
                        <ViewCertificatePdf
                          data={certificate}
                          certificateId={certificateId}
                          setCertificateId={setCertificateId}
                          certificateInfoObj={certificateInfoObj}
                          trainingTitleForCertificate={trainingTitleForCertificate}
                          trainingDuration={trainingDuration}
                          trainingDurationUnit={trainingDurationUnit}
                        />
                      ) : null}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MyTraining;
