import { useFormik } from 'formik';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import DataTable, { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Button, Container, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';
import { useGetResearchGrantListForRtmdQuery } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import loader from '../../assets/images/loader.gif';
import { FormATraineePDF } from './FormATraineePDF';
import { FormBTraineePdf } from './FormBTraineePdf';
import ProfileViewModal from './ProfileViewModal';

const ResearchGrantList = () => {
  let referenceUrl = new URLSearchParams(location.search).get('ref');

  const [selectedSubject, setSelectedSubject] = useState<any>('');
  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [profileId, setProfileId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [appointmentModalShow, setAppointmentModalShow] = useState(false);
  const [formBId, setFormBId] = useState('');
  const [formAId, setFormAId] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject_id) => ({
      value: subject_id.id!,
      label: subject_id.subject_name,
    })) || [];

  const instituteList: SelectOption[] =
    useGetOrganizationsQuery()
      .data?.data?.map((item) => ({
        value: item.id!,
        label: item.organization_name!,
      })) || [];

  const allStatus: SelectOption[] = [
    { value: 'Appointment Created', label: 'Appointment Created' },
    { value: 'Resolved', label: 'Resolved' },
    { value: 'Pending', label: 'Pending' },
  ]
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const history = useHistory();

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    from_date?: string;
    to_date?: string;
    bmdc_registration_no?: string;
    institute_name?: string;
    mobile?: string;
    status?: string;
    subject_id?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    from_date: '',
    to_date: '',
    bmdc_registration_no: '',
    institute_name: '',
    mobile: '',
    status: '',
    subject_id: '',
  };

  const {
    data: grantList,
    isSuccess,
    isLoading,
    isFetching,
    refetch
  } = useGetResearchGrantListForRtmdQuery(generateQueryUrl());

  useEffect(() => {
    refetch();
  }, [referenceUrl]);

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.bmdc_registration_no !== '') {
        s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
      }

      if (values.institute_name !== '') {
        s = s + `&institute_name=${values.institute_name}`;
      }

      if (values.mobile !== '') {
        s = s + `&mobile=${values.mobile}`;
      }

      if (values.subject_id !== '') {
        s = s + `&subject_id=${values.subject_id}`;
      }

      if (values.status !== '') {
        s = s + `&status=${values.status}`;
      }

      setSearch(s);
      setPage('1');
    },
  });

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
      width: '60px',
    },
    {
      name: <div>Form No</div>,
      selector: (row) => "-",
      sortable: false,
      wrap: true,
    },
    {
      name: <div>DSN No</div>,
      selector: (row) => row?.title?.thesis_protocol?.protocol_no,
      sortable: false,
      wrap: true,
      width: '170px',
    },
    {
      name: <div>Name of Researchers/Account Holder</div>,
      selector: (row) => row?.registration?.personal_info?.full_name,
      sortable: false,
      wrap: true,
      width: '170px',
    },
    {
      name: <div>Subject</div>,
      selector: (row) => row?.registration?.subject?.subject_name,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>BMDC Reg. No.</div>,
      selector: (row) => row?.registration?.bmdc_registration_no,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Mobile Number</div>,
      selector: (row) => row?.registration?.personal_info?.mobile,
      sortable: false,
      wrap: true,
      width: '110px',
    },
    {
      name: <div>Bank Name</div>,
      selector: (row) => row?.bank_info?.bank_name,
      sortable: false,
      wrap: true,
      width: '130px',
    },
    {
      name: <div>Branch Name</div>,
      selector: (row) => row?.bank_info?.branch_name,
      sortable: false,
      wrap: true,
      width: '110px',
    },
    {
      name: <div>Account No</div>,
      selector: (row) => row?.bank_info?.account_no,
      sortable: false,
      wrap: true,
      width: '110px',
    },
    {
      name: <div>Routing No</div>,
      selector: (row) => row?.bank_info?.routing_no,
      sortable: false,
      wrap: true,
      width: '110px',
    },

    {
      name: <div>Role</div>,
      cell: (row) => row?.role,
      width: '90px',
    },
    {
      name: <div>Status</div>,
      width: '150px',
      selector: (row) => row?.status,
      cell: (row: any) => {
        switch (row?.status) {
          case 'Resolved':
            return (
              <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                {row?.status}
              </div>
            );
          case 'Appointment Created':
            return (
              <div className="badge rounded-pill d-inline-block  badge-applied font-size-12 fw-medium">
                {row?.status}
              </div>
            );
          default:
            return (
              <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                {'N/A'}
              </div>
            );
        }
      },
      sortable: false,
      wrap: true,
    },

    {
      name: 'Action',
      width: '300px',
      style: actionColumnStyles,
      cell: (row) => (
        <>
          <div className="d-flex gap-1 my-1">
            <Button
              className="btn btn-sm"
              color='primary'
              onClick={() => modalShowHandler(true, row?.id!)}
            >
              Profile
            </Button>

            {row?.role === 'Trainee' &&
              <>
                <Button
                  type="button"
                  color='secondary'
                  className="btn btn-sm"

                  onClick={() => {
                    setFormAId(row?.id);
                    setSubmitting(true);
                  }}
                >
                  Form A
                </Button>
                <Button
                  className="btn btn-sm btn-success"
                  onClick={() => {
                    setFormBId(row?.id);
                    setSubmitting(true);
                  }}
                >
                  Form B
                </Button>

                <Button
                  className="btn btn-sm btn-info"
                  onClick={() => history.push(`/money-reciept-for-researcher/${row?.role}/${row?.registration?.registration_no}/${row?.id}`)}
                >
                  Form C
                </Button>
              </>}

            {(row?.role === 'Guide' || row?.role === 'Co-Guide') &&
              <Button
                className="btn btn-sm btn-warning"
                onClick={() => history.push(`/money-reciept-for-researcher/${row?.role}/${row?.registration?.registration_no}/${row?.id}`)}
              >
                Form D
              </Button>}
          </div>
          {modalShow && profileId === row?.id ? (
            <ProfileViewModal
              isOpen={modalShow}
              toggle={() => setModalShow(!modalShow)}
              info={row}
            />
          ) : null}
          {formBId === row.id ? (
            <FormBTraineePdf data={row} setFormBId={setFormBId} setSubmitting={setSubmitting} />
          ) : null}
          {formAId === row?.id ? (
            <FormATraineePDF data={row} setFormAId={setFormAId} setSubmitting={setSubmitting} />
          ) : null}
        </>
      ),
    },
  ];

  const modalShowHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setProfileId(id);
  }


  useEffect(() => {
    if (selectedSubject !== '') {
      setFieldValue('subject_id', selectedSubject?.value);
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedInstitue !== '') {
      setFieldValue('institute_name', selectedInstitue?.value);
    }
  }, [selectedInstitue]);

  // START PDF AND CSV FOR TABLE DATA

  const tableHeaders = ["Sl. No.", "Form No", "DSN No", "Name of Account Holder", "Subject", "BMDC Reg. No.", "Mobile Number", "Bank Name", "Branch Name", "Account No", "Routing No", "Amount (in Taka)"];
  const tableHeadersTwo = ["Sl. No.", "DSN No", "Name of Researchers", "Subject", "Title", "Guide", "Co-Guide"];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 300;
    const doc: any = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "List of Researchers for Dissertation Grant";
    const headers = [tableHeaders];
    const data = grantList?.data?.data?.filter((item: any) => item?.role === 'Trainee')?.map((data: any, index: number) => [
      ((page - 1) * limit + index) + 1,
      '-',
      data?.title?.thesis_protocol?.protocol_no ?? '-',
      data?.registration?.personal_info?.full_name ?? '-',
      data?.registration?.subject?.subject_name ?? '-',
      data?.registration?.bmdc_registration_no ?? '-',
      data?.registration?.personal_info?.mobile ?? '-',
      data?.bank_info?.bank_name ?? '-',
      data?.bank_info?.branch_name ?? '-',
      data?.bank_info?.account_no ?? '-',
      data?.bank_info?.routing_no ?? '-',
      '35000',
    ]) || [];

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Research_Grant_List(Payment).pdf");
  }

  const exportPDFTwo = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 300;
    const doc: any = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "List of Researchers for Dissertation Grant";
    const headers = [tableHeadersTwo];
    const data = grantList?.data?.data?.filter((item: any) => item?.role === 'Trainee')?.map((data: any, index: number) => [
      ((page - 1) * limit + index) + 1,
      data?.title?.thesis_protocol?.protocol_no ?? '-',
      data?.registration?.personal_info?.full_name ?? '-',
      data?.registration?.subject?.subject_name ?? '-',
      data?.title?.title_of_thesis ?? '-',
      data?.title?.guide_name ?? '-',
      data?.title?.co_guide_1_name + ' ' + data?.title?.co_guide_2_name + ' ' + data?.title?.co_guide_3_name,
    ]) || [];

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Research_Grant_List(Researchers).pdf");
  }

  // END PDF AND CSV FOR TABLE DATA


  return (
    <div className="page-content">
      {isLoading || isFetching || submitting ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '/', name: 'Research Grant' }]}
          title={'Research Grant List'}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card">
            <div className="card-body">
              <div>
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  onReset={(e) => {
                    e.preventDefault();
                    setSelectedSubject('');
                    setSelectedInstitute('');
                    resetForm();
                    handleSubmit();
                  }}
                >
                  <div className="filter-wrapper">
                    <FormGroup>
                      <Label for="bmdc_registration_no">BMDC Reg. No.</Label>
                      <InputField
                        type="text"
                        placeholder="BMDC Reg. No."
                        {...getFieldProps('bmdc_registration_no')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="mobile">Mobile</Label>
                      <InputField
                        type="text"
                        placeholder="Mobile"
                        {...getFieldProps('mobile')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="status">Status</Label>
                      <InputField
                        type="select"
                        placeholder="Status"
                        options={allStatus}
                        {...getFieldProps('status')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="from_date">From Date</Label>
                      <InputField
                        type="date"
                        placeholder=""
                        invalid={{ errors, touched }}
                        {...getFieldProps('from_date')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="to_date">To Date</Label>
                      <InputField
                        type="date"
                        placeholder=""
                        invalid={{ errors, touched }}
                        {...getFieldProps('to_date')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="institute_name">Institute</Label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        value={selectedInstitue}
                        onChange={setSelectedInstitute}
                        options={instituteList}
                        placeholder='Institute'
                        name="institute_name"
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="subject_id">Subject</Label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        value={selectedSubject}
                        onChange={setSelectedSubject}
                        options={allSubjects}
                        placeholder='Subject'
                        name="subject_id"
                      />
                    </FormGroup>

                    <FormGroup className='btn-column mb-3'>
                      <Button color="danger" type="reset" text="Reset" >Reset</Button>
                      <Button type="submit" text="Search" color="primary" >Search</Button>
                    </FormGroup>

                  </div>
                  {grantList?.data?.data?.length! > 0 &&
                    <div className='row mb-3'>
                      <div className='col-sm-4 d-flex gap-2'>
                        <Button type="button" color='warning' outline onClick={() => exportPDF()} className='fw-bold'>
                          <i className="fa-solid fa-print"></i>{" "}Payment List PDF
                        </Button>
                        <CSVLink
                          headers={tableHeaders}
                          data={grantList?.data?.data?.filter((item: any) => item?.role === 'Trainee')?.map((data: any, index: number) => [
                            ((page - 1) * limit + index) + 1,
                            '-',
                            data?.title?.thesis_protocol?.protocol_no,
                            data?.registration?.personal_info?.full_name,
                            data?.registration?.subject?.subject_name,
                            data?.registration?.bmdc_registration_no,
                            "'" + data?.registration?.personal_info?.mobile,
                            data?.bank_info?.bank_name,
                            data?.bank_info?.branch_name,
                            data?.bank_info?.account_no,
                            data?.bank_info?.routing_no,
                            '35000',
                          ]) || []}
                          filename="Research_Grant_List(Payment).csv"
                        >
                          <Button type="button" color='success' outline className='fw-bold'>
                            <i className="fa-solid fa-file-arrow-down"></i>{' '}
                            Payment List CSV
                          </Button>
                        </CSVLink>
                      </div>
                      <div className='col-sm-4 d-flex gap-2'>
                        <Button type="button" color='warning' outline onClick={() => exportPDFTwo()} className='fw-bold'>
                          <i className="fa-solid fa-print"></i>{" "}Researchers List PDF
                        </Button>
                        <CSVLink
                          headers={tableHeadersTwo}
                          data={grantList?.data?.data?.filter((item: any) => item?.role === 'Trainee')?.map((data: any, index: number) => [
                            ((page - 1) * limit + index) + 1,
                            data?.title?.thesis_protocol?.protocol_no,
                            data?.registration?.personal_info?.full_name,
                            data?.registration?.subject?.subject_name,
                            data?.title?.title_of_thesis,
                            data?.title?.guide_name,
                            data?.title?.co_guide_1_name + ' ' + data?.title?.co_guide_2_name + ' ' + data?.title?.co_guide_3_name
                          ]) || []}
                          filename="Research_Grant_List(Researchers).csv"
                        >
                          <Button type="button" color='success' outline className='fw-bold'>
                            <i className="fa-solid fa-file-arrow-down"></i>{' '}
                            Researchers List CSV
                          </Button>
                        </CSVLink>
                      </div>
                    </div>
                  }
                </Form>
              </div>
              <DataTable
                columns={columns}
                data={grantList?.data?.data!}
                customStyles={dataTableCustomStyles}
                pagination
                paginationServer
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={isLoading}
                expandOnRowClicked
                paginationTotalRows={grantList?.data?.total}
                paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
                responsive
                persistTableHead={true}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ResearchGrantList;
