import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Alert, Card, CardBody, Col, Container, FormGroup, Row } from 'reactstrap';
import defaultAvatar from 'src/assets/images/users/default-avatar.png';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { useAppSelector } from 'src/rtk/app/hook';
import { useCreateFellowApplyForSupervisorMutation } from 'src/rtk/features/fellowSupervisorApply/fellowSupervisorApplyApi';
import { useGetMyRegInfoQuery } from 'src/rtk/features/registration-approval/registrationApprovalApi';
import loader from '../../assets/images/loader.gif';

const ApplyForSupervisor = () => {
    const authUser = useAppSelector((state) => state.auth.user);

    const { data, isLoading: isDataLoading } = useGetMyRegInfoQuery(authUser?.registration_no);
    const userInfo = data?.data || ({} as RegInfo);
    const [credentialCheck, setCredentialCheck] = useState<boolean>(false);
    const [createFellowApplyForSupervisor, { isSuccess, isError, error, isLoading }] = useCreateFellowApplyForSupervisorMutation();

    console.log(userInfo);
    const err: CustomError = error as CustomError;

    useEffect(() => {
        if (isSuccess) {
            toast.success('Applied Successfully!');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if(userInfo?.current_employment_info?.designation === 'Professor' || userInfo?.current_employment_info?.designation === 'Associate Professor' || userInfo?.current_employment_info?.designation === 'Assistant Professor' || userInfo?.current_employment_info?.department_head || userInfo?.current_employment_info?.in_charge){
            setCredentialCheck(true);
        }
    },[userInfo]);

    return (
        <div className="page-content">
            {isDataLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '#', name: 'Supervisor' }]}
                    title={'Apply For Supervisor'}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={1}>
                                <div className="img-fluid img-rounded text-center">
                                    <img
                                        src={
                                            userInfo?.personal_info?.picture
                                                ? `${config?.APP_URL}${userInfo?.personal_info?.picture}`
                                                : defaultAvatar
                                        }
                                        width="120"
                                        className="img-thumbnail"
                                    />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div><strong>Name: </strong> {userInfo?.personal_info?.full_name}</div>
                                <div><strong>Speciliaty: </strong> {userInfo?.subject?.subject_name}</div>
                                {userInfo?.fellow_supervisor_apply ? 
                                <div>
                                    <Alert color="success">&nbsp;You have already Applied!</Alert>
                                </div>
                                :
                                <div>
                                    {userInfo?.current_employment_info?.designation === 'Professor'|| userInfo?.current_employment_info?.designation === 'Associate Professor' || userInfo?.current_employment_info?.designation === 'Assistant Professor' || userInfo?.current_employment_info?.department_head || userInfo?.current_employment_info?.in_charge ?
                                        <Alert color="success"><i className="fa-solid fa-check fa-2xl" style={{ color: "#215903" }} />&nbsp;You are eligible for applying as a Supervisor</Alert> :
                                        <Alert color="danger"><i className="fa-solid fa-xmark fa-2xl" style={{ color: "#f00505" }} />&nbsp;Eligibility not matched! Please Update Your Profile</Alert>
                                    }
                                </div>}
                            </Col>
                        </Row>
                        <>
                            <h6 className="div-heading text-white mt-3">Current Work Information</h6>
                            <Card>
                                <CardBody>
                                    <ul className="list-unstyled mb-0">
                                        <Row>
                                            <Col>
                                                <li>
                                                    <strong>Job Title: </strong>
                                                    {userInfo?.current_employment_info?.designation ?? 'N/A'}
                                                </li>

                                                <li>
                                                    <strong>Institute: </strong>
                                                    {userInfo?.current_employment_info?.institute_name ?? 'N/A'}
                                                </li>

                                                <li>
                                                    <strong>Department: </strong>
                                                    {userInfo?.current_employment_info?.department_name ?? 'N/A'}
                                                </li>

                                                <li>
                                                    <strong>From Date: </strong>
                                                    {userInfo?.current_employment_info?.from_date ?? 'N/A'}
                                                </li>
                                            </Col>
                                            <Col>

                                                <li>
                                                    <strong>Institute Type: </strong>
                                                    {userInfo?.current_employment_info?.employment_type ?? 'N/A'}
                                                </li>

                                                <li>
                                                    <strong>Head of the Department: </strong>

                                                    {userInfo?.current_employment_info?.department_head
                                                        ? <span className="badge rounded-pill d-inline-block  badge-approved font-size-12">
                                                            Yes
                                                        </span>
                                                        : <span className="badge rounded-pill d-inline-block  badge-pending font-size-12">
                                                            No
                                                        </span>}
                                                </li>
                                                <li>
                                                    <strong>In Charge of Unit: </strong>

                                                    {userInfo?.current_employment_info?.in_charge
                                                        ? <span className="badge rounded-pill d-inline-block  badge-approved font-size-12">
                                                            Yes
                                                        </span>
                                                        : <span className="badge rounded-pill d-inline-block  badge-pending font-size-12">
                                                            No
                                                        </span>}
                                                </li>
                                                <li>
                                                    <strong>Unit Name: </strong>
                                                    {userInfo?.current_employment_info?.unit_name ?? 'N/A'}

                                                </li>
                                            </Col>
                                        </Row>
                                    </ul>
                                </CardBody>
                            </Card>
                        </>
                        <Row>
                            <Col>
                                <h6 className="div-heading text-white mt-3">Supervisor Eligibility Criteria</h6>
                                <Card>
                                    <CardBody>
                                        <>
                                            <div className='mb-1'>
                                                Professor or Associate Professor of an accredited department of an accredited institute &nbsp;
                                                {userInfo?.current_employment_info?.designation === 'Professor' || userInfo?.current_employment_info?.designation === 'Associate Professor' ?
                                                    <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                                    : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                }
                                            </div>
                                            <div className='mb-1'>
                                                Assistant Professor or Consultant holding a clinical unit as in-charge of an  accredited department of an accredited institute &nbsp;
                                                {userInfo?.current_employment_info?.designation === 'Assistant Professor' || userInfo?.current_employment_info?.in_charge ?
                                                    <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                                    : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                }
                                            </div>
                                            <div className='mb-1'>
                                                Consultant working on an accredited department of an accredited District Hospital &nbsp;
                                                {userInfo?.current_employment_info?.designation === 'Consultant' || userInfo?.current_employment_info?.institute_name === 'District' ?
                                                    <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                                    : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                }
                                            </div>
                                            <div>
                                                Head of the Department of an accredited department of an accredited District Institute &nbsp;
                                                {userInfo?.current_employment_info?.department_head ?
                                                    <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                                                    : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                                                }
                                            </div>
                                        </>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3 text-end">
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        disabled={!credentialCheck || userInfo?.fellow_supervisor_apply}
                                        onClick={() => {
                                            createFellowApplyForSupervisor('');
                                        }}
                                    >
                                        Apply
                                    </button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default ApplyForSupervisor;
