import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Modal,
    Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import Breadcrumb from 'src/components/Common/Breadcrumb';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';
import { config } from 'src/config';
import { useGetDissertationApprovalByRtmdAdminMutation, useGetDissertationListForAssigningReviewerByPaginateQuery } from 'src/rtk/features/disertation-thesis/dissertationListForAssigningReviewerApi';
import loader from '../../../assets/images/loader.gif';
import AssignDissertationReviewer from './AssignDissertationReviewer';
import ReviewerComments from './ReviewerComments';

interface SearchFields {
    from_date?: string;
    to_date?: string;
    bmdc_registration_no?: string;
}

const initialValues: SearchFields = {
    from_date: '',
    to_date: '',
    bmdc_registration_no: '',
};

const DissertationListForAssigningReview = () => {
    document.title = 'Dissertation List | BCPS';
    let referenceUrl = new URLSearchParams(location.search).get('ref');

    const [commentModalShow, setCommentModalShow] = useState<boolean>(false);
    const [viewModalShow, setViewModalShow] = useState<boolean>(false);
    const [assignReviewerModalShow, setAssignReviewerModalShow] = useState<boolean>(false);
    const [assignReviewerDissertation, setAssignReviewerDissertation] = useState<DissertationListForReviewer>({} as DissertationListForReviewer);
    const [commentThesisId, setCommentThesisId] = useState<string>('');
    const [dissertationToView, setDissertationToView] = useState<DissertationListForReviewer>({} as DissertationListForReviewer);
    const [page, setPage] = useState<any>(1);
    const [paginate_per_page, set_paginate_per_page] = useState(15);
    const [search, setSearch] = useState('');
    const [approvalModalShow, setApprovalModalShow] = useState<boolean>(false);
    const [traineeDetails, setTraineeDetails] = useState<any>({});
    const [approvalStatus, setApprovalStatus] = useState<string>('Approve');
    const [approvalNote, setApprovalNote] = useState<string>('');

    const {
        data: dissertationThesisListData,
        error: dissertationThesisListDataError,
        isLoading: isDissertationThesisListDataLoading,
        isError: isDissertationThesisListDataError,
        isSuccess: isDissertationThesisListDataSuccess,
        refetch,
        isFetching,
    } = useGetDissertationListForAssigningReviewerByPaginateQuery({
        page,
        paginate_per_page,
        search,
    });

    const [getDissertationApprovalByRtmdAdmin, { isSuccess: isApprovalSuccess, isError: isApprovalError, isLoading: isApprovalLoading }] = useGetDissertationApprovalByRtmdAdminMutation();

    const allDissertationThesis = dissertationThesisListData?.data;

    useEffect(() => {
        refetch();
    }, [referenceUrl]);

    const commentModalShowHandler = (modalState: boolean, thesisId: string) => {
        setCommentModalShow(modalState);
        setCommentThesisId(thesisId);
    };

    const approvalViewHandler = (modalState: boolean, row: any) => {
        setApprovalModalShow(modalState);
        setTraineeDetails(row);
    };

    const viewHandler = (thesis: DissertationListForReviewer) => {
        window.open(`${config.APP_URL}${thesis.document_name}`);
    };

    const assignReviewerHandler = (modalState: boolean, thesis: DissertationListForReviewer) => {
        setAssignReviewerModalShow(modalState);
        setAssignReviewerDissertation(thesis);
    };

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        set_paginate_per_page(newPerPage);
        setPage(page);
    };

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            width: '100px',
            cell: (row, index) => <strong>{((page - 1) * paginate_per_page + index) + 1}</strong>,

        },

        {
            name: <div>Trainee Name</div>,
            selector: (row) =>
                row.trainee_name ?? '-',
            sortable: false,

            wrap: true,
            width: '150px',
        },

        {
            name: <div>BMDC Reg. No.</div>,
            selector: (row) => row.bmdc_registration_no ?? '-',
            sortable: false,
            wrap: true,

            width: '150px',
        },
        {
            name: <div>Subject</div>,
            selector: (row) => row.subject_name ?? '-',
            sortable: false,
            wrap: true,

            width: '150px',
        },

        {
            name: <div>Type</div>,
            selector: (row) => row.document_type ?? '-',
            sortable: false,
            wrap: true,

            width: '150px',
        },

        // {
        //     name: <div>ID</div>,
        //     selector: (row) =>
        //         '-',
        //     sortable: false,
        //     wrap: true,
        //     
        //     width: '150px',
        // },

        {
            name: <div>Title</div>,
            selector: (row) =>
                row?.title?.title_of_thesis ?? '-',
            sortable: false,
            wrap: true,

            width: '150px',
        },

        {
            name: <div>Date of Submission</div>,
            selector: (row) =>
                row.submission_date ?? '-',
            sortable: false,
            wrap: true,

            width: '200px',
        },

        {
            name: <div>Guide</div>,
            selector: (row) =>
                row?.title?.guide?.personal_info?.full_name ?? '-',
            sortable: false,
            wrap: true,

            width: '150px',
        },

        {
            name: <div>Co-Guide 1</div>,
            selector: (row) =>
                row?.title?.co_guide_1?.personal_info?.full_name ?? '-',
            sortable: false,
            wrap: true,

            width: '150px',
        },

        {
            name: <div>Co-Guide 2</div>,
            selector: (row) =>
                row?.title?.co_guide_2?.personal_info?.full_name ?? '-',
            sortable: false,
            wrap: true,

            width: '150px',
        },

        {
            name: <div>Co-Guide 3</div>,
            selector: (row) =>
                row?.title?.co_guide_3?.personal_info?.full_name ?? '-',
            sortable: false,
            wrap: true,

            width: '150px',
        },

        {
            name: <div>Status</div>,
            selector: (row) => row?.admin_approval_status,
            width: '150px',
            cell: (row) => {
                switch (row?.admin_approval_status) {
                    case 'Reject':
                        return (
                            <>
                                <div className="badge badge-rejected rounded-pill font-size-12">
                                    {'Rejected'}
                                </div>
                            </>
                        );

                    case 'Correction':
                        return (
                            <>
                                <div className="badge badge-correction rounded-pill font-size-12">
                                    {row?.admin_approval_status}
                                </div>
                            </>
                        );

                    case 'Approve':
                        return (
                            <>
                                <div className="badge badge-approved rounded-pill font-size-12">
                                    {'Approved'}
                                </div>
                            </>
                        );

                    case 'Pending':
                        return (
                            <>
                                <div className="badge badge-pending rounded-pill font-size-12">
                                    {row?.admin_approval_status}
                                </div>
                            </>
                        );

                    default:
                        return (
                            <>
                                <div className="badge badge-soft-secondary font-size-12">
                                    N/A
                                </div>
                            </>
                        );
                }
            },
        },

        {
            name: <div>Action</div>,
            width: '550px',
            style: actionColumnStyles,
            cell: (row) => (
                <>
                    {/* btn-group-example */}
                    <div className="d-flex gap-1">
                        <Button
                            size="sm"
                            color="primary"
                            type="button"
                            text={`<span class="fas fa-eye" ></span> Approval`}
                            onClick={() => {
                                approvalViewHandler(true, row);
                            }}
                            disabled
                        // style={{width: '200px'}}
                        />
                        <Button
                            size="sm"
                            color="info"
                            type="button"
                            text={`<span class="fas fa-eye" ></span> View`}
                            onClick={() => {
                                viewHandler(row);
                            }}
                        // style={{width: '200px'}}
                        />

                        <Button
                            size="sm"
                            color="success"
                            type="button"
                            text={`<span class="fas fa-upload" ></span> Assign Reviewer`}
                            onClick={() => {
                                assignReviewerHandler(true, row);
                            }}
                            style={{ width: '150px' }}
                        />

                        <Button
                            size="sm"
                            color="secondary"
                            type="button"
                            text={`<span class="fas fa-edit" ></span> Reviewer's Comment`}
                            onClick={() => {
                                commentModalShowHandler(true, row.id);
                            }}
                            style={{ width: '180px' }}
                        />
                    </div>
                </>
            ),
        },
    ];

    const {
        handleSubmit,
        getFieldProps,
        values,
        touched,
        errors,
        resetForm,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values) => {
            let s: string = '';

            if (values.from_date) {
                s = s + `&from_date=${values.from_date}`;
            }

            if (values.to_date) {
                s = s + `&to_date=${values.to_date}`;
            }

            if (values.bmdc_registration_no) {
                s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
            }

            setSearch(s);
            setPage('1');
            set_paginate_per_page(15);
        },
    });

    // useEffect(() => {
    //     if (isDissertationThesisListDataLoading || !isDissertationThesisListDataSuccess) {
    //         toast.dismiss();
    //         toast.loading('Data Loading...');
    //     } else {
    //         toast.dismiss();
    //         toast.success('Data Load Successfully');
    //     }
    // }, [isDissertationThesisListDataLoading, isDissertationThesisListDataSuccess]);

    const handleApprovalSubmit = () => {
        const url = `${traineeDetails?.id}/${approvalStatus}?note=${approvalNote}&email=${traineeDetails?.title?.registration?.personal_info?.email}`;
        getDissertationApprovalByRtmdAdmin(url);
    }

    useEffect(() => {
        if (isApprovalSuccess) {
            toast.success('Approval Status Updated Successfully!');
            setApprovalModalShow(false);
        }
        if (isApprovalError) {
            toast.error('Something went wrong');
        }
    }, [isApprovalSuccess, isApprovalError]);

    return (
        <div className="page-content">
            {isApprovalLoading || isDissertationThesisListDataLoading || isFetching ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <Breadcrumb title="Dashboard" breadcrumbItem="Assign Reviewer for Dissertation" />
                <Card className="mb-5">

                    <CardBody>
                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                //   handleSubmit();
                            }}
                        >
                            <div className="filter-wrapper">
                                <FormGroup>
                                    <Label for="from_date">From Date</Label>
                                    <InputField
                                        type="date"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('from_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="to_date">To Date</Label>
                                    <InputField
                                        type="date"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('to_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="id">BMDC Reg. No.</Label>
                                    <InputField
                                        type="text"
                                        placeholder='BMDC Reg. No.'
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('bmdc_registration_no')}
                                    />
                                </FormGroup>

                                <FormGroup className="btn-column">
                                    <Button
                                        color="danger"
                                        type="reset"
                                        text="Reset"
                                        onClick={() => {
                                            resetForm();
                                            handleSubmit();
                                        }}
                                    />
                                    <Button
                                        type="button"
                                        text="Search"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </Form>
                        <DataTable
                            columns={columns}
                            data={allDissertationThesis ? allDissertationThesis : []}
                            pagination
                            paginationServer
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={isDissertationThesisListDataLoading}
                            paginationTotalRows={dissertationThesisListData?.total}
                            customStyles={dataTableCustomStyles}
                            expandOnRowClicked
                            highlightOnHover
                            paginationRowsPerPageOptions={[2, 5, 10, 15, 20, 50, 100, 200]}
                            persistTableHead
                        />

                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={commentModalShow}
                            scrollable={true}
                            size="lg"
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Reviewer's Comment
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setCommentModalShow(!commentModalShow);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <Row>
                                    <Col>
                                        <ReviewerComments thesisId={commentThesisId} />
                                    </Col>
                                </Row>
                            </div>
                        </Modal>

                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={approvalModalShow}
                            // scrollable={true}
                            size="lg"
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Approval
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setApprovalModalShow(!approvalModalShow);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <h6 className='block-heading'>Approval Form</h6>
                                <table className="table table-bordered table-sm">
                                    <tbody>
                                        <tr>
                                            <th className='col-4'>Status</th>
                                            <td>
                                                <div>
                                                    <FormGroup check inline className="mb-3 ">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="status"
                                                            id="approve"
                                                            onChange={() => {
                                                                setApprovalStatus('Approve');
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label text-success"
                                                            htmlFor="approve"
                                                        >
                                                            Approve
                                                        </label>
                                                    </FormGroup>

                                                    <FormGroup check inline className="mb-3 ">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="status"

                                                            id="reject"
                                                            onChange={() => {
                                                                setApprovalStatus('Reject');
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label text-danger"
                                                            htmlFor="reject"
                                                        >
                                                            Reject
                                                        </label>
                                                    </FormGroup>

                                                    <FormGroup check inline className="mb-3 ">
                                                        <input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="status"
                                                            id="correction"
                                                            onChange={() => {
                                                                setApprovalStatus('Correction');
                                                            }}
                                                        />
                                                        <label
                                                            className="form-check-label text-info"
                                                            htmlFor="correction"
                                                        >
                                                            Correction
                                                        </label>
                                                    </FormGroup>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className='col-4'>
                                                Note
                                            </th>
                                            <td>
                                                <InputField
                                                    type="textarea"
                                                    placeholder={'Enter Note'}
                                                    invalid={{ errors, touched }}
                                                    name='note'
                                                    onChange={(e: any) => {
                                                        setApprovalNote(e.target.value);
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <Button
                                    type="submit"
                                    text="Save"
                                    className="btn btn-success"
                                    onClick={handleApprovalSubmit}
                                ></Button>
                                <Button
                                    type="button"
                                    text="Close"
                                    className="btn btn-danger"
                                    onClick={() => {
                                        setApprovalModalShow(!approvalModalShow);
                                    }}
                                ></Button>
                            </div>
                        </Modal>
                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={assignReviewerModalShow}
                            // scrollable={true}
                            size="lg"
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Assign Reviewer
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setAssignReviewerModalShow(!assignReviewerModalShow);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <Row>
                                    <Col>
                                        <AssignDissertationReviewer dissertationToView={assignReviewerDissertation} setModalShow={setAssignReviewerModalShow} />
                                    </Col>
                                </Row>
                            </div>
                        </Modal>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default DissertationListForAssigningReview;
