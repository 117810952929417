import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button as BsButton, Card, CardBody, Col, Form, FormGroup, Label, Modal, Row } from 'reactstrap';
import { Button } from 'src/components';
import { config } from 'src/config';
import * as url from 'src/helpers/url_helper';
import {
  useGetCommentsDataByMutation,
} from 'src/rtk/features/disertation-thesis/crudApi';
import {
  useCreateThesisEntryMasterWithDetailsMutation,
} from 'src/rtk/features/fileUploadForThesis/thesisDissertationFileEntryApi';
import fileOthers from '../../../assets/images/file-others.svg';
import { CommentSectionModal } from '../CommentSectionForThesisDissertation/CommentSectionModal';
import ProtocolSummaryView from '../ProtocolSummaryView/ProtocolSummaryView';
import ExaminerSignature from './ExaminerSignature';
import FileArray from './FileArray';
import ThesisDissertationFileUpload from './FileUpload';

export const DynamicInputField = (props: any) => {
  const {
    dynamicInputFields,
    activeStates,
    setActiveStates,
    activeStateId,
    thesisDissertationTitle,
    thesisEntryDetails,
    thesisEntryListForTrainee,
    setThesisEntryDetails,
    toggleStates,
    setToggleStates,
    thesisDissertationFile,
    isThesisDissertationFileSuccess,
    isThesisDissertationFileLoading,
  } = props;

  const fileRef = useRef<any>();
  const [editorInitialValue, setEditorInitialValue] = useState<string>('');
  const [showCommentSectionModal, setShowCommentSectionModal] = useState<boolean>(false);
  const [submitStatus, setSubmitStatus] = useState<string>('');
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [messages, setMessages] = useState<any>([]);

  const lastIndex: number = (Object.keys(dynamicInputFields)?.length)!;
  const lastActiveId: string = Object.keys(dynamicInputFields)[lastIndex - 1];

  const [createThesisEntryMasterWithDetails, {
    data: thesisEntryMasterWithDetails,
    isSuccess: isThesisEntryMasterWithDetailsSuccess,
    isLoading: isThesisEntryMasterWithDetailsLoading,
    isError: isThesisEntryMasterWithDetailsError,
    error: thesisEntryMasterWithDetailsError,
  }] = useCreateThesisEntryMasterWithDetailsMutation();

  const err: CustomError = thesisEntryMasterWithDetailsError as CustomError;

  const [
    commentsDataList,
    {
      isLoading: commentsDataListLoading,
      isSuccess: commentsDataListLoadingSuccess,
      error: commentsDataListLoadingError,
    },
  ] = useGetCommentsDataByMutation();

  const getCommentsData = (thesis_dissertation_id: string, column_id: string) => {
    commentsDataList(`${url.THESIS_DISSERTATION_ENTRY_COMMENT_LIST}/${thesis_dissertation_id}/${column_id}`).then(function (result: any,) {
      // here you can use the result of promiseB
      if (result?.data?.data) {
        setMessages(result?.data?.data);
      }
    });    
  };

  useEffect(() => {
    if (thesisDissertationFile?.data?.id && activeStateId) {
      getCommentsData(thesisDissertationFile?.data?.id, activeStateId);
    }
  }, [thesisDissertationFile, activeStateId]);

  const handleFormSubmit = (submit_status: string) => {
    const formData = new FormData();

    Object.keys(thesisEntryDetails).forEach((id: string, index: number) => {
      formData.append(`thesis_entry_details[${index}][thesis_dissertation_setup_id]`, thesisEntryDetails[id]['thesis_dissertation_setup_id']);
      formData.append(`thesis_entry_details[${index}][thesis_setup_column_name]`, thesisEntryDetails[id]['thesis_setup_column_name']);
      formData.append(`thesis_entry_details[${index}][details_text]`, thesisEntryDetails[id]['details_text']);
      formData.append(`thesis_entry_details[${index}][details_file]`, typeof thesisEntryDetails[id]['details_file'] === 'string' ? thesisEntryDetails[id]['details_file']?.replace(`storage/uploads/thesis-entry-details-file/`, ``) : thesisEntryDetails[id]['details_file']);
      formData.append(`thesis_entry_details[${index}][comments_text]`, thesisEntryDetails[id]['comments_text']);
      formData.append(`thesis_entry_details[${index}][plagarism]`, thesisEntryDetails[id]['plagarism']);

      if (thesisEntryDetails[id]['file_array']?.length > 0) {
        let fileArrayList: any = [];

        if (thesisEntryListForTrainee?.data?.details?.length > 0) {
          const fileArrayEntry: any = thesisEntryListForTrainee?.data?.details?.find((d: any, index: number) => d.thesis_dissertation_setup_id === id);
          if (fileArrayEntry?.file_array) {
            fileArrayList = JSON.parse(fileArrayEntry?.file_array);
          }
        }

        thesisEntryDetails[id]['file_array']?.forEach((fileObj: any, fileIndex: number) => {
          if (typeof fileObj?.file === 'string' && fileObj?.file !== '') {
            formData.append(`thesis_entry_details[${index}][file_array][${fileIndex}][file_name]`, fileArrayList?.find((f: any, index: number) => f.file === fileObj.file)?.file_name);
            formData.append(`thesis_entry_details[${index}][file_array][${fileIndex}][file]`, fileObj?.file?.replace('storage/uploads/thesis-entry-details-file-array/', ''));
          } else if (fileObj?.file) {
            formData.append(`thesis_entry_details[${index}][file_array][${fileIndex}][file_name]`, fileObj?.file_name);
            formData.append(`thesis_entry_details[${index}][file_array][${fileIndex}][file]`, fileObj?.file);
          }
        });
      }
    });

    formData.append('submission_status', submit_status);

    createThesisEntryMasterWithDetails(formData);
  };

  const handleEditorChange = (content: any, editor: any) => {
    const newThesisEntryDetails: any = { ...thesisEntryDetails };
    newThesisEntryDetails[activeStateId]['details_text'] = content;
    setThesisEntryDetails(newThesisEntryDetails);
  };

  useEffect(() => {
    if (activeStateId && !['title'].includes(dynamicInputFields[activeStateId]?.column_name?.toLowerCase())) {
      setEditorInitialValue(thesisEntryDetails[activeStateId]['details_text']);
    }
  }, [activeStateId]);

  useEffect(() => {
    if (isThesisEntryMasterWithDetailsSuccess) {

      // Handling the active menu item at each save button click
      const newActiveStates: any = { ...activeStates };
      const newActiveStatesList: string[] = Object.keys(newActiveStates);
      let newActiveStateId: string = '';

      Object.keys(newActiveStates)?.forEach((activeId: string, index: number) => {
        if (activeId === activeStateId && index < lastIndex - 1) {
          let i = index + 1;

          while (i < lastIndex - 1 && dynamicInputFields[newActiveStatesList[i]]?.child?.length) {
            i = i + 1;
          }

          if (i <= lastIndex - 1 && !dynamicInputFields[newActiveStatesList[i]]?.child?.length) {
            newActiveStates[activeId] = false;
            newActiveStates[newActiveStatesList[i]] = true;
            newActiveStateId = newActiveStatesList[i];
          }
        }
      });

      setActiveStates(newActiveStates);

      // Handling the menu item toggling at each next button click
      const newToggleStates: any = { ...toggleStates };
      const parentIds: string[] = [];
      const currentToggleId: string = newActiveStateId ? dynamicInputFields[newActiveStateId]?.parent_id : dynamicInputFields[activeStateId]?.parent_id;
      // console.log(newActiveStateId);
      // console.log(currentToggleId);

      Object.keys(newToggleStates)?.forEach((toggleId: string, index: number) => {
        if (toggleId === currentToggleId) {
          let initialParentId: string = dynamicInputFields[currentToggleId]?.parent_id;
          while (initialParentId) {
            parentIds.push(initialParentId);
            initialParentId = dynamicInputFields[initialParentId]?.parent_id;
          }
        }
      });

      if (!newToggleStates[currentToggleId]) {
        Object.keys(newToggleStates)?.forEach((toggleId: string, index: number) => {
          if (toggleId === currentToggleId || parentIds.indexOf(toggleId) !== -1) {
            newToggleStates[toggleId] = true;
          } else {
            newToggleStates[toggleId] = false;
          }
        });
      }

      setToggleStates(newToggleStates);

      toast.success('Saved successfully');

      if (fileRef.current) {
        fileRef.current.value = '';
      }

      // Scrolling the page back to top
      window.scrollTo(0, 0);
    }
    if (isThesisEntryMasterWithDetailsError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isThesisEntryMasterWithDetailsSuccess, isThesisEntryMasterWithDetailsError]);

  const history = useHistory();

  return (
    <Card>
      <CardBody>
        <Form
          className='custom-form'
          onSubmit={(e: any) => {
            e.preventDefault();
            // handleSubmit();
          }}
          onReset={(e: any) => {
            e.preventDefault();
            // handleReset(e);
          }}
        >
          {dynamicInputFields[activeStateId].data_type === 'rich-text'
            ?
            <>
              <Row className='justify-content-between mb-2'>
                <Col sm={'auto'}>
                  <Label
                    className={dynamicInputFields[activeStateId].required_ind ? 'form-Label required-field' : 'form-Label'}>
                    {dynamicInputFields[activeStateId].column_name}
                  </Label>
                </Col>
                <Col sm={'auto'} className='btn noti-icon floating-comments-btn'>
                  <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
                  <BsButton
                    type='button'
                    className='btn-sm'
                    onClick={(e) => {
                      setShowCommentSectionModal(!showCommentSectionModal);
                    }}
                    disabled={!thesisDissertationFile?.data?.id || !activeStateId}
                  >
                    <i className='fas fa-comments font-size-10'></i>
                  </BsButton>
                </Col>
              </Row>
              <Editor
                initialValue={editorInitialValue ?? ''}
                apiKey={config.EDITOR_API_KEY}
                init={{
                  height: 500,
                  menubar: true,
                  plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'wordcount',
                  ],
                  toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                    'alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist checklist outdent indent | removeformat | a11ycheck code table',
                }}
                value={thesisEntryDetails[activeStateId]['details_text'] ?? ''}
                onEditorChange={handleEditorChange} />
            </>
            :
            null}

          {/*START */}

          {dynamicInputFields[activeStateId].has_file || dynamicInputFields[activeStateId].data_type === 'file'
            ?
            <div className={'dissertation-upload-files-wrapper'}>
              {dynamicInputFields[activeStateId].data_type !== 'rich-text' ?
                <div className='d-flex justify-content-between gap-3'>
                  <p className={"mb-0"}>{dynamicInputFields[activeStateId].column_name}</p>
                  <Col sm={'auto'} className='btn noti-icon floating-comments-btn'>
                    <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
                    <BsButton
                      type='button'
                      className='btn-sm'
                      onClick={(e) => {
                        setShowCommentSectionModal(!showCommentSectionModal);
                      }}
                    >
                      <i className='fas fa-comments font-size-10'></i>
                    </BsButton>
                  </Col>
                </div>
                :
                ''
              }
              <div className='dissertation-file-field-wrapper'>
                {typeof thesisEntryDetails[activeStateId]['details_file'] === 'string' && thesisEntryDetails[activeStateId]['details_file'] !== '' ? (
                  <div>
                    <a
                      className=''
                      href={`${config?.APP_URL}${thesisEntryDetails[activeStateId]['details_file']}`}
                      rel='noreferrer'
                      target='_blank'
                    >
                      <span className='uploaded-file-preview'>
                        <img src={fileOthers} alt='' />
                      </span>
                      <p className={'file-name my-2'}>
                        {thesisEntryDetails[activeStateId]['details_file']}
                      </p>
                    </a>

                  </div>
                ) : (
                  typeof thesisEntryDetails[activeStateId]['details_file'] !== 'string' && thesisEntryDetails[activeStateId]['details_file'] ?
                    <div>
                      <a
                        className=''
                        href={URL.createObjectURL(thesisEntryDetails[activeStateId]['details_file'])}
                        rel='noreferrer'
                        target='_blank'
                      >
                        <span className='uploaded-file-preview'>
                          {
                            thesisEntryDetails[activeStateId]['details_file']?.type === 'image/png' ||
                              thesisEntryDetails[activeStateId]['details_file']?.type === 'image/jpeg' ||
                              thesisEntryDetails[activeStateId]['details_file']?.type === 'image/gif' ? (
                              <img src={URL.createObjectURL(thesisEntryDetails[activeStateId]['details_file'])} alt='' />
                            ) : (
                              <img src={fileOthers} alt='' />
                            )
                          }
                        </span>
                        <p className={'file-name my-2'}>
                          {thesisEntryDetails[activeStateId]['details_file']?.name}
                        </p>
                      </a>
                    </div>
                    :
                    null
                )}


                <div>
                  <Label
                    htmlFor='fileUpload'
                    className={dynamicInputFields[activeStateId].required_ind ? 'form-Label required-field' : 'form-Label'}
                  >
                    Upload Attachment
                  </Label>
                  <input
                    ref={fileRef}
                    name='fileUpload'
                    id='fileUpload'
                    type='file'
                    className='form-control'
                    placeholder='Upload File'
                    onChange={(e: any) => {
                      const newThesisEntryDetails: any = { ...thesisEntryDetails };
                      newThesisEntryDetails[activeStateId]['details_file'] = e.target.files[0];
                      setThesisEntryDetails(newThesisEntryDetails);
                    }}
                  ></input>
                </div>
              </div>
            </div>
            :
            null}

          {/*END */}


          {dynamicInputFields[activeStateId]?.data_type === 'file-array'
            ?
            <>
              <Row className='justify-content-between mb-2'>
                <Col sm={'auto'}>
                  <Label
                    className={dynamicInputFields[activeStateId].required_ind ? 'form-Label required-field' : 'form-Label'}>
                    {dynamicInputFields[activeStateId].column_name}
                  </Label>
                </Col>
                <Col sm={'auto'} className='btn noti-icon floating-comments-btn'>
                  <span className="badge bg-danger rounded-pill">{messages?.length ?? 0}</span>
                  <BsButton
                    type='button'
                    className='btn-sm'
                    onClick={(e) => {
                      setShowCommentSectionModal(!showCommentSectionModal);
                    }}
                    disabled={!thesisDissertationFile?.data?.id || !activeStateId}
                  >
                    <i className='fas fa-comments font-size-10'></i>
                  </BsButton>
                </Col>
              </Row>
              <FileArray
                activeStateId={activeStateId}
                thesisEntryDetails={thesisEntryDetails}
                setThesisEntryDetails={setThesisEntryDetails} />
            </>
            :
            null}
          {dynamicInputFields[activeStateId]?.column_name?.toLowerCase() === 'protocol'
            ?
            <>
              <ProtocolSummaryView />
            </>
            :
            null}
          {dynamicInputFields[activeStateId]?.column_name?.toLowerCase() === 'title'
            ?
            <p className={'fw-bold'}>
              {thesisDissertationTitle?.title_of_thesis ?? ''}
            </p>
            :
            null}
          {dynamicInputFields[activeStateId]?.column_name?.toLowerCase() === 'acceptance (signatory)'
            ?
            <>
              <ExaminerSignature thesisEntryListForTrainee={thesisEntryListForTrainee} />
            </>
            :
            null}


          {lastActiveId === activeStateId && thesisDissertationFile?.data?.submission_status?.toLowerCase() === 'submit' && (
            <p className={'my-4 text-center'}><b>Your {thesisDissertationTitle?.category?.toLowerCase()} is submitted and is under review</b></p>
          )}


          {lastActiveId === activeStateId && thesisDissertationFile?.data?.submission_status?.toLowerCase() === 'draft' && (
            <p className={'my-4 text-center'}><b>Your {thesisDissertationTitle?.category?.toLowerCase()} is saved as draft. Only you can view
              and update the {thesisDissertationTitle?.category?.toLowerCase()} until you submit</b></p>
          )}

          {!['protocol', 'title', 'acceptance (signatory)'].includes(dynamicInputFields[activeStateId]?.column_name?.toLowerCase()) ?
            <FormGroup className='mb-3 text-center'>
              <Button
                type='button'
                text='Reset'
                className='btn btn-warning me-2'
                disabled={['submit', 'reject', 'approve'].includes(thesisDissertationFile?.data?.submission_status?.toLowerCase()) ||
                  thesisEntryListForTrainee?.data?.approval_status?.toLowerCase() === 'approve' ||
                  thesisEntryListForTrainee?.data?.approval_status?.toLowerCase() === 'reject'}
                onClick={(e) => {
                  const newThesisEntryDetails: any = { ...thesisEntryDetails };
                  newThesisEntryDetails[activeStateId]['details_text'] = '';
                  newThesisEntryDetails[activeStateId]['details_file'] = '';
                  setThesisEntryDetails(newThesisEntryDetails);
                  if (fileRef.current) {
                    fileRef.current.value = '';
                  }
                  window.scrollTo(0, 0);
                }}
              ></Button>
              {lastActiveId === activeStateId ? (
                <Button
                  type='button'
                  text='Save as Draft'
                  className='btn btn-info me-2'
                  disabled={(dynamicInputFields?.[activeStateId]?.required_ind &&

                    ((dynamicInputFields?.[activeStateId]?.data_type === 'rich-text' &&
                      !thesisEntryDetails?.[activeStateId]?.['details_text']) ||

                      ((dynamicInputFields?.[activeStateId]?.data_type === 'file' ||
                        dynamicInputFields?.[activeStateId].has_file) &&
                        !thesisEntryDetails?.[activeStateId]?.['details_file']) ||

                      (dynamicInputFields?.[activeStateId]?.data_type === 'file-array' &&
                        !thesisEntryDetails?.[activeStateId]?.['file_array']?.length))) ||

                    ['submit', 'reject', 'approve'].includes(thesisDissertationFile?.data?.submission_status?.toLowerCase()) ||
                    thesisEntryListForTrainee?.data?.approval_status?.toLowerCase() === 'approve' ||
                    thesisEntryListForTrainee?.data?.approval_status?.toLowerCase() === 'reject'}
                  onClick={(e) => {
                    setModalShow(true);
                    setSubmitStatus('Draft');
                  }}
                ></Button>
              ) : (
                null
              )}
              <Button
                type='button'
                text={lastActiveId === activeStateId ? 'Submit' : 'Save'}
                className='btn btn-success me-2'
                disabled={(dynamicInputFields?.[activeStateId]?.required_ind &&

                  ((dynamicInputFields?.[activeStateId]?.data_type === 'rich-text' &&
                    !thesisEntryDetails?.[activeStateId]?.['details_text']) ||

                    ((dynamicInputFields?.[activeStateId]?.data_type === 'file' ||
                      dynamicInputFields?.[activeStateId].has_file) &&
                      !thesisEntryDetails?.[activeStateId]?.['details_file']) ||

                    (dynamicInputFields?.[activeStateId]?.data_type === 'file-array' &&
                      !thesisEntryDetails?.[activeStateId]?.['file_array']?.length))) ||

                  ['submit', 'reject', 'approve'].includes(thesisDissertationFile?.data?.submission_status?.toLowerCase()) ||
                  thesisEntryListForTrainee?.data?.approval_status?.toLowerCase() === 'approve' ||
                  thesisEntryListForTrainee?.data?.approval_status?.toLowerCase() === 'reject'}
                onClick={(e) => {
                  if (lastActiveId !== activeStateId) {
                    handleFormSubmit('Draft');
                  } else {
                    setModalShow(true);
                    setSubmitStatus('Submit');
                  }
                }}
              ></Button>
            </FormGroup>
            :
            null}


          {showCommentSectionModal ?
            <CommentSectionModal
              thesisDissertationFile={thesisDissertationFile}
              thesisDissertationFormSetupId={activeStateId}
              thesisDissertationFormSetupColumnName={dynamicInputFields[activeStateId]?.column_name}
              showModal={showCommentSectionModal}
              setShowModal={setShowCommentSectionModal}
              setMessageCount={setMessages}
            />
            :
            null
          }

          <Modal
            onOpened={function () {
              toast.dismiss();
            }}
            isOpen={modalShow}
            scrollable={true}
            size='md'
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <div className='modal-header'>
              <h5 className='modal-title' id='staticBackdropLabel'>
                {thesisDissertationTitle?.category} Upload
              </h5>
              <button
                type='button'
                className='btn-close'
                onClick={() => {
                  setModalShow(!modalShow);
                }}
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <Row>
                <Col>
                  {submitStatus && modalShow &&
                    <ThesisDissertationFileUpload
                      list={thesisDissertationFile}
                      isSuccess={isThesisDissertationFileSuccess}
                      isLoading={isThesisDissertationFileLoading}
                      thesisDissertationTitle={thesisDissertationTitle!}
                      thesisEntryListForTrainee={thesisEntryListForTrainee}
                      handleFormSubmit={handleFormSubmit}
                      submitStatus={submitStatus}
                      modalShow={modalShow}
                      setModalShow={setModalShow}
                    />}
                </Col>
              </Row>
            </div>
          </Modal>
        </Form>
      </CardBody>
    </Card>
  );
};
