import { AddLogBookSession } from "src/pages/Setup/LogBookEntrySession/AddLogBookSession";
import { EditLogBookSessionSetup } from "src/pages/Setup/LogBookEntrySession/EditLogBookSessionSetup";
import { LogBookEntrySession } from "src/pages/Setup/LogBookEntrySession/Index";


export const logBookEntrySessionRoutes = [
  { path: '/log/book/entry/session', component:  LogBookEntrySession},
  { path: '/log/book/entry/session/Add', component:  AddLogBookSession},
  { path: '/log/book/entry/session/:id', component:  EditLogBookSessionSetup},
];
