import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Col, Form, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Button, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import {
  useCreateLogbookEntrySetupDetailGroupMutation,
  useGetFcpsCourseListsForLogbookEntryMutation,
  useLazyGetDynamicFormPropertyByParticularSetupApiQuery,
  useLazyGetFormByLogbookTypeAndSubjectQuery
} from 'src/rtk/features/setup/logBookEntrySetupDetails/logBookEntrySetupDetailsApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import AddCompetencyGroupDetails from './AddCompetencyGroupDetails';
import AddCompetencyGroupTargets from './AddCompetencyGroupTargets';
import AddLogbookEntryDetailsFields from './AddLogbookEntryDetailsFields';
import loader from '../../../assets/images/loader.gif';

const initialValues: LogbookEntryDetailsGroup = {
  id: '',
  title: '',
  subject_id: '',
  main_training_id: '',
  course_type: '',
  from: '',
  case_type_id: '',
  skill_type_id: '',
  status: false,
  creator_organization_id: '',
  updater_organization_id: '',
  created_by: '',
  updated_by: '',
  deleted_by: '',
  created_at: '',
  updated_at: '',
  deleted_at: '',
};

interface QueryParams {
  id?: number;
  course_type?: string;
  subject_id?: string;
  main_training?: string;
}

export const AddLogbookEntryDetails = () => {
  const [createLogbookEntryDetailGroup, { data, isSuccess, isError, isLoading, error }] =
    useCreateLogbookEntrySetupDetailGroupMutation();

  const [
    getDynamicFormFieldsAndCompetencyGroup,
    {
      data: logbookEntryFieldList,
      error: logbookEntryFieldListError,
      isLoading: isLogbookEntryFieldListLoading,
      isFetching: isLogbookEntryFieldListFetching,
      isError: isLogbookEntryFieldListError,
      isSuccess: isLogbookEntryFieldListSuccess,
    }
  ] = useLazyGetDynamicFormPropertyByParticularSetupApiQuery();

  const err: CustomError = error as CustomError;

  const [activeTab, setActiveTab] = useState<string>('field_list');

  const [subject, setSubject] = useState<SubjectInfo>({} as SubjectInfo);

  const [selectedSubject, setSelectedSubject] = useState<any>('');

  const [mainTrainings, setMainTrainings] = useState<FcpsCourseList[]>([]);

  const [mainTraining, setMainTraining] = useState<FcpsCourseList>(
    {} as FcpsCourseList,
  );

  const [coreAdvance, setCoreAdvance] = useState<string>('');
  const [coreAdvanceOptions, setCoreAdvanceOptions] = useState<SelectOption[]>([]);

  const [forms, setForms] = useState<LogbookEntrySetupMaster[]>([]);

  const [form, setForm] = useState<LogbookEntrySetupMaster>(
    {} as LogbookEntrySetupMaster,
  );

  const [logbookEntrySetupMasters, setLogbookEntrySetupMasters] = useState<LogbookEntrySetupMaster[][]>([]);
  const [logbookEntrySetupMaster, setLogbookEntrySetupMaster] = useState<LogbookEntrySetupMaster[]>([]);

  // const [caseType, setCaseType] = useState<LogbookEntrySetupMaster>(
  //   {} as LogbookEntrySetupMaster,
  // );

  // const [caseTypes, setCaseTypes] = useState<LogbookEntrySetupMaster[]>([]);

  // const [skillType, setSkillType] = useState<LogbookEntrySetupMaster>(
  //   {} as LogbookEntrySetupMaster,
  // );

  const [queryParams, setQueryParams] = useState<QueryParams>(
    {} as QueryParams,
  );

  // const [skillTypes, setSkillTypes] = useState<LogbookEntrySetupMaster[]>([]);

  const [logbookEntrySetupMasterId, setLogbookEntrySetupMasterId] =
    useState<string>('');

  const [logbookGroup, setLogbookGroup] = useState<LogbookEntryDetailsGroup>(
    {} as LogbookEntryDetailsGroup,
  );

  const [competencyList, setCompetencyList] = useState<AddCompetencyGroup[]>([]);

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [search, setSearch] = useState('');

  const { data: getSubjectInfolist } = useGetSubjectInfosQuery();
  const subjects = getSubjectInfolist?.data;

  const [
    createFcpsCourse,
    {
      data: fcpsCourseListData,
      isSuccess: isFcpsCourseListSuccess,
      isError: isFcpsCourseListError,
      isLoading: isFcpsCourseListLoading,
      error: fcpsCourseListError,
    },
  ] = useGetFcpsCourseListsForLogbookEntryMutation();

  const [getForms, { data: formSetups, isSuccess: isFormSetupsSuccess, isLoading: isFormSetupsLoading, isFetching: isFormSetupsFetching }] =
    useLazyGetFormByLogbookTypeAndSubjectQuery();

  // const { data: logbookEntrySetupDetailsData } = useGetLogbookEntrySetupDetailsQuery();
  // const logbookEntrySetupDetails = logbookEntrySetupDetailsData?.data?.filter((l: LogbookEntryDetails) => l.logbook_entry_setup_master_id === logbookEntrySetupMasterId) || [];  

  let subjectOptions: SelectOption[] =
    subjects?.map((subject) => ({
      value: subject.id!,
      label: subject.subject_name,
    })) || [];

  let mainTrainingOptions: SelectOption[] =
    mainTrainings?.map((mainTraining) => ({
      value: mainTraining.id!,
      label: mainTraining.name!,
    })) || [];

  let formOptions: SelectOption[] =
    forms?.map((e: LogbookEntrySetupMaster) => {
      return {
        label: e.particulars!,
        value: e.id!,
      };
    }) ?? [];

  // let caseTypeOptions: SelectOption[] =
  //   caseTypes?.map((e: LogbookEntrySetupMaster) => {
  //     return {
  //       label: e.particulars!,
  //       value: e.id!,
  //     };
  //   }) ?? [];

  // let skillTypeOptions: SelectOption[] =
  //   skillTypes?.map((e: LogbookEntrySetupMaster) => {
  //     return {
  //       label: e.particulars!,
  //       value: e.id!,
  //     };
  //   }) ?? [];

  // let logbookEntrySetupDetailOptions: SelectOption[] = [
  //   ...logbookEntrySetupDetails?.map((l: LogbookEntryDetails) => ({
  //     label: l.column_name!,
  //     value: l.id!,
  //   })),
  // ];

  const formik = useFormik({
    initialValues,
    // validationSchema: Yup.object({
    //   column_name: Yup.string().matches(
    //     /^[^.]*$/,
    //     'Please provide the name without a dot(.)',
    //   ),
    //   // logbook_entry_setup_master_id: Yup.string().required('Please select the logbook entry setup master'),
    // }),

    onSubmit: (values) => {
      values.subject_id = subject?.id!;
      values.main_training_id = mainTraining?.id!;
      values.course_type = coreAdvance;
      values.from = form?.id!;
      values.case_type_id = logbookEntrySetupMaster[0]?.id!;
      if (logbookEntrySetupMaster.length > 0) {
        values.skill_type_id = logbookEntrySetupMaster[logbookEntrySetupMaster.length - 1]?.id!;
      }
      values.title =
        subject?.subject_name +
        ' -> ' +
        mainTraining?.name +
        ' -> ' +
        coreAdvance +
        ' -> ' +
        form?.particulars;

      logbookEntrySetupMaster.forEach((entrySetupMaster: LogbookEntrySetupMaster) => {
        values.title = values.title + ' -> ' + entrySetupMaster.particulars;
      });

      createLogbookEntryDetailGroup({
        ...values,
      });

      console.log(values);
    },
  });

  const handleFormReset = () => {
    setSubject({ id: '' } as SubjectInfo);
    setSelectedSubject({ label: '', value: '' });
    setMainTraining({ id: '' } as FcpsCourseList);
    setCoreAdvance('');
    setForm({ id: '' } as LogbookEntrySetupMaster);
    setForms([]);
    setMainTrainings([]);
    setCoreAdvanceOptions([]);
    // setCaseType({ id: '' } as LogbookEntrySetupMaster);
    // setSkillType({ id: '' } as LogbookEntrySetupMaster);
    setQueryParams({} as QueryParams);
    setLogbookEntrySetupMasterId('');
    setLogbookEntrySetupMasters([]);
    setLogbookEntrySetupMaster([]);
  };

  const handleFormResetAfterSubmit = () => {
    setSubject({ id: '' } as SubjectInfo);
    setMainTraining({ id: '' } as FcpsCourseList);
    setCoreAdvance('');
    setForm({ id: '' } as LogbookEntrySetupMaster);
    setForms([]);
    setMainTrainings([]);
    setCoreAdvanceOptions([]);
    // setCaseType({ id: '' } as LogbookEntrySetupMaster);
    // setSkillType({ id: '' } as LogbookEntrySetupMaster);
    setQueryParams({} as QueryParams);
    setSubmitted(true);
    setLogbookEntrySetupMasters([]);
    setLogbookEntrySetupMaster([]);
  };

  useEffect(() => {
    if (isSuccess) {
      handleFormResetAfterSubmit();
      setLogbookGroup(data?.data!);
      toast.success('Group created successfully');
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, formik.setErrors);
    }
  }, [isSuccess, isError]);

  const handleSubjectSelect = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const subject = subjects?.find((subject) => subject.id === value);
    setSubject(subject!);
    const newQueryParams: QueryParams = { ...queryParams };
    newQueryParams.subject_id = subject?.id;
    newQueryParams.main_training = '';
    newQueryParams.course_type = '';
    setQueryParams(newQueryParams);
    setCoreAdvanceOptions([]);
    setForms([]);
    setLogbookEntrySetupMaster([]);
    setLogbookEntrySetupMasters([]);
    setLogbookEntrySetupMasterId('');
    setForm({ id: '' } as LogbookEntrySetupMaster);
    setMainTraining({ id: '' } as FcpsCourseList);
    setCoreAdvance('');
    if (value === '') {
      setMainTrainings([]);
    }
  };

  const handleMainTrainingOption = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const mainTraining = mainTrainings?.find(
      (mainTraining) => mainTraining.id === value,
    );
    setMainTraining(mainTraining!);
    const newQueryParams: QueryParams = { ...queryParams };
    newQueryParams.main_training = mainTraining?.id;
    newQueryParams.course_type = '';
    setQueryParams(newQueryParams);
    setForms([]);
    setLogbookEntrySetupMaster([]);
    setLogbookEntrySetupMasters([]);
    setLogbookEntrySetupMasterId('');
    setCoreAdvance('');
    setForm({ id: '' } as LogbookEntrySetupMaster);
    if (value === '') {
      setCoreAdvanceOptions([]);
    } else {
      setCoreAdvanceOptions([
        { label: 'Core', value: 'core' },
        { label: 'Specialty', value: 'adv' },
      ]);
    }
  };

  const handleCoreAdvance = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    setCoreAdvance(value!);
    const newQueryParams: QueryParams = { ...queryParams };
    newQueryParams.course_type = value;
    setQueryParams(newQueryParams);
    setLogbookEntrySetupMaster([]);
    setLogbookEntrySetupMasters([]);
    setLogbookEntrySetupMasterId('');
    setForm({ id: '' } as LogbookEntrySetupMaster);
    if (value === '') {
      setForms([]);
    } else {
      setForms(formSetups?.data!);
    }
  };

  const handleFormSelect = (e: any) => {
    const { options, selectedIndex, value } = e.target;
    const form = forms.find((form) => form.id === value);
    setForm(form!);
    setLogbookEntrySetupMasterId('');
    const newLogbookEntrySetupMasters: LogbookEntrySetupMaster[][] = [];
    const newLogbookEntrySetupMaster: LogbookEntrySetupMaster[] = [];
    if (form) {
      newLogbookEntrySetupMasters[0] = form?.entry_setup_master!;
      newLogbookEntrySetupMaster[0] = form!;
      setLogbookEntrySetupMasters(newLogbookEntrySetupMasters);
      setLogbookEntrySetupMaster(newLogbookEntrySetupMaster);
    } else {
      setLogbookEntrySetupMasters([]);
      setLogbookEntrySetupMaster([]);
    }
  };

  // const handleCaseTypeSelect = (e: any) => {
  //   const { options, selectedIndex, value } = e.target;
  //   const caseType: any = caseTypes?.find((c: any) => c.id === value);
  //   const caseTypeChildData: any = caseTypes?.find(
  //     (c: any) => c.id === value,
  //   )?.entry_setup_master;
  //   setCaseType(caseType);
  //   if (value === '') {
  //     setLogbookEntrySetupMasterId('');
  //   } else {
  //     if (caseTypeChildData?.length === 0) {
  //       setLogbookEntrySetupMasterId(caseType.id || '');
  //       setSubmitted(false);
  //     }
  //   }
  // };

  // const handleSkillTypeSelect = (e: any) => {
  //   const { options, selectedIndex, value } = e.target;
  //   const skillType: any = skillTypes?.find((s: any) => s.id === value);
  //   setSkillType(skillType);
  //   if (value === '') {
  //     setLogbookEntrySetupMasterId('');
  //   } else {
  //     setLogbookEntrySetupMasterId(skillType.id || '');
  //     setSubmitted(false);
  //   }
  // };

  const handleEntrySetupMasterChilds = (e: any, index: number) => {
    const { options, selectedIndex, value } = e.target;
    const newLogbookEntrySetupMasters: LogbookEntrySetupMaster[][] = [...logbookEntrySetupMasters];
    const newLogbookEntrySetupMaster: LogbookEntrySetupMaster[] = [...logbookEntrySetupMaster];

    if (value !== '') {
      const skillType: LogbookEntrySetupMaster = logbookEntrySetupMasters[index]?.find((s: any) => s.id === value)!;

      if (skillType?.entry_setup_master?.length! > 0 && !newLogbookEntrySetupMasters[index + 1]) {
        newLogbookEntrySetupMasters.push(skillType?.entry_setup_master!);
        newLogbookEntrySetupMaster[index] = skillType;
        newLogbookEntrySetupMaster.forEach((entrySetupMaster: LogbookEntrySetupMaster, i: number) => {
          if (i > index) {
            newLogbookEntrySetupMaster[i] = { id: '', setup_logbook_master_id: '', status: false };
          }
        });
        setLogbookEntrySetupMasterId('');
      } else if (skillType?.entry_setup_master?.length! > 0 && newLogbookEntrySetupMasters[index + 1]) {
        newLogbookEntrySetupMasters[index + 1] = skillType?.entry_setup_master!;
        newLogbookEntrySetupMaster[index] = skillType;
        newLogbookEntrySetupMaster.forEach((entrySetupMaster: LogbookEntrySetupMaster, i: number) => {
          if (i > index) {
            newLogbookEntrySetupMaster[i] = { id: '', setup_logbook_master_id: '', status: false };
          }
        });
        setLogbookEntrySetupMasterId('');
      } else if (skillType?.entry_setup_master?.length! === 0) {
        newLogbookEntrySetupMaster[index] = skillType;
        if (newLogbookEntrySetupMaster.length > index) {
          newLogbookEntrySetupMaster.splice(index + 1, newLogbookEntrySetupMaster.length - index - 1);
        }
        if (newLogbookEntrySetupMasters.length > index) {
          newLogbookEntrySetupMasters.splice(index + 1, newLogbookEntrySetupMasters.length - index - 1);
        }
        setLogbookEntrySetupMasterId(skillType?.id!);
        setSubmitted(false);
        setLogbookGroup({} as LogbookEntryDetailsGroup);
      }
    } else {
      newLogbookEntrySetupMasters.splice(index + 1, newLogbookEntrySetupMasters.length - index - 1);
      newLogbookEntrySetupMaster[index] = { id: '', setup_logbook_master_id: '', status: false };
      setLogbookEntrySetupMasterId('');
    }

    setLogbookEntrySetupMasters(newLogbookEntrySetupMasters);
    setLogbookEntrySetupMaster(newLogbookEntrySetupMaster);
  };

  // const handleCompetencyGroupSubmission = (e: any) => {

  //   const competencyParticularsArray: string[] = competencyList?.map((c: AddCompetencyGroup) => {
  //     const { id, ...newObj } = { ...c };
  //     return newObj?.particulars!;
  //   });

  //   createLogbookCompetencyGroup({ particulars: competencyParticularsArray, particulary_type: 6, logbook_entry_setup_master_id: logbookGroup?.skill_type_id ? logbookGroup?.skill_type_id : logbookGroup?.case_type_id });

  // };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    if (isFcpsCourseListSuccess && fcpsCourseListData?.success) {
      const mainTrainings: FcpsCourseList[] = fcpsCourseListData.data;
      setMainTrainings(mainTrainings);
    } else {
      setMainTrainings([]);
    }
  }, [isFcpsCourseListSuccess, fcpsCourseListData?.success]);

  useEffect(() => {
    createFcpsCourse(subject?.id!);
  }, [subject]);

  useEffect(() => {
    const subject = subjects?.find((subject) => subject.id === selectedSubject?.value);
    setSubject(subject!);
    const newQueryParams: QueryParams = { ...queryParams };
    newQueryParams.subject_id = subject?.id;
    newQueryParams.main_training = '';
    newQueryParams.course_type = '';
    setQueryParams(newQueryParams);
    setCoreAdvanceOptions([]);
    setForms([]);
    setLogbookEntrySetupMaster([]);
    setLogbookEntrySetupMasters([]);
    setLogbookEntrySetupMasterId('');
    setForm({ id: '' } as LogbookEntrySetupMaster);
    setMainTraining({ id: '' } as FcpsCourseList);
    setCoreAdvance('');
    if (selectedSubject?.value === '') {
      setMainTrainings([]);
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (
      queryParams.subject_id &&
      queryParams.main_training &&
      queryParams.course_type
    ) {
      queryParams.id = 1;
      getForms(queryParams);
    }
  }, [queryParams]);

  useEffect(() => {
    if (formSetups && isFormSetupsSuccess) {
      setForms(formSetups?.data);
    }
  }, [formSetups, isFormSetupsSuccess]);

  useEffect(() => {
    if (logbookGroup?.id) {
      getDynamicFormFieldsAndCompetencyGroup({
        id: logbookGroup?.skill_type_id ? logbookGroup?.skill_type_id : logbookGroup?.case_type_id,
        publish_status: 0,
        status: search,
      });
    }
  }, [logbookGroup, search]);

  // useEffect(() => {
  //   setCaseTypes(form?.entry_setup_master! ?? []);
  // }, [form]);

  // useEffect(() => {
  //   setSkillTypes(caseType?.entry_setup_master! ?? []);
  // }, [caseType]);

  useEffect(() => {
    if (logbookEntryFieldList?.data?.competency_group?.length! && !isLogbookEntryFieldListLoading && isLogbookEntryFieldListSuccess) {
      const competencyListOps: any = logbookEntryFieldList?.data?.competency_group?.map((c: LogbookEntrySetupMaster) => {
        return {
          id: c.id!,
          particulars: c.particulars,
          particulary_type: c.particulary_type,
          logbook_entry_setup_master_id: c.logbook_entry_setup_master_id,
          show_minus_btn: false,
        };
      });

      setCompetencyList(competencyListOps);
    } else {
      setCompetencyList([]);
    }
  }, [logbookEntryFieldList, isLogbookEntryFieldListLoading, isLogbookEntryFieldListSuccess]);

  return (
    <>
      {isLoading || isFcpsCourseListLoading || isFormSetupsFetching || isLogbookEntryFieldListFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <hr />
      <Form
        className="custom-form"
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
        onReset={(e) => {
          e.preventDefault();
          handleFormReset();
        }}
      >
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3">
              <Label className="form-label" htmlFor="subject">Subject</Label>
              <Select
                isClearable={true}
                isSearchable={true}
                value={selectedSubject}
                onChange={setSelectedSubject}
                options={subjectOptions}
                placeholder=""
                name="subject"
                id="subject"
              />
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3">
              <Label className="form-label">Main Training</Label>
              <InputField
                type="select"
                name="mainTraining"
                value={mainTraining?.id}
                placeholder=""
                options={mainTrainingOptions}
                onChange={(e) => {
                  handleMainTrainingOption(e);
                }}
              />
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3">
              <Label className="form-label">Core/Specialty</Label>
              <InputField
                type="select"
                name="coreAdvance"
                placeholder=""
                value={coreAdvance}
                options={coreAdvanceOptions}
                onChange={(e) => {
                  handleCoreAdvance(e);
                }}
              />
            </FormGroup>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3">
              <Label className="form-label">Form</Label>
              <InputField
                type="select"
                name="form"
                placeholder=""
                value={form?.id}
                options={formOptions}
                onChange={(e) => {
                  handleFormSelect(e);
                }}
              />
            </FormGroup>
          </div>

          {/* <div className="col-lg-4 col-md-6 col-sm-12">
            <FormGroup className="mb-3">
              <Label className="form-label">Case Type</Label>
              <InputField
                type="select"
                name="caseType"
                value={caseType?.id}
                placeholder=""
                options={caseTypeOptions}
                onChange={(e) => {
                  handleCaseTypeSelect(e);
                }}
              />
            </FormGroup>
          </div>

          {skillTypes?.length > 0 ? (
            <div className="col-lg-4 col-md-6 col-sm-12">
              <FormGroup className="mb-3">
                <Label className="form-label">Skill Type</Label>
                <InputField
                  type="select"
                  name="skillType"
                  value={skillType?.id}
                  placeholder=""
                  options={skillTypeOptions}
                  onChange={(e) => {
                    handleSkillTypeSelect(e);
                  }}
                />
              </FormGroup>
            </div>
          ) : null} */}

          {logbookEntrySetupMasters?.map((entrySetupMaster: LogbookEntrySetupMaster[], index: number) => {
            return (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                <FormGroup className="mb-3">
                  <Label className="form-label">{index === 0 ? 'Case Type' : `Skill Type ${index}`}</Label>
                  <InputField
                    type="select"
                    name={`skillType${index + 1}`}
                    value={logbookEntrySetupMaster[index]?.id}
                    placeholder=""
                    options={
                      entrySetupMaster?.map((e: LogbookEntrySetupMaster) => {
                        return {
                          label: e.particulars!,
                          value: e.id!,
                        };
                      }) ?? []
                    }
                    onChange={(e) => {
                      handleEntrySetupMasterChilds(e, index);
                    }}
                  />
                </FormGroup>
              </div>
            );
          })}

          <div className="col-sm-4 mt-4">
            {!submitted ? (
              <>
                {logbookEntrySetupMasterId === '' ? (
                  <Button
                    type="submit"
                    text="Add Group"
                    className="me-2"
                    disabled
                  />
                ) : (
                  <Button type="submit" text="Add Group" className="me-2" />
                )}
                <Button type="reset" text="Reset" color="warning" />
              </>
            ) : (
              <>
                {logbookEntrySetupMasterId === '' ? (
                  <Button
                    type="submit"
                    text="Add Group"
                    className="me-2"
                    disabled
                  />
                ) : (
                  <Button type="submit" text="Add Group" className="me-2" />
                )}
                <Button type="reset" text="Reset" color="warning" disabled />
              </>
            )}
          </div>
        </div>
      </Form>

      {logbookEntrySetupMasterId !== '' && submitted && logbookGroup && isLogbookEntryFieldListSuccess && !isLogbookEntryFieldListLoading ? (
        <div className="bg-soft-light p-2">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'field_list' ? 'active' : ''}
                onClick={() => toggleTab('field_list')}
              >
                Logbook Entry Field
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'competency_list' ? 'active' : ''}
                onClick={() => toggleTab('competency_list')}
              >
                Competency Group
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={activeTab === 'target_list' ? 'active' : ''}
                onClick={() => toggleTab('target_list')}
              >
                Target Setup
              </NavLink>
            </NavItem> */}
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="field_list">
              <AddLogbookEntryDetailsFields
                key={logbookEntrySetupMasterId}
                logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                logbookGroup={logbookGroup}
                logbookEntryFieldList={logbookEntryFieldList}
                setSearch={setSearch}
                submitStatus={submitted}
                readOnly={false}
              />
            </TabPane>
            <TabPane tabId="competency_list">
              <Row className='mt-2'>
                <Col>
                  <AddCompetencyGroupDetails
                    key={logbookEntrySetupMasterId}
                    logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                    logbookGroup={logbookGroup}
                    logbookEntryFieldList={logbookEntryFieldList}
                    setSearch={setSearch}
                    submitStatus={submitted}
                    readOnly={false}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="target_list">
              <Row className='mt-2'>
                <Col>
                  <AddCompetencyGroupTargets
                    key={logbookEntrySetupMasterId}
                    logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                    logbookGroup={logbookGroup}
                    logbookEntryFieldList={logbookEntryFieldList}
                    setSearch={setSearch}
                    submitStatus={submitted}
                    readOnly={false}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      ) : (
        <div className="bg-soft-light p-3">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === 'field_list' ? 'active' : ''}
                onClick={() => toggleTab('field_list')}
              >
                Logbook Entry Field
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === 'competency_list' ? 'active' : ''}
                onClick={() => toggleTab('competency_list')}
              >
                Competency Group
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={activeTab === 'target_list' ? 'active' : ''}
                onClick={() => toggleTab('target_list')}
              >
                Target Setup
              </NavLink>
            </NavItem> */}
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="field_list">
              <AddLogbookEntryDetailsFields
                key={logbookEntrySetupMasterId}
                logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                logbookGroup={logbookGroup}
                logbookEntryFieldList={logbookEntryFieldList}
                setSearch={setSearch}
                submitStatus={submitted}
                readOnly={true}
              />
            </TabPane>
            <TabPane tabId="competency_list">
              <Row className='mt-2'>
                <Col>
                  <AddCompetencyGroupDetails
                    key={logbookEntrySetupMasterId}
                    logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                    logbookGroup={logbookGroup}
                    logbookEntryFieldList={logbookEntryFieldList}
                    setSearch={setSearch}
                    submitStatus={submitted}
                    readOnly={true}
                  />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="target_list">
              <Row className='mt-2'>
                <Col>
                  <AddCompetencyGroupTargets
                    key={logbookEntrySetupMasterId}
                    logbookEntrySetupMasterId={logbookEntrySetupMasterId}
                    logbookGroup={logbookGroup}
                    logbookEntryFieldList={logbookEntryFieldList}
                    setSearch={setSearch}
                    submitStatus={submitted}
                    readOnly={true}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      )}
    </>
  );
};
