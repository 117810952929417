import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Form, Input, Label } from 'reactstrap';
import InputField from 'src/components/Input';
import { setFromErrors } from 'src/helpers';
import { useLazyGetDepartmentsByInstituteQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import {
  useCreateTrainingRecordMutation
} from 'src/rtk/features/trainingRecord/trainingRecordlApi';

import Select from 'react-select';
import * as Yup from 'yup';
import loader from '../../../assets/images/loader.gif';

const AddTrainingProfile = () => {

  const initialValues: TrainingRecord = {
    id: '',
    supervisor_id: '',
    name_of_institute: '',
    training_title: '',
    others_institute_value: '',
    department: '',
    others_department_value: '',
    supervisor_name: '',
    supervisor_designation: '',
    others_supervisor_designation_value: '',
    supervisor_post_graduation_degree: '',
    duration: '',
    duration_unit: '',
    document: '',
  };

  const FILE_SIZE = 1024 * 1024;

  const fileInputRef = useRef<any>();
  const buttonRef = useRef<any>();
  // const { data: supervisors } = useGetSupervisorsQuery();

  // const supervisorList: Supervisors[] = supervisors?.data || [];

  const { data: instituteLookupData } = useGetOrganizationsQuery();

  const [instituteName, setInstituteName] = useState('');
  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [selectedDepartment, setSelectedDepartment] = useState<any>('');

  const handleInstitute = (e: any) => {
    if (e.target.value) {
      setInstituteName(e.target.value);
    }
  };

  const [getDepartmentsByInstitute, { data: departmentLookupData }] = useLazyGetDepartmentsByInstituteQuery();

  useEffect(() => {
    if (instituteName !== '') {
      getDepartmentsByInstitute(instituteName);
    }
  }, [instituteName]);

  const { data: designationLookupData } = useGetLookupDataQuery('designation');

  const instituteList: Organization[] =
    (instituteLookupData as any)?.data || [];

  const allInstitute: SelectOption[] =
    instituteLookupData?.data?.map((institute) => ({
      value: institute.organization_name,
      label: institute.organization_name,
    })) || [];

  allInstitute.push({ value: 'Others', label: 'Others' });

  const departmentList: Department[] =
    (departmentLookupData as any)?.data || [];

  const allDepartment: SelectOption[] =
    departmentLookupData?.data?.map((department: any) => ({
      value: department.department_name,
      label: department.department_name,
    })) || [];

  allDepartment.push({ value: 'Others', label: 'Others' });

  const designationList: Lookup[] =
    (designationLookupData as any)?.data[0]?.childs || [];

  const [createTrainingRecord, { isSuccess, isError, error, isLoading }] =
    useCreateTrainingRecordMutation();


  const durationUnitOptions = [
    { value: 'Day', label: 'Day' },
    { value: 'Month', label: 'Month' },
    { value: 'Year', label: 'Year' },
  ];

  const err: CustomError = error as CustomError;

  const {
    values,
    errors,
    touched,
    handleChange,
    getFieldProps,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
    setErrors,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name_of_institute: Yup.string().required('Institute is Required'),
      training_title: Yup.string().required('Title is Required'),
      supervisor_designation: Yup.string().required(
        'Supervisor Designation is Required',
      ),
      others_supervisor_designation_value: Yup.string().when(
        'supervisor_designation',
        (supervisor_designation, schema) => {
          if (supervisor_designation === 'Others') {
            return schema.required('Designation is Required!');
          }
          return schema;
        },
      ),
      supervisor_post_graduation_degree: Yup.string().required(
        'Supervisor Post Graduate Qualification is Required',
      ),
      supervisor_name: Yup.string().required('Supervisor Name is Required'),
      duration: Yup.number().required('Duration is Required'),
      duration_unit: Yup.string().required('Select Duration Unit is Required'),
      others_institute_value: Yup.string().when(
        'name_of_institute',
        (name_of_institute, schema) => {
          if (name_of_institute === 'Others') {
            return schema.required('Institute is Required!');
          }
          return schema;
        },
      ),
      department: Yup.string().required('Department is Required'),
      others_department_value: Yup.string().when(
        'department',
        (department, schema) => {
          if (department === 'Others') {
            return schema.required('Department is Required!');
          }
          return schema;
        },
      ),
      document: Yup.mixed()
        .required('File is Required')
        .test('is-pdf', 'Must be a File', (value) => {
          if (value) {
            return ['application/pdf', 'image/jpeg', 'image/png', 'image/jpg'].includes(value.type);
          }
          return true;
        })
        .test('file-size', 'File too large', (value) => {
          if (value) {
            return value.size <= FILE_SIZE;
          }
          return true;
        }),
    }),
    onSubmit: (values: any) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      buttonRef.current.disabled = true;
      createTrainingRecord(formData);
    },
  });

  useEffect(() => {
    if (isSuccess) {
      setSelectedInstitute('');
      setSelectedDepartment('');
      resetForm();
      fileInputRef.current.value = '';
      toast.success('Training Record Stored Successfully!');
      buttonRef.current.disabled = false;
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
      buttonRef.current.disabled = false;
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    console.log(selectedInstitue?.value);
    if (selectedInstitue !== '') {
      setFieldValue('name_of_institute', selectedInstitue?.value);
      setInstituteName(selectedInstitue?.value);
    }
  }, [selectedInstitue]);

  useEffect(() => {
    console.log(selectedDepartment?.value);
    if (selectedDepartment !== '') {
      setFieldValue('department', selectedDepartment?.value);
    }
  }, [selectedDepartment]);

  return (
    <div>
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="incremental-from-field-grid">
          <div className="large-from-grid">
            <div className="card">
              <div className="card-body">
                <div>
                  <Label className="form-label required-field">Training Title</Label>
                  <Input
                    type="text"
                    name="training_title"
                    placeholder='Enter Training Title'
                    value={values.training_title}
                    onChange={handleChange}
                  >
                  </Input>
                  {errors.training_title && touched.training_title ? (
                    <div className="text-danger">{errors.training_title as string}</div>
                  ) : null}
                </div>
                <div>
                  <Label for="name_of_institute" className='required-field'>Institute</Label>
                  {/* <Input
                type="select"
                name="name_of_institute"
                value={values.name_of_institute}
                onChange={(e) => {
                  handleChange(e);
                  handleInstitute(e);
                }}
              >
                <option value="">Select Institute</option>
                {instituteList?.map((instituteItem) => (
                  <option key={instituteItem.id} value={instituteItem.organization_name}>
                    {instituteItem.organization_name}
                  </option>
                ))}
                <option value="Others">Others</option>
              </Input> */}
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    value={selectedInstitue}
                    onChange={setSelectedInstitute}
                    options={allInstitute}
                    placeholder='Select Institute'
                    name="name_of_institute"
                  />
                  {errors.name_of_institute && touched.name_of_institute ? (
                    <div className="text-danger">{errors.name_of_institute as string}</div>
                  ) : null}
                </div>

                {values.name_of_institute === 'Others' ? (
                  <div>
                    <Label className="form-label required-field">
                      Other Institute
                    </Label>
                    <InputField
                      type="text"
                      placeholder="Other Institute"
                      invalid={{ errors, touched }}
                      {...getFieldProps('others_institute_value')}
                    />
                  </div>
                ) : null}

                <div>
                  <Label for="exampleSelect" className="form-label required-field">Department</Label>
                  {/* <Input
                type="select"
                name="department"
                value={values.department}
                onChange={handleChange}
              >
                <option value="">Select Department</option>
                {departmentList?.map((departmentItem) => (
                  <option key={departmentItem.id} value={departmentItem.department_name}>
                    {departmentItem.department_name}
                  </option>
                ))}
                <option value="Others">Others</option>
              </Input> */}
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    value={selectedDepartment}
                    onChange={setSelectedDepartment}
                    options={allDepartment}
                    placeholder='Select Department'
                    name="department"
                  />
                  {errors.department && touched.department ? (
                    <div className="text-danger">{errors.department as string}</div>
                  ) : null}
                </div>

                {values.department === 'Others' ? (
                  <div>
                    <Label className="form-label required-field">
                      Other Department
                    </Label>
                    <InputField
                      type="text"
                      placeholder="Other Department"
                      invalid={{ errors, touched }}
                      {...getFieldProps('others_department_value')}
                    />
                  </div>
                ) : null}

                <div>
                  <div className="row">
                    <div className="col-sm-6 me-0 pe-0">
                      <Label className="form-label required-field">Duration</Label>
                      <InputField
                        name="duration"
                        type="number"
                        min={'1'}
                        placeholder="Duration of the training"
                        onChange={handleChange}
                        value={values.duration}
                        invalid={{ errors, touched }}
                      />
                    </div>

                    <div className="col-sm-6 ms-0 ps-0">
                      <Label className="form-label required-field">Duration Unit</Label>
                      <InputField
                        name="duration_unit"
                        type="select"
                        placeholder="Duration of the training"
                        onChange={handleChange}
                        value={values.duration_unit}
                        options={durationUnitOptions}
                        invalid={{ errors, touched }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <Label className="form-label required-field">Document</Label> <i className="fas fa-info-circle" title="Upload training document (if any) as per the training. Format must be .pdf or .jpg or .png. Size cannot be greater than 1MB."></i>
                  <input
                    ref={fileInputRef}
                    name="document"
                    type="file"
                    className="form-control"
                    placeholder="Upload Document"
                    onChange={(e: any) => {
                      setFieldValue('document', e.target.files[0]);
                    }}
                  ></input>
                  {errors.document && touched.document ? (
                    <div className="text-danger">{errors.document as string}</div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div>
                  <Label className="form-label required-field">Supervisor Name</Label>
                  <Input
                    type="text"
                    name="supervisor_name"
                    placeholder='Supervisor Name'
                    value={values.supervisor_name}
                    onChange={handleChange}
                  >
                    {/* <option value="">Select Supervisor</option>
                {supervisorList?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))} */}
                  </Input>
                  {errors.supervisor_name && touched.supervisor_name ? (
                    <div className="text-danger">{errors.supervisor_name as string}</div>
                  ) : null}
                </div>
                <div>
                  <Label className="form-label required-field">Supervisor's Designation</Label>
                  <Input
                    type="select"
                    name="supervisor_designation"
                    value={values.supervisor_designation}
                    onChange={handleChange}
                  >
                    <option>Select Designation</option>
                    {designationList?.map((designationItem) => (
                      <option key={designationItem.id} value={designationItem.name}>
                        {designationItem.name}
                      </option>
                    ))}
                    <option value="Others">Others</option>
                  </Input>
                  {errors.supervisor_designation &&
                    touched.supervisor_designation ? (
                    <div className="text-danger">
                      {errors.supervisor_designation as string}
                    </div>
                  ) : null}
                </div>

                {values.supervisor_designation === 'Others' ? (
                  <div>
                    <Label className="form-label required-field">
                      Other Designation
                    </Label>
                    <InputField
                      type="text"
                      placeholder="Other Designation"
                      invalid={{ errors, touched }}
                      {...getFieldProps('others_supervisor_designation_value')}
                    />
                  </div>
                ) : null}

                <div>
                  <Label className="form-label required-field">
                    Supervisor's Post Graduate Qualification
                  </Label>
                  <InputField
                    name="supervisor_post_graduation_degree"
                    type="textarea"
                    placeholder="Supervisor's Post Graduate Qualification"
                    onChange={handleChange}
                    value={values.supervisor_post_graduation_degree}
                    invalid={{ errors, touched }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex gap-2">
            <button className="btn btn-primary px-5" type="submit" ref={buttonRef}>
              Add
            </button>
            <button
              className="btn btn-outline-secondary px-5"
              type="button"
              onClick={(e) => {
                setSelectedInstitute('');
                setSelectedDepartment('');
                resetForm();
                fileInputRef.current.value = '';
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddTrainingProfile;
