import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Button, Form, Input, Label } from 'reactstrap';
import InputField from 'src/components/Input';
import { setFromErrors } from 'src/helpers';
import { useAppSelector } from 'src/rtk/app/hook';
import { useLazyGetDepartmentsByInstituteQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetInstitutesByOrganizationQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useGetPersonalProfileQuery } from 'src/rtk/features/trainee/traineeApi';
import {
  useCreateWorkExperienceMutation
} from 'src/rtk/features/workExperience/workExperienceApi';
import * as Yup from 'yup';
import loader from '../../../assets/images/loader.gif';

interface Props {
  nextDisabled?: any;
  length?: number;
}
const AddExperienceProfile = (props: Props) => {

  const initialValues: WorkExperience = {
    id: '',
    employment_type: 'Government',
    designation: '',
    others_designation_value: '',
    institute_name: '',
    department_name: '',
    others_department_value: '',
    others_institute_value: '',
    working_address: '',
    from_date: '',
    to_date: '',
    current_position: 0,
    department_head: '',
    in_charge: '',
    unit_name: '',
    period_from: '',
    // period_to: '',
    reason: '',
  };

  const buttonRef = useRef<any>();
  const authUser = useAppSelector((state) => state.auth.user);

  const [currentPosition, SetCurrentPosition] = useState(true);
  const [currentPosCheck, setCurrentPosCheck] = useState<boolean>(false);
  const [inChargeCheck, setInChargeCheck] = useState<boolean>(false);
  const [notAtWorkCheck, setNotAtWorkCheck] = useState<boolean>(false);

  const [organizationName, setOrganizationName] = useState('');
  const [instituteName, setInstituteName] = useState('');

  const [governmentChecked, setGovernmentChecked] = useState<boolean>(true);
  const [privateChecked, setPrivateChecked] = useState<boolean>(false);
  const [foreignChecked, setForeignChecked] = useState<boolean>(false);

  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [selectedDepartment, setSelectedDepartment] = useState<any>('');

  const { data: designationLookupData } = useGetLookupDataQuery('designation');

  const { data: instituteLookupData } = useGetInstitutesByOrganizationQuery(organizationName);

  const [getDepartmentsByInstitute, { data: departmentLookupData }] = useLazyGetDepartmentsByInstituteQuery();

  const departmentList: Department[] =
    (departmentLookupData as any)?.data || [];

  useEffect(() => {
    if (instituteName !== '') {
      getDepartmentsByInstitute(instituteName);
    }
  }, [instituteName]);

  const allDepartment: SelectOption[] =
    departmentLookupData?.data?.map((department) => ({
      value: department.department_name,
      label: department.department_name,
    })) || [];

  departmentLookupData && allDepartment.push({ value: 'Others', label: 'Others' });

  const designationList: Lookup[] =
    (designationLookupData as any)?.data[0]?.childs || [];

  const instituteList: Organization[] =
    (instituteLookupData as any)?.data || [];

  const allInstitute: SelectOption[] =
    instituteLookupData?.data?.map((institute) => ({
      value: institute.organization_name,
      label: institute.organization_name,
    })) || [];

  // allInstitute.push({ value: 'Others', label: 'Others' });
  const { data: personalProfile } = useGetPersonalProfileQuery();

  useEffect(() => {
    setOrganizationName('Government');
  }, []);


  const [createWorkExperience, { data: createWorkExperienceData, isSuccess, isError, error, isLoading }] =
    useCreateWorkExperienceMutation();

  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    touched,
    errors,
    values,
    setValues,
    handleChange,
    handleBlur,
    setFieldValue,
    resetForm,
    setErrors,
  } = useFormik<WorkExperience>({
    initialValues,
    validationSchema: Yup.object({
      designation: Yup.string().required('Select your designation'),
      others_designation_value: Yup.string().when(
        'designation',
        (designation, schema) => {
          if (designation === 'Others') {
            return schema.required('Designation is Required!');
          }
          return schema;
        },
      ),
      institute_name: Yup.string().required('Institute is Required'),
      from_date: Yup.date().max(new Date(Date.now()), 'From date can\'t be a future date').required('From Date is Required'),
      to_date: Yup.date().when("from_date", (from_date, schema) => {
        // if (from_date) {
        //   // This can be calculated in many ways. Just be sure that its type is `Date` object
        //   const fromDate = new Date(from_date);

        //   return schema.min(fromDate, 'To date must be greater than from date');
        // }
        if (!currentPosCheck && from_date) {
          const fromDate = new Date(from_date);

          return schema.required('To date is Required').min(fromDate, 'To date must be greater than from date').max(new Date(), 'To date must be before today').nullable();
        }

        return schema;
      }),
      others_institute_value: Yup.string().when(
        'institute_name',
        (institute_name, schema) => {
          if (institute_name === 'Others') {
            return schema.required('Institute is Required!');
          }
          return schema;
        },
      ),
      department_name: Yup.string().required('Department is Required'),
      others_department_value: Yup.string().when(
        'department_name',
        (department_name, schema) => {
          if (department_name === 'Others') {
            return schema.required('Department is Required!');
          }
          return schema;
        },
      ),
      unit_name: inChargeCheck ? Yup.string().required('Unit Name is Required') : Yup.string(),
    }),
    onSubmit(values: any) {
      console.log(values);
      values.from_date = moment(
        values.from_date,
      ).format('YYYY-MM-DD');
      values.to_date ? values.to_date = moment(
        values.to_date,
      ).format('YYYY-MM-DD') : values.to_date = '';
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      buttonRef.current.disabled = true;
      createWorkExperience(formData);
    },
  });

  const handleInstitute = (e: any) => {
    if (e.target.value) {
      setInstituteName(e.target.value);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      setSelectedInstitute('');
      setSelectedDepartment('');
      if (createWorkExperienceData?.success) {
        toast.success('Experience Information Stored Successfully!');
        buttonRef.current.disabled = false;
      } else {
        if (createWorkExperienceData?.data?.errors) {
          toast.error(createWorkExperienceData?.data?.message);
          buttonRef.current.disabled = false;
        } else {
          toast.error(createWorkExperienceData?.message!);
          buttonRef.current.disabled = false;
        }
      }

      SetCurrentPosition(true);
      setGovernmentChecked(true);
      setPrivateChecked(false);
      setForeignChecked(false);
      setCurrentPosCheck(false);
      setInChargeCheck(false);
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
      SetCurrentPosition(true);
      setGovernmentChecked(true);
      setPrivateChecked(false);
      setForeignChecked(false);
      setCurrentPosCheck(false);
      setInChargeCheck(false);
      buttonRef.current.disabled = false;
    }
  }, [isSuccess, isError]);

  const handlePosition = (e: any) => {
    if (e.target.checked) {
      SetCurrentPosition(false);
      setFieldValue('to_date', '');
    } else {
      SetCurrentPosition(true);
    }
  };

  const handleCurrentPosCheck = (e: any) => {
    setCurrentPosCheck(e.target.checked);
    setInChargeCheck(false);
  };

  const handleInChargeCheck = (e: any) => {
    setInChargeCheck(e.target.checked);
    setFieldValue('in_charge', e.target.checked);
  };

  const handleHeadOfDepartment = (e: any) => {
    setFieldValue('department_head', e.target.checked);
  };

  const handleGovernmentChecked = (e: any) => {
    setGovernmentChecked(e.target.checked);
    setPrivateChecked(!e.target.checked);
    setForeignChecked(!e.target.checked);

    setOrganizationName(e.target.value);
  };

  const handlePrivateChecked = (e: any) => {
    setGovernmentChecked(!e.target.checked);
    setPrivateChecked(e.target.checked);
    setForeignChecked(!e.target.checked);

    setOrganizationName(e.target.value);
  };

  const handleForeignChecked = (e: any) => {
    setGovernmentChecked(!e.target.checked);
    setPrivateChecked(!e.target.checked);
    setForeignChecked(e.target.checked);

    setOrganizationName(e.target.value);
  };
  const handleNotAtWorkCheck = (e: any) => {
    if (e.target.checked) {
      setNotAtWorkCheck(true);
      // props.nextDisabled(false);
      const data = {
        not_at_work: e.target.checked,
        registration_no: authUser?.registration_no,
      }
      // createWorkExperience(data);
    }
    else {
      setNotAtWorkCheck(false);
      // props.nextDisabled(true);
      const data = {
        not_at_work: e.target.checked,
        registration_no: authUser?.registration_no,
      }
      // createWorkExperience(data);
    }
  }

  const handleReasonForNotWork = () => {
    const data = {
      not_at_work: notAtWorkCheck,
      registration_no: authUser?.registration_no,
      period_from: values?.period_from,
      // period_to: values?.period_to ? values?.period_to : new Date(Date.now()).toISOString().slice(0, 10),
      reason: values?.reason,
    }
    createWorkExperience(data);
    console.log(data);
  }


  useEffect(() => {
    // if(props.length! > 0) {
    //   setNotAtWorkCheck(false)
    // } else {
    setNotAtWorkCheck(personalProfile?.data?.details?.not_at_work!);

    if (personalProfile?.data?.details?.period_from && personalProfile?.data?.details?.reason) {
      setFieldValue('period_from', personalProfile?.data?.details?.period_from);
      // setFieldValue('period_to', personalProfile?.data?.details?.period_to);
      setFieldValue('reason', personalProfile?.data?.details?.reason);
      console.log('123');
    }
    // }
  }, [personalProfile, isSuccess]);

  // useEffect(() => {
  //   if (notAtWorkCheck) {
  //     props.nextDisabled(false);
  //   }
  // }, [notAtWorkCheck]);

  useEffect(() => {
    if (personalProfile?.data?.details?.period_from && personalProfile?.data?.details?.reason && notAtWorkCheck) {
      props.nextDisabled(false);
    }
    else {
      if(personalProfile?.data?.details?.employment_infos?.length! > 0){
        props.nextDisabled(false);
      }
      else{
        props.nextDisabled(true);
      }
    }
  }, [personalProfile?.data?.details?.period_from , personalProfile?.data?.details?.reason, notAtWorkCheck]);

  useEffect(() => {
    console.log(selectedInstitue?.value);
    // if(selectedInstitue !== ''){
    //   setFieldValue('institute_name', selectedInstitue?.value);
    //   setInstituteName(selectedInstitue?.value);
    // }
    if (selectedInstitue !== '') {
      setInstituteName(selectedInstitue?.value);
    }
    if (selectedInstitue?.value) {
      let selectedId = selectedInstitue.value;
      if (
        allInstitute.find(
          (option: SelectOption) =>
            option.value === selectedId,
        )
      ) {
        setFieldValue('institute_name', selectedId);
      } else {
        setFieldValue('institute_name', 'Others');
        setFieldValue('others_institute_value', selectedId);
      }
    } else {
      setFieldValue('institute_name', '');
    }
  }, [selectedInstitue]);

  useEffect(() => {
    console.log(selectedDepartment?.value);
    if (selectedDepartment !== '') {
      setFieldValue('department_name', selectedDepartment?.value);
    }
  }, [selectedDepartment]);

  return (
    <div>
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="incremental-from-field-grid">
          <div>
            <div className="large-form-field">
              {authUser?.type !== 'Supervisor' &&
                <div className='mb-3 mx-0 row'>
                  <div className='form-check form-check-inline col-md-3'>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="not_at_work"
                      onChange={(e) => {
                        handleNotAtWorkCheck(e);
                      }}
                      checked={notAtWorkCheck}
                      id="not-at-work"
                      // disabled={props.length! > 0}
                      disabled={personalProfile?.data?.details?.current_employment_info !== null}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="not-at-work"
                    >
                      Currently Not At Work
                    </label>
                  </div>
                  {notAtWorkCheck &&
                    <>
                      <div className="d-flex gap-2 input-group-with-label col-md-4">
                        <div>
                          <Label className="form-Label">Period From</Label>
                          <Input
                            className="form-control"
                            type="date"
                            name="period_from"
                            onChange={handleChange}
                            value={values.period_from}
                          />
                          {errors.period_from && touched.period_from ? (
                            <div className="text-danger">
                              {errors.period_from}
                            </div>
                          ) : null}
                        </div>
                        {/* <div>
                      <Label className="form-Label">Period To</Label>
                      <Input
                        className="form-control"
                        type="date"
                        name="period_to"
                        onChange={handleChange}
                        value={values.period_to || new Date(Date.now()).toISOString().slice(0, 10)}
                      />
                      {errors.period_to && touched.period_to ? (
                      <div className="text-danger">
                        {errors.period_to}
                      </div>
                      ) : null}
                    </div> */}
                        <div>
                          <Label>Reason</Label>
                          <InputField
                            type="select"
                            placeholder="Reason"
                            invalid={{ errors, touched }}
                            options={[
                              { value: 'Abroad', label: 'Abroad' },
                              { value: 'Illness', label: 'Illness' },
                              { value: 'Maternity', label: 'Maternity' },
                              { value: 'Others', label: 'Others' },
                            ]}
                            {...getFieldProps('reason')}
                          />
                        </div>
                      </div>
                      <div className='col-md-1 mt-4'>
                        <Button
                          className="btn btn-success"
                          type="button"
                          onClick={handleReasonForNotWork}
                          disabled={values.period_from === '' || values.reason === ''}
                        >
                          Save
                        </Button>
                      </div>
                    </>
                  }
                </div>}
              <h6>Institute Type <i className="fas fa-info-circle" title="Select Government or Private as per your selected institute type."></i></h6>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="employment_type"
                  value="Government"
                  onChange={(e) => {
                    handleGovernmentChecked(e);
                    handleChange(e);
                  }}
                  checked={governmentChecked}
                  id="employment-type-government"
                // disabled={notAtWorkCheck}
                />
                <label
                  className="form-check-label"
                  htmlFor="employment-type-government"
                >
                  Government
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="employment_type"
                  value="Private"
                  onChange={(e) => {
                    handlePrivateChecked(e);
                    handleChange(e);
                  }}
                  checked={privateChecked}
                  id="employment-type-private"
                // disabled={notAtWorkCheck}
                />
                <label
                  className="form-check-label"
                  htmlFor="employment-type-private"
                >
                  Private
                </label>
              </div>

              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="employment_type"
                  value="Foreign"
                  onChange={(e) => {
                    handleForeignChecked(e);
                    handleChange(e);
                  }}
                  checked={foreignChecked}
                  id="employment-type-Foreign"
                // disabled={notAtWorkCheck}
                />
                <label
                  className="form-check-label"
                  htmlFor="employment-type-Foreign"
                >
                  Foreign
                </label>
              </div>
            </div>
            <div className='row mt-3 mb-3'>
              <div className='col-md-4 col-sm-12'>
                <Label className="form-label required-field">Job Title</Label>
                <Input
                  type="select"
                  name="designation"
                  value={values.designation}
                  onChange={handleChange}
                // disabled={notAtWorkCheck}                
                >
                  <option value="">Select Title</option>
                  {designationList?.map((designationItem) => (
                    <option key={designationItem.id} value={designationItem.name}>
                      {designationItem.name}
                    </option>
                  ))}
                  <option value="Others">Others</option>
                </Input>
                {errors.designation && touched.designation ? (
                  <div className="text-danger">
                    {errors.designation}
                  </div>
                ) : null}
              </div>

              {values.designation === 'Others' ? (
                <div className='col-md-4 col-sm-12'>
                  <Label className="form-label required-field">
                    Other Designation
                  </Label>
                  <InputField
                    type="text"
                    placeholder="Other Designation"
                    invalid={{ errors, touched }}
                    {...getFieldProps('others_designation_value')}
                  />
                </div>
              ) : null}

              <div className='col-md-4 col-sm-12'>
                <Label className="form-label required-field">Institute</Label>
                {/* <Input
                  type="select"
                  name="institute_name"
                  value={values.institute_name}
                  onChange={(e) => {
                    handleChange(e); 
                    handleInstitute(e);
                  }}
                  disabled={notAtWorkCheck}
                >
                  <option value="">Select Institute</option>
                  {instituteList?.map((instituteItem) => (
                    <option key={instituteItem.id} value={instituteItem.organization_name}>
                      {instituteItem.organization_name}
                    </option>
                  ))}
                  <option value="Others">Others</option>
                </Input> */}
                <CreatableSelect
                  isClearable={true}
                  isSearchable={true}
                  value={selectedInstitue}
                  onChange={setSelectedInstitute}
                  options={allInstitute}
                  placeholder='Select Institute'
                  name="institute_name"
                // isDisabled={notAtWorkCheck}
                />
                {errors.institute_name && touched.institute_name ? (
                  <div className="text-danger">
                    {errors.institute_name}
                  </div>
                ) : null}
              </div>

              {/* {values.institute_name === 'Others' ? (
                <div>
                  <Label className="form-label required-field">
                    Other Institute
                  </Label>
                  <InputField
                    type="text"
                    placeholder="Other Institute"
                    invalid={{ errors, touched }}
                    {...getFieldProps('others_institute_value')}
                  />
                </div>
              ) : null} */}

              <div className='col-md-4 col-sm-12'>
                <Label for="exampleSelect" className="form-label required-field">Department</Label>
                {/* <Input
                  type="select"
                  name="department_name"
                  value={values.department_name}
                  onChange={handleChange}
                  disabled={notAtWorkCheck}
                >
                  <option value="">Select Department</option>
                  {departmentList?.map((departmentItem) => (
                    <option key={departmentItem.id} value={departmentItem.department_name}>
                      {departmentItem.department_name}
                    </option>
                  ))}
                  <option value="Others">Others</option>
                </Input> */}
                <Select
                  isClearable={true}
                  isSearchable={true}
                  value={selectedDepartment}
                  onChange={setSelectedDepartment}
                  options={allDepartment}
                  placeholder='Select Department'
                  name="department_name"
                // isDisabled={notAtWorkCheck}
                />
                {errors.department_name && touched.department_name ? (
                  <div className="text-danger">{errors.department_name}</div>
                ) : null}
              </div>

              {values.department_name === 'Others' ? (
                <div className='col-md-4 col-sm-12'>
                  <Label className="form-label required-field">
                    Other Department
                  </Label>
                  <InputField
                    type="text"
                    placeholder="Other Department"
                    invalid={{ errors, touched }}
                    {...getFieldProps('others_department_value')}
                  />
                </div>
              ) : null}

              <div className='col-md-4 col-sm-12'>
                <div className="input-group-with-label">
                  <div>
                    <Label className="form-Label required-field">From Date</Label>
                    <Input
                      className="form-control"
                      type="date"
                      name="from_date"
                      onChange={handleChange}
                      value={values.from_date}
                    // disabled={notAtWorkCheck}
                    />
                    {errors.from_date && touched.from_date ? (
                      <div className="text-danger">
                        {errors.from_date}
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <Label className="form-Label">To Date</Label>
                    <Input
                      className="form-control"
                      type="date"
                      name="to_date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.to_date}
                      disabled={currentPosition === false}
                    />
                    {errors.to_date && touched.to_date ? (
                      <div className="text-danger">
                        {errors.to_date}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-3 col-sm-12'>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="current_position"
                    onChange={(e) => {
                      handleCurrentPosCheck(e);
                      handleChange(e);
                    }}
                    checked={currentPosCheck}
                    id="employment-current-position"
                    onClick={(e) => handlePosition(e)}
                  // disabled={notAtWorkCheck}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="employment-current-position"
                  >
                    Current Position
                  </label>
                </div>
              </div>

              {currentPosCheck && (authUser?.type === 'Fellow' || authUser?.type === 'Supervisor') &&
                <>
                  <div className="form-check form-check-inline col-md-3 col-sm-12">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="department_head"
                      id='department-head'
                      onChange={(e: any) => {
                        handleHeadOfDepartment(e);
                        handleChange(e);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="department-head"
                    >
                      Head of the Department?
                    </label>
                  </div>
                  <div className="form-check form-check-inline col-md-3 col-sm-12">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="in_charge"
                      id='in-charge'
                      onChange={(e) => {
                        handleInChargeCheck(e);
                        handleChange(e);
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="in-charge"
                    >
                      In-Charge of an Unit?
                    </label>
                  </div>
                  {inChargeCheck &&
                    <div className='col-md-2 col-sm-12'>
                      <Label className="form-label required-field">
                        Unit Name
                      </Label>
                      <InputField
                        type="text"
                        placeholder="Unit Name"
                        invalid={{ errors, touched }}
                        {...getFieldProps('unit_name')}
                      />
                    </div>}
                </>}
            </div>
          </div>
          <div className="d-flex mt-3 gap-2">
            <button className="btn btn-primary px-5" type="submit" ref={buttonRef}>
              Add
            </button>
            <button
              className="btn btn-outline-secondary px-5"
              type="button"
              onClick={(e) => {
                resetForm();
                setSelectedInstitute('');
                setSelectedDepartment('');
                // setValues(initialValues);
                SetCurrentPosition(true);
                setCurrentPosCheck(false);
                setInChargeCheck(false);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AddExperienceProfile;
