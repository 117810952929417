import { Redirect } from 'react-router-dom';

import AdminLogin from 'src/pages/Authentication/AdminLogin';
import ForgetPasswordPage from 'src/pages/Authentication/ForgetPassword';
import Login from 'src/pages/Authentication/Login';
import Logout from 'src/pages/Authentication/Logout';
import { PasswordChangeForm } from 'src/pages/Authentication/PasswordChangeForm';
import Registration from 'src/pages/Authentication/Registration';
import ResendVerificationEmail from 'src/pages/Authentication/ResendVerificationEmail';
import ResetPassword from 'src/pages/Authentication/ResetPassword';
import userProfile from 'src/pages/Authentication/UserProfile';

import GatewayDemo from 'src/pages/Payment/GatewayDemo';
import SupervisorList from 'src/pages/Supervisor/SupervisorList';
import SupervisorInformation from 'src/pages/Supervisor/SupervisorReg';
import { EmailVerification } from '../pages/Authentication/EmailVerification';
import Dashboard from '../pages/Dashboard';
import { approvalSetupRoutes } from './approvalSetupRoutes';
import { demoRoutes } from './demoRoutes';
import { departmentSetupRoutes } from './departmentSetupRoutes';
import { deviceRegistrationRoutes } from './deviceRegistrationRoutes';
import { dissertationAndThesisRoutes } from './dissertationAndThesisRoutes';
import { documentFileUploadSetupRoutes } from './documentFileUploadSetupRoutes';
import { dynamicRoutes } from './dynamicRoutes';
import { eLogBookRoutes } from './eLogBookRoutes';
import { examEligibilityCriteriaSetupRoutes } from './examEligibilityCriteriaSetupRoutes';
import { examInfoRoutes } from './examInfoRoutes';
import { examTrainingFeeSetupRoutes } from './examTrainingFeeSetupRoutes';
import { facultyInfoSetupRoutes } from './facultyInfoSetupRoutes';
import { facultyRoutes } from './facultyRoutes';
import { guideRoutes } from './guideRoutes';
import { instituteRoutes } from './instituteRoutes';
import { logBookDynamicFormPropertiesSetupRoutes } from './logBookDynamicFormPropertiesSetupRoutes';
import { logBookEntryMasterRoutes } from './logBookEntryMaster';
import { logBookEntrySetupDetailsRecordRoutes } from './logBookEntrySetupDetailsRecordRoutes';
import { logBookEntrySetupDetailsValueRoutes } from './logBookEntrySetupDetailsValueRoutes';
import { logBookEntrySetupMasterRoutes } from './logBookEntrySetupMasterRoutes';
import { logBookEssentialInfoSetupRoutes } from './logBookEssentialInfoSetupRoutes';
import { logBookMasterSetupRoutes } from './logBookMasterSetup';
import { logBookTableOfContentSetupRoutes } from './logBookTableOfContentSetup';
import { logbookEntrySetupDetailsRoutes } from './logbookEntrySetupDetailsRoutes';
import { logbookTrainingSummaryRoutes } from './logbookTrainingSummaryRoutes';
import { lookupRoutes } from './lookupRoutes';
import { myTrainingRoutes } from './myTrainingRoutes';
import { noticeRoutes } from './noticeRoutes';
import { noticeTemplateDetailsSetupRoutes } from './noticeTemplateDetailsSetupRoutes';
import { noticeTemplateListSetupRoutes } from './noticeTemplateListSetupRoutes';
import { organizationSetupRoutes } from './organizationSetupRoutes';
import { paymentRoutes } from './paymentRoutes';
import { registrationInfoRoutes } from './registrationInfoRoutes';
import { roleRoutes } from './roleRoutes';
import { roomSetupRoutes } from './roomSetupRoutes';
import { rtmdRoutes } from './rtmdRoutes';
import { subjectInfoSetupRoutes } from './subjectInfoSetupRoutes';
import { subjectSetupRoutes } from './subjectSetupRoutes';
import { supervisorRoutes } from './supervisorRoutes';
import { traineeRoutes } from './traineeRoutes';
import { trainingWorkshopCreateRoutes } from './trainingWorkshopCreateRoutes';
import { trainingWorkshopRoutes } from './trainingWorkshopRoutes';
import { trnNoticeDetailsRoutes } from './trnNoticeDetailsRoutes';
import { trnNoticeMasterRoutes } from './trnNoticeMasterRoutes';
import { userRoutes } from './userRoutes';
import { logBookMonitoringRoutes } from './logBookMonitoringRoutes';
import { researchClinicSetupRoutes } from './researchClinicSetupRoutes';
import { researchClinicFaqRoutes } from './researchClinicFaqRoutes';
import { logBookAssesmentRoutes } from './logBookAssesmentRoutes';
import InstituteAdminLogin from 'src/pages/Authentication/InstituteAdminLogin';
import InstituteAdminRegistration from 'src/pages/Authentication/InstituteAdminRegistration';
import { certificateRoutes } from './certificateRoutes';
import { noticeApprovalRoutes } from './noticeApprovalRoutes';
import { leaveRoutes } from './leaveRoutes';
import { inspectionTeamRoutes } from './inspectionTeamRoutes';
import { dashboardRoutes } from './dashboardRoutes';
import { thesisEntrySetupRoutes } from './thesisEntrySetupRoutes';
import { traininAllowanceRoutes } from './trainingAllowanceRoutes';
import { listOfTitleProtocolAndThesisRoutes } from './listOfTitleProtocolAndThesisDissertationRoutes';
import OnlinePaymentForm from 'src/pages/Payment/OnlinePaymentForm';
import NotFound from 'src/components/Common/NotFound';
import ServerError from 'src/components/Common/ServerError';
import { logBookEntrySessionRoutes } from './logBookEntrySession';

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const protectedRoutes: Array<RouteProps> = [
  //User Profile
  { path: '/profile', component: userProfile },

  { path: '/change-password', component: PasswordChangeForm },

  //dashboard
  ...dashboardRoutes,

  //trainee
  ...demoRoutes,

  //trainee
  ...traineeRoutes,

  //supervisor
  ...supervisorRoutes,

  //guide/co-guide
  ...guideRoutes,

  //Training Workshop
  ...trainingWorkshopRoutes,

  //faculty
  ...facultyRoutes,

  //subjectSetup
  ...subjectSetupRoutes,

  // users
  ...userRoutes,

  // role
  ...roleRoutes,

  { path: '/supervisor/registration', component: SupervisorInformation },
  { path: '/supervisor/list', component: SupervisorList },
  //guide/co-guide
  ...guideRoutes,

  //Training Workshop
  ...trainingWorkshopRoutes,

  // users
  ...userRoutes,

  // role
  ...roleRoutes,

  // Enrollment
  { path: '/enrollment-payment', component: GatewayDemo },

  // Online Payment
  { path: '/online-payment-form', component: OnlinePaymentForm },

  // institute
  ...instituteRoutes,

  // departmentSetup
  ...departmentSetupRoutes,

  // lookup routes
  ...lookupRoutes,

  //organizationSetup
  ...organizationSetupRoutes,

  //roomSetup
  ...roomSetupRoutes,

  //facultyInfoSetup
  ...facultyInfoSetupRoutes,

  //subjectInfoSetup
  ...subjectInfoSetupRoutes,

  // approval setup
  ...approvalSetupRoutes,

  // exam info routes
  ...examInfoRoutes,

  // dynamic routes info routes
  ...dynamicRoutes,

  //examEligibilityCriteriaSetup
  ...examEligibilityCriteriaSetupRoutes,

  // exam training fee routes
  ...examTrainingFeeSetupRoutes,

  //documentFileUploadSetup
  ...documentFileUploadSetupRoutes,

  // notice template list routes
  ...noticeTemplateListSetupRoutes,

  // notice template details routes
  ...noticeTemplateDetailsSetupRoutes,

  // registration info routes
  ...registrationInfoRoutes,

  // rtdm routes
  ...rtmdRoutes,

  //trn notice master routes
  ...trnNoticeMasterRoutes,

  //trn notice details routes
  ...trnNoticeDetailsRoutes,

  //my training
  ...myTrainingRoutes,

  // Log Book Master Setup
  ...logBookMasterSetupRoutes,

  // Log Book Entry Master
  ...logBookEntryMasterRoutes,

  // Log Book Entry Master
  ...logBookEntrySessionRoutes,

  // Log Book Table of Content
  ...logBookTableOfContentSetupRoutes,

  // Log Book Entry Setup Master
  ...logBookEntrySetupMasterRoutes,

  // Log Book Setup Details
  ...logbookEntrySetupDetailsRoutes,

  // Log Book Entry Setup Details Value
  ...logBookEntrySetupDetailsValueRoutes,

  // Log Book Essential Info
  ...logBookEssentialInfoSetupRoutes,

  // Log Book Entry Setup Details Record
  ...logBookEntrySetupDetailsRecordRoutes,

  // payment
  ...paymentRoutes,

  // trainee device registration
  ...deviceRegistrationRoutes,

  //E-Log Book
  ...eLogBookRoutes,

  // logbook dynamic form properties setup
  ...logBookDynamicFormPropertiesSetupRoutes,

  // logbook training summary routes
  ...logbookTrainingSummaryRoutes,

  // create training workshop routes
  ...trainingWorkshopCreateRoutes,

  // notice routes
  ...noticeRoutes,

  // notice approval routes
  ...noticeApprovalRoutes,

  // certificate routes
  ...certificateRoutes,

  // disssertation and thesis
  ...dissertationAndThesisRoutes,

  // logbookMonitoring
  ...logBookMonitoringRoutes,

  // researchClinicSetup
  ...researchClinicSetupRoutes,

  // research clinic faq
  ...researchClinicFaqRoutes,

  // logbookAssesment
  ...logBookAssesmentRoutes,

  // leaveRecords
  ...leaveRoutes,

  // inspectionTeam
  ...inspectionTeamRoutes,

  //thesis entry setup routes
  ...thesisEntrySetupRoutes,

  //allowance routes
  ...traininAllowanceRoutes,

  //thesis dissertation list routes
  ...listOfTitleProtocolAndThesisRoutes,

  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: '*', exact: true, component: () => <Redirect to="/not-found" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: '/login', component: Login },
  { path: '/admin-login', component: AdminLogin },
  { path: '/institute-login', component: InstituteAdminLogin },
  { path: '/logout', component: Logout },
  { path: '/registration', component: Registration },
  { path: '/institute-registration', component: InstituteAdminRegistration },
  { path: '/forget-password', component: ForgetPasswordPage },
  { path: '/password-reset', component: ResetPassword },
  { path: '/email-verification', component: EmailVerification },
  { path: '/resend-verification-email', component: ResendVerificationEmail },
  { path: '/not-found', component: NotFound },
  { path: '/server-error', component: ServerError },
];

export { protectedRoutes, authRoutes };
