import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import { setFromErrors } from 'src/helpers';
import { useCreateSupervisorCourseApplyMutation } from 'src/rtk/features/myTraining/myTrainingApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';
import { SessionApplyComponent } from './SessionApplyComponent';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
  trainingType?: string;
  slotName?: string;
  fcpsPartOneInfo: any;
}

const tabStyling: any = {
  navTabs: {
    borderBottom: "1px solid black",
  },

  navItem: {
    marginRight: "4px",
  },

  navLink: {
    marginBottom: "-1px",
    cursor: "pointer",
  },

  navLinkActive: {
    marginBottom: "-1px",
    cursor: "pointer",
    color: "#000000",
    backgroundColor: "#ffffff",
    borderColor: "#ced4da #ced4da #fff",
  }
};

const CoreTrainingLogbookModal = (props: Props) => {
  const { isOpen, toggle, id, trainingType, slotName, fcpsPartOneInfo } = props;

  const initialValues: SupervisorCourseApply = {
    exam_course_id: '',
    supervisor_id: '',
    start_date: '',
    end_date: '',
    course_session: '',
    institute_name: '',
    department: '',
  };

  const [instituteName, setInstituteName] = useState('');
  const [activeTab, setActiveTab] = useState<string>('choice1');
  const [instituteForDeparment, setInstituteForDeparment] = useState('');

  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [selectedDepartment, setSelectedDepartment] = useState<any>('');
  const [selectedSupervisor, setSelectedSupervisor] = useState<any>('');

  // const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  // const currentYear = new Date().getFullYear();

  const [createSupervisorCourseApply, { isError, isSuccess, error, isLoading }] =
    useCreateSupervisorCourseApplyMutation();

  const { data: instituteLookupData } = useGetOrganizationsQuery();

  const allInstitute: SelectOption[] =
    instituteLookupData?.data?.map((institute) => ({
      value: institute.organization_name,
      label: institute.organization_name,
    })) || [];

  const sessionOptions = [
    { label: 'Select Session', value: '' },
    { label: 'January', value: 'January-June' },
    { label: 'July', value: 'July-December' },
  ];

  const err: CustomError = error as CustomError;

  const {
    handleSubmit,
    getFieldProps,
    handleChange,
    touched,
    errors,
    values,
    resetForm,
    setErrors,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      supervisor_id: Yup.string().required('Select Supervisor'),
      course_session: Yup.string().required('Select Session'),
      start_date: Yup.string().required('Select Statring Date'),
      end_date: Yup.string().required('Select End Date'),
    }),

    onSubmit: (values) => {
      values.exam_course_id = id;
      createSupervisorCourseApply(values);
    },
  });

  const handleFromDateChange = (event: any) => {
    // const selectedSession = event.target.value;

    // if (selectedSession === "January-June") {
    //   setFromDate(new Date(currentYear, 0, 2));
    //   setToDate(new Date(currentYear, 6, 1));
    // } else if (selectedSession === "July-December") {
    //   setFromDate(new Date(currentYear, 6, 2));
    //   setToDate(new Date(currentYear, 12, 1));
    // }
    // else{
    //   setFromDate(null);
    //   setToDate(null);
    // }
    if (event.target.value !== '') {
      const fromDate = new Date(event.target.value);
      const newToDate = new Date(fromDate.getFullYear(), fromDate.getMonth() + 6, fromDate.getDate());
      // newToDate.setDate(newToDate.getDate() + 180);
      setToDate(newToDate);
    } else {
      setToDate(null);
    }
  };

  useEffect(() => {
    if (toDate) {
      setFieldValue('end_date', toDate?.toISOString()?.slice(0, 10) || "");
    }
  }, [toDate]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Core Training Logbook Created successfully');
      toggle(false);
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  const handleInstitute = (e: any) => {
    setInstituteName(e.target.value);
    setInstituteForDeparment(e.target.value);
  };

  const toggleTab = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };


  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="lg">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <ModalHeader className="slot-apply-header" toggle={toggle}>Apply for {trainingType} - {slotName}</ModalHeader>

      <ModalBody>
        <div>
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="row justify-content-center">
              <div className="col-md-10">
                <div className='mb-3'>
                  <div className="input-group-with-label">
                    <div className="m-0">
                      <Label className="form-Label">FCPS Part-1 Passing Session</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="course_session"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        readOnly
                        value={fcpsPartOneInfo?.fcps_part1_info?.fcps_part_one_passing_session}
                      />
                    </div>
                    <div className="m-0">
                      <Label className="form-Label">FCPS Part-1 Passing Year</Label>
                      <Input
                        className="form-control"
                        type="text"
                        name="course_year"
                        onChange={handleChange}
                        readOnly
                        value={fcpsPartOneInfo?.fcps_part1_info?.fcps_part_one_passing_year}
                      />
                    </div>
                  </div>
                </div>
                <div className='mb-3'>
                  <div className="input-group-with-label">
                    <div className="m-0">
                      <Label className="form-Label required-field">From Date</Label>
                      <Input
                        className="form-control"
                        type="date"
                        name="start_date"
                        onChange={(e) => {
                          handleFromDateChange(e);
                          handleChange(e);
                        }}
                        value={values.start_date}
                        // min={new Date(new Date().getFullYear(), new Date().getMonth() - 1, 2).toISOString().slice(0, 10)}
                      />
                      {errors.start_date && touched.start_date ? (
                        <div className="text-danger">{errors.start_date}</div>
                      ) : null}
                    </div>
                    <div className="m-0">
                      <Label className="form-Label required-field">To Date</Label>
                      <Input
                        className="form-control"
                        type="date"
                        name="end_date"
                        // onChange={handleChange}
                        value={values.end_date}
                      />
                      {errors.end_date && touched.end_date ? (
                        <div className="text-danger">{errors.end_date}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <Nav tabs>
                  <NavItem style={tabStyling.navItem}>
                    <NavLink
                      style={activeTab === 'choice1' ? tabStyling.navLinkActive : tabStyling.navLink}
                      onClick={() => toggleTab('choice1')}
                    >
                      1st Choice
                    </NavLink>
                  </NavItem>
                  <NavItem style={tabStyling.navItem}>
                    <NavLink
                      style={activeTab === 'choice2' ? tabStyling.navLinkActive : tabStyling.navLink}
                      onClick={() => toggleTab('choice2')}
                    >
                      2nd Choice
                    </NavLink>
                  </NavItem>
                  <NavItem style={tabStyling.navItem}>
                    <NavLink
                      style={activeTab === 'choice3' ? tabStyling.navLinkActive : tabStyling.navLink}
                      onClick={() => toggleTab('choice3')}
                    >
                      3rd Choice
                    </NavLink>
                  </NavItem>
                  <NavItem style={tabStyling.navItem}>
                    <NavLink
                      style={activeTab === 'choice4' ? tabStyling.navLinkActive : tabStyling.navLink}
                      onClick={() => toggleTab('choice4')}
                    >
                      4th Choice
                    </NavLink>
                  </NavItem>
                  <NavItem style={tabStyling.navItem}>
                    <NavLink
                      style={activeTab === 'choice5' ? tabStyling.navLinkActive : tabStyling.navLink}
                      onClick={() => toggleTab('choice5')}
                    >
                      5th Choice
                    </NavLink>
                  </NavItem>
                  <NavItem style={tabStyling.navItem}>
                    <NavLink
                      style={activeTab === 'choice6' ? tabStyling.navLinkActive : tabStyling.navLink}
                      onClick={() => toggleTab('choice6')}
                    >
                      6th Choice
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-10">
                <TabContent activeTab={activeTab} className='border border-top-0 pt-3 shadow-lg'>
                  <TabPane tabId="choice1">
                    <SessionApplyComponent
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      allInstitute={allInstitute}
                      fcpsPartOneInfo={fcpsPartOneInfo}
                    />
                  </TabPane>
                  <TabPane tabId="choice2">
                    <SessionApplyComponent
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      allInstitute={allInstitute}
                      fcpsPartOneInfo={fcpsPartOneInfo}
                    />
                  </TabPane>
                  <TabPane tabId="choice3">
                    <SessionApplyComponent
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      allInstitute={allInstitute}
                      fcpsPartOneInfo={fcpsPartOneInfo}
                    />
                  </TabPane>
                  <TabPane tabId="choice4">
                    <SessionApplyComponent
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      allInstitute={allInstitute}
                      fcpsPartOneInfo={fcpsPartOneInfo}
                    />
                  </TabPane>
                  <TabPane tabId="choice5">
                    <SessionApplyComponent
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      allInstitute={allInstitute}
                      fcpsPartOneInfo={fcpsPartOneInfo}
                    />
                  </TabPane>
                  <TabPane tabId="choice6">
                    <SessionApplyComponent
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      allInstitute={allInstitute}
                      fcpsPartOneInfo={fcpsPartOneInfo}
                    />
                  </TabPane>
                </TabContent>
              </div>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-center mt-3">
              <Button color="danger" onClick={toggle}>
                Close
              </Button>

              <Button color="success" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CoreTrainingLogbookModal;
