import { apiSlice } from 'src/rtk/api/apiSlice';
import { userLoggedIn } from './authSlice';
import * as url from 'src/helpers/url_helper';

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDataForRegistration: builder.query<APIResponse<any>, any>({
      query: (data: any) => ({
        url: `${url.GET_DATA_FOR_REGISTRATION}/${data.regNumber}/${data.type}`,
        method: 'GET',
      }),
    }),
    
    verifyEmailToken: builder.query<APIResponse<any>, string>({
      query: (token) => ({
        url: `${url.VERIFY_EMAIL_TOKEN}/${token}`,
        method: 'GET',
      }),
    }),
    resendEmailToken: builder.mutation({
      query: (data: { email: string }) => ({
        url: `${url.RESEND_EMAIL_TOKEN}`,
        method: 'POST',
        body: data,
        data,
        headers: {
          Accept: 'application/json',
        },
      }),
    }),

    registration: builder.mutation({
      query: (data: any) => ({
        url: `${url.REGISTRATION}`,
        method: 'POST',
        body: data,
        headers: {
          Accept: 'application/json',
        },
      }),
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;

          // localStorage.setItem(
          //     'bcps-auth-token-and-user',
          //     JSON.stringify({
          //         token: result.data.token,
          //         user: result.data.user,
          //     })
          // );

          // dispatch(userLoggedIn(result.data));
        } catch (error) { }
      },
    }),
    login: builder.mutation({
      query: (data) => ({
        url: `${url.LOGIN}`,
        method: 'POST',
        body: data,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }),

      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const result = await queryFulfilled;
          const { data } = result;

          if (data) {
            localStorage.setItem(
              'bcps-auth-token-and-user',
              JSON.stringify({
                token: data?.data?.token,
                user: data?.data?.user,
                menus: data?.data?.menus,
                menu_permissions: data?.data?.menu_permissions,
              }),
            );
          }
          dispatch(userLoggedIn(data?.data));
        } catch (err) { }
      },
    }),

    forgetPassword: builder.mutation({
      query: (data) => ({
        url: `${url.FORGET_PASSWORD}`,
        method: 'POST',
        body: data,
      }),
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `${url.RESET_PASSWORD}`,
        method: 'POST',
        body: data,
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `${url.CHANGE_PASSWORD}`,
        method: 'POST',
        body: data,
      }),
    }),

    layout: builder.mutation<void, void>({
      query: () => ({
        url: `${url.LOGOUT}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetDataForRegistrationQuery,
  useLazyGetDataForRegistrationQuery,
  useLoginMutation,
  useRegistrationMutation,
  useLayoutMutation,
  useForgetPasswordMutation,
  useChangePasswordMutation,
  useResetPasswordMutation,
  useVerifyEmailTokenQuery,
  useResendEmailTokenMutation,
} = authApi;
