import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import { Button, Container, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';
import { useGetWorkListForConsultantQuery } from 'src/rtk/features/researchClinicSetup/researchClinicSetupApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import loader from '../../assets/images/loader.gif';
import SolutionEntryByConsultantModal from './SolutionEntryByConsultantModal';


const WorkListViewByConsultant = () => {
  let referenceUrl = new URLSearchParams(location.search).get('ref');

  const [selectedSubject, setSelectedSubject] = useState<any>('');
  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);

  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject_id) => ({
      value: subject_id.id!,
      label: subject_id.subject_name,
    })) || [];

  const instituteList: SelectOption[] =
    useGetOrganizationsQuery()
      .data?.data?.map((item) => ({
        value: item.id!,
        label: item.organization_name!,
      })) || [];

  const allStatus: SelectOption[] = [
    { value: 'Appointment Created', label: 'Appointment Created' },
    { value: 'Resolved', label: 'Resolved' },
    { value: 'Pending', label: 'Pending' },
  ];

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    from_date?: string;
    to_date?: string;
    bmdc_registration_no?: string;
    institute_name?: string;
    mobile?: string;
    status?: string;
    subject_id?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    from_date: '',
    to_date: '',
    bmdc_registration_no: '',
    institute_name: '',
    mobile: '',
    status: '',
    subject_id: '',
  };

  const {
    data: workList,
    isSuccess,
    isLoading,
    isFetching,
    refetch,
  } = useGetWorkListForConsultantQuery(generateQueryUrl());

  useEffect(() => {
    refetch();
  }, [referenceUrl]);

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.bmdc_registration_no !== '') {
        s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
      }

      if (values.institute_name !== '') {
        s = s + `&institute_name=${values.institute_name}`;
      }

      if (values.mobile !== '') {
        s = s + `&mobile=${values.mobile}`;
      }

      if (values.subject_id !== '') {
        s = s + `&subject_id=${values.subject_id}`;
      }

      if (values.status !== '') {
        s = s + `&status=${values.status}`;
      }

      setSearch(s);
      setPage('1');
    },
  });

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
      width: '60px',
    },

    {
      name: <div>Name of the Trainee</div>,
      selector: (row) => row?.thesis_title?.trainee_name,
      sortable: false,
      wrap: true,
      width: '130px',
    },
    {
      name: <div>BCPS Reg. No.</div>,
      selector: (row) => row?.thesis_title?.registration?.bcps_registration_no,
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>BMDC Reg. No.</div>,
      selector: (row) => row?.thesis_title?.bmdc_registration_no,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Present Working Place</div>,
      selector: (row) => row?.thesis_title?.registration?.current_session_and_supervisor_info?.supervisor?.institute_name,
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>Research Methodology Training Completion Date</div>,
      width: '160px',
      selector: (row) => '-',
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Mobile Number</div>,
      selector: (row) => row?.thesis_title?.registration?.personal_info?.mobile,
      sortable: false,
      wrap: true,
      width: '110px',
    },
    {
      name: <div>Email</div>,
      selector: (row) => row?.thesis_title?.registration?.personal_info?.email,
      sortable: false,
      wrap: true,
      width: '130px',
    },
    {
      name: <div>Specialty</div>,
      selector: (row) => row?.thesis_title?.registration?.subject?.subject_name,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Title of the Research Protocol/Thesis</div>,
      selector: (row) => row?.thesis_title?.title_of_thesis,
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>Appointment Date</div>,
      width: '120px',
      selector: (row) => row?.meeting_date ? moment(row?.meeting_date).format('DD-MMM-YYYY') : '-',
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Resource Person</div>,
      selector: (row) => row?.consultant?.name ?? '-',
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>Status</div>,
      width: '170px',
      selector: (row) => row?.progress_status,
      cell: (row: any) => {
        switch (row?.progress_status) {
          case 'Resolved':
            return (
              <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                {row?.progress_status}
              </div>
            );
          case 'Appointment Created':
            return (
              <div className="badge rounded-pill d-inline-block  badge-applied font-size-12 fw-medium">
                {row?.progress_status}
              </div>
            );
          case 'Answered':
            return (
              <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                {row?.progress_status}
              </div>
            );
          default:
            return (
              <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                {row?.progress_status}
              </div>
            );
        }
      },
      sortable: false,
      wrap: true,
    },

    {
      name: 'Action',
      width: '105px',
      style: actionColumnStyles,
      cell: (row) => (
        <>
          <div className="d-flex gap-1 my-1">
            <Button
              className="btn btn-sm btn-info"
              onClick={() => modalShowHandler(true, row?.id)}
            >
              <span className="fas fa-eye"></span> View
            </Button>

          </div>
          {modalShow && infoId === row?.id ? (
            <SolutionEntryByConsultantModal
              isOpen={modalShow}
              toggle={() => setModalShow(!modalShow)}
              info={row}
            />
          ) : null}
        </>
      ),
    },
  ];

  const modalShowHandler = (modalState: boolean, id: string) => {
    setModalShow(modalState);
    setInfoId(id);
  }


  useEffect(() => {
    if (selectedSubject !== '') {
      setFieldValue('subject_id', selectedSubject?.value);
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedInstitue !== '') {
      setFieldValue('institute_name', selectedInstitue?.value);
    }
  }, [selectedInstitue]);

  return (
    <div className="page-content">
      {isLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '/', name: 'Research Clinic' }]}
          title={'Research Clinic Service'}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card">
            <div className="card-body">
              <div>
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  onReset={(e) => {
                    e.preventDefault();
                    setSelectedSubject('');
                    setSelectedInstitute('');
                    resetForm();
                    handleSubmit();
                  }}
                >
                  <div className="filter-wrapper">
                    <FormGroup>
                      <Label for="bmdc_registration_no">BMDC Reg. No.</Label>
                      <InputField
                        type="text"
                        placeholder="BMDC Reg. No."
                        {...getFieldProps('bmdc_registration_no')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="mobile">Mobile</Label>
                      <InputField
                        type="text"
                        placeholder="Mobile"
                        {...getFieldProps('mobile')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="status">Status</Label>
                      <InputField
                        type="select"
                        placeholder="Status"
                        options={allStatus}
                        {...getFieldProps('status')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="from_date">From Date</Label>
                      <InputField
                        type="date"
                        placeholder=""
                        invalid={{ errors, touched }}
                        {...getFieldProps('from_date')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="to_date">To Date</Label>
                      <InputField
                        type="date"
                        placeholder=""
                        invalid={{ errors, touched }}
                        {...getFieldProps('to_date')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="institute_name">Institute</Label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        value={selectedInstitue}
                        onChange={setSelectedInstitute}
                        options={instituteList}
                        placeholder='Institute'
                        name="institute_name"
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="subject">Speciality</Label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        value={selectedSubject}
                        onChange={setSelectedSubject}
                        options={allSubjects}
                        placeholder='Speciality'
                        name="subject"
                      />
                    </FormGroup>

                    <FormGroup className='btn-column mb-3'>
                      <Button color="danger" type="reset" text="Reset" >Reset</Button>
                      <Button type="submit" text="Search" color="primary" >Search</Button>
                    </FormGroup>
                  </div>
                </Form>
              </div>
              <DataTable
                columns={columns}
                data={workList?.data?.data!}
                customStyles={dataTableCustomStyles}
                pagination
                paginationServer
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={isLoading}
                expandOnRowClicked
                paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
                responsive
                persistTableHead={true}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default WorkListViewByConsultant;
