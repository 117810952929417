import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Select from 'react-select';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import { setFromErrors } from 'src/helpers';
import { useDepartmentAssignMutation, useLazyGetAssignedDepartmentQuery } from 'src/rtk/features/instituteInspection/institutueInspectionApi';
import { useGetInstituteNameQuery, useLazyGetInstituteWiseDepartmentNameQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import * as Yup from 'yup';

interface Props {
  isOpen: boolean;
  toggle: any;
  teamId: string;
}
const initialValues = {
  team_creation_id: '',
  inspection_date: '',
  institute_id: '',
  departments: '',
};

const DepartmentAssignModal = (props: Props) => {
  const { isOpen, toggle, teamId } = props;
  const [instituteName, setInstituteName] = useState('');

  const [departmentAssign, { isSuccess, isError, error }] = useDepartmentAssignMutation();

  const err: CustomError = error as CustomError;

  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [selectedDepartment, setSelectedDepartment] = useState<any>('');

  const [assignAllChecked, setAssignAllChecked] = useState<boolean>(false);

  const { data: instituteLookupData } = useGetInstituteNameQuery();
  const [getAssignedDepartment, { data: list, isSuccess: isListSuccess }] = useLazyGetAssignedDepartmentQuery();

  useEffect(() => {
    if (teamId !== '' && selectedInstitue !== '') {
      const url = {
        id: teamId,
        institute_id: selectedInstitue?.value
      };
      getAssignedDepartment(url);
    }
  }, [teamId, selectedInstitue]);

  const instituteList: SelectOption[] = instituteLookupData?.map((item) => ({
    value: item.id!,
    label: item.institute_name!,
  })) || [];

  // const [getDepartmentByInstitute, { data: departmentLookupData }] = useLazyGetDepartmentsByInstituteQuery();
  const [getInstituteWiseDepartmentName, { data: departmentLookupData }] = useLazyGetInstituteWiseDepartmentNameQuery();


  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    setErrors,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      inspection_date: Yup.date().min(new Date(Date.now()), "Date Must be Day after today").required(),
    }),
    onSubmit: (values) => {
      values.team_creation_id = teamId;

      console.log(values);
      departmentAssign(values);
    }
  });

  useEffect(() => {
    if (selectedInstitue !== '') {
      setFieldValue('institute_id', selectedInstitue?.value);
      setInstituteName(selectedInstitue?.value);
      setAssignAllChecked(false);
      getInstituteWiseDepartmentName(selectedInstitue?.label ?? '');
    }
  }, [selectedInstitue]);

  const handleAssignAllDepartment = (e: any) => {
    let updatedDeptList = assignAllChecked ? [] : departmentLookupData?.map((item) => item.id);
    setSelectedDepartment(updatedDeptList);
    setAssignAllChecked(!assignAllChecked);
    // console.log(updatedDeptList);
  }

  const handleDeparmetChecked = (e: any, id: string) => {
    let updatedSelectedDept;

    if (selectedDepartment.includes(id)) {
      updatedSelectedDept = selectedDepartment?.filter((item: any) => item !== id);
    }
    else {
      updatedSelectedDept = [...selectedDepartment, id];
    }

    setSelectedDepartment(updatedSelectedDept);
    setAssignAllChecked(false);
  }

  // useEffect(() => {
  //   if(isListSuccess){
  //     const newArray = [...selectedDepartment];

  //     list?.data?.forEach((key: any) => {
  //       if(key?.department_id !== null){
  //         newArray.push(key?.department_id);
  //       }
  //     });
  //     setSelectedDepartment(newArray);
  //   }
  // },[isListSuccess]);

  useEffect(() => {
    if (selectedDepartment !== '') {
      setFieldValue('departments', selectedDepartment);
      console.log(selectedDepartment);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (isSuccess) {
      resetForm();
      toast.success('Assigned Successfully!');
    }
    if (isError) {
      toast.error(err?.message || 'Something Went Wrong!');
      setFromErrors(err.data, setErrors);
    }
  }, [isSuccess, isError]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="xl">
      <ModalHeader toggle={toggle}>Assign to Inspection Team</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
        onReset={(e) => {
          e.preventDefault();
          resetForm();
        }}
      >
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label for="institute_id">Institute</Label>
                <Select
                  isClearable={true}
                  isSearchable={true}
                  value={selectedInstitue}
                  onChange={setSelectedInstitute}
                  options={instituteList}
                  placeholder='Institute'
                  name="institute_id"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="inspection_date">Inspection Date</Label>
                <InputField
                  type="date"
                  placeholder=""
                  invalid={{ errors, touched }}
                  {...getFieldProps('inspection_date')}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <table className="table table-sm table-striped table-bordered table-hover">
                <thead className="bg-light fixed">
                  {departmentLookupData &&
                    <tr className="text-center text-dark font-size-16 fw-bold align-top">
                      <td>SL#</td>
                      <td>Department List</td>
                      <td>
                        <div>
                          Assign All?
                        </div>
                        <Input
                          type="checkbox"
                          id={'assign-switch-all'}
                          switch="success"
                          checked={assignAllChecked}
                          onChange={(e) => {
                            handleAssignAllDepartment(e);
                          }}
                        />
                        <Label
                          htmlFor={'assign-switch-all'}
                          data-on-label="Yes"
                          data-off-label="No"
                        ></Label>
                      </td>
                    </tr>}
                </thead>

                <tbody>
                  {departmentLookupData?.map((item: any, key: number) => {
                    return (
                      <tr key={item.id} className="">
                        <td className="text-center" style={{ width: '50px' }}>
                          <span
                            style={{ width: '20px', height: '20px' }}
                            className="avatar-title rounded-circle bg-soft-info text-dark font-size-12 m-2"
                          >
                            {key + 1}
                          </span>
                        </td>
                        <td style={{ width: '50%' }}>
                          <div className="font-size-16 fw-bold mt-1">
                            {item.department_name}
                          </div>
                        </td>
                        <td className="text-center align-middle">
                          <Input
                            key={'assign-switch-key-' + item.id}
                            type="checkbox"
                            id={'assign-switch-' + item.id}
                            data-id={item.id}
                            switch="success"
                            disabled={list?.data?.find((i: any) => i.department_id === item.id)}
                            checked={selectedDepartment.includes(item.id) || list?.data?.find((i: any) => i.department_id === item.id)} // || list?.data?.find((i: any) => i.department_id === item.id)
                            onChange={(e) => {
                              handleDeparmetChecked(e, item.id);
                            }}
                          />
                          <Label
                            htmlFor={'assign-switch-' + item.id}
                            data-on-label="Yes"
                            data-off-label="No"
                          ></Label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <div className="text-center">
            <Button
              type="submit"
              text="Save"
              className="btn btn-success"
            ></Button>
          </div>
        </ModalFooter>

      </Form>
    </Modal>
  );
};

export default DepartmentAssignModal;
