import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import { config } from 'src/config';
import { setFromErrors } from 'src/helpers';
import { useCreateMiniCexEntryByTraineeMutation } from 'src/rtk/features/miniCexCBD/miniCexAndCBDApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

interface Props {
    registrationNo: string;
    supervisorId: string;
    sessionId: string;
    miniCexInfo?: any;
}

const PatientInformationUpdate = (props: Props) => {
    const { registrationNo, supervisorId, sessionId, miniCexInfo } = props;
    const [photo, setPhoto] = useState('');

    const [createMiniCexEntryByTrainee, { isSuccess, isError, error, isLoading: isCreateLoading }] = useCreateMiniCexEntryByTraineeMutation();
    const err: CustomError = error as CustomError;

    const initialValues = {
        registration_id: '',
        supervisor_id: '',
        logbook_entry_session_id: '',
        date_of_assessment: new Date(Date.now()).toISOString().slice(0, 10),
        complexity_level: '',
        diagnosis_of_the_case: '',
        competency_setting: '',
        patient_address: '',
        patient_name: '',
        patient_reg_no: '',
        patient_age: '',
        patient_gender: '',
        case_description: '',
        file_upload: '',
    }

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setValues,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            // questions: Yup.mixed().required('required'),
            // date_of_assessment: Yup.date().max(new Date(Date.now()), 'Payment date can\'t be a future date').required(),
        }),

        onSubmit: (values: any) => {
            const formData = new FormData();

            Object.keys(values).forEach((key) => {
                formData.append(key, values[key]);
            });
            createMiniCexEntryByTrainee(formData);
        },
    });


    useEffect(() => {
        if (miniCexInfo) {
            setPhoto(`${config?.APP_URL}${miniCexInfo?.file_upload}`);

            setValues({
                registration_id: registrationNo,
                supervisor_id: supervisorId,
                logbook_entry_session_id: sessionId,
                date_of_assessment: new Date(Date.now()).toISOString().slice(0, 10),
                complexity_level: miniCexInfo?.complexity_level,
                diagnosis_of_the_case: miniCexInfo?.diagnosis_of_the_case,
                competency_setting: miniCexInfo?.competency_setting,
                patient_address: miniCexInfo?.patient_address,
                patient_name: miniCexInfo?.patient_name,
                patient_reg_no: miniCexInfo?.patient_reg_no,
                patient_age: miniCexInfo?.patient_age,
                patient_gender: miniCexInfo?.patient_gender,
                case_description: miniCexInfo?.case_description,
                file_upload: '',
            });
        }

    }, [miniCexInfo])

    useEffect(() => {
        if (isSuccess) {
            toast.success('Updated Successfully!');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);



    return (
        <>
            {isCreateLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Card>
                <CardBody>
                    <Form
                        className="custom-form"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                        onReset={(e) => {
                            // e.preventDefault();
                            resetForm();
                        }}
                    >
                        <Row>
                            <h5 className='block-heading'>3. Patient Information</h5>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_name" className='form-label'>Patient Name</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Patient Name"
                                        {...getFieldProps('patient_name')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_address" className='form-label'>Address of the Patient</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Address of the Patient"
                                        {...getFieldProps('patient_address')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_reg_no" className='form-label'>Reg. No. of the Patient</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Reg. No. of the Patient"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('patient_reg_no')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label for="patient_age" className='form-label'>Age</Label>
                                    <InputField
                                        type="number"
                                        placeholder="Age"
                                        {...getFieldProps('patient_age')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label className='form-label me-1'>Gender : </Label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="patient_gender"
                                            value="Male"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('patient_gender', 'Male');
                                            }}
                                            checked={values.patient_gender === 'Male'}
                                            id="gender_male"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="gender_male"
                                        >
                                            Male
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="patient_gender"
                                            value="Female"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('patient_gender', 'Female');
                                            }}
                                            checked={values.patient_gender === 'Female'}
                                            id="gender_female"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="gender_female"
                                        >
                                            Female
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="patient_gender"
                                            value="Others"
                                            onChange={(e) => {
                                                handleChange(e);
                                                setFieldValue('patient_gender', 'Others');
                                            }}
                                            id="gender_others"
                                            checked={values.patient_gender === 'Others'}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="gender_others"
                                        >
                                            Others
                                        </label>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col sm={9}>
                                <FormGroup>
                                    <Label for="diagnosis_of_the_case" className='form-label'>Diagnosis/Problem</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Diagnosis/Problem"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('diagnosis_of_the_case')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <Label className='form-label me-1'>Complexity : </Label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="complexity_level"
                                            value="Low"
                                            onChange={(e) => {
                                                setFieldValue("complexity_level", 'Low');
                                            }}
                                            checked={values.complexity_level === 'Low'}
                                            id="complexity_low"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="complexity_low"
                                        >
                                            Low
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="complexity_level"
                                            value="Medium"
                                            onChange={(e) => {
                                                setFieldValue("complexity_level", 'Medium');
                                            }}
                                            checked={values.complexity_level === 'Medium'}
                                            id="complexity_medium"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="complexity_medium"
                                        >
                                            Medium
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="complexity_level"
                                            value="High"
                                            onChange={(e) => {
                                                setFieldValue("complexity_level", 'High');
                                            }}
                                            checked={values.complexity_level === 'High'}
                                            id="complexity_high"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="complexity_high"
                                        >
                                            High
                                        </label>
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col sm={5}>
                                <FormGroup>
                                    <Label className='form-label me-1'>Setting : </Label>
                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="In-Patient"
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'In-Patient');
                                            }}
                                            checked={values.competency_setting === 'In-Patient'}
                                            id="in_patient"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="in_patient"
                                        >
                                            In-Patient
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="Out-Patient"
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'Out-Patient');
                                            }}
                                            checked={values.competency_setting === 'Out-Patient'}
                                            id="out_patient"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="out_patient"
                                        >
                                            Out-Patient
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="Emergency"
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'Emergency');
                                            }}
                                            checked={values.competency_setting === 'Emergency'}
                                            id="emergency"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="emergency"
                                        >
                                            Emergency
                                        </label>
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="competency_setting"
                                            value="Others"
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", 'Others');
                                            }}
                                            checked={values.competency_setting === 'Others'}
                                            id="others"
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor="others"
                                        >
                                            Others
                                        </label>
                                    </div>
                                </FormGroup>
                            </Col>
                            {values.competency_setting === 'Others' &&
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label className='form-label'>Please Specify</Label>
                                        <InputField
                                            type="text"
                                            placeholder="Please Specify"
                                            invalid={{ errors, touched }}
                                            name='competency_setting'
                                            onChange={(e) => {
                                                setFieldValue("competency_setting", e.target.value);
                                            }}
                                        />
                                    </FormGroup>
                                </Col>}
                        </Row>
                        <Row>
                            <Col>
                                <h5 className='block-heading'>4. Brief case description/Case summary/Salient feature:</h5>
                                <InputField
                                    type="textarea"
                                    placeholder="Brief case description"
                                    invalid={{ errors, touched }}
                                    {...getFieldProps('case_description')}
                                />
                                <p className='text-muted'>(For example-A pediatric second  year  trainee has presented a case of 12-year-old boy presenting with history of    three weeks of fever and generalized lymphadenopathy. He covered history taking, physical examination, investigations and treatment plan, follow up and future plan of this patient.)</p>
                            </Col>
                            <Col>
                                <Label>Upload by Trainee</Label>
                                <a
                                    className="me-2 p-0 center"
                                    href={photo}
                                    rel="noreferrer"
                                    target="_blank"
                                >
                                    <span className="btn-outline-info btn-sm btn ms-2">View</span>
                                </a>
                                <input
                                    name="file_upload"
                                    type="file"
                                    className="form-control"
                                    placeholder="Upload Certificate"
                                    onChange={(e: any) => {
                                        setFieldValue('file_upload', e.target.files[0]);
                                    }}
                                ></input>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup className="mb-3 text-end mt-3">
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                    >
                                        Update
                                    </button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        </>
    );
};

export default PatientInformationUpdate;
