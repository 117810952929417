import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import Select from 'react-select';
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Modal,
    Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import * as url from 'src/helpers/url_helper';
import {
    useCreateByPostMutation,
    useGetDataQuery,
} from 'src/rtk/features/common/crudApi';
import { useGetThesisDissertationFileListForAdminByPaginateQuery, useLazyGetNotifyTraineeQuery } from 'src/rtk/features/disertation-thesis/thesisMarksheetApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import loader from '../../../assets/images/loader.gif';
import { actionColumnStyles, dataTableCustomStyles } from '../../../components/Common/FixedActionColumn';

interface SearchFields {
    bmdc_registration_no?: string;
    subject_name?: string;
    from_date?: string;
    to_date?: string;
    marking_status?: string;
}

const initialValues: SearchFields = {
    bmdc_registration_no: '',
    subject_name: '',
    from_date: '',
    to_date: '',
    marking_status: '',
};

const ThesisMarksheetList = () => {
    document.title = 'Thesis Marksheet | BCPS';
    let referenceUrl = new URLSearchParams(location.search).get('ref');

    const [page, setPage] = useState<string | number>('1');
    const [paginate_per_page, set_paginate_per_page] = useState(15);
    const [search, setSearch] = useState('');
    const [selectedSubject, setSelectedSubject] = useState<any>('');
    const [viewModalShow, setViewModalShow] = useState<boolean>(false);
    const [viewInfo, setViewInfo] = useState<any>({});
    const [showReportModal, setShowReportModal] = useState<boolean>(false);
    const [viewMarksheetData, setViewMarksheetData] = useState<any>({});

    const {
        data: thesisListData,
        error: thesisListDataError,
        isLoading: isThesisListDataLoading,
        isError: isThesisListDataError,
        isSuccess: isThesisListDataSuccess,
        refetch,
        isFetching: isFetchingThesisList,
    } = useGetThesisDissertationFileListForAdminByPaginateQuery({
        page,
        paginate_per_page,
        search,
    });

    const {
        data: examinerListData,
        isLoading: isExaminerListDataLoading,
        isSuccess: isExaminerListDataSuccess,
        error: examinerListDataError,
    } = useGetDataQuery(url.THESIS_DISSERTATION_EXAMINER_LIST);

    const examinerOptions: SelectOption[] = (
        examinerListData?.data || []
    ).map((item: any) => ({
        value: item.id ?? '',
        label: item.name ?? '',
    }));

    const allThesis = thesisListData?.data;

    const { data: subjectList, isError: isSubFetchError } =
        useGetSubjectInfosQuery();

    const [getNotifyTrainee, { data: notifyData, isSuccess: isNotifySuccess, isError: isNotifyError, isLoading: isNotifyLoading, isFetching }] = useLazyGetNotifyTraineeQuery();

    const [createByPost, {
        data: formSubmitData,
        isSuccess: formSubmitSuccess,
        isError: formSubmitError,
        error: formError
    }] = useCreateByPostMutation();

    const formSubError: CustomError = formError as CustomError;

    useEffect(() => {
        refetch();
    }, [referenceUrl]);

    useEffect(() => {
        if (formSubmitSuccess) {
            if (formSubmitData?.success) {
                toast.dismiss();
                setShowReportModal(false);
                toast.success('Next session date assigned successfully');
            } else {
                toast.dismiss();
                toast.error(
                    formSubmitData?.message || 'Something went wrong',
                    { icon: <i className="fa-solid fa-2x fa-circle-exclamation" style={{ color: '#FFD43B' }}></i> }
                );
            }
        }
        if (formSubmitError) {
            if (formSubError?.data?.message) {
                toast.error(formSubError?.data?.message || 'Something went wrong');
            } else {
                toast.error(formSubError?.message || 'Something went wrong');
            }
        }
    }, [formSubmitSuccess, formSubmitError]);

    useEffect(() => {
        if (isNotifySuccess) {
            toast.success('Notified Successfully!');
        }
        if (isNotifyError) {
            toast.success('Something went wrong');
        }
    }, [isNotifySuccess, isNotifyError]);

    const allSubjects: SelectOption[] =
        subjectList?.data?.map((subject) => ({
            value: subject.subject_name!,
            label: subject.subject_name,
        })) || [];

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        set_paginate_per_page(newPerPage);
        setPage(page);
    };

    const handleExaminerChange = (e: any) => {
        const examinerList: string = e?.map((item: any) => item.value);
        approvalSetFieldValue('examiners_id', examinerList);
    };

    const getTotalMark = (data: any) => {
        let totalMark: number = 0;

        if (!data?.examiners || !data?.examiners?.length) {
            return '-';
        }

        data?.examiners?.forEach((examiner: any) => {
            totalMark += (+examiner?.thesis_writing_mark || 0) + (+examiner?.thesis_defence_mark || 0);
        });

        return totalMark;
    };

    const getAverageMark = (data: any) => {
        let totalMark: number = 0;
        let averageMark: number = 0;

        if (!data?.examiners || !data?.examiners?.length) {
            return '-';
        }

        data?.examiners?.forEach((examiner: any) => {
            totalMark += (+examiner?.thesis_writing_mark || 0) + (+examiner?.thesis_defence_mark || 0);
        });

        averageMark = totalMark / data?.examiners?.length;

        return averageMark.toFixed(2);
    };

    const initialValuesForSetupSession = {
        examiners_id: '',
        exam_date: '',
        exam_start_time: '',
        exam_end_time: '',
        building: '',
        room_no: '',
        table_no: '',
        instruction: '',
    }

    const {
        values: approvalValues,
        handleSubmit: approvalHandleSubmit,
        setFieldValue: approvalSetFieldValue,
        getFieldProps: approvalGetFieldProps,
        touched: approvalTouched,
        errors: approvalErrors,
        resetForm: approvalResetForm,
    } = useFormik({
        initialValues: initialValuesForSetupSession,
        // validationSchema: Yup.object({ ...validationObj }),
        onSubmit: (approvalValues: any) => {
            let examiners: any = [];
            approvalValues['examiners_id'].map((user: any, key: any) => {
                examiners.push({ 'examiner_id': user, 'add': 1 });
            });

            // toast.loading(submitFormType + ' Submited...');

            approvalValues['thesis_id'] = viewMarksheetData['id'];
            approvalValues['examiners'] = examiners;


            let postData = {
                queryUrl: url.THESIS_DISSERTATION_THESIS_EXAMINER_ASSIGN_BY_ADMIN,
                formData: approvalValues,
            };

            console.log(approvalValues);

            createByPost(postData);
        },
    });

    // const getAverageWritingMark = (data: any) => {
    //     let totalMark: number = 0;
    //     let averageMark: number = 0;

    //     if (!data || !data?.length) {
    //         return '-';
    //     }

    //     data?.forEach((examiner: any) => {
    //         totalMark += (+examiner?.thesis_writing_mark || 0);
    //     });

    //     averageMark = totalMark / data?.length;

    //     return averageMark.toFixed(2);
    // }

    // const getAverageDefenceMark = (data: any) => {
    //     let totalMark: number = 0;
    //     let averageMark: number = 0;

    //     if (!data || !data?.length) {
    //         return '-';
    //     }

    //     data?.forEach((examiner: any) => {
    //         totalMark += (+examiner?.thesis_defence_mark || 0);
    //     });

    //     averageMark = totalMark / data?.length;

    //     return averageMark.toFixed(2);
    // }

    const handleViewModalShow = (modalState: boolean, viewInfo: any) => {
        setViewModalShow(modalState);
        setViewInfo(viewInfo);
    };

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No.</div>,
            width: '80px',
            cell: (row, index) => index + 1,
            wrap: true,
        },

        {
            name: <div>Name</div>,
            selector: (row) => row.trainee_name ?? '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>BMDC Reg. No.</div>,
            selector: (row) => row.bmdc_registration_no ?? '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Discipline</div>,
            selector: (row) => row.subject_name ?? '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Type</div>,
            selector: (row) => row.document_type ?? '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Submission Date</div>,
            width: '130px',
            selector: (row) =>
                row.submission_date ? moment(row.submission_date).format('DD/MMM/YYYY') : '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Examiner 1 Marking</div>,
            selector: (row) =>
                row.examiners?.length && row.examiners?.length > 0 ?
                    (+row.examiners[0]?.thesis_writing_mark || 0) + (+row.examiners[0]?.thesis_defence_mark || 0) : '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Examiner 2 Marking</div>,
            selector: (row) =>
                row.examiners?.length && row.examiners?.length > 1 ?
                    (+row.examiners[1]?.thesis_writing_mark || 0) + (+row.examiners[1]?.thesis_defence_mark || 0) : '-',
            sortable: false,
            wrap: true,
        },

        // {
        //     name: <div>Examiner 3 Marking</div>,
        //     selector: (row) =>
        //         row.examiners?.length && row.examiners?.length > 2 ?
        //             (+row.examiners[2]?.thesis_writing_mark || 0) + (+row.examiners[2]?.thesis_defence_mark || 0) : '-',
        //     sortable: false,
        //     wrap: true,
        // },

        {
            name: <div>Total Mark</div>,
            selector: (row) => getTotalMark(row),
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Average Mark</div>,
            selector: (row) => getAverageMark(row),
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Marking Status</div>,
            selector: (row) => row?.marking_status ?? '-',
            sortable: false,
            wrap: true,
        },

        {
            name: <div>Action</div>,
            style: actionColumnStyles,
            width: '300px',
            cell: (row) => (
                <>
                    {/* btn-group-example */}
                    <div className="d-flex gap-1 my-2">
                        <button
                            className="btn btn-sm btn-info"
                            onClick={() => {
                                handleViewModalShow(true, row);
                            }}
                        >
                            <span className="fas fa-eye"></span> View
                        </button>
                        {row?.marking_status === 'Marking Done' &&
                            <Button
                                size="sm"
                                className="rounded-0"
                                text={`<span class=" fas fa-certificate" ></span> Mark Sheet`}
                                color="dark"
                                onClick={() => {
                                    setShowReportModal(true);
                                    setViewMarksheetData(row);
                                    console.log(row);
                                }}
                            />
                        }
                        {
                            <Button
                                size="sm"
                                color="success"
                                type="button"
                                text={`<span class="fas fa-bell" ></span> Notify Trainee`}
                                onClick={() => {
                                    getNotifyTrainee(row?.id);
                                }}
                            />
                        }
                    </div>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const {
        handleSubmit,
        getFieldProps,
        setFieldValue,
        values,
        touched,
        errors,
        resetForm,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values: SearchFields) => {
            let s: string = '';

            if (values.from_date !== '') {
                s = s + `&from_date=${values.from_date}`;
            }

            if (values.to_date !== '') {
                s = s + `&to_date=${values.to_date}`;
            }

            if (values.bmdc_registration_no !== '') {
                s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
            }

            if (values.subject_name !== '') {
                s = s + `&subject_name=${values.subject_name}`;
            }

            if (values.marking_status !== '') {
                s = s + `&marking_status=${values.marking_status}`;
            }

            setSearch(s);
            setPage('1');
            set_paginate_per_page(15);
        },
    });

    useEffect(() => {
        if (selectedSubject !== '') {
            setFieldValue('subject_name', selectedSubject?.value);
        }
    }, [selectedSubject]);

    return (
        <div className="page-content">
            {isNotifyLoading || isFetching || isFetchingThesisList || isThesisListDataLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    title="Thesis Marksheet"
                    breadcrumbItem={[{ link: '/', name: 'Dashboard' }]}
                />
                <Card>
                    <CardBody>
                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                //   handleSubmit();
                            }}
                        >
                            <div className="filter-wrapper">
                                <FormGroup>
                                    <Label for="bmdc_registration_no">BMDC Reg. No.</Label>
                                    <InputField
                                        type="text"
                                        placeholder='BMDC Reg. No.'
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('bmdc_registration_no')}
                                    />
                                </FormGroup>


                                <FormGroup className="mb-3">
                                    <Label for="subject_name">Discipline</Label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        value={selectedSubject}
                                        onChange={setSelectedSubject}
                                        options={allSubjects}
                                        placeholder='Select...'
                                        name="subject_name"
                                        id="subject_name"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="from_date">From Date</Label>
                                    <InputField
                                        type="date"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('from_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="to_date">To Date</Label>
                                    <InputField
                                        type="date"
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('to_date')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="marking_status">Status</Label>
                                    <InputField
                                        type="select"
                                        options={[{ label: 'Pending', value: 'Pending' }, { label: 'Marking Done', value: 'Marking Done' },]}
                                        invalid={{ errors, touched }}
                                        {...getFieldProps('marking_status')}
                                    />
                                </FormGroup>

                                <FormGroup className="btn-column">
                                    <Button
                                        color="danger"
                                        type="reset"
                                        text="Reset"
                                        onClick={() => {
                                            resetForm();
                                            setSelectedSubject('');
                                            handleSubmit();
                                        }}
                                    />
                                    <Button
                                        type="button"
                                        text="Search"
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        </Form>
                        <DataTable
                            columns={columns}
                            data={allThesis?.length ? allThesis : []}
                            customStyles={dataTableCustomStyles}
                            pagination
                            paginationServer
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={isThesisListDataLoading}
                            paginationTotalRows={thesisListData?.total}
                            expandOnRowClicked
                            highlightOnHover
                            paginationRowsPerPageOptions={[2, 5, 10, 15, 20, 50, 100, 200]}
                            persistTableHead={true}
                        />

                        <Modal
                            onOpened={function () {
                                toast.dismiss();
                            }}
                            isOpen={viewModalShow}
                            scrollable={true}
                            size="xl"
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">
                                    Marksheet Information
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => {
                                        setViewModalShow(!viewModalShow);
                                    }}
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h6 className="block-heading">Trainee Information</h6>
                                            <table className="table table-bordered table-striped table-sm">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Trainee Name: </strong>
                                                            {viewInfo?.trainee_name ?? '-'}
                                                        </td>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>BMDC Reg. No: </strong>
                                                            {viewInfo?.bmdc_registration_no ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Document Type: </strong>
                                                            {viewInfo?.document_type ?? '-'}
                                                        </td>
                                                        <td>
                                                            <strong>Discipline: </strong>
                                                            {viewInfo?.subject_name ?? '-'}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className='col-md-6'>
                                            <h6 className="block-heading">Thesis Title Details</h6>
                                            <table className="table table-bordered table-striped table-sm">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Category: </strong>
                                                            {viewInfo?.title?.category ?? '-'}
                                                        </td>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Title: </strong>
                                                            {viewInfo?.title?.title_of_thesis ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Place Of Study: </strong>
                                                            {viewInfo?.title?.place_of_study ?? '-'}
                                                        </td>
                                                        <td>
                                                            <strong>Study Period: </strong>
                                                            {viewInfo?.title?.study_period + ' ' + viewInfo?.title?.study_period_unit ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Guide: </strong>
                                                            {viewInfo?.title?.guide_name ?? '-'}
                                                        </td>
                                                        <td>
                                                            <strong>Guide Approval Note: </strong>
                                                            {viewInfo?.title?.guide_approval_note ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Guide Approval Status: </strong>
                                                            {viewInfo?.title?.guide_approval_status ?? '-'}
                                                        </td>
                                                        <td>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <strong>Co-Guide </strong>
                                                        </td>
                                                        <td>
                                                            {viewInfo?.title?.co_guide_1_name &&
                                                                <div className='row card mx-1 my-1'>
                                                                    <div className="col">
                                                                        <strong>Name:</strong>  {viewInfo?.title?.co_guide_1_name} <br />
                                                                        <strong>Note:</strong>  {viewInfo?.title?.co_guide1_approval_note} <br />
                                                                        <strong>Approval Status:</strong>  {viewInfo?.title?.co_guide1_approval_status}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {viewInfo?.title?.co_guide_2_name &&
                                                                <div className='row card mx-1 my-1'>
                                                                    <div className="col">
                                                                        <strong>Name:</strong> {viewInfo?.title?.co_guide_2_name} <br />
                                                                        <strong>Note:</strong> {viewInfo?.title?.co_guide2_approval_note} <br />
                                                                        <strong>Approval Status:</strong> {viewInfo?.title?.co_guide2_approval_status}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {viewInfo?.title?.co_guide_3_name &&
                                                                <div className='row card mx-1 my-1'>
                                                                    <div className="col">
                                                                        <strong>Name:</strong> {viewInfo?.title?.co_guide_3_name} <br />
                                                                        <strong>Note:</strong> {viewInfo?.title?.co_guide3_approval_note} <br />
                                                                        <strong>Approval Status:</strong> {viewInfo?.title?.co_guide3_approval_status}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h6 className="block-heading">Exam Information</h6>
                                            <table className="table table-bordered table-striped table-sm">
                                                <tbody>
                                                    <tr>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Exam Date: </strong>
                                                            {viewInfo?.exam_date ?? '-'}
                                                        </td>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Instruction: </strong>
                                                            {viewInfo?.instruction ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Exam Start Time: </strong>
                                                            {viewInfo?.exam_start_time ?? '-'}
                                                        </td>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Exam End Time: </strong>
                                                            {viewInfo?.exam_end_time ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Building: </strong>
                                                            {viewInfo?.building ?? '-'}
                                                        </td>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Room No: </strong>
                                                            {viewInfo?.room_no ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Average Writing Mark: </strong>
                                                            {viewInfo?.average_writing_mark ?? '-'}
                                                        </td>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Average Defence Mark: </strong>
                                                            {viewInfo?.average_defence_mark ?? '-'}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Marking Status: </strong>
                                                            {viewInfo?.marking_status ?? '-'}
                                                        </td>
                                                        <td className="col-md-6 col-lg-6">
                                                            <strong>Result: </strong>
                                                            {viewInfo?.result ?? '-'}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6">
                                            <h6 className="block-heading">Examiner Information</h6>
                                            <table className="table table-bordered table-striped table-sm">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <strong>Examiners</strong>
                                                        </td>
                                                        <td>
                                                            {viewInfo?.examiners?.length > 0 &&
                                                                <div className='row card mx-1 my-1'>
                                                                    <div className="col">
                                                                        <strong>Name:</strong>  {viewInfo?.examiners[0]?.examiner_name ?? '-'} <br />
                                                                        <strong>Thesis Writing Mark:</strong>  {viewInfo?.examiners[0]?.thesis_writing_mark ?? '-'} <br />
                                                                        <strong>Thesis Defence Mark:</strong>  {viewInfo?.examiners[0]?.thesis_defence_mark ?? '-'} <br />
                                                                        <strong>Thesis Marking Comment:</strong>  {viewInfo?.examiners[0]?.thesis_marking_comment ?? '-'} <br />
                                                                        <strong>Result:</strong>  {viewInfo?.examiners[0]?.thesis_writing_mark !== null && viewInfo?.examiners[0]?.thesis_defence_mark !== null ?
                                                                            ((+viewInfo?.examiners[0]?.thesis_writing_mark) + (+viewInfo?.examiners[0]?.thesis_defence_mark)) >= 60 ? 'Pass' : 'Fail'
                                                                            : '-'}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {viewInfo?.examiners?.length > 1 &&
                                                                <div className='row card mx-1 my-1'>
                                                                    <div className="col">
                                                                        <strong>Name:</strong>  {viewInfo?.examiners[1]?.examiner_name ?? '-'} <br />
                                                                        <strong>Thesis Writing Mark:</strong>  {viewInfo?.examiners[1]?.thesis_writing_mark ?? '-'} <br />
                                                                        <strong>Thesis Defence Mark:</strong>  {viewInfo?.examiners[1]?.thesis_defence_mark ?? '-'} <br />
                                                                        <strong>Thesis Marking Comment:</strong>  {viewInfo?.examiners[1]?.thesis_marking_comment ?? '-'} <br />
                                                                        <strong>Result:</strong>  {viewInfo?.examiners[1]?.thesis_writing_mark !== null && viewInfo?.examiners[1]?.thesis_defence_mark !== null ?
                                                                            ((+viewInfo?.examiners[1]?.thesis_writing_mark) + (+viewInfo?.examiners[1]?.thesis_defence_mark)) >= 60 ? 'Pass' : 'Fail'
                                                                            : '-'}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {viewInfo?.examiners?.length > 2 &&
                                                                <div className='row card mx-1 my-1'>
                                                                    <div className="col">
                                                                        <strong>Name:</strong>  {viewInfo?.examiners[2]?.examiner_name ?? '-'} <br />
                                                                        <strong>Thesis Writing Mark:</strong>  {viewInfo?.examiners[2]?.thesis_writing_mark ?? '-'} <br />
                                                                        <strong>Thesis Defence Mark:</strong>  {viewInfo?.examiners[2]?.thesis_defence_mark ?? '-'} <br />
                                                                        <strong>Thesis Marking Comment:</strong>  {viewInfo?.examiners[2]?.thesis_marking_comment ?? '-'}
                                                                        <strong>Result:</strong>  {viewInfo?.examiners[2]?.thesis_writing_mark !== null && viewInfo?.examiners[2]?.thesis_defence_mark !== null ?
                                                                            ((+viewInfo?.examiners[2]?.thesis_writing_mark) + (+viewInfo?.examiners[2]?.thesis_defence_mark)) >= 60 ? 'Pass' : 'Fail'
                                                                            : '-'}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div >
                            </div>
                        </Modal>

                        <Modal
                            onOpened={function () { }}
                            isOpen={showReportModal}
                            scrollable={false}
                            size="xl"
                            toggle={() => {
                                document.body.classList.add('no_padding');
                            }}
                            backdrop={'static'}
                            modalTransition={{ timeout: 0 }}
                            backdropTransition={{ timeout: 0 }}
                        >
                            <Form
                                className="custom-form"
                                onSubmit={(e) => { }}
                                onReset={(e) => { }}
                            >
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">
                                        Mark Sheet Details Info
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={() => {
                                            setShowReportModal(false);
                                        }}
                                        aria-label="Close"
                                    ></button>
                                </div>
                                <div id="pdfHtmlData" className="modal-body">

                                    <div className='p-3' style={{ maxHeight: '50vh', overflow: 'hidden', overflowY: 'auto', }} >
                                        <div className='row'>
                                            <div className='col-md-12' style={{ textAlign: 'center' }}>
                                                <h4>FCPS Examination, {moment(viewMarksheetData?.exam_date).format("MMMM YYYY")}</h4>
                                            </div>
                                            <div className='col-md-12 text-center'>
                                                <h4>Mark Sheet for Thesis Defense for Specialized Subjects</h4>
                                                <hr />
                                            </div>

                                            <div className='col-md-12' style={{ marginTop: '25px' }}>
                                                <strong>Trainee name :</strong> {viewMarksheetData?.trainee_name || ""}
                                            </div>
                                            <div className='col-md-12'>
                                                <strong>BMDC Reg. :</strong> {viewMarksheetData?.bmdc_registration_no || ""}
                                            </div>
                                            <div className='col-md-12'>
                                                <strong>Subject name :</strong> {viewMarksheetData?.subject_name || ""}
                                            </div>
                                            <div className='col-md-12'>
                                                <strong>Centre :</strong> BCPS
                                                {/* {viewData?.title?.place_of_study || ""} */}
                                            </div>
                                            <div className='col-md-12'>
                                                <strong>Exam Date :</strong> {moment(viewMarksheetData?.exam_date).format("DD, MMMM YYYY")}
                                            </div>
                                            <div className='col-md-12'>
                                                <strong>Thesis Title :</strong> {viewMarksheetData?.title?.title_of_thesis || ""}
                                            </div>



                                            {viewMarksheetData?.examiners?.length > 0 && (
                                                <div className='col-md-12 my-3' style={{ marginTop: '25px' }}>
                                                    <table className='table table-bordered border-dark'>
                                                        <thead className='text-center'>
                                                            <tr>
                                                                <th>Trainee name</th>
                                                                <th>Thesis Writing=50<br />(Pass Mark=30)</th>
                                                                <th>Thesis Defence=50<br />(Pass Mark=30)</th>
                                                                <th>Total Marks=100<br />(Pass Mark=60)</th>
                                                                <th>Result</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='text-center'>
                                                            <tr>
                                                                <th>{viewMarksheetData?.trainee_name || ""}</th>
                                                                <th>{viewMarksheetData?.average_writing_mark || 0}</th>
                                                                <th>{viewMarksheetData?.average_defence_mark || 0}</th>
                                                                <th>{parseInt(viewMarksheetData?.average_writing_mark || 0) + parseInt(viewMarksheetData?.average_defence_mark || 0)}</th>
                                                                <th>{viewMarksheetData?.result || ""}</th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            )}
                                            <div className="col-md-12">
                                                <div className="row">
                                                    {viewMarksheetData?.examiners && viewMarksheetData?.examiners?.map((mark: any) => {
                                                        return (
                                                            <div key={"signature-" + mark.id} style={{ width: '200px' }} className="m-1 p-2  text-center">
                                                                <div style={{ marginTop: '50px' }}><hr style={{ border: '1px solid #222' }} /></div>
                                                                <div>{mark?.examiner_name || ""}</div>
                                                                <div>Examiner</div>
                                                            </div>
                                                        )
                                                    })
                                                    }

                                                </div>
                                            </div>

                                            {viewMarksheetData?.result === 'Failed' && (
                                                <>
                                                    <div className='align-items-center justify-content-center mt-3'>

                                                        <div className='card'>
                                                            <div className='card-header text-center fw-bold bg-light'>
                                                                Setup Next Session
                                                            </div>
                                                            <div className='card-body'>
                                                                <Form
                                                                    autoComplete="off"
                                                                    className="custom-form"
                                                                    onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        approvalHandleSubmit();
                                                                    }}
                                                                    onReset={(e) => {
                                                                        e.preventDefault();
                                                                        approvalResetForm();
                                                                    }}
                                                                >
                                                                    <Row>
                                                                        <Col md={6} sm={12}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="notice_no" className="form-Label">
                                                                                    Examiner
                                                                                </Label>
                                                                                <Select
                                                                                    // defaultValue={[]}
                                                                                    isMulti
                                                                                    name="examiner_id"
                                                                                    // value={
                                                                                    //     approvalValues.examiners_id !== ''
                                                                                    //         ? (approvalValues.examiners_id as any)                                                                                            
                                                                                    //             ?.map((item: any) => ({
                                                                                    //                 label: item,
                                                                                    //                 value: item,
                                                                                    //             }))
                                                                                    //         : []
                                                                                    // }
                                                                                    options={examinerOptions}
                                                                                    className="basic-multi-select"
                                                                                    classNamePrefix="select"
                                                                                    onChange={handleExaminerChange}
                                                                                    menuPortalTarget={document.body}
                                                                                    menuPosition={'fixed'}
                                                                                    styles={{ menuPortal: (provided: any, state: any) => ({ ...provided, zIndex: '9999', }) }}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6} sm={12}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="exam_date" className="form-label">
                                                                                    Exam Date
                                                                                </Label>
                                                                                <InputField
                                                                                    type="date"
                                                                                    placeholder=""
                                                                                    invalid={{ errors: approvalErrors, touched: approvalTouched }}
                                                                                    {...approvalGetFieldProps('exam_date')}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6} sm={12}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="exam_start_time" className="form-label">
                                                                                    Exam Start Time
                                                                                </Label>
                                                                                <InputField
                                                                                    type="time"
                                                                                    placeholder=""
                                                                                    invalid={{ errors: approvalErrors, touched: approvalTouched }}
                                                                                    {...approvalGetFieldProps('exam_start_time')}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6} sm={12}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="exam_end_time" className="form-label">
                                                                                    Exam End Time
                                                                                </Label>
                                                                                <InputField
                                                                                    type="time"
                                                                                    placeholder=""
                                                                                    invalid={{ errors: approvalErrors, touched: approvalTouched }}
                                                                                    {...approvalGetFieldProps('exam_end_time')}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6} sm={12}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="building" className="form-label">
                                                                                    Building
                                                                                </Label>
                                                                                <InputField
                                                                                    type="text"
                                                                                    placeholder=""
                                                                                    invalid={{ errors: approvalErrors, touched: approvalTouched }}
                                                                                    {...approvalGetFieldProps('building')}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6} sm={12}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="room_no" className="form-label">
                                                                                    Room No
                                                                                </Label>
                                                                                <InputField
                                                                                    type="text"
                                                                                    placeholder=""
                                                                                    invalid={{ errors: approvalErrors, touched: approvalTouched }}
                                                                                    {...approvalGetFieldProps('room_no')}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6} sm={12}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="table_no" className="form-label">
                                                                                    Table No
                                                                                </Label>
                                                                                <InputField
                                                                                    type="text"
                                                                                    placeholder=""
                                                                                    invalid={{ errors: approvalErrors, touched: approvalTouched }}
                                                                                    {...approvalGetFieldProps('table_no')}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col md={6} sm={12}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="instruction" className="form-label">
                                                                                    Instruction
                                                                                </Label>
                                                                                <InputField
                                                                                    type="textarea"
                                                                                    placeholder=""
                                                                                    invalid={{ errors: approvalErrors, touched: approvalTouched }}
                                                                                    {...approvalGetFieldProps('instruction')}
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        <Col sm={12} className="mt-5">
                                                                            <FormGroup className="mb-3 text-center">
                                                                                <Button
                                                                                    type="reset"
                                                                                    text="Reset"
                                                                                    className="btn btn-warning me-2"
                                                                                    onClick={(e) => {
                                                                                        approvalResetForm();
                                                                                    }}
                                                                                ></Button>
                                                                                <Button
                                                                                    type="button"
                                                                                    text="Save"
                                                                                    className="btn btn-success me-2"
                                                                                    onClick={(e) => {
                                                                                        approvalHandleSubmit();
                                                                                    }}
                                                                                ></Button>
                                                                            </FormGroup>
                                                                        </Col>
                                                                    </Row>
                                                                </Form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <Button
                                        type="button"
                                        text="Close"
                                        className="btn btn-danger"
                                        onClick={() => {
                                            setShowReportModal(false);
                                        }}
                                    ></Button>
                                </div>
                            </Form>
                        </Modal>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default ThesisMarksheetList;
