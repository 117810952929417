import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import {
    Card,
    CardBody,
    Container,
    Form,
    FormGroup,
    Label
} from 'reactstrap';
import { Button } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';
import { useDeleteDepartmentMutation, useGetDepartmentsByPaginateQuery, useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import loader from '../../../assets/images/loader.gif';

interface SearchFields {
    department_name?: string;
    organization_id?: string;
}

const initialValues: SearchFields = {
    department_name: '',
    organization_id: '',
};

const UpdatedDepartmentList = () => {
    document.title = 'Setup Department | BCPS';
    const { id: courseId } = useParams<{ id: string }>();
    const history = useHistory();
    const [page, setPage] = useState<string | number>('1');
    const [paginate_per_page, set_paginate_per_page] = useState(15);
    const [search, setSearch] = useState('');
    const [infoId, setInfoId] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState<any>('');
    const [selectedDepartment, setSelectedDepartment] = useState<any>('');

    const { data: departmentsData, error: departmentsDataError, isLoading: isDepartmentsDataLoading, isError: isDepartmentsDataError } = useGetDepartmentsByPaginateQuery({
        page,
        paginate_per_page,
        search
    });
    const { data: dropdownDepartments } = useGetDepartmentsQuery();  

    const departments = dropdownDepartments?.data || [];
    const departmentOptions: SelectOption[] = departments?.map((d: Department) => {
        return { label: d.department_name!, value: d.department_name! };
    })!;

    const { data: organizationsData } = useGetOrganizationsQuery();
    const organizations = organizationsData?.data;
    const organizationOptions: SelectOption[] = organizations?.map((o: Organization) => {
        return { label: o.organization_name!, value: o.id! };
    })!;

    const [deleteDepartment, { isSuccess: isDeleteDepartmentSuccess, isLoading: isDeleteDepartmentLoading, isError: isDeleteDepartmentError }] =
        useDeleteDepartmentMutation();

    const deleteHandler = (modalState: boolean, id: string) => {
        setModalShow(modalState);
        setInfoId(id);
    };

    useEffect(() => {
        if (isDeleteDepartmentSuccess) {
            toast.success('Department deleted successfully');
        }
        if (isDeleteDepartmentError) {
            toast.error('Error deleting department');
        }
    }, [isDeleteDepartmentSuccess, isDeleteDepartmentError]);

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        set_paginate_per_page(newPerPage);
        setPage(page);
    };

    const columns: TableColumn<any>[] = [
        {
            name: <div style={{ textAlign: 'center' }}>Sl. No.</div>,
            width: '80px',
            cell: (row, index) => (+page - 1) * paginate_per_page + index + 1,
            // center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Dept. Name</div>,
            selector: (row) =>
                row.department_name,
            sortable: false,
            // center: true,
            wrap: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Institute</div>,
            selector: (row) =>
                organizations?.find((o) => o.id === row.organization_id)
                    ?.organization_name,
            sortable: false,
            // center: true,
            wrap: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Date of Last Accreditation</div>,
            selector: (row) => '',
            sortable: false,
            wrap: true,
            // center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Duration to be Counted</div>,
            selector: (row) => '',
            sortable: false,
            wrap: true,
            // center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Validity Date</div>,
            selector: (row) => '',
            sortable: false,
            wrap: true,
            // center: true,
        },

        {
            name: <div style={{ textAlign: 'center' }}>Accreditation Status</div>,
            selector: (row) => 'Accreditated',
            sortable: false,
            wrap: true,
            center: true,
        },

        // {
        //     name: <div style={{ textAlign: 'center' }}>Status</div>,
        //     selector: (row) => row.status,
        //     cell: (row) => {
        //         switch (row.status) {
        //             case true:
        //                 return (
        //                     <>
        //                         <div className="badge badge-soft-success font-size-12">
        //                             {'Active'}
        //                         </div>
        //                     </>
        //                 );

        //             case false:
        //                 return (
        //                     <>
        //                         <div className="badge badge-soft-dark font-size-12">
        //                             {'Inactive'}
        //                         </div>
        //                     </>
        //                 );

        //             default:
        //                 return (
        //                     <>
        //                         <div className="badge badge-soft-secondary font-size-12">
        //                             {'N/A'}
        //                         </div>
        //                     </>
        //                 );
        //         }
        //     },
        //     sortable: false,
        //     center: true,
        //     wrap: true,
        // },
        {
            name: <div style={{ textAlign: 'center' }}>Action</div>,
            width: '150px',
            cell: (row) => (
                <>
                    <div className="d-flex gap-2">
                        <Button
                            size="sm"
                            text="View Details"
                            color="info"
                            onClick={() => {
                                // history.push(`/setup/department/update/${row.id}`)
                            }}
                        />
                    </div>
                    {modalShow && infoId === row.id ? (
                        <DeleteInformationModal
                            isOpen={modalShow}
                            toggle={() => setModalShow(!modalShow)}
                            id={infoId}
                            deleteInfomation={deleteDepartment}
                        />
                    ) : null}
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            center: true,
            wrap: true,
        },
    ];

    const {
        handleSubmit,
        getFieldProps,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values) => {
            let s: string = '';

            if (values.organization_id !== '') {
                s = s + `&organization_id=${values.organization_id}`;
            }

            if (values.department_name !== '') {
                s = s + `&department_name=${values.department_name}`;
            }

            setSearch(s);
            setPage('1');
            set_paginate_per_page(15);
        },
    });

    useEffect(() => {
        if (selectedOrganization !== '') {
            setFieldValue('organization_id', selectedOrganization?.value);
        }
    }, [selectedOrganization]);

    useEffect(() => {
        if (selectedDepartment !== '') {
            setFieldValue('department_name', selectedDepartment?.value);
        }
    }, [selectedDepartment]);

    return (
        <div className="page-content">
            {isDepartmentsDataLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    title={'Department List'}
                    breadcrumbItem={[{ link: '/setup/department/', name: 'Department' }]}
                />
                <Card className="mb-5">
                    {/* <CardHeader className="d-flex justify-content-end">
                        <Row>
                            <Col>
                                <div className="flex-shrink-0 align-self-end">
                                    <Link
                                        to="/setup/department/add"
                                        className="btn btn-success btn-sm waves-effect waves-light p-2"
                                    >
                                        <i className="fa fa-plus me-2" /> Add Department
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </CardHeader> */}
                    <CardBody>
                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                //   handleSubmit();
                            }}
                        >
                            <div className="row">
                                <div className='col-sm-4'>
                                    <FormGroup className="mb-3">
                                        <Label for="department_name">Department Name</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedDepartment}
                                            onChange={setSelectedDepartment}
                                            options={departmentOptions}
                                            placeholder='Select...'
                                            name="department_name"
                                            id="department_name"
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-sm-4'>
                                    <FormGroup className="mb-3">
                                        <Label for="organization">Institute</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedOrganization}
                                            onChange={setSelectedOrganization}
                                            options={organizationOptions}
                                            placeholder='Select...'
                                            name="organization_id"
                                            id="organization"
                                        />
                                    </FormGroup>
                                </div>

                                {/* <div className='col-sm-2'>
                                    <FormGroup className="mb-3">
                                        <Label for="accreditation_status">Accreditation Status</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedOrganization}
                                            onChange={setSelectedOrganization}
                                            options={organizationOptions}
                                            placeholder='Select...'
                                            name="accreditation_status"
                                            id="accreditation"
                                        />
                                    </FormGroup>
                                </div> */}

                                <div className="col-sm-2 mt-4">
                                    <FormGroup className="btn-separator mb-3">
                                        <Button
                                            color="danger"
                                            type="reset"
                                            text="Reset"
                                            onClick={() => {
                                                setSelectedOrganization('');
                                                setSelectedDepartment('');
                                                resetForm();
                                                handleSubmit();
                                            }}
                                        />
                                        <Button
                                            type="button"
                                            text="Search"
                                            onClick={() => {
                                                handleSubmit();
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                        </Form>
                        <DataTable
                            columns={columns}
                            data={departmentsData?.data?.data ? departmentsData?.data?.data : []}
                            pagination
                            paginationServer
                            paginationPerPage={15}
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            progressPending={isDepartmentsDataLoading}
                            paginationTotalRows={departmentsData?.data?.total}
                            expandOnRowClicked
                            highlightOnHover
                            paginationRowsPerPageOptions={[2, 5, 10, 15, 20, 50, 100, 200]}
                            persistTableHead={true}
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default UpdatedDepartmentList;
