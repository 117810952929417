import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import { useGetNoticeTemplateListQuery } from 'src/rtk/features/notice/publishNoticeApi';
import { useGetDepartmentsQuery } from 'src/rtk/features/setup/department/departmentApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import InfoViewModal from './ViewNoticeModal';

interface SearchFields {
  search?: string;
}

const initialValues: SearchFields = {
  search: '',
};

const NoticeTemplateSelectionList = (props: any) => {
  const { selectedTemplate, setSelectedTemplate, setModalShow } = props;
  // document.title = 'Notice Entry | BCPS';

  // const [page, setPage] = useState<string | number>('1');
  // const [paginate_per_page, set_paginate_per_page] = useState(15);
  const [search, setSearch] = useState('');
  const [viewModalShow, setViewModalShow] = useState<boolean>(true);
  const [templateToView, setTemplateToView] = useState<NoticeTemplate>({} as NoticeTemplate);
  const [reRenderState, setRerenderState] = useState<number>(0);

  const {
    data: noticeListData,
    error: noticeListDataError,
    isLoading: isNoticeListDataLoading,
    isError: isNoticeListDataError,
    isSuccess: isNoticeListDataSuccess,
  } = useGetNoticeTemplateListQuery();

  // const {
  //   data: departments,
  //   error: departmentsError,
  //   isLoading: isDepartmentsLoading,
  //   isError: isDepartmentsError,
  //   isSuccess: isDepartmentsSuccess,
  // } = useGetDepartmentsQuery();

  // const departmentOptions: SelectOption[] = (departments?.data || []).map(
  //   (item) => ({
  //     value: item.id ?? '',
  //     label: item.department_name ?? '',
  //   }),
  // );

  const noticeTypeOptions: SelectOption[] =
    useGetLookupDataQuery('notice_type')
      .data?.data[0]?.childs.map((item) => ({
        value: item.name,
        label: item.name,
      }))
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      }) || [];

  let allNotices: NoticeTemplate[] = [];

  if (search === '') {
    allNotices = noticeListData?.data!;
  } else {
    allNotices = noticeListData?.data?.filter((n: NoticeTemplate) => {
      return n.template_name?.includes(search) || n.notice_type?.includes(search) || n.department_id?.includes(search);
    })!;
  }

  // const allDepartments = departments?.data;

  // const handlePageChange = (page: number) => {
  //   setPage(page);
  // };

  // const handlePerRowsChange = (newPerPage: number, page: number) => {
  //   set_paginate_per_page(newPerPage);
  //   setPage(page);
  // };

  const handleViewTemplate = (template: NoticeTemplate) => {
    setTemplateToView(template);
    setViewModalShow(true);
    if (reRenderState === 0) {
      setRerenderState(1);
    } else {
      setRerenderState(0);
    }
  };

  const columns: TableColumn<any>[] = [
    // {
    //   name: 'Sl. No.',
    //   width: '100px',
    //   cell: (row, index) => index + 1,
    //   center: true,
    // },

    {
      name: <div>Template Name</div>,
      selector: (row) => row.template_name,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: 'Notice Type',
      selector: (row) => row.notice_type,
      sortable: false,
      wrap: true,
      center: true,
    },

    {
      name: 'Subject',
      selector: (row) =>
        row.department_id ?? '-',
      sortable: false,
      wrap: true,
      center: true,
    },

    // {
    //   name: 'Notice Title',
    //   selector: (row) => row.notice_subject,
    //   sortable: false,
    //   wrap: true,
    //   center: true,
    // },

    {
      name: 'Action',
      width: '150px',
      cell: (row) => (
        <>
          {/* btn-group-example */}
          <FormGroup className="btn-group mt-2">
            <Button
              size="sm"
              color="info"
              type="button"
              text={`<span class="fas fa-eye" ></span> View`}
              onClick={() => {
                handleViewTemplate(row);
              }}
            />
            <Button
              size="sm"
              color="success"
              type="button"
              text={`<span class="fas fa-square-check" ></span> Select`}
              onClick={() => {
                setSelectedTemplate(row);
                setModalShow(false);
              }}
            />
          </FormGroup>
        </>
      ),

      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const {
    handleSubmit,
    getFieldProps,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = `${values.search}`;
      }

      setSearch(s);
      // setPage('1');
      // set_paginate_per_page(15);
    },
  });

  // useEffect(() => {
  //   if (isNoticeListDataLoading || !isNoticeListDataSuccess) {
  //     toast.dismiss();
  //     toast.loading('Data Loading...');
  //   } else {
  //     toast.dismiss();
  //     //   toast.success('Data Load Successfully');
  //   }
  // }, [isNoticeListDataLoading, isNoticeListDataSuccess]);

  return (
    <Container fluid>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <Row>
            <Col>
              <div className="gap-2 w-100">
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    //   handleSubmit();
                  }}
                >
                  <div className="filter-wrapper">
                    <FormGroup>
                      <InputField
                        type="text"
                        invalid={{ errors, touched }}
                        {...getFieldProps('search')}
                      />
                    </FormGroup>

                    <FormGroup className="btn-group btn-group-example mb-3">
                      <Button
                        color="danger"
                        type="reset"
                        text="Reset"
                        onClick={() => {
                          resetForm();
                          handleSubmit();
                        }}
                      />
                      <Button
                        type="button"
                        text="Search"
                        onClick={() => {
                          handleSubmit();
                        }}
                      />
                    </FormGroup>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col sm={5} className='me-5'>
              <DataTable
                columns={columns}
                data={allNotices ? allNotices : []}
                // pagination
                // paginationServer
                // onChangePage={handlePageChange}
                // onChangeRowsPerPage={handlePerRowsChange}
                progressPending={isNoticeListDataLoading}
                // paginationTotalRows={noticeListData?.total}
                expandOnRowClicked
                highlightOnHover
                // paginationRowsPerPageOptions={[2, 5, 10, 15, 20, 50, 100, 200]}
                persistTableHead={true}
              />
            </Col>
            {viewModalShow ? (
              <Col sm={6} className='ps-5 border-start'>
                <InfoViewModal
                  key={reRenderState}
                  data={templateToView}
                />
              </Col>
            ) : null}
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default NoticeTemplateSelectionList;
