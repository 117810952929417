import InstituteAndDepartmentDetails from "src/pages/InspectionTeam/InstituteAndDepartmentDetails";
import InstituteApproval from "src/pages/Institute/InstituteApproval/index";
import InstituteList from "src/pages/Institute/InstituteList";
import InstituteRegistration from "src/pages/Institute/InstituteRegistration";
import EditInstituteDeptRegistration from "src/pages/InstituteDeptRegistrationFormOne/EditInstituteDeptRegistration";
import InstituteDeptRegistrationFormOne from "src/pages/InstituteDeptRegistrationFormOne/InstituteDeptRegistrationFormOne";
import RenewInstituteDeptRegistration from "src/pages/InstituteDeptRegistrationFormOne/RenewInstituteDeptRegistration";
import ViewInstituteDeptRegistration from "src/pages/InstituteDeptRegistrationFormOne/ViewInstituteDeptRegistration";
import InstituteDeptRegistrationFormTwo from "src/pages/InstituteDeptRegistrationFormTwo/InstituteDeptRegistrationFormTwo";
import InstituteReg from "src/pages/InstituteRegistration/InstituteRegistration";
import InstituteRegistrationList from "src/pages/InstituteRegistration/InstituteRegistrationList";
import RecomendationForm from "src/pages/RecomendationCriteria/RecomendationForm";
import AcademicStaff from "src/pages/Subject/AcademicStaff";
import OperationTheater from "src/pages/Subject/OperationTheater";
import ProcedureRecords from "src/pages/Subject/ProcedureRecords";
import ResearchInformation from "src/pages/Subject/ResearchInformation";
import SubjectAccreditation from "src/pages/Subject/SubjectAccreditation";
import SubjectList from "src/pages/Subject/SubjectList";
import SupportService from "src/pages/Subject/SupportService";

export const instituteRoutes = [
  // Subject Accreditation
  { path: "/institute/subject-registration", component: SubjectAccreditation },
  {
    path: "/institute/subject-registration-2",
    component: OperationTheater,
  },
  {
    path: "/institute/subject-registration-3",
    component: ProcedureRecords,
  },
  {
    path: "/institute/subject-registration-4",
    component: AcademicStaff,
  },
  {
    path: "/institute/subject-registration-5",
    component: ResearchInformation,
  },
  {
    path: "/institute/subject-registration-6",
    component: SupportService,
  },
  {
    path: "/institute/institute-List",
    component: InstituteList,
  },
  {
    path: "/institute/institute-registration",
    component: InstituteReg,
  },
  {
    path: "/institute/institute-registration/:viewOrEditTag",
    component: InstituteReg,
  },
  {
    path: "/institute/view-institute-registration-info/:viewOrEditTag",
    component: InstituteReg,
  },
  {
    path: "/institute/department-registration",
    component: InstituteDeptRegistrationFormOne,
  },
  {
    path: "/institute/department-registration/:departmentId/:formBId",
    component: EditInstituteDeptRegistration,
  },
  {
    path: "/institute/view-department/:departmentId/:formBId",
    component: ViewInstituteDeptRegistration,
  },
  {
    path: "/institute/renew-department/:departmentId/:formBId",
    component: RenewInstituteDeptRegistration,
  },
  {
    path: "/institute/institute-registration-prototype",
    component: InstituteRegistration,
  },
  {
    path: "/institute/subject-List",
    component: SubjectList,
  },

  {
    path: "/institute/institute-approval",
    component: InstituteApproval,
  },

  {
    path: "/recomendation-form/:id/:insId/:insLocation/:date/:chairman",
    component: InstituteDeptRegistrationFormTwo,
  },
  {
    path: "/recomendation-form-without-department/:id/:insId/:date/:chairman",
    component: RecomendationForm,
  },
  {
    path: "/institute-registration-list",
    component: InstituteRegistrationList,
  },

  {
    path: "/institute-department-details/:insId/:deptId",
    component: InstituteAndDepartmentDetails,
  }

];
