import { useFormik } from 'formik';
import { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import { InputField } from 'src/components';
import { useCreateTitleApprovalForRtmdMutation } from 'src/rtk/features/courseApproval/courseApprovalApi';
import * as Yup from 'yup';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
  info: any;
}

const initialValues = {
  id: '',
  admin_approval_status: '',
  note: '',
};

const TitleApprovalViewModal = (props: Props) => {
  const { isOpen, toggle, id, info } = props;
  // console.log(info);
  const buttonRef = useRef<any>();

  const [createTitleApprovalForRtmd, { isSuccess, isError, error }] = useCreateTitleApprovalForRtmdMutation();

  const err = error as CustomError;

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      admin_approval_status: Yup.string().required('Please Choose Status!'),
      note: Yup.string().when('admin_approval_status', (admin_approval_status, schema) => {
        if (admin_approval_status === 'Reject') {
          return schema.required('Reject note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {
      buttonRef.current.disabled = true;
      values.id = info?.id;
      createTitleApprovalForRtmd(values);
      console.log(values);
    },
  });


  useEffect(() => {
    if (isSuccess) {
      toast.success('Approval Status Updated Successfully!');
      buttonRef.current.disabled = false;
    }
    if (isError) {
      toast.error('Something went wrong');
      buttonRef.current.disabled = false;
    }
  }, [isSuccess, isError]);

  return (
    <Modal
      isOpen={isOpen}
      backdrop={'static'}
      toggle={toggle}
      size="lg"
    >
      <ModalHeader toggle={toggle}>Title Approval</ModalHeader>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(e);
        }}
      >
        <ModalBody>
          <div>
            <h6 className="block-heading">Trainee Information</h6>
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td className="col-md-6 col-lg-6">
                        <strong>Trainee Name: </strong>
                        {info?.registration?.personal_info?.full_name}
                      </td>
                      <td className="col-md-6 col-lg-6">
                        <strong>BMDC Reg. No: </strong>
                        {info?.registration?.bmdc_registration_no}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Date of Birth: </strong>
                        {info?.registration?.personal_info?.date_of_birth ?? 'N/A'}
                      </td>
                      <td>
                        <strong>NID: </strong>
                        {info?.registration?.personal_info?.national_id ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Mobile Number: </strong>
                        {info?.registration?.personal_info?.mobile ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Email: </strong>
                        {info?.registration?.personal_info?.email ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Alternative Number: </strong>
                        {info?.registration?.personal_info?.telephone ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Subject: </strong>
                        {info?.registration?.subject?.subject_name ?? 'N/A'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <h6 className="block-heading">Thesis Title Details</h6>
            <div className="row">
              <div className='col-md-12'>
                <table className="table table-bordered table-striped table-sm">
                  <tbody>
                    <tr>
                      <td className="col-md-6 col-lg-6">
                        <strong>Category: </strong>
                        {info?.category}
                      </td>
                      <td className="col-md-6 col-lg-6">
                        <strong>Title: </strong>
                        {info?.title_of_thesis}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Place Of Study: </strong>
                        {info?.place_of_study ?? 'N/A'}
                      </td>
                      <td>
                        <strong>Study Period: </strong>
                        {info?.study_period + ' ' + info?.study_period_unit ?? 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Guide: </strong>
                      </td>
                      <td>
                        <div className='row'>
                          <div className="col">
                            <strong>BMDC Reg. No.:</strong> {info?.guide?.bmdc_registration_no ?? 'N/A'} <br />
                            <strong>Name:</strong>  {info?.guide_name ?? 'N/A'} <br />
                            <div className="d-flex gap-1 justify-content-start">
                              <strong>Approval Status:</strong>
                              <div>
                                {info?.guide_approval_status?.toLowerCase() === 'approve' && (
                                  <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                    {'Accepted'}
                                  </div>
                                )}
                                {info?.guide_approval_status?.toLowerCase() === 'reject' && (
                                  <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                    {info?.guide_approval_status}
                                  </div>
                                )}
                                {info?.guide_approval_status?.toLowerCase() === 'correction' && (
                                  <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                    {info?.guide_approval_status}
                                  </div>
                                )}
                                {info?.guide_approval_status !== 'Approve' &&
                                  info?.guide_approval_status !== 'Reject' &&
                                  info?.guide_approval_status !== 'Correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                      {info?.guide_approval_status ?? 'Pending'}
                                    </div>
                                  )}
                              </div>
                            </div>
                            <strong>Approval Note: </strong>
                            <span className={info?.guide_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                              info?.guide_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                            >
                              {info?.guide_approval_note}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Co-Guide </strong>
                      </td>
                      <td>
                        {info?.co_guide_1_name &&
                          <div className='row card mx-1 my-1'>
                            <div className="col">
                              <strong>BMDC Reg. No.:</strong> {info?.co_guide_1?.bmdc_registration_no ?? 'N/A'} <br />
                              <strong>Name:</strong>  {info?.co_guide_1_name ?? 'N/A'} <br />
                              <div className="d-flex gap-1 justify-content-start">
                                <strong>Approval Status:</strong>
                                <div>
                                  {info?.co_guide1_approval_status?.toLowerCase() === 'approve' && (
                                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                      {'Accepted'}
                                    </div>
                                  )}
                                  {info?.co_guide1_approval_status?.toLowerCase() === 'reject' && (
                                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                      {info?.co_guide1_approval_status}
                                    </div>
                                  )}
                                  {info?.co_guide1_approval_status?.toLowerCase() === 'correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                      {info?.co_guide1_approval_status}
                                    </div>
                                  )}
                                  {info?.co_guide1_approval_status !== 'Approve' &&
                                    info?.co_guide1_approval_status !== 'Reject' &&
                                    info?.co_guide1_approval_status !== 'Correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                        {info?.co_guide1_approval_status ?? 'Pending'}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <strong>Approval Note: </strong>
                              <span className={info?.co_guide1_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                info?.co_guide1_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                              >
                                {info?.co_guide1_approval_note}
                              </span>
                            </div>
                          </div>
                        }
                        {info?.co_guide_2_name &&
                          <div className='row card mx-1 my-1'>
                            <div className="col">
                              <strong>BMDC Reg. No.:</strong> {info?.co_guide_2?.bmdc_registration_no ?? 'N/A'} <br />
                              <strong>Name:</strong>  {info?.co_guide_2_name ?? 'N/A'} <br />
                              <div className="d-flex gap-1 justify-content-start">
                                <strong>Approval Status:</strong>
                                <div>
                                  {info?.co_guide2_approval_status?.toLowerCase() === 'approve' && (
                                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                      {'Accepted'}
                                    </div>
                                  )}
                                  {info?.co_guide2_approval_status?.toLowerCase() === 'reject' && (
                                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                      {info?.co_guide2_approval_status}
                                    </div>
                                  )}
                                  {info?.co_guide2_approval_status?.toLowerCase() === 'correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                      {info?.co_guide2_approval_status}
                                    </div>
                                  )}
                                  {info?.co_guide2_approval_status !== 'Approve' &&
                                    info?.co_guide2_approval_status !== 'Reject' &&
                                    info?.co_guide2_approval_status !== 'Correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                        {info?.co_guide2_approval_status ?? 'Pending'}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <strong>Approval Note: </strong>
                              <span className={info?.co_guide2_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                info?.co_guide2_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                              >
                                {info?.co_guide2_approval_note}
                              </span>
                            </div>
                          </div>
                        }
                        {info?.co_guide_3_name &&
                          <div className='row card mx-1 my-1'>
                            <div className="col">
                              <strong>BMDC Reg. No.:</strong> {info?.co_guide_3?.bmdc_registration_no ?? 'N/A'} <br />
                              <strong>Name:</strong>  {info?.co_guide_3_name ?? 'N/A'} <br />
                              <div className="d-flex gap-1 justify-content-start">
                                <strong>Approval Status:</strong>
                                <div>
                                  {info?.co_guide3_approval_status?.toLowerCase() === 'approve' && (
                                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                                      {'Accepted'}
                                    </div>
                                  )}
                                  {info?.co_guide3_approval_status?.toLowerCase() === 'reject' && (
                                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                                      {info?.co_guide3_approval_status}
                                    </div>
                                  )}
                                  {info?.co_guide3_approval_status?.toLowerCase() === 'correction' && (
                                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                                      {info?.co_guide3_approval_status}
                                    </div>
                                  )}
                                  {info?.co_guide3_approval_status !== 'Approve' &&
                                    info?.co_guide3_approval_status !== 'Reject' &&
                                    info?.co_guide3_approval_status !== 'Correction' && (
                                      <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                                        {info?.co_guide3_approval_status ?? 'Pending'}
                                      </div>
                                    )}
                                </div>
                              </div>
                              <strong>Approval Note: </strong>
                              <span className={info?.co_guide3_approval_status?.toLowerCase() === 'approve' ? 'text-success' :
                                info?.co_guide3_approval_status?.toLowerCase() === 'correction' ? 'text-warning' : 'text-danger'}
                              >
                                {info?.co_guide3_approval_note}
                              </span>
                            </div>
                          </div>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* <h6 className="block-heading">Approval Status</h6>
          <Row>
            {info?.admin_approval_status !== 'Approve' && info?.admin_approval_status !== 'Reject' ?
              <>
                <div className="col">
                  <FormGroup check inline className="mb-3 ms-2">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="profile_approval_status"
                      id="profile-approve"
                      checked={values.admin_approval_status === 'Approve'}
                      onChange={(e) => {
                        setFieldValue('admin_approval_status', 'Approve');
                      }}
                    />
                    <label
                      className="form-check-label text-success"
                      htmlFor="profile-approve"
                    >
                      Accept
                    </label>
                  </FormGroup>
                  <FormGroup check inline className="mb-3 ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="profile_approval_status"
                      checked={values.admin_approval_status === 'Reject'}
                      id="reject"
                      onChange={() => {
                        setFieldValue('admin_approval_status', 'Reject');
                      }}
                    />
                    <label
                      className="form-check-label text-danger"
                      htmlFor="reject"
                    >
                      Reject
                    </label>
                  </FormGroup>

                  {errors.admin_approval_status && touched.admin_approval_status ? (
                    <div className="text-danger error-size">{errors.admin_approval_status}</div>
                  ) : null}
                </div>

                <div className="col">
                  <Label for="note">{values.admin_approval_status}&nbsp;Note</Label>
                  <InputField
                    type="textarea"
                    placeholder="Enter.."
                    invalid={{ errors, touched }}
                    {...getFieldProps('note')}
                  />
                </div>
              </>
              :
              <div className='d-flex gap-1 justify-content-start'>
                <strong>Admin Approval Status : </strong>
                <div>
                  {info?.admin_approval_status?.toLowerCase() === 'approve' && (
                    <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                      {'Accepted'}
                    </div>
                  )}
                  {info?.admin_approval_status?.toLowerCase() === 'reject' && (
                    <div className="badge rounded-pill d-inline-block badge-rejected font-size-12 fw-medium">
                      {info?.admin_approval_status}
                    </div>
                  )}
                  {info?.admin_approval_status?.toLowerCase() === 'correction' && (
                    <div className="badge rounded-pill d-inline-block badge-correction font-size-12 fw-medium">
                      {info?.admin_approval_status}
                    </div>
                  )}
                  {info?.admin_approval_status !== 'Approve' &&
                    info?.admin_approval_status !== 'Reject' &&
                    info?.admin_approval_status !== 'Correction' && (
                      <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                        {info?.admin_approval_status ?? 'Pending'}
                      </div>
                    )}
                </div>
              </div>
            }

          </Row> */}
        </ModalBody>

        {/* <ModalFooter>
          <div className="d-flex gap-2 ">
            {info?.admin_approval_status !== 'Approve' && info?.admin_approval_status !== 'Reject' ?
              <button
                type="submit"
                className="btn btn-primary"
                ref={buttonRef}
              >
                Save
              </button>
              : null
            }
            <Button color="danger" onClick={toggle}>
              Close
            </Button>
          </div>
        </ModalFooter> */}
      </Form>
    </Modal>
  );
};

export default TitleApprovalViewModal;
