import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Modal,
  Row
} from 'reactstrap';
import { Button, InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { config } from 'src/config';
import { useGetInstitutePaymentApprovalListQuery, useGetPaymentConfirmMutation } from 'src/rtk/features/payment/paymentApprovalApi';
import loader from '../../assets/images/loader.gif';
import { useGetInstituteNameQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';

const DepartmentPaymentApprovalList = () => {
  document.title = 'Department Payment List | BCPS';
  let referenceUrl = new URLSearchParams(location.search).get('ref');

  const [modalShow, setModalShow] = useState<boolean>(false);
  const [institutePayment, setInstitutePayment] = useState<InstituteDepartmentPaymentList>({} as InstituteDepartmentPaymentList);

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `Department?page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    from_date?: string;
    to_date?: string;
    approval_status?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    from_date: '',
    to_date: '',
    approval_status: '',
  };

  const {data: institute} = useGetInstituteNameQuery();

  const {
    data: institutePayments,
    error: institutePaymentsError,
    isLoading: isInstitutePaymentsLoading,
    isError: isInstitutePaymentsError,
    isSuccess: isInstitutePaymentsSuccess,
    refetch,
    isFetching
  } = useGetInstitutePaymentApprovalListQuery(generateQueryUrl());

  const institutePaymentList = institutePayments?.data?.data;

  useEffect(() => {
    refetch();
  }, [referenceUrl]);

  const modalShowHandler = (modalState: boolean, institutePayment: InstituteDepartmentPaymentList) => {
    setInstitutePayment(institutePayment);
    setModalShow(modalState);
  };

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.from_date !== '') {
        s = s + `&from_date=${values.from_date}`;
      }

      if (values.to_date !== '') {
        s = s + `&to_date=${values.to_date}`;
      }

      if (values.approval_status !== '') {
        s = s + `&approval_status=${values.approval_status}`;
      }

      setSearch(s);
      setPage('1');
    },
  });

  const getInstitute = (id: string) => {
    const name = institute?.find((item) => item.id === id);
    return name?.institute_name;
  }

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      width: '80px',
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
    },
    {
      name: <div>Department Name</div>,
      selector: (row) => row?.department?.department_name,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Institute Name</div>,
      selector: (row) => getInstitute(row?.department?.institute_id),
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Payment Mode</div>,
      selector: (row) => row.payment_mode,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Payment Date</div>,
      selector: (row) =>
        row.payment_date ? moment(row.payment_date).format('DD-MMM-YYYY') : '',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Payment Method</div>,
      selector: (row) => row.payment_method,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Fees</div>,
      selector: (row) => row.total_amount,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Status</div>,
      selector: (row) => row.payment_verification_status,
      cell: (row) => {
        switch (row.payment_verification_status) {
          case 'Reject':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-rejected font-size-12 fw-medium">
                  {'Rejected'}
                </div>
              </>
            );

          case 'Draft':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-draft font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );

          case 'Approve':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                  {'Approved'}
                </div>
              </>
            );

          case 'Approved':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-approved font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );

          case 'Pending':
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );

          default:
            return (
              <>
                <div className="badge rounded-pill d-inline-block  badge-pending font-size-12 fw-medium">
                  {row.payment_verification_status}
                </div>
              </>
            );
        }
      },
    },

    {
      name: <div>Action</div>,
      width: '200px',
      cell: (row) => (
        <div>
          <Button
            size="sm"
            color="primary"
            type="button"
            className='me-1'
            text={`<span class="fas fa-eye" ></span> View`}
            onClick={() => {
              modalShowHandler(true, row);
            }}
          />
          <a
            href={config.APP_URL + row?.bank_deposit_slip}
            rel="noreferrer"
            target="_blank"
          >
            <p className="btn btn-sm btn-outline-primary my-2">
              <i className="fa-solid fa-file" /> Deposit Slip
            </p>
          </a>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const [
    getPaymentConfirm,
    { data: paymentConfirm, isSuccess, isError, error },
  ] = useGetPaymentConfirmMutation();

  const handleVerify = (id: string) => {
    getPaymentConfirm(id);
  };
  const err = error as CustomError;

  useEffect(() => {
    if (isSuccess) {
      toast.success('Verified Successfully');
      setModalShow(!modalShow);
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  return (
    <div className="page-content">
      {isInstitutePaymentsLoading || isFetching ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb breadcrumbItem={[{ link: '#', name: 'Department' }]} title={'Departments Payment List'} />
        <Card>
          <CardBody>
            <Form
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onReset={(e) => {
                resetForm();
                handleSubmit();
              }}
            >
              <div className="filter-wrapper">
                <FormGroup>
                  <Label for="from_date">From Date</Label>
                  <InputField
                    type="date"
                    placeholder=""
                    invalid={{ errors, touched }}
                    {...getFieldProps('from_date')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="to_date">To Date</Label>
                  <InputField
                    type="date"
                    placeholder=""
                    invalid={{ errors, touched }}
                    {...getFieldProps('to_date')}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="approval_status">
                    Approval Status
                  </Label>
                  <InputField
                    type="select"
                    name='approval_status'
                    placeholder="Approval Status"
                    options={[
                      { value: 'Approved', label: 'Approved' },
                      { value: 'Pending', label: 'Pending' },
                    ]}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </FormGroup>
                <div className="btn-column mb-3">
                  <Button color="danger" type="reset" text="Reset" />
                  <Button type="submit" text="Search" />
                </div>
              </div>
            </Form>
            <DataTable
              columns={columns}
              data={institutePaymentList ? institutePaymentList : []}
              progressPending={isInstitutePaymentsLoading}
              pagination
              paginationServer
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              expandOnRowClicked
              highlightOnHover
              paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
              paginationTotalRows={institutePayments?.data?.total}
              responsive
              persistTableHead={true}
            />
          </CardBody>
        </Card>

        <Modal
          onOpened={function () {
            toast.dismiss();
          }}
          isOpen={modalShow}
          scrollable={true}
          size="lg"
          toggle={() => {
            document.body.classList.add('no_padding');
          }}
          backdrop={'static'}
          modalTransition={{ timeout: 0 }}
          backdropTransition={{ timeout: 0 }}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              {institutePayment?.application_type} Payment Details
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setModalShow(!modalShow);
              }}
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <Row>
              <Col>
                <table className="table table-sm table-bordered table-striped">
                  <tbody>
                    <tr>
                      <td>
                        <span className="fw-semibold">Department Name: </span>
                        <span>{institutePayment?.department?.department_name}</span>
                      </td>
                      <td>
                        <span className="fw-semibold">Institute Name: </span>
                        <span>{institute?.find((item) => item.id === institutePayment?.department?.institute_id)?.institute_name}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Payment Date: </span>
                        <span>{moment(institutePayment?.payment_date).format('DD-MMM-YYYY')}</span>
                      </td>

                      <td>
                        <span className="fw-semibold">Total Amount: </span>
                        <span>{institutePayment?.total_amount}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="fw-semibold">Payment For: </span>
                        <span>{institutePayment?.payment_for}</span>
                      </td>

                      <td>
                        <span className="fw-semibold">
                          Payment Method:{' '}
                        </span>
                        <span>{institutePayment?.payment_method}</span>
                      </td>
                    </tr>
                    <tr>

                      <td>
                        <span className="fw-semibold">Bank Name: </span>
                        <span>{institutePayment?.bank_name}</span>
                      </td>

                      <td>
                        <span className="fw-semibold">Branch Name: </span>
                        <span>{institutePayment?.branch_name}</span>
                      </td>
                    </tr>
                    <tr>

                      <td>
                        <span className="fw-semibold">
                          Deposit Slip No:{' '}
                        </span>
                        <span>{institutePayment?.deposit_slip_no}</span>
                      </td>

                      <td>
                        <span className="fw-semibold">Payment Verification Status: </span>
                        {institutePayment?.payment_verification_status === 'Approved' ? (
                          <span className="ms-1 badge badge-approved">
                            Verified
                          </span>
                        ) : (
                          <span className="ms-1 badge badge-pending">
                            Pending
                          </span>
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <span className="fw-semibold">Deposit Slip: </span>
                        <span>
                          {institutePayment?.bank_deposit_slip ? (
                            <a
                              className="ms-2 btn btn-sm btn-outline-info"
                              href={
                                config.APP_URL +
                                institutePayment?.bank_deposit_slip
                              }
                              rel="noreferrer"
                              target="_blank"
                            >
                              <i className="fa-solid fa-file" /> Deposit Slip
                            </a>
                          ) : (
                            <span>N/A</span>
                          )}
                        </span>
                      </td>
                      <td>
                        {!institutePayment?.payment_confirmation_ind && (
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={(e) => {
                              handleVerify(institutePayment?.id!);
                            }}
                          >
                            Verify Payment Now
                          </button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default DepartmentPaymentApprovalList;
