import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { config } from 'src/config';
import { useAppSelector } from 'src/rtk/app/hook';

const Footer = () => {
    const authUser = useAppSelector((state) => state.auth.user);

    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid={true}>
                    <Row>
                        <Col md={4}>
                            <h6>Help & Support ( Sat - Thu : 9 AM - 4 PM )</h6>
                            <span><b>Email : </b> bcpselogbook@gmail.com</span> <br />
                            <span><b>Phone : </b> +8801721881962, +8801521503928</span>
                        </Col>
                        <Col md={4} className='text-center'>
                            <div>
                                {new Date().getFullYear()} © BCPS.
                                {authUser?.type === 'Trainee' &&
                                    <a
                                        href={'/documents/User Manual for BCPS eLogbook (TRAINEE).pdf'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="ms-1 text-decoration-underline"
                                    >
                                        User Manual
                                    </a>
                                }
                                
                                {authUser?.type === 'Supervisor' &&
                                    <a
                                        href={'/documents/User Manual for BCPS eLogbook (SUPERVISOR).pdf'}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="ms-1 text-decoration-underline"
                                    >
                                        User Manual
                                    </a>
                                }
                            </div>
                            <div>
                                <a
                                    href='https://www.youtube.com/@BCPSOfficial/videos'
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="ms-1 text-decoration-underline"
                                >
                                    Video Tutorial
                                </a>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="text-sm-end d-none d-sm-block">
                                <p className='mb-0'>
                                    In Planning and Implementation: BCPS IT.
                                </p>
                                Technical Partner:
                                <a
                                    href="https://egeneration.co/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="ms-1 text-decoration-underline"
                                >
                                    eGeneration Ltd.
                                </a>

                            </div>

                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;
