import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { InputField } from 'src/components';
import { config } from 'src/config';
import {
  useGetBmdcApprovalMutation,
  useGetBmdcSourceVerifyMutation,
  useGetCertificateSourceVerifyMutation,
  useGetRegInfoQuery,
  useGetTraineeProfileApprovalMutation,
  useLazyGetRegInfoQuery,
  useUpdateRegInfoMutation,
} from 'src/rtk/features/registration-approval/registrationApprovalApi';

import DataTable, { TableColumn } from 'react-data-table-component';
import DisplayImage from 'src/components/Common/displayImage';
import * as url from 'src/helpers/url_helper';
import { useAppSelector } from 'src/rtk/app/hook';
import { useLazyGetDataForRegistrationQuery } from 'src/rtk/features/auth/authApi';
import { useLazyGetDataQuery } from 'src/rtk/features/common/crudApi';
import * as Yup from 'yup';
import loader from '../../assets/images/loader.gif';

interface Props {
  isOpen: boolean;
  toggle: any;
  id: string;
}

const initialValues: RegApprove = {
  registration_no: '',
  status: '',
  message: '',
  type: '',
  registration_type: '',
};

const InfoViewModal = (props: Props) => {
  const { isOpen, toggle, id } = props;
  const buttonRef = useRef<any>();

  const [verifyId, setVerifyId] = useState('');
  const [profileUpdate, setProfileUpdate] = useState(false);
  const [profileNotOk, setProfileNotOk] = useState(false);
  const [bmdcCheckModalShow, setBmdcCheckModalShow] = useState(false);
  const [fcpsPartOneInfoModalShow, setFcpsPartOneInfoModalShow] = useState<boolean>(false);
  const [fakeReg, setFakeReg] = useState(false);
  const authUser = useAppSelector((state) => state.auth.user);
  const [
    getBmdcApproval,
    {
      data: bmdcApprovalData,
      isLoading: isBmdcLoading,
      isSuccess: isBmdcApproveSuccess,
      isError: isBmdcApproveError,
      error: bmdcError,
    },
  ] = useGetBmdcApprovalMutation();

  const [
    getBmdcSourceVerify,
    {
      data: isBmdcSourceVerifyData,
      isSuccess: isBmdcSourceVerifySuccess,
      isError: isBmdcSourceVerifyError,
      isLoading: isBmdcSourceVerifyLoading,
    },
  ] = useGetBmdcSourceVerifyMutation();

  const [
    getTraineeProfileApproval,
    { data: approvalData, isSuccess: isProfileApproveSuccess, isError: isProfileApproveError, error: profileApprovalError, isLoading: isProfileApprovalLoading },
  ] = useGetTraineeProfileApprovalMutation();

  const [getBmdcRegData, { data: checkData, isLoading: isBmdcCheckLoading, isSuccess: isBmdcCheckSuccess, isError: isBmdcCheckError }] = useLazyGetDataForRegistrationQuery();

  const [getRegInfo] = useLazyGetRegInfoQuery();

  const [updateRegInfo, { isSuccess, isError, error }] =
    useUpdateRegInfoMutation();
  const err = profileApprovalError as CustomError;

  const { data, isLoading } = useGetRegInfoQuery(id);
  const userInfo = data?.data || ({} as RegInfo);

  const [getFcpsPartOneDetails,
    {
      data: fcpsPartOneDetailsData,
      isLoading: isFcpsPartOneDetailsDataLoading,
      isSuccess: isFcpsPartOneDetailsDataSuccess,
      isError: isFcpsPartOneDetailsDataError,
      error: fcpsPartOneDetailsDataError,
    }
  ] = useLazyGetDataQuery();

  const [
    getCertificateVerify,
    {
      data: certificateVerified,
      isSuccess: isCertificateVerifiedSuccess,
      isLoading: isCertificateVerifiedLoading,
    },
  ] = useGetCertificateSourceVerifyMutation();

  // useEffect(() => {
  //   if (isLoading) {
  //     toast.dismiss();
  //     toast.loading('Data Loading...');
  //   } else {
  //     toast.dismiss();
  //     toast.success('Data Load Successfully');
  //   }
  // }, [isLoading, isError]);

  const {
    values,
    handleSubmit,
    resetForm,
    setFieldValue,
    handleChange,
    getFieldProps,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: Yup.object({
      message: Yup.string().when('status', (status, schema) => {
        if (status === 'Correction' || status === 'Reject') {
          return schema.required('Note is Required!');
        }
        return schema;
      }),
    }),
    onSubmit: (values) => {
      // const url = `${userInfo?.bcps_registration_no}?user_id=${
      //   userInfo?.user_id
      // }&bcps_registration_no=${userInfo?.bcps_registration_no}&status=${
      //   userInfo?.status ? 1 : 0
      // }`;
      setProfileUpdate(true);

      /*values.registration_no = userInfo.registration_no;
      values.registration_type = userInfo.registration_type;

      if (values.status === '' && userInfo?.profile_approval_status) {
        values.status = userInfo?.profile_approval_status;
        values.type = 'profile';
      }*/
      buttonRef.current.disabled = true;

      if (values.status === 'Approve') {
        const approveUrl = `${userInfo.registration_no}/${values.status}`;
        getTraineeProfileApproval(approveUrl);
      }

      if (values.status === 'Correction') {
        const correctionUrl = `${userInfo.registration_no}/${values.status}?note=${values.message}&email=${userInfo?.personal_info?.email}`;
        getTraineeProfileApproval(correctionUrl);
      }

      if (values.status === 'Reject') {
        const rejectUrl = `${userInfo.registration_no}/${values.status}?note=${values.message}&email=${userInfo?.personal_info?.email}&profile_not_ok=${profileNotOk}&fake_registration=${fakeReg}`;
        getTraineeProfileApproval(rejectUrl);
      }

      // updateRegInfo(values);
    },
  });

  const handleCertificateSourceVerify = (e: any, id: string) => {
    if (e.target.checked) {
      // setVerifyId(id);
      const url = `${id}/Approve`;
      getCertificateVerify(url);
    }
  };

  const bmdcAprove = (e: any) => {
    if (e.target.checked) {
      // values.registration_no = userInfo.registration_no;
      // values.registration_type = userInfo.registration_type;
      // values.status = 'Approve';
      // values.type = 'bmdc';
      // updateRegInfo(values);
      const url = `${userInfo.registration_no}/Approve`;
      getBmdcApproval(url);
    }
  };
  const bmdcSourceVerify = (e: any) => {
    if (e.target.checked) {
      // values.registration_no = userInfo.registration_no;
      // values.registration_type = userInfo.registration_type;
      // values.status = 'Approve';
      // values.type = 'bmdc_sorce';
      // updateRegInfo(values);

      const url = `${userInfo.registration_no}/Approve`;
      getBmdcSourceVerify(url);
    }
  };

  useEffect(() => {
    if (
      certificateVerified &&
      !isCertificateVerifiedLoading &&
      isCertificateVerifiedSuccess
    ) {
      toast.success('Verified Successfully');
      // getRegInfo(id);
    }
  }, [
    certificateVerified,
    isCertificateVerifiedLoading,
    isCertificateVerifiedSuccess,
  ]);

  useEffect(() => {
    if (isBmdcApproveSuccess) {
      toast.success('BMDC Approved Successfully!');
      // getRegInfo(id);
    }
    if (isBmdcApproveError) {
      toast.error(bmdcApprovalData?.message || 'Something went wrong');
    }
  }, [isBmdcApproveSuccess, isBmdcApproveError]);

  useEffect(() => {
    if (isBmdcSourceVerifySuccess) {
      toast.success('BMDC Source Verified Successfully');
      // getRegInfo(id);
    }
    if (isBmdcSourceVerifyError) {
      toast.error(isBmdcSourceVerifyData?.message || 'Something went wrong');
    }
  }, [isBmdcSourceVerifySuccess, isBmdcSourceVerifyError]);

  useEffect(() => {
    if (isProfileApproveSuccess) {
      toast.success('Profile Approval Status Updated Successfully');
      // getRegInfo(id);
      buttonRef.current.disabled = false;
    }
    if (isProfileApproveError) {
      toast.error(err?.message!);
      buttonRef.current.disabled = false;
    }
  }, [isProfileApproveSuccess, isProfileApproveError]);

  useEffect(() => {
    if (isBmdcCheckSuccess) {
      if (checkData?.success) {
        toast.success('BMDC Reg. Number Found');
      }
      else {
        toast.error('Not Found BMDC Reg. Number');
      }
    }
    if (isBmdcCheckError) {
      toast.error(checkData?.message || 'Something went wrong');
    }
  }, [isBmdcCheckSuccess, isBmdcCheckError]);

  const handleProfileNotOk = (e: any) => {
    if (e.target.checked) {
      setProfileNotOk(true);
    }
    else {
      setProfileNotOk(false);
    }
  }

  const handleFakeReg = (e: any) => {
    if (e.target.checked) {
      setFakeReg(true);
    }
    else {
      setFakeReg(false);
    }
  }

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => index + 1,
      width: '80px',
    },

    {
      name: <div>Roll No.</div>,
      selector: (row) => row?.roll ? row?.roll : '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Subject</div>,
      selector: (row) => row?.subject ? row?.subject : '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Institute</div>,
      selector: (row) => row?.institute ? row?.institute : '-',
      sortable: false,
      wrap: true,
      width: '250px',
    },

    {
      name: <div>Passing Session</div>,
      selector: (row) => row?.passing_session ? row?.passing_session : '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Passing Year</div>,
      selector: (row) => row?.passing_year ? row?.passing_year : '-',
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Result</div>,
      selector: (row) => row?.result ? row?.result : '-',
      sortable: false,
      wrap: true,
    },
  ];

  // useEffect(() => {
  //   if (isSuccess) {
  //     resetForm();
  //     toast.success('Registration Approval Updated');
  //     isOpen;

  //     if (profileUpdate) {
  //       toggle();
  //     }
  //   }
  //   if (isError) {
  //     toast.error(err?.message || 'Something went wrong');
  //   }
  // }, [isSuccess, isError]);

  // useEffect(() => {
  //   const url = `${verifyId}/Approve`
  //   getCertificateVerify(url);
  // }, [verifyId]);

  // const {
  //   data: sourceVerify,
  //   isSuccess: verifySuccess,
  //   isError: verifyEroor,
  // } = useGetCertificateSourceVerifyQuery(verifyId);

  // useEffect(() => {
  //   if (verifySuccess) {
  //     toast.success('Verify Successfully');
  //     isOpen && toggle();
  //   }
  //   if (verifyEroor && sourceVerify) {
  //     toast.error('Something went wrong');
  //   }
  // }, [verifySuccess, verifyEroor, sourceVerify]);

  const bmdcRegFilteredValue =
    userInfo?.bmdc_registration_no_verified_approval_history?.filter((item) => {
      return item.entry_by_user_id === authUser.id;
    });
  const lengthOfBmdcRegFilteredValue = bmdcRegFilteredValue?.length;

  const bmdcCertificateFilteredValue =
    userInfo?.bmdc_certificate_source_verified_approval_history?.filter(
      (item) => {
        return item.entry_by_user_id === authUser.id;
      },
    );
  const lengthOfBmdcCertificateFilteredValue =
    bmdcCertificateFilteredValue?.length;
  console.log(userInfo?.current_employment_info);
  return (
    <>
      {isBmdcCheckLoading || isLoading || isProfileApprovalLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Modal
        isOpen={isOpen}
        backdrop={'static'}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader toggle={toggle}>Registration Approval</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e);
            console.log(values);
          }}
        >
          <ModalBody>
            <div>
              <h6 className="block-heading"> Personal Information</h6>
              <div className="row">
                <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-bordered table-striped table-sm">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Name: </strong>
                              {userInfo?.personal_info?.full_name}
                            </td>
                            <td>
                              {userInfo?.personal_info?.fathers_name && (
                                <div>
                                  <strong>Father's Name: </strong>
                                  {userInfo?.personal_info?.fathers_name ?? 'N/A'}
                                </div>
                              )}

                              {userInfo?.personal_info?.spouse_name && (
                                <div>
                                  <strong>Spouse Name: </strong>
                                  {userInfo?.personal_info?.spouse_name ?? 'N/A'}
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Mother's Name: </strong>
                              {userInfo?.personal_info?.mothers_name ?? 'N/A'}
                            </td>
                            <td>
                              <strong>Date of Birth: </strong>
                              {userInfo?.personal_info?.date_of_birth ?? 'N/A'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>NID: </strong>
                              {userInfo?.personal_info?.national_id ?? 'N/A'}
                            </td>

                            <td>
                              <strong>Mobile Number: </strong>
                              {userInfo?.personal_info?.mobile ?? 'N/A'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email: </strong>
                              {userInfo?.personal_info?.email ?? 'N/A'}
                            </td>

                            <td>
                              <strong>Alternative Number: </strong>
                              {userInfo?.personal_info?.telephone ?? 'N/A'}
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <strong>Subject: </strong>
                              {userInfo?.subject?.subject_name ?? 'N/A'}
                            </td>
                            <td>
                              <strong>BCPS Reg. No: </strong>
                              {userInfo?.bcps_registration_no ?? 'N/A'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>BMDC Reg. No: </strong>
                              {userInfo?.bmdc_registration_no ?? 'N/A'}
                            </td>
                            <td>

                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 text-center">
                  {/* <div className="img-fluid img-rounded text-center">
                    <img
                      src={
                        userInfo?.personal_info?.picture
                          ? `${config?.APP_URL}${userInfo?.personal_info?.picture}`
                          : avatar
                      }
                      width="120"
                      className="img-thumbnail"
                    />
                  </div> */}
                  {userInfo?.personal_info?.picture &&
                    <DisplayImage
                      className="img-thumbnail profile-img-width"
                      src={`${config?.APP_URL}${userInfo?.personal_info?.picture}`}
                    />}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-12 mb-2">
                  <h6 className="block-heading">Present Address</h6>
                  <table className="table table-bordered table-striped table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Address: </strong>
                          {userInfo?.latest_present_address?.address_line_1 ?? ''}
                          &nbsp;
                          {userInfo?.latest_present_address?.address_line_2 ?? ''}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Upazilla: </strong>
                          {userInfo?.latest_present_address?.police_station ??
                            'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>District: </strong>
                          {userInfo?.latest_present_address?.district ?? 'N/A'}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Division: </strong>
                          {userInfo?.latest_present_address?.division ?? 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Mailing Address: </strong>
                          {userInfo?.mailing_address === 'present_address' ? (
                            <span className="badge badge-soft-success text-success">
                              Yes
                            </span>
                          ) : (
                            <span className="badge badge-soft-danger text-danger">
                              No
                            </span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6 col-sm-12 mb-2">
                  <h6 className="block-heading">Permanent Address</h6>
                  <table className="table table-bordered table-striped table-sm">
                    <tbody>
                      <tr>
                        <td>
                          <strong>Address: </strong>
                          {userInfo?.latest_permanent_address?.address_line_1 ??
                            ''}
                          &nbsp;
                          {userInfo?.latest_permanent_address?.address_line_2 ??
                            ''}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Upazilla: </strong>

                          {userInfo?.latest_permanent_address?.police_station ??
                            'N/A'}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>District: </strong>

                          {userInfo?.latest_permanent_address?.district ?? 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Division: </strong>

                          {userInfo?.latest_permanent_address?.division ?? 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Mailing Address: </strong>
                          {userInfo?.mailing_address === 'permanent_address' ? (
                            <span className="badge badge-soft-success text-success">
                              Yes
                            </span>
                          ) : (
                            <span className="badge badge-soft-danger text-danger">
                              No
                            </span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {userInfo?.educational_infos?.length > 0 && (
                <>
                  <h6 className="block-heading">
                    Education Information
                  </h6>
                  <Row>
                    {userInfo?.educational_infos.map((edu, index) => (
                      <div className="col-md-6" key={edu.id}>
                        <Card>
                          <CardBody>
                            <ul className="list-unstyled mb-0">
                              <li>
                                <strong>Degree: </strong>
                                {edu.name_of_the_degree}
                              </li>
                              <li>
                                <strong>Institute: </strong>
                                {edu.institute_or_university}
                              </li>

                              <li>
                                <strong>Passing Year: </strong>
                                {edu.passing_year}
                              </li>

                              <li>
                                <strong>Result: </strong>
                              </li>

                              <li>
                                <strong>Certificate: </strong>
                                {edu.certificate_pdf ? (
                                  <>
                                    <a
                                      className="me-2 p-0"
                                      href={config.APP_URL + edu.certificate_pdf}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      Download
                                    </a>
                                    <div className="form-check form-check-inline">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="source_verify"
                                        disabled={
                                          edu?.certificate_pdf_source_verified ===
                                          'Approve'
                                        }
                                        checked={
                                          edu?.certificate_pdf_source_verified ===
                                          'Approve'
                                        }
                                        onChange={(e) => {
                                          handleCertificateSourceVerify(
                                            e,
                                            edu?.id,
                                          );
                                          handleChange(e);
                                        }}
                                        id={'source_verify' + edu.id}
                                      />
                                      <label
                                        className="form-check-label"
                                        htmlFor={'source_verify' + edu.id}
                                      >
                                        Source Verify
                                      </label>
                                    </div>
                                  </>
                                ) : (
                                  <span className="text-muted">N/A</span>
                                )}
                              </li>
                            </ul>
                          </CardBody>
                        </Card>
                      </div>
                    ))}
                  </Row>
                </>
              )}
              {userInfo?.training_infos?.length > 0 && (
                <>
                  <h6 className="block-heading">Training Record</h6>
                  <div className="row">
                    {userInfo?.training_infos?.map((training, index) => (
                      <div className="col-md-6" key={training.id}>
                        <div className="card">
                          <div className="card-body">
                            <ul className="list-unstyled mb-0">
                              <li>
                                <strong>Institute: </strong>
                                <span>{training.name_of_institute ?? 'N/A'}</span>
                              </li>

                              <li>
                                <strong>Supervisor: </strong>
                                <span>{training.supervisor_name ?? 'N/A'}</span>
                              </li>

                              <li>
                                <strong>Department: </strong>
                                <span>{training.department ?? 'N/A'}</span>
                              </li>

                              <li>
                                <strong>Duration: </strong>
                                <span className="me-2">
                                  {training.duration ?? '-'}
                                </span>
                                <span>{training.duration_unit ?? '-'}</span>
                              </li>

                              <li>
                                <strong>Supervisor's Post-Grad. Degree:</strong>
                                <span className="ms-2">
                                  {training.supervisor_post_graduation_degree ??
                                    'N/A'}
                                </span>
                              </li>

                              <li>
                                <strong>Document: </strong>
                                <span>
                                  {training.document ? (
                                    <a
                                      className="m-0 p-0"
                                      href={config.APP_URL + training.document}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      Download
                                    </a>
                                  ) : (
                                    <span className="text-muted">N/A</span>
                                  )}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {userInfo?.employment_infos?.length > 0 && (
                <>
                  <h6 className="block-heading">Work Experience</h6>
                  <div className="row">
                    {userInfo?.employment_infos?.map((employment, index) => (
                      <div className="col-md-6" key={employment.id}>
                        <div className="card">
                          <div className="card-body">
                            <ul className="list-unstyled mb-0">
                              <li>
                                <strong>Institute: </strong>
                                {employment.institute_name ?? 'N/A'}
                              </li>

                              <li>
                                <strong>Designation: </strong>
                                {employment.designation ?? 'N/A'}
                              </li>

                              <li>
                                <strong>Employment Type: </strong>
                                {employment.employment_type ?? 'N/A'}
                              </li>

                              <li>
                                <strong>Current Position: </strong>

                                {employment.current_position
                                  ? 'Yes'
                                  : 'No'}
                              </li>
                              {employment.current_position && 
                              <li>
                                <strong>Head of the Department: </strong>
                                {userInfo?.current_employment_info?.department_head ?
                                  'Yes'
                                  : 'No'
                                }
                              </li>}
                              {employment.current_position && 
                              <li>
                                <strong>In-Charge of Unit: </strong>
                                {userInfo?.current_employment_info?.in_charge ?
                                  'Yes'
                                  : 'No'
                                }
                              </li>}
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
            {(userInfo?.registration_type === 'Supervisor' || userInfo?.registration_type === 'Fellow') &&
              <Row>
                <Col>
                  <h6 className="block-heading mt-3">Supervisor Eligibility Criteria</h6>
                  <Card>
                    <CardBody>
                      <>
                        <div className='mb-1'>
                          Professor or Associate Professor of an accredited department of an accredited institute &nbsp;
                          {userInfo?.current_employment_info?.designation === 'Professor' || userInfo?.current_employment_info?.designation === 'Associate Professor' ?
                            <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                            : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                          }
                        </div>
                        <div className='mb-1'>
                          Assistant Professor or Consultant holding a clinical unit as in-charge of an accredited department of an accredited institute &nbsp;
                          {userInfo?.current_employment_info?.designation === 'Assistant Professor' || userInfo?.current_employment_info?.in_charge ?
                            <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                            : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                          }
                        </div>
                        <div className='mb-1'>
                          Consultant working on an accredited department of an accredited District Hospital &nbsp;
                          {userInfo?.current_employment_info?.designation === 'Consultant' || userInfo?.current_employment_info?.institute_name === 'District' ?
                            <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                            : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                          }
                        </div>
                        <div>
                          Head of the Department of an accredited department of an accredited District Institute &nbsp;
                          {userInfo?.current_employment_info?.department_head ?
                            <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
                            : <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
                          }
                        </div>
                      </>
                    </CardBody>
                  </Card>
                </Col>
              </Row>}

            {/* ----------- Update form ---------- */}
            <h6 className="block-heading">Approval Status</h6>
            <Row>
              {/* {userInfo.bmdc_registration_no_verified !== 'Approve' ? ( */}
              <div className="col-md-4">
                <FormGroup check inline className="mb-3 ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="bmdc_registration_no_verified"
                    value={userInfo?.bmdc_registration_no_verified}
                    id="bmdc-approve"
                    onChange={(e) => {
                      bmdcAprove(e);
                    }}
                    disabled={
                      userInfo?.bmdc_registration_no_verified === 'Approve' ||
                      (lengthOfBmdcRegFilteredValue !== 0 &&
                        lengthOfBmdcRegFilteredValue !== undefined &&
                        bmdcRegFilteredValue[lengthOfBmdcRegFilteredValue - 1]
                          ?.approve_status! ===
                        bmdcRegFilteredValue[lengthOfBmdcRegFilteredValue - 1]
                          ?.preset_approve_status!)
                    }
                    checked={
                      userInfo?.bmdc_registration_no_verified === 'Approve' ||
                      (lengthOfBmdcRegFilteredValue !== 0 &&
                        lengthOfBmdcRegFilteredValue !== undefined &&
                        bmdcRegFilteredValue[lengthOfBmdcRegFilteredValue - 1]
                          ?.approve_status! ===
                        bmdcRegFilteredValue[lengthOfBmdcRegFilteredValue - 1]
                          ?.preset_approve_status!)
                    }
                  />
                  <label
                    className="form-check-label text-success me-1"
                    htmlFor="bmdc-approve"
                  >
                    BMDC Approve
                  </label>
                  {isBmdcLoading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : null}
                </FormGroup>
              </div>
              {/* ) : null} */}

              {/* {userInfo.bmdc_certificate_source_verified !== 'Approve' ? ( */}
              <div className="col-md-4">
                <FormGroup check inline className="mb-3 ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="bmdc_certificate_source_verified"
                    value={userInfo?.bmdc_certificate_source_verified}
                    id="bmdc-source-approve"
                    onChange={(e) => {
                      bmdcSourceVerify(e);
                    }}
                    disabled={
                      userInfo?.bmdc_certificate_source_verified === 'Approve' ||
                      (lengthOfBmdcCertificateFilteredValue !== 0 &&
                        lengthOfBmdcCertificateFilteredValue !== undefined &&
                        bmdcCertificateFilteredValue[
                          lengthOfBmdcCertificateFilteredValue - 1
                        ]?.approve_status! ===
                        bmdcCertificateFilteredValue[
                          lengthOfBmdcCertificateFilteredValue - 1
                        ]?.preset_approve_status!)
                    }
                    checked={
                      userInfo?.bmdc_certificate_source_verified === 'Approve' ||
                      (lengthOfBmdcCertificateFilteredValue !== 0 &&
                        lengthOfBmdcCertificateFilteredValue !== undefined &&
                        bmdcCertificateFilteredValue[
                          lengthOfBmdcCertificateFilteredValue - 1
                        ]?.approve_status! ===
                        bmdcCertificateFilteredValue[
                          lengthOfBmdcCertificateFilteredValue - 1
                        ]?.preset_approve_status!)
                    }
                  />
                  <label
                    className="form-check-label text-success me-1"
                    htmlFor="bmdc-source-approve"
                  >
                    BMDC Source Verify
                  </label>
                  {isBmdcSourceVerifyLoading ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : null}
                  {userInfo.bmdc_certificate ? (
                    <a
                      className="me-2 p-0"
                      href={config.APP_URL + userInfo.bmdc_certificate}
                      rel="noreferrer"
                      target="_blank"
                    >
                      Download
                    </a>
                  ) : null}
                </FormGroup>
              </div>
              <div className='col-md-3'>
                <div className="row">
                  <div className="col-sm-6">
                    <FormGroup check inline className="mb-3 ">
                      <button
                        type='button'
                        className="btn btn-outline-success btn-sm "
                        onClick={() => {
                          const data: any = {                 
                            regNumber: userInfo?.bmdc_registration_no,
                            type: userInfo?.bmdc_registration_no.charAt(0) === 'A' ? 'MBBS' : 'BDS',                       
                          };
                          getBmdcRegData(data);
                          setBmdcCheckModalShow(true);
                        }}
                      >
                        BMDC Check
                      </button>
                    </FormGroup>
                  </div>
                  <div className="col-sm-6">
                    <FormGroup check inline className="mb-3 ">
                      <button
                        type='button'
                        className="btn btn-outline-success btn-sm"
                        onClick={() => {
                          getFcpsPartOneDetails(`${url.GET_FCPS_PART_1_DETAILS}/${userInfo?.bmdc_registration_no}`);
                          setFcpsPartOneInfoModalShow(true);
                        }}
                      >
                        FCPS Part-1 History
                      </button>
                    </FormGroup>
                  </div>
                </div>
              </div>
              {/* ) : null} */}
            </Row>
            <Row>
              {userInfo.bmdc_registration_no_verified === 'Approve' &&
                (userInfo.profile_approval_status === 'Submit' || userInfo.profile_approval_status === 'Reject') && (
                  <>
                    <div className="col">
                      <p className="fw-bold mb-1">Profile </p>
                      <FormGroup check inline className="mb-3 ms-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="profile_approval_status"
                          id="profile-approve"
                          checked={values.status === 'Approve'}
                          onChange={(e) => {
                            setFieldValue('status', 'Approve');
                            setFieldValue('type', 'profile');
                          }}
                        />
                        <label
                          className="form-check-label text-success"
                          htmlFor="profile-approve"
                        >
                          Approve
                        </label>
                      </FormGroup>
                      <FormGroup check inline className="mb-3 ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="profile_approval_status"
                          checked={values.status === 'Reject'}
                          id="reject"
                          onChange={() => {
                            setFieldValue('status', 'Reject');
                            setFieldValue('type', 'profile');
                          }}
                        />
                        <label
                          className="form-check-label text-danger"
                          htmlFor="reject"
                        >
                          Reject
                        </label>
                      </FormGroup>
                      <FormGroup check inline className="mb-3 ">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="profile_approval_status"
                          checked={values.status === 'Correction'}
                          id="correction"
                          onChange={() => {
                            setFieldValue('status', 'Correction');
                            setFieldValue('type', 'profile');
                          }}
                        />
                        <label
                          className="form-check-label text-info"
                          htmlFor="correction"
                        >
                          Correction
                        </label>
                      </FormGroup>

                      {errors.status && touched.status ? (
                        <div className="text-danger">{errors.status}</div>
                      ) : null}
                    </div>
                    {values.status === 'Reject' ? (
                      <div className='row'>
                        <div className="col">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            name="profile_not_ok"
                            id="profile_not_ok"
                            onChange={(e) => {
                              handleProfileNotOk(e);
                            }}
                          />
                          <label
                            className="form-check-label text-danger"
                            htmlFor='profile_not_ok'
                          >
                            Profile Not Ok
                          </label>
                        </div>
                        <div className="col">
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            name="fake_registration"
                            id="fake_registration"
                            onChange={(e) => {
                              handleFakeReg(e);
                            }}
                          />
                          <label
                            className="form-check-label text-danger"
                            htmlFor='fake_registration'
                          >
                            Fake Registration
                          </label>
                        </div>
                      </div>
                    ) : null}

                    {values.status !== 'Approve' && values.status !== '' ? (
                      <div className="col">
                        <Label for="message" className="required-field">{values.status}&nbsp;Note</Label>
                        <InputField
                          type="textarea"
                          placeholder="Enter.."
                          invalid={{ errors, touched }}
                          {...getFieldProps('message')}
                        />
                      </div>
                    ) : null}
                  </>
                )}
            </Row>
          </ModalBody>

          <ModalFooter>
            <div className="d-flex gap-2 ">
              {userInfo.bmdc_registration_no_verified === 'Approve' &&
                (userInfo.profile_approval_status === 'Submit' || userInfo.profile_approval_status === 'Reject') && (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    ref={buttonRef}
                  // disabled={userInfo.profile_approval_status !== 'Submit'}
                  >
                    Save
                  </button>
                )}
              <Button color="danger" onClick={toggle}>
                Close
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={bmdcCheckModalShow}
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            BMDC Reg. Info &nbsp;
            {checkData?.success ?
              <i className="fa-solid fa-square-check" style={{ color: "#1de010" }} />
              :
              <i className="fa-sharp fa-solid fa-square-xmark" style={{ color: "#f20202" }} />
            }
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              setBmdcCheckModalShow(!bmdcCheckModalShow);
            }}
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm={12}>
              <table className='table table-bordered table-sm table-striped'>
                <tbody>
                  <tr>
                    <td className='col-md-6 col-sm-6'>
                      <strong>BMDC No.</strong>
                    </td>
                    <td className='col-md-6 col-sm-6'>
                      {checkData?.data?.bmdc}
                    </td>
                  </tr>
                  <tr>
                    <td className='col-md-6 col-sm-6'>
                      <strong>Name</strong>
                    </td>
                    <td className='col-md-6 col-sm-6'>
                      {checkData?.data?.name}
                    </td>
                  </tr>
                  <tr>
                    <td className='col-md-6 col-sm-6'>
                      <strong>BMDC Reg. Validity</strong>
                    </td>
                    <td className='col-md-6 col-sm-6'>
                      {checkData?.data?.bmdc_registration_validity}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        onOpened={function () {
          toast.dismiss();
        }}
        isOpen={fcpsPartOneInfoModalShow}
        size='xl'
        toggle={() => {
          document.body.classList.add('no_padding');
        }}
        backdrop={'static'}
        modalTransition={{ timeout: 0 }}
        backdropTransition={{ timeout: 0 }}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">
            FCPS Part-1 History
          </h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => {
              setFcpsPartOneInfoModalShow(!fcpsPartOneInfoModalShow);
            }}
          ></button>
        </div>
        <div className="modal-body">
          <Row>
            <Col sm={12}>
              <DataTable
                columns={columns}
                data={fcpsPartOneDetailsData?.data ? fcpsPartOneDetailsData?.data : []}
                // progressPending={isTrainingSummariesLoading}
                expandOnRowClicked
                highlightOnHover
                responsive
                persistTableHead
              />
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default InfoViewModal;
