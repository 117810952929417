import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Button, Card, CardBody, Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { setFromErrors } from 'src/helpers';
import { useCreateInspectionTeamMutation } from 'src/rtk/features/instituteInspection/institutueInspectionApi';
import { useGetInstituteInspectorQuery } from 'src/rtk/features/user/userSelectByPermissionApi';
import * as Yup from 'yup';

const initialValues: CreateTeam = {
    chairman_id: '',
    team_name: '',
    members: [],
};

const CreateInspectionTeam = () => {
    const [createInspectionTeam, { isSuccess, isError, error }] = useCreateInspectionTeamMutation();
    const [btnClicked, setBtnClicked] = useState<string>('');
    const [selectedChairman, setSelectedChairman] = useState<any>('');
    const history = useHistory();

    const { data: chairmanMember } = useGetInstituteInspectorQuery();

    const allChairmanMember: SelectOption[] =
        chairmanMember?.data?.map((item) => ({
            value: item.id!,
            label: item.name,
        })) || [];

    const err: CustomError = error as CustomError;

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        validationSchema: Yup.object({
            team_name: Yup.string().required('Enter Team Name'),
            chairman_id: Yup.string().required('Select Chairman'),
        }),

        onSubmit: (values: any) => {
            // const formData = new FormData();

            // Object.keys(values).forEach((key) => {
            //     if (key === 'submission_status') {
            //         if (btnClicked === 'submit') {
            //             values[key] = 'Submit';
            //         } else if (btnClicked === 'save_as_draft') {
            //             values[key] = 'Draft';
            //         }
            //     }
            //     formData.append(key, values[key]);
            // });
            console.log(values);
            createInspectionTeam(values);
        },
    });

    useEffect(() => {
        if (isSuccess) {
            resetForm();
            toast.success('Team Created Successfully!');
            history.push('/inspection-team-list');
        }
        if (isError) {
            toast.error(err?.message || 'Something Went Wrong!');
            setFromErrors(err.data, setErrors);
        }
    }, [isSuccess, isError]);

    useEffect(() => {
        if (selectedChairman !== '') {
            setFieldValue('chairman_id', selectedChairman?.value);
        }
    }, [selectedChairman]);

    // useEffect(() => {
    //     console.log(selectedSubject[0]?.value);
    //     let member = [];
    //     if (selectedSubject !== '') {
    //         member.push(selectedSubject.value); 
    //     }
    //     // console.log(member);
    //     // setFieldValue('members', selectedSubject);
    // }, [selectedSubject]);

    const handleMember = (e: any) => {
        const member: any = [];

        e && e.map((item: any, index: number) => {
            member[index] = item.value;
        });
        console.log(member);

        setFieldValue('members', member);
    }

    return (
        <div className="page-content">
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[
                        { link: '#', name: 'Inspection' },
                        { link: '/inspection-team-list', name: 'Inspection Team List' }
                    ]}
                    title={'Create Inspection Team'}
                />
                <Card>
                    <CardBody>
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            onReset={(e) => {
                                resetForm();
                                setSelectedChairman('');
                            }}
                        >
                            <Row>
                                <Col lg={6} md={6} sm={12}>
                                    <FormGroup>
                                        <Label for="team_name" className="form-label required-field">Team Code/Name</Label>
                                        <InputField
                                            type="text"
                                            name='team_name'
                                            placeholder='Team Name'
                                            onChange={(e: any) => {
                                                handleChange(e);
                                            }}
                                            onBlur={handleBlur}
                                            value={values.team_name}
                                            invalid={{ errors, touched }}
                                        />
                                    </FormGroup>
                                </Col>


                                <Col lg={6} md={6} sm={12}>
                                    <FormGroup>
                                        <Label for="chairman_id">Chairman</Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            value={selectedChairman}
                                            onChange={setSelectedChairman}
                                            options={allChairmanMember}
                                            placeholder='Select Chairman'
                                            name="chairman_id"
                                        />
                                        {errors.chairman_id && touched.chairman_id ? (
                                            <div className="text-danger">{errors.chairman_id as string}</div>
                                        ) : null}
                                    </FormGroup>
                                </Col>

                                <Col lg={6} md={6} sm={12}>
                                    <FormGroup>
                                        <Label for="members">
                                            Members &nbsp;
                                            {/* <span className="text-muted fw-light">
                                                (Max : 3)
                                            </span> */}
                                        </Label>
                                        <Select
                                            isClearable={true}
                                            isSearchable={true}
                                            isMulti={true}
                                            value={allChairmanMember.find(
                                                (option: any) =>
                                                    option.value === values.members,
                                            )}
                                            // isOptionDisabled={() => values.members.length >= 3}
                                            onChange={(e: any) => handleMember(e)}
                                            options={allChairmanMember?.filter((item) => item?.value !== values.chairman_id)}
                                            placeholder='Select Members'
                                            name="members"
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <FormGroup className="mb-3 text-center">
                                    <Button
                                        type="reset"
                                        className="btn btn-secondary me-2"
                                    >
                                        Reset
                                    </Button>
                                    
                                    <button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={(e) => {
                                            setBtnClicked('submit');
                                        }}
                                    >
                                        Submit
                                    </button>
                                </FormGroup>
                            </Row>
                        </Form>
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default CreateInspectionTeam;
