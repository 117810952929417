import React, { useEffect, useState } from 'react';
import {
    Card,
    CardBody,
    CardGroup,
    CardText,
    CardTitle,
    Col,
    Container,
    Row
} from "reactstrap";
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetThesisExamDashboardBasicInfoQuery } from 'src/rtk/features/thesis-exam-dashboard/thesisExamDashboardApi';
import loader from '../../../assets/images/loader.gif';
import ThesisExamScheduleList from './ThesisExamScheduleList';

const ThesisExamDashboard = (props: any) => {
    const { mainDashboard } = props;
    document.title = 'Thesis Exam Dashboard | BCPS';

    const [search, setSearch] = useState('');

    const {
        data: thesisExamDashboardBasicInfoData,
        isSuccess: isThesisExamDashboardBasicInfoDataSuccess,
        isError: isThesisExamDashboardBasicInfoDataError,
        isLoading: isThesisExamDashboardBasicInfoDataLoading,
        error: thesisExamDashboardBasicInfoDataError,
    } = useGetThesisExamDashboardBasicInfoQuery();

    const Counter = ({ end, duration }: any) => {
        const [count, setCount] = useState<number>(0);

        useEffect(() => {
            let start = 0;
            const totalSteps = 60; // Number of updates in the given duration
            const increment = end / totalSteps;

            const stepTime = (duration * 1000) / totalSteps; // Step interval in ms

            const interval = setInterval(() => {
                start += increment;
                if (start >= end) {
                    start = end;
                    clearInterval(interval);
                }
                setCount(Math.floor(start));
            }, stepTime);

            return () => clearInterval(interval);
        }, [end, duration]);

        // Format number with separator
        const formatNumber = (num: number) => num.toLocaleString();

        return (
            <span>
                {formatNumber(count)}
            </span>
        );
    };

    return (
        <>
            {mainDashboard ?
                <React.Fragment>
                    {isThesisExamDashboardBasicInfoDataLoading ? (
                        <div className="overlay">
                            <img src={loader} alt="Loading" height={100} width={300} />
                        </div>
                    ) : null}
                    <BetterBreadcrumb title='Thesis Exam Dashboard' />
                    <Container fluid>
                        <Row>
                            <Col xl='12'>
                                <Card className=''>
                                    <CardBody>
                                        <h4>Thesis Status</h4>
                                        <div className='mt-3'>
                                            <Row className='justify-content-center'>
                                                <Col sm={12} md={12} lg={12} xl={12} xxl={6}>
                                                    <CardGroup>
                                                        <Card body inverse color="info" className='text-center fw-bold rounded me-3'>
                                                            <CardTitle>Total Thesis</CardTitle>
                                                            <CardText>
                                                                <Counter end={thesisExamDashboardBasicInfoData?.data?.total_thesis ?? 0} duration={0.5} />
                                                                {/* {thesisExamDashboardBasicInfoData?.data?.total_thesis ?? 0} */}
                                                            </CardText>
                                                        </Card>
                                                        <Card body inverse color="success" className='text-center fw-bold rounded me-3'>
                                                            <CardTitle>Approved Thesis</CardTitle>
                                                            <CardText>
                                                                <Counter end={thesisExamDashboardBasicInfoData?.data?.approved_thesis ?? 0} duration={0.5} />
                                                                {/* {thesisExamDashboardBasicInfoData?.data?.approved_thesis ?? 0} */}
                                                            </CardText>
                                                        </Card>
                                                        <Card body inverse color="secondary" className='text-center fw-bold rounded me-3'>
                                                            <CardTitle>Upcoming Thesis Exam</CardTitle>
                                                            <CardText>
                                                                <Counter end={thesisExamDashboardBasicInfoData?.data?.upcoming_exams ?? 0} duration={0.5} />
                                                                {/* {thesisExamDashboardBasicInfoData?.data?.upcoming_exams ?? 0} */}
                                                            </CardText>
                                                        </Card>
                                                        <Card body color="warning" className='text-center fw-bold rounded'>
                                                            <CardTitle>Pending Thesis</CardTitle>
                                                            <CardText>
                                                                <Counter end={thesisExamDashboardBasicInfoData?.data?.pending_thesis ?? 0} duration={0.5} />
                                                                {/* {thesisExamDashboardBasicInfoData?.data?.pending_thesis ?? 0} */}
                                                            </CardText>
                                                        </Card>
                                                    </CardGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col xl="12">
                                <Card className=''>
                                    <CardBody>
                                        <h4 className='mb-3'>Thesis Exam Schedule</h4>
                                        <ThesisExamScheduleList />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </React.Fragment>
                :
                <div className='page-content'>
                    {isThesisExamDashboardBasicInfoDataLoading ? (
                        <div className="overlay">
                            <img src={loader} alt="Loading" height={100} width={300} />
                        </div>
                    ) : null}
                    <React.Fragment>
                        <BetterBreadcrumb title='Thesis Exam Dashboard' />
                        <Container fluid>
                            <Row>
                                <Col xl='12'>
                                    <Card className=''>
                                        <CardBody>
                                            <h4>Thesis Status</h4>
                                            <div className='mt-3'>
                                                <Row className='justify-content-center'>
                                                    <Col sm={12} md={12} lg={12} xl={12} xxl={6}>
                                                        <CardGroup>
                                                            <Card body inverse color="info" className='text-center fw-bold rounded me-3'>
                                                                <CardTitle>Total Thesis</CardTitle>
                                                                <CardText>
                                                                    <Counter end={thesisExamDashboardBasicInfoData?.data?.total_thesis ?? 0} duration={0.5} />
                                                                    {/* {thesisExamDashboardBasicInfoData?.data?.total_thesis ?? 0} */}
                                                                </CardText>
                                                            </Card>
                                                            <Card body inverse color="success" className='text-center fw-bold rounded me-3'>
                                                                <CardTitle>Approved Thesis</CardTitle>
                                                                <CardText>
                                                                    <Counter end={thesisExamDashboardBasicInfoData?.data?.approved_thesis ?? 0} duration={0.5} />
                                                                    {/* {thesisExamDashboardBasicInfoData?.data?.approved_thesis ?? 0} */}
                                                                </CardText>
                                                            </Card>
                                                            <Card body inverse color="secondary" className='text-center fw-bold rounded me-3'>
                                                                <CardTitle>Upcoming Thesis Exam</CardTitle>
                                                                <CardText>
                                                                    <Counter end={thesisExamDashboardBasicInfoData?.data?.upcoming_exams ?? 0} duration={0.5} />
                                                                    {/* {thesisExamDashboardBasicInfoData?.data?.upcoming_exams ?? 0} */}
                                                                </CardText>
                                                            </Card>
                                                            <Card body color="warning" className='text-center fw-bold rounded'>
                                                                <CardTitle>Pending Thesis</CardTitle>
                                                                <CardText>
                                                                    <Counter end={thesisExamDashboardBasicInfoData?.data?.pending_thesis ?? 0} duration={0.5} />
                                                                    {/* {thesisExamDashboardBasicInfoData?.data?.pending_thesis ?? 0} */}
                                                                </CardText>
                                                            </Card>
                                                        </CardGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xl="12">
                                    <Card className=''>
                                        <CardBody>
                                            <h4 className='mb-3'>Thesis Exam Schedule</h4>
                                            <ThesisExamScheduleList />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                </div>
            }
        </>
    );
};

export default ThesisExamDashboard;
