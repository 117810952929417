import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import { Button, Card, CardBody, Container, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetTitleListForRtmdQuery } from 'src/rtk/features/courseApproval/courseApprovalApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoGlobalApi';
import loader from '../../../assets/images/loader.gif';
import TitleApprovalViewModal from './TitleApprovalViewModal';

const TitleListForRTMD = () => {
    const [modalShow, setModalShow] = useState(false);
    const [approvalId, setApprovalId] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<any>('');
    const [selectedSubject, setSelectedSubject] = useState<any>('');

    const allStatus: SelectOption[] = [
        { value: 'Approve', label: 'Approve' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Reject', label: 'Reject' },
    ];

    const allCategory: SelectOption[] = [
        { label: 'Thesis', value: 'Thesis' },
        { label: 'Dissertation', value: 'Dissertation' },
    ];

    const [page, setPage] = useState<any>(1);
    const [limit, setLimit] = useState<any>(10);
    const [search, setSearch] = useState('');

    const generateQueryUrl = () => {
        return `page=${page}&paginate_per_page=${limit}${search}`;
    };

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setLimit(newPerPage);
        setPage(page);
    };

    const {
        data: approvalList,
        isSuccess,
        isLoading,
    } = useGetTitleListForRtmdQuery(generateQueryUrl());

    const { data: subjectList, isError: isSubFetchError } =
        useGetSubjectInfosQuery();

    const allSubjects: SelectOption[] =
        subjectList?.data?.map((subject) => ({
            value: subject.id!,
            label: subject.subject_name,
        })) || [];

    interface SearchFields {
        search?: string;
        category?: string;
        title_of_thesis?: string;
        admin_approval_status?: string;
        bmdc_registration_no?: string;
        subject_id?: string;
    }

    const initialValues: SearchFields = {
        search: '',
        category: '',
        title_of_thesis: '',
        admin_approval_status: '',
        bmdc_registration_no: '',
        subject_id: '',
    };

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values: any) => {
            let s: string = '';

            if (values.search !== '') {
                s = s + `&search=${values.search}`;
            }

            if (values.category !== '') {
                s = s + `&category=${values.category}`;
            }

            if (values.title_of_thesis !== '') {
                s = s + `&title_of_thesis=${values.title_of_thesis}`;
            }

            if (values.admin_approval_status !== '') {
                s = s + `&admin_approval_status=${values.admin_approval_status}`;
            }
            if (values.bmdc_registration_no !== '') {
                s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
            }
            if (values.subject_id !== '') {
                s = s + `&subject_id=${values.subject_id}`;
            }

            setSearch(s);
            setPage('1');
            console.log(values);
        },
    });

    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No</div>,
            width: '80px',
            cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
        },
        {
            name: 'Category',
            selector: (row) => row?.category,
            wrap: true,
        },

        {
            name: 'Title',
            selector: (row) => row?.title_of_thesis,
            wrap: true,
        },

        {
            name: <div>BMDC Reg. No.</div>,
            selector: (row) => row?.registration?.bmdc_registration_no,
            wrap: true,
        },
        {
            name: <div>Trainee Name</div>,
            selector: (row) => row?.registration?.personal_info?.full_name,
            wrap: true,
        },

        {
            name: <div>Discipline</div>,
            selector: (row) => row?.registration?.subject_name,
            wrap: true,
        },

        {
            name: <div>Contact No</div>,
            selector: (row) => row?.registration?.personal_info?.mobile,
            wrap: true,
        },

        // {
        //     name: <div>Status</div>,
        //     selector: (row) => row?.admin_approval_status,
        //     width: '140px',
        //     cell: (row) => {
        //         switch (row?.admin_approval_status) {
        //             case 'Reject':
        //                 return (
        //                     <>
        //                         <div className="badge badge-rejected rounded-pill font-size-12">
        //                             {'Rejected'}
        //                         </div>
        //                     </>
        //                 );

        //             case 'Draft':
        //                 return (
        //                     <>
        //                         <div className="badge badge-draft rounded-pill font-size-12">
        //                             {row?.admin_approval_status}
        //                         </div>
        //                     </>
        //                 );

        //             case 'Approve':
        //                 return (
        //                     <>
        //                         <div className="badge badge-approved rounded-pill font-size-12">
        //                             {'Approved'}
        //                         </div>
        //                     </>
        //                 );

        //             case 'Pending':
        //                 return (
        //                     <>
        //                         <div className="badge badge-pending rounded-pill font-size-12">
        //                             {row?.admin_approval_status}
        //                         </div>
        //                     </>
        //                 );

        //             default:
        //                 return (
        //                     <>
        //                         <div className="badge badge-soft-secondary font-size-12">
        //                             N/A
        //                         </div>
        //                     </>
        //                 );
        //         }
        //     },
        // },
        {
            name: 'Action',
            cell: (row, index) => (
                <div className='my-2'>
                    <Button
                        className="btn btn-sm btn-info"
                        onClick={() => modalShowHandler(true, row?.id)}
                    >
                        <span className="fas fa-eye"></span> View
                    </Button>
                    {modalShow && approvalId === row?.id ? (
                        <TitleApprovalViewModal
                            isOpen={modalShow}
                            toggle={() => setModalShow(!modalShow)}
                            info={row}
                            id={row.registration_id!}
                        />
                    ) : null}
                </div>
            ),
        },
    ];

    const modalShowHandler = (modalState: boolean, id: string) => {
        setModalShow(modalState);
        setApprovalId(id);
    }

    useEffect(() => {
        if (selectedCategory !== '') {
            setFieldValue('category', selectedCategory?.value);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (selectedSubject !== '') {
            setFieldValue('subject_id', selectedSubject?.value);
        }
    }, [selectedSubject]);

    return (
        <div className="page-content">
            {isLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '#', name: 'RTMD' }]}
                    title={'Title List Approval'}
                />
                <Card>
                    <CardBody>
                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            onReset={(e) => {
                                e.preventDefault();
                                handleSubmit();
                                setSelectedCategory('');
                                setSelectedSubject('');
                                resetForm();
                            }}
                        >
                            <div className="filter-wrapper">
                                <FormGroup>
                                    <Label for="category">Category</Label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder='Category'
                                        options={allCategory}
                                        value={selectedCategory}
                                        onChange={setSelectedCategory}
                                        name="category"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="title_of_thesis">Title</Label>
                                    <InputField
                                        type="text"
                                        placeholder='Title'
                                        {...getFieldProps('title_of_thesis')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="bmdc_registration_no">BMDC Reg. No</Label>
                                    <InputField
                                        type="text"
                                        placeholder="BMDC Reg. No"
                                        {...getFieldProps('bmdc_registration_no')}
                                    />
                                </FormGroup>

                                {/* <FormGroup>
                                    <Label for="name">Trainee Name</Label>
                                    <InputField
                                        type="text"
                                        placeholder='Trainee Name'
                                        {...getFieldProps('name')}
                                    />
                                </FormGroup> */}

                                <FormGroup className="mb-3">
                                    <Label for="subject">Discipline</Label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        value={selectedSubject}
                                        onChange={setSelectedSubject}
                                        options={allSubjects}
                                        placeholder='Select...'
                                        name="subject"
                                        id="subject_id"
                                    />
                                </FormGroup>

                                {/* <FormGroup>
                                    <Label for="name">Contact No</Label>
                                    <InputField
                                        type="text"
                                        placeholder='Contact No'
                                        {...getFieldProps('contact_no')}
                                    />
                                </FormGroup> */}

                                <FormGroup>
                                    <Label for="admin_approval_status">Status</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Status"
                                        options={allStatus}
                                        {...getFieldProps('admin_approval_status')}
                                    />
                                </FormGroup>

                                <FormGroup className="btn-column">
                                    <Button color="danger" type="reset">Reset</Button>
                                    <Button type="submit" color='primary'>Search</Button>
                                </FormGroup>
                            </div>
                        </Form>

                        <DataTable
                            columns={columns}
                            data={approvalList?.data!}
                            pagination
                            paginationServer
                            progressPending={isLoading}
                            expandOnRowClicked
                            highlightOnHover
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationTotalRows={approvalList?.total}
                            paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
                            responsive
                            persistTableHead={true}
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default TitleListForRTMD;
