import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

//Import Icons

// //Import Scrollbar
import SimpleBar from 'simplebar-react';

//Import images

//i18n

// MetisMenu
import MetisMenu from 'metismenujs';
import { Link, withRouter } from 'react-router-dom';

const DynamicMenu = (props: any) => {
  const { menus } = props;

  const ref = useRef<any>();
  // const { menus } = useSelector((state: any) => ({
  //   menus: state.auth.menus,
  // }));
  // console.log(menus);
  const defaultIcon = 'mdi mdi-format-list-bulleted';

  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const menusData: any = menus && menus.length > 0 ? menus : [];

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      let matchingMenuItem = null;
      const ul: any = document.getElementById('side-menu');
      const items = ul.getElementsByTagName('a');
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname, activateParentDropdown, menus]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: '100%' }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title"> Menu </li>
            {/* <li>
              <Link to="/dashboard" className="">
                <i className="mdi mdi-home" />
                <span> Dashboard </span>
              </Link>
            </li> */}

            {menusData.length > 0 &&
              menusData.map(
                (menuItem: any) =>
                  // menuItem.name !== 'Dashboard' &&
                  menuItem.type.toLowerCase() === 'main-menu' && (
                    <li key={menuItem.id}>
                      <Link
                        to={menuItem.url ? menuItem.url + `?ref=${new Date().getTime()}` : '/'}
                        className={
                          menuItem.sub_menus && menuItem.sub_menus?.length > 0
                            ? 'has-arrow '
                            : ''
                        }
                      >
                        <i
                          className={
                            menuItem.icon_class_name
                              ? menuItem.icon_class_name
                              : defaultIcon
                          }
                        />

                        <span className="w-75 d-inline-flex">
                          <span className="text-truncate text-wrap" title={menuItem.name}>{menuItem.name}</span>
                        </span>
                      </Link>
                      {menuItem.sub_menus && menuItem.sub_menus?.length > 0 && (
                        <ul className="sub-menu">
                          {menuItem.sub_menus.map(
                            (subMenuItem: any) =>
                              subMenuItem.name &&
                              // subMenuItem.url &&
                              subMenuItem.type.toLowerCase() === 'sub-menu' && (
                                <li key={subMenuItem.id}>
                                  <Link
                                    to={subMenuItem.url ? subMenuItem.url + `?ref=${new Date().getTime()}` : '/'}
                                    className={
                                      subMenuItem.url
                                        ? ''
                                        : 'disabled text-danger'
                                    }
                                  >
                                    <span className="w-100 d-inline-flex">
                                      <span>
                                        {subMenuItem.name}
                                      </span>
                                    </span>
                                  </Link>
                                </li>
                              ),
                          )}
                        </ul>
                      )}
                    </li>
                  ),
              )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

DynamicMenu.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(DynamicMenu);
