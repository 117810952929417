import { useEffect, useState } from "react";
import Select from 'react-select';
import { Col, FormGroup, Label, Row } from "reactstrap";
import { InputField } from "src/components";
import { useGetInstituteNameQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';

const InstituteAndDepartmentName = (props: any) => {
    const { 
        instituteName,
        setInstituteName,
        instituteLocation,        
        instituteId,
        departmentOptions,
        selectedDepartment,
        setSelectedDepartment, 
        errors,
        touched
    } = props;   

    const { data: instituteRegData, isSuccess: isInstituteRegDataSuccess } = useGetInstituteNameQuery();

    const instituteObj = instituteRegData?.find((item: any) => item.id === instituteId);

    useEffect(() => {
        if (isInstituteRegDataSuccess) {
            setInstituteName(instituteObj?.institute_name!);            
        }
    }, [isInstituteRegDataSuccess]);  

    return (
        <>
            <Col sm={12}>
                <Row className='justify-content-evenly'>
                    <Col sm={12} md={6}>
                        <FormGroup className="col-sm-12 mb-3">
                            <Label for="institute_name">Name of the institute</Label>
                            <InputField
                                id="institute_name"
                                name="institute_name"
                                type="text"
                                value={instituteName}
                                placeholder=""
                                disabled
                            />
                            <span className="me-5"><strong>Institute Location: </strong>{instituteLocation}</span>                            
                        </FormGroup>
                    </Col>

                    <Col sm={12} md={6}>
                        <FormGroup className="col-sm-12 mb-3">
                            <Label for="department_name">Name of the department</Label>
                            <Select
                                isClearable={true}
                                isSearchable={true}
                                value={selectedDepartment}
                                onChange={setSelectedDepartment}
                                options={departmentOptions}
                                placeholder='Select...'
                                name="department_name"
                                id="department_name"
                            />
                            {
                                errors.department_name && touched.department_name && (
                                    <span className="text-danger">{errors.department_name}</span>
                                )
                            }
                        </FormGroup>
                    </Col>
                </Row>
            </Col>
        </>
    )
};

export default InstituteAndDepartmentName;