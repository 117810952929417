import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import Select from 'react-select';
import { Button, Card, CardBody, Container, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { useGetAllChangeRequestsQuery } from 'src/rtk/features/changeRequest/changeRequest';
import loader from '../../../assets/images/loader.gif';
import ChangeRequestsApproval from './ChangeRequestsApproval';

const TitelChangeRequests = () => {
    const [modalShow, setModalShow] = useState(false);
    const [approvalId, setApprovalId] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<any>('');
    const [selectedSubject, setSelectedSubject] = useState<any>('');

    const allStatus: SelectOption[] = [
        { value: 'Approve', label: 'Approve' },
        { value: 'Pending', label: 'Pending' },
        { value: 'Reject', label: 'Reject' },
    ];

    const allCategory: SelectOption[] = [
        { label: 'Thesis', value: 'Thesis' },
        { label: 'Dissertation', value: 'Dissertation' },
    ];

    const [page, setPage] = useState<any>(1);
    const [limit, setLimit] = useState<any>(10);
    const [search, setSearch] = useState('');

    const generateQueryUrl = () => {
        return `page=${page}&paginate_per_page=${limit}${search}`;
    };

    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handlePerRowsChange = (newPerPage: number, page: number) => {
        setLimit(newPerPage);
        setPage(page);
    };

    const {
        data: allChangeRequests,
        isSuccess,
        isLoading,
    } = useGetAllChangeRequestsQuery(generateQueryUrl());


    interface SearchFields {
        search?: string;
        category?: string;
        admin_approval_status?: string;
        trainee_name?: string;
    }

    const initialValues: SearchFields = {
        search: '',
        category: '',
        admin_approval_status: '',
        trainee_name: '',
    };

    const {
        handleSubmit,
        getFieldProps,
        handleChange,
        handleBlur,
        values,
        touched,
        errors,
        resetForm,
        setFieldValue,
        setErrors,
    } = useFormik({
        initialValues,

        onSubmit: (values: any) => {
            let s: string = '';

            if (values.search !== '') {
                s = s + `&search=${values.search}`;
            }

            if (values.category !== '') {
                s = s + `&category=${values.category}`;
            }

            if (values.admin_approval_status !== '') {
                s = s + `&admin_approval_status=${values.admin_approval_status}`;
            }

            if (values.trainee_name !== '') {
                s = s + `&trainee_name=${values.trainee_name}`;
            }

            setSearch(s);
            setPage('1');
            console.log(values);
        },
    });
    console.log(allChangeRequests);
    const columns: TableColumn<any>[] = [
        {
            name: <div>Sl. No</div>,
            width: '80px',
            cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
        },
        {
            name: 'Trainee Name',
            selector: (row) => row?.trainee_name,
            wrap: true,
        },
        {
            name: 'Type',
            selector: (row) => row?.type?.toUpperCase(),
            wrap: true,
        },

        {
            name: <div>Field Name</div>,
            selector: (row) => row?.column_name === 'title_of_thesis' ? 'Title of the Thesis'
                : row?.column_name === 'place_of_study' ? 'Place of Study'
                    : row?.column_name === 'study_period' ? 'Study Period'
                        : row?.column_name === 'guide_id' ? 'Guide'
                            : row?.column_name === 'co_guide_id' ? 'Co-Guide'
                                : '',
            wrap: true,
        },

        {
            name: 'Previous Value',
            selector: (row) => row?.previous_value_info ?
                row?.previous_value_info?.map((item: any) => (
                    <div key={item.registration_no}>
                        {item?.name}
                    </div>
                )) : row?.previous_value,
            wrap: true,
        },

        {
            name: <div>Updated Value</div>,
            selector: (row) => row?.updated_value_info ?
                row?.updated_value_info?.map((item: any) => (
                    <div key={item.registration_no}>
                        {item?.name}
                    </div>
                )) : row?.updated_value,
            wrap: true,
        },

        {
            name: <div>Status</div>,
            selector: (row) => row?.admin_approval_status,
            width: '140px',
            cell: (row) => {
                switch (row?.admin_approval_status) {
                    case 'Reject':
                        return (
                            <>
                                <div className="badge badge-rejected rounded-pill font-size-12">
                                    {'Rejected'}
                                </div>
                            </>
                        );

                    case 'Draft':
                        return (
                            <>
                                <div className="badge badge-draft rounded-pill font-size-12">
                                    {row?.admin_approval_status}
                                </div>
                            </>
                        );

                    case 'Approve':
                        return (
                            <>
                                <div className="badge badge-approved rounded-pill font-size-12">
                                    {'Approved'}
                                </div>
                            </>
                        );

                    case 'Pending':
                        return (
                            <>
                                <div className="badge badge-pending rounded-pill font-size-12">
                                    {row?.admin_approval_status}
                                </div>
                            </>
                        );

                    default:
                        return (
                            <>
                                <div className="badge badge-soft-secondary font-size-12">
                                    N/A
                                </div>
                            </>
                        );
                }
            },
        },
        {
            name: 'Action',
            cell: (row, index) => (
                <div className='my-2'>
                    <Button
                        className="btn btn-sm btn-info"
                        onClick={() => modalShowHandler(true, row?.id)}
                    >
                        <span className="fas fa-eye"></span> View
                    </Button>
                    {modalShow && approvalId === row?.id ? (
                        <ChangeRequestsApproval
                            isOpen={modalShow}
                            toggle={() => setModalShow(!modalShow)}
                            info={row}
                        />
                    ) : null}
                </div>
            ),
        },
    ];

    const modalShowHandler = (modalState: boolean, id: string) => {
        setModalShow(modalState);
        setApprovalId(id);
    }

    useEffect(() => {
        if (selectedCategory !== '') {
            setFieldValue('category', selectedCategory?.value);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (selectedSubject !== '') {
            setFieldValue('subject_id', selectedSubject?.value);
        }
    }, [selectedSubject]);

    return (
        <div className="page-content">
            {isLoading ? (
                <div className="overlay">
                    <img src={loader} alt="Loading" height={100} width={300} />
                </div>
            ) : null}
            <Container fluid>
                <BetterBreadcrumb
                    breadcrumbItem={[{ link: '#', name: 'RTMD' }]}
                    title={'Title List Approval'}
                />
                <Card>
                    <CardBody>
                        <Form
                            className="custom-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleSubmit();
                            }}
                            onReset={(e) => {
                                e.preventDefault();
                                handleSubmit();
                                setSelectedCategory('');
                                setSelectedSubject('');
                                resetForm();
                            }}
                        >
                            <div className="filter-wrapper">
                                <FormGroup>
                                    <Label for="category">Category</Label>
                                    <Select
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder='Category'
                                        options={allCategory}
                                        value={selectedCategory}
                                        onChange={setSelectedCategory}
                                        name="category"
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="trainee_name">Trainee Name</Label>
                                    <InputField
                                        type="text"
                                        placeholder="Trainee Name"
                                        {...getFieldProps('trainee_name')}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="admin_approval_status">Status</Label>
                                    <InputField
                                        type="select"
                                        placeholder="Status"
                                        options={allStatus}
                                        {...getFieldProps('admin_approval_status')}
                                    />
                                </FormGroup>

                                <FormGroup className="btn-column">
                                    <Button color="danger" type="reset">Reset</Button>
                                    <Button type="submit" color='primary'>Search</Button>
                                </FormGroup>
                            </div>
                        </Form>

                        <DataTable
                            columns={columns}
                            data={allChangeRequests?.data?.data!}
                            pagination
                            paginationServer
                            progressPending={isLoading}
                            expandOnRowClicked
                            highlightOnHover
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handlePerRowsChange}
                            paginationTotalRows={allChangeRequests?.data?.total}
                            paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
                            responsive
                            persistTableHead={true}
                        />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
};

export default TitelChangeRequests;
