import { useFormik } from 'formik';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Button, Container, Form, FormGroup, Label } from 'reactstrap';
import { InputField } from 'src/components';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';
import { useGetOrganizationsQuery } from 'src/rtk/features/setup/organization/setupOrganizationApi';
import { useGetSubjectInfosQuery } from 'src/rtk/features/setup/subjectInfo/subjectInfoApi';
import { useGetAllowanceApprovalByBCPSMutation, useGetAllTrainingAllowanceListForBCPSQuery } from 'src/rtk/features/trainingAllowance/trainingAllowanceApi';
import loader from '../../assets/images/loader.gif';
import ApprovalModal from './ApprovalModal';
import AttachmentModal from './AttachmentModal';
import { TrainingAllowanceApplicationPDF } from './TrainingAllowanceApplicationPDF';

const TrainingAllowanceListForBCPS = () => {
  let referenceUrl = new URLSearchParams(location.search).get('ref');

  const [selectedSubject, setSelectedSubject] = useState<any>('');
  const [selectedInstitue, setSelectedInstitute] = useState<any>('');
  const [infoId, setInfoId] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [attachmentModalShow, setAttachmentModalShow] = useState(false);
  const [attachmentModalId, setAttachmentModalId] = useState('');
  const [allowanceId, setAllowanceId] = useState('');
  const [modalId, setModalId] = useState('');
  const [allowanceFormId, setAllowanceFormId] = useState('');
  const [showAllowanceReport, setShowAllowanceReport] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [approvalModalId, setApprovalModalId] = useState('');
  const [approvalModalShow, setApprovalModalShow] = useState(false);

  const allStatus: SelectOption[] = [
    { value: 'Approve', label: 'Eligible' },
    { value: 'Reject', label: 'Not Eligible' },
    { value: 'Pending', label: 'Pending' },
  ]
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const { data: sessionLookupData } = useGetLookupDataQuery('session');

  const sessionList: SelectOption[] =
    (sessionLookupData as any)?.data[0]?.childs?.map((item: any) => ({
      value: item.name,
      label: item.name,
    })) || [];

  const currentYear = new Date().getFullYear();
  const startYear = 2025;

  const years = [];
  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }

  const allYears: SelectOption[] = years?.map((item: any) => ({
    value: item,
    label: item,
  })) || [];

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    bmdc_registration_no?: string;
    institute_name?: string;
    mobile?: string;
    admin_approval_status?: string;
    subject_name?: string;
    full_name?: string;
    fcps_part_one_passing_session?: string;
    fcps_part_one_passing_year?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    bmdc_registration_no: '',
    institute_name: '',
    mobile: '',
    admin_approval_status: '',
    subject_name: '',
    full_name: '',
    fcps_part_one_passing_session: '',
    fcps_part_one_passing_year: '',
  };

  const {
    data: allowanceList,
    isSuccess: isListSuccess,
    isLoading,
    isFetching,
    refetch
  } = useGetAllTrainingAllowanceListForBCPSQuery(generateQueryUrl());

  const [getAllowanceApprovalByBCPS, { isSuccess, isError, error }] = useGetAllowanceApprovalByBCPSMutation();

  const err = error as CustomError;

  useEffect(() => {
    refetch();
  }, [referenceUrl]);

  useEffect(() => {
    if (isSuccess) {
      toast.success('Status Updated Successfully!');
      setApprovalModalShow(false);
    }
    if (isError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  const { data: subjects } = useGetSubjectInfosQuery();

  const allSubjects: SelectOption[] =
    subjects?.data?.map((subject_id) => ({
      value: subject_id.subject_name,
      label: subject_id.subject_name,
    })) || [];

  const instituteList: SelectOption[] =
    useGetOrganizationsQuery()
      .data?.data?.map((item) => ({
        value: item.organization_name!,
        label: item.organization_name!,
      })) || [];

  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.bmdc_registration_no !== '') {
        s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
      }

      if (values.institute_name !== '') {
        s = s + `&institute_name=${values.institute_name}`;
      }

      if (values.mobile !== '') {
        s = s + `&mobile=${values.mobile}`;
      }

      if (values.subject_name !== '') {
        s = s + `&subject_name=${values.subject_name}`;
      }

      if (values.full_name !== '') {
        s = s + `&full_name=${values.full_name}`;
      }

      if (values.admin_approval_status !== '') {
        s = s + `&admin_approval_status=${values.admin_approval_status}`;
      }

      if (values.fcps_part_one_passing_session !== '') {
        s = s + `&fcps_part_one_passing_session=${values.fcps_part_one_passing_session}`;
      }
      if (values.fcps_part_one_passing_year !== '') {
        s = s + `&fcps_part_one_passing_year=${values.fcps_part_one_passing_year}`;
      }

      setSearch(s);
      setPage('1');
    },
  });

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => <strong>{((page - 1) * limit + index) + 1}</strong>,
      width: '60px',
    },

    {
      name: <div>Name of the Trainee</div>,
      selector: (row) => row?.registration?.personal_info?.full_name,
      sortable: false,
      wrap: true,
      width: '130px',
    },
    {
      name: <div>BCPS Reg. No.</div>,
      selector: (row) => row?.registration?.bcps_registration_no,
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>BMDC Reg. No.</div>,
      selector: (row) => row?.registration?.bmdc_registration_no,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Present Working Place</div>,
      selector: (row) => row?.registration?.current_session_and_supervisor_info?.supervisor?.institute_name,
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>Mobile Number</div>,
      selector: (row) => row?.registration?.personal_info?.mobile,
      sortable: false,
      wrap: true,
      width: '110px',
    },
    {
      name: <div>Email</div>,
      selector: (row) => row?.registration?.personal_info?.email,
      sortable: false,
      wrap: true,
      width: '130px',
    },
    {
      name: <div>Specialty</div>,
      selector: (row) => row?.registration?.subject?.subject_name,
      sortable: false,
      wrap: true,
    },

    {
      name: <div>Passing Session & Year</div>,
      width: '110px',
      selector: (row) => row?.registration?.fcps_part_one_passing_session ? row?.registration?.fcps_part_one_passing_session + ' - ' + row?.registration?.fcps_part_one_passing_year : 'N/A',
      wrap: true,
    },
    {
      name: <div>Current Duration of FCPS Part-II</div>,
      selector: (row) => row?.registration?.current_session_and_supervisor_info?.session_start_date && row?.registration?.current_session_and_supervisor_info?.session_end_date ?
        row?.registration?.current_session_and_supervisor_info?.session_start_date + ' to ' + row?.registration?.current_session_and_supervisor_info?.session_end_date : '-',
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>Status</div>,
      width: '120px',
      selector: (row) => row?.admin_approval_status,
      cell: (row: any) => {
        switch (row?.admin_approval_status) {
          case 'Approve':
            return (
              <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                {'Eligible'}
              </div>
            );
          case 'Reject':
            return (
              <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                {'Not Eligible'}
              </div>
            );
          default:
            return (
              <div className="badge rounded-pill d-inline-block badge-draft font-size-12 fw-medium">
                {'Pending'}
              </div>
            );
        }
      },
      sortable: false,
      wrap: true,
    },

    {
      name: 'Action',
      width: '350px',
      style: actionColumnStyles,
      cell: (row) => (
        <>
          <div className="d-flex gap-1 my-1">

            <Button
              className="btn btn-sm btn-danger"
              onClick={() => {
                setApprovalModalShow(true);
                setApprovalModalId(row?.id);
              }}
              disabled={row?.admin_approval_status === 'Approve' || row?.admin_approval_status === 'Reject'}
            >
              <span className="fas fa-eye"></span> Approval
            </Button>

            <Button
              className="btn btn-sm"
              color='primary'
              onClick={() => {
                setAttachmentModalShow(true);
                setAttachmentModalId(row?.id);
              }}
            >
              <span className="fas fa-eye"></span> Attachment
            </Button>

            <Button
              className="btn btn-sm btn-info"
              onClick={() => {
                setAllowanceId(row?.id);
                setSubmitting(true);
              }}
            >
              <span className="fas fa-eye"></span> Allowance Form
            </Button>

            {/* <Button
              className="btn btn-sm btn-success"
              onClick={(e) => {
                setShowAllowanceReport(true);
                setAllowanceFormId(row?.id);
              }}
            >
              <span className="fas fa-eye"></span> Allowance
            </Button> */}
          </div>

          {approvalModalShow && approvalModalId === row?.id ? (
            <ApprovalModal
              isOpen={approvalModalShow}
              toggle={() => setApprovalModalShow(!approvalModalShow)}
              infoId={approvalModalId}
              getAllowanceApproval={getAllowanceApprovalByBCPS}
            />
          ) : null}

          {attachmentModalShow && attachmentModalId === row?.id ? (
            <AttachmentModal
              isOpen={attachmentModalShow}
              toggle={() => setAttachmentModalShow(!attachmentModalShow)}
              data={row}
            />
          ) : null}
          {allowanceId === row?.id ? (
            <TrainingAllowanceApplicationPDF data={row} setAllowanceId={setAllowanceId} setSubmitting={setSubmitting} />
          ) : null}
          {/* {showAllowanceReport && allowanceFormId === row?.id ? (
            <TrainingAllowancePDF data={row} setShowAllowanceReport={setShowAllowanceReport} setAllowanceFormId={setAllowanceFormId} />
          ) : null} */}
        </>
      ),
    },
  ];

  useEffect(() => {
    if (selectedSubject !== '') {
      setFieldValue('subject_name', selectedSubject?.value);
    }
  }, [selectedSubject]);

  useEffect(() => {
    if (selectedInstitue !== '') {
      setFieldValue('institute_name', selectedInstitue?.value);
    }
  }, [selectedInstitue]);

  // START PDF AND CSV FOR TABLE DATA

  const tableHeaders = ["Sl. No.", "Name of the Account Holder", "Mobile Number", "BMDC Reg. No.", "Reg. No.", "Bank Name", "Branch Name", "Account No", "Routing No", "Amount (in Taka)"];

  const tableHeadersCSV = ["Sl. No.", "Applied Session", "Name", "Mobile", "Email", "BMDC", "Speciality", "Current Institute", "Year of Passing Part-1", "Session", "Online Reg. No.", "Previous training (In month)", " Current training (In month)", "Remaining training", "New Joining", "Discontinued", "Re-joining", "Applied for honorarium", "Remarks"];

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4";
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 300;
    const doc: any = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "List of Trainees for Training Allowances";
    const headers = [tableHeaders];
    const data = allowanceList?.data?.data?.map((data: any, index: number) => [
      ((page - 1) * limit + index) + 1,
      data?.registration?.personal_info?.full_name,
      data?.registration?.personal_info?.mobile,
      data?.registration?.bmdc_registration_no,
      data?.registration?.bcps_registration_no,
      data?.registration?.bank_info?.bank_name,
      data?.registration?.bank_info?.branch_name,
      data?.registration?.bank_info?.account_no,
      data?.registration?.bank_info?.routing_no,
      '120000'
    ]) || [];

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("Allowance_List.pdf");
  }

  // END PDF AND CSV FOR TABLE DATA

  return (
    <div className="page-content">
      {isLoading || isFetching || showAllowanceReport || submitting ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <BetterBreadcrumb
          breadcrumbItem={[{ link: '/', name: 'Training Allowance' }]}
          title={'Training Allowance List'}
        />
        <div className="row align-items-center justify-content-center">
          <div className="card">
            <div className="card-body">
              <div>
                <Form
                  className="custom-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                  onReset={(e) => {
                    e.preventDefault();
                    setSelectedSubject('');
                    setSelectedInstitute('');
                    resetForm();
                    handleSubmit();
                  }}
                >
                  <div className="filter-wrapper">
                    <FormGroup>
                      <Label for="bmdc_registration_no">BMDC Reg. No.</Label>
                      <InputField
                        type="text"
                        placeholder="BMDC Reg. No."
                        {...getFieldProps('bmdc_registration_no')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="full_name">Full Name</Label>
                      <InputField
                        type="text"
                        placeholder="Full Name"
                        {...getFieldProps('full_name')}
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="mobile">Mobile</Label>
                      <InputField
                        type="text"
                        placeholder="Mobile"
                        {...getFieldProps('mobile')}
                      />
                    </FormGroup>



                    <FormGroup className='extent-field'>
                      <Label for="institute_name">Institute</Label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        value={selectedInstitue}
                        onChange={setSelectedInstitute}
                        options={instituteList}
                        placeholder='Institute'
                        name="institute_name"
                      />
                    </FormGroup>

                    <FormGroup className='extent-field'>
                      <Label for="subject_name">Speciality</Label>
                      <Select
                        isClearable={true}
                        isSearchable={true}
                        value={selectedSubject}
                        onChange={setSelectedSubject}
                        options={allSubjects}
                        placeholder='Speciality'
                        name="subject_name"
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="fcps_part_one_passing_session">Passing Session</Label>
                      <InputField
                        type="select"
                        placeholder="Passing Session"
                        options={sessionList}
                        {...getFieldProps('fcps_part_one_passing_session')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="fcps_part_one_passing_year">Passing Year</Label>
                      <InputField
                        type="select"
                        placeholder="Passing Year"
                        options={allYears}
                        {...getFieldProps('fcps_part_one_passing_year')}
                      />
                    </FormGroup>
                    
                    <FormGroup>
                      <Label for="admin_approval_status">Status</Label>
                      <InputField
                        type="select"
                        placeholder="Status"
                        options={allStatus}
                        {...getFieldProps('admin_approval_status')}
                      />
                    </FormGroup>
                    <FormGroup className='btn-column mb-3'>
                      <Button color="danger" type="reset" text="Reset" >Reset</Button>
                      <Button type="submit" text="Search" color="primary" >Search</Button>
                    </FormGroup>

                    {allowanceList?.data?.data?.length! > 0 &&
                      <FormGroup className='d-flex justify-content-end gap-2'>
                        <Button type="button" color='warning' outline onClick={() => exportPDF()} className='fw-bold'>
                          <i className="fa-solid fa-print"></i>{" "}PDF
                        </Button>
                        <CSVLink
                          headers={tableHeadersCSV}
                          data={allowanceList?.data?.data?.map((data: any, index: number) => [
                            ((page - 1) * limit + index) + 1,
                            data?.registration?.current_session_and_supervisor_info?.session_name,
                            data?.registration?.personal_info?.full_name,
                            "'" + data?.registration?.personal_info?.mobile,
                            data?.registration?.personal_info?.email,
                            data?.registration?.bmdc_registration_no,
                            data?.registration?.subject?.subject_name,
                            data?.registration?.current_session_and_supervisor_info?.supervisor?.institute_name,
                            data?.registration?.fcps_part_one_passing_year,
                            data?.registration?.fcps_part_one_passing_session,
                            data?.registration?.bcps_registration_no,
                            data?.previous_slots?.length * 6,
                            '6',
                            data?.next_sessions?.length * 6,
                            data?.previous_slots?.length === 0 ? 'Y' : 'N',
                            'N/A',
                            'N/A',
                            data?.no_of_allowance_received,
                            '',
                          ]) || []}
                          filename="Allowance_List.csv"
                        >
                          <Button type="button" color='success' outline className='fw-bold'>
                            <i className="fa-solid fa-file-arrow-down"></i>{' '}
                            CSV
                          </Button>
                        </CSVLink>
                      </FormGroup>
                    }
                  </div>
                </Form>
              </div>
              <DataTable
                columns={columns}
                data={allowanceList?.data?.data!}
                customStyles={dataTableCustomStyles}
                pagination
                paginationServer
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={isLoading}
                expandOnRowClicked
                paginationRowsPerPageOptions={[2, 5, 10, 20, 50, 100, 200]}
                responsive
                persistTableHead={true}
                paginationTotalRows={allowanceList?.data?.total}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TrainingAllowanceListForBCPS;
