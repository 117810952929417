import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Button,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { InputField } from 'src/components';
import { config } from 'src/config';
import store from 'src/rtk/app/store';
import {
    useLazyGetFcpsPartOneInfoQuery,
} from 'src/rtk/features/educational/educationalApi';
import { useGetLookupDataQuery } from 'src/rtk/features/setup/lookup/lookupDataApi';

interface Props {
    isOpen: boolean;
    toggle: any;
    courseList: FcpsCourseList;
}

const CourseListApplyModal = (props: Props) => {
    const { isOpen, toggle, courseList } = props;
    //   const [courseListInfo, setCourseListInfo] = useState<FcpsCourseList[]>([]);
    const [onlinePayment, setOnlinePayment] = useState<boolean>(true);
    const [offlinePayment, setOfflinePayment] = useState<boolean>(false);
    const [agreeState, setAgreeState] = useState<boolean>(false);
    const [selectedSession, setSelectedSession] = useState<string>('');
    const [passingYear, setPassingYear] = useState<string>('');
    const [difference, setDifference] = useState<number | null>(null);
    const [undertakingModalShow, setUndertakingModalShow] = useState<boolean>(true);

    const [courseFee, setCourseFee] = useState<number>(0);

    //   const [createFcpsCourse, { data: fcpsCourseData, isSuccess, isError, error }] =
    //     useGetFcpsCourseListMutation();   

    //   let supervisorOptions: SelectOption[] = [...supervisorList?.map((e: Supervisors) => ({label: e.name!, value: e.id!}))]

    //   const err: CustomError = error as CustomError;

    //   useEffect(() => {
    //     createFcpsCourse(courseName);           
    //   }, []);

    //   useEffect(() => {
    //     if (isSuccess) { 
    //       setCourseListInfo(fcpsCourseData?.data!);                                    
    //     }
    //     if (isError) {
    //       toast.error(err?.message || 'Something went wrong');         
    //     }
    //   }, [isSuccess, isError]);  

    const { data: sessionLookupData } = useGetLookupDataQuery('session');
    const [getFcpsPartOneInfo, { data: fcpsPart,
        isSuccess: isFcpsPartSuccess,
        isError: isFcpsPartError,
        isLoading: isFcpsPartLoading,
        error: fcpsPartError
    }] = useLazyGetFcpsPartOneInfoQuery();
    const sessionList: SelectOption[] =
        (sessionLookupData as any)?.data[0]?.childs?.map((item: any) => ({
            value: item.name,
            label: item.name,
        })) || [];
    // console.log(sessionList);
    // console.log(passingYear);
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 20;

    const years = [];
    for (let year = currentYear; year >= startYear; year--) {
        years.push(year);
    }

    const allYears: SelectOption[] = years?.map((item: any) => ({
        value: item,
        label: item,
    }));

    const [selectedYear, setSelectedYear] = useState(currentYear);

    useEffect(() => {
        getFcpsPartOneInfo(store.getState().auth.user.details.bmdc_registration_no!);
    }, []);

    useEffect(() => {
        if (isFcpsPartSuccess && !isFcpsPartError) {
            setSelectedSession(fcpsPart?.data?.passing_session ?? '');
            setSelectedYear(fcpsPart?.data?.passing_year ?? currentYear);
        }
    }, [isFcpsPartSuccess, isFcpsPartError]);

    useEffect(() => {
        if (selectedSession !== '' && passingYear !== '') {
            const selectedDate = new Date(`${selectedSession} 1, ${passingYear}`);
            const currentDate = new Date();
            const yearsDiff = Math.abs(currentDate.getFullYear() - selectedDate.getFullYear());
            const monthsDiff = yearsDiff * 12 + currentDate.getMonth() - selectedDate.getMonth();
            setDifference(monthsDiff);
        }

    }, [selectedSession, passingYear]);

    useEffect(() => {
        if (difference! >= 3 && difference! < 6) {
            setCourseFee(+courseList?.exam_tranning_fee?.exam_fee! + 500);
        }
        else if (difference! >= 6) {
            setCourseFee(+courseList?.exam_tranning_fee?.exam_fee! + 1000);
        }
        else {
            setCourseFee(+courseList?.exam_tranning_fee?.exam_fee!);
        }
    }, [difference]);

    const handleYearChange = (value: any) => {
        const enteredYear = value;
        const currentYear = new Date().getFullYear();

        if (enteredYear <= currentYear.toString() && /^\d{0,4}$/.test(enteredYear)) {
            setPassingYear(enteredYear);
        }
    };

    useEffect(() => {
        console.log(selectedYear);
        handleYearChange(selectedYear);
    }, [selectedYear]);

    // console.log(difference);
    return (
        <Modal isOpen={isOpen} toggle={toggle} backdrop={'static'} size="md">
            <ModalHeader toggle={toggle}>
                <span className='me-5'>
                    Apply for {courseList?.name}
                </span>
            </ModalHeader>

            <ModalBody>
                <div className='table-responsive'>
                    <table className='table table-bordered'>
                        <tbody>
                            <tr>
                                <td>
                                    <strong>Course Title: </strong>
                                </td>
                                <td>  {courseList?.name}</td>


                                {/* <div className="col-sm-12 mb-2">
                                <span className="fw-semibold">Supervisors: </span>
                                <span>
                                    <InputField
                                        name='supervisors'
                                        id='supervisors'
                                        className='form-select'                                        
                                        type='select'                                        
                                        options={supervisorOptions}                                                                              
                                    />
                                </span>
                            </div>                             */}


                            </tr>
                            <tr>
                                <td><strong>FCPS Part-1 Passing Session: </strong></td>
                                <td>
                                    <InputField
                                        className='form-select-sm'
                                        type='select'
                                        name='passing_session'
                                        options={sessionList}
                                        onChange={(e: any) => {
                                            setSelectedSession(e.target.value);
                                        }}
                                        value={selectedSession}
                                        disabled={fcpsPart?.data?.passing_session}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td><strong>FCPS Part-1 Passing Year: </strong></td>
                                <td>
                                    <InputField
                                        className='form-select-sm'
                                        type='select'
                                        name='passing_session'
                                        options={allYears}
                                        onChange={(e: any) => {
                                            setSelectedYear(e.target.value);
                                        }}
                                        value={selectedYear}
                                        disabled={fcpsPart?.data?.passing_year}
                                    />
                                </td>
                                {/* <td>
                                <Select
                                    isClearable={true}
                                    isSearchable={true}
                                    value={allYears.find(
                                        (option: any) =>
                                          option.value === selectedYear,
                                      )}
                                    onChange={setSelectedYear}
                                    options={allYears}
                                    placeholder='Passing Year'
                                    name="passing_year"
                                />
                                </td> */}
                            </tr>
                            <tr>
                                <td>
                                    <strong>Fees: </strong>

                                </td>
                                <td>{courseFee}</td>
                            </tr>
                        </tbody>
                    </table>

                    <a href='#' className='font-size-14 text-decoration-underline' onClick={(e) => {
                        setUndertakingModalShow(!undertakingModalShow);
                    }}>
                        Declaration
                    </a>
                </div>

                {undertakingModalShow ? (
                    <div className='border border-dark justified-text p-3'>
                        <h3>DECLARATION</h3>
                        <p>
                            I undertake to keep the BCPS informed about my training every six months and submit progress report from my trainer! supervisor after every six months period of training and within the time stipulated by the BCPS. I undertake to inform the BCPS in case of transfer/change of supervisor. I understand that training undertaken by me without registration and prior information and training for which progress report has not been submitted within the stipulated time will not be accepted by the BCPS for the purpose of appearing in BCPS examinations. I further understand that day to day activities during my training period should be entered into the Logbook collected from BCPS.

                            I understand that I have to complete my dissertation at least 6 months before I intend to appear in the FCPS part-II examination. I undertake to complete the compulsory training for dissertation writing as soon as possible.
                        </p>
                        <FormGroup check inline>
                            <Input
                                id='agreeCheck'
                                name='agreeCheck'
                                type='checkbox'
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setAgreeState(true);
                                    } else {
                                        setAgreeState(false);
                                    }
                                }}
                                checked={agreeState}
                            />

                            <Label check htmlFor="agreeCheck">
                                I agree
                            </Label>
                        </FormGroup>
                    </div>
                ) : null}

                <table className='table table-bordered mt-2'>
                    <tr>
                        <td><strong>Payment Mode:</strong></td>
                        <td>
                            <Form
                                className="custom-form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                onReset={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <FormGroup check inline>
                                    <Input
                                        id='paymentMode1'
                                        name='payment_mode'
                                        type='radio'
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setOnlinePayment(true);
                                                setOfflinePayment(false);
                                            }
                                        }}
                                        checked={onlinePayment}
                                        value={'Online'}
                                    />

                                    <Label check for="paymentMode1">
                                        Online
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Input
                                        id='paymentMode2'
                                        name='payment_mode'
                                        type='radio'
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setOnlinePayment(false);
                                                setOfflinePayment(true);
                                            }
                                        }}
                                        checked={offlinePayment}
                                        value={'Offline'}
                                    />

                                    <Label check for="paymentMode2">
                                        Offline
                                    </Label>
                                </FormGroup>
                            </Form>
                        </td>
                    </tr>
                </table>
                {onlinePayment ? <img src='/images/payment_gateways.png' width={'470px'} /> : null}
            </ModalBody>

            <ModalFooter>
                <div className="d-flex gap-2 ">
                    <Button color="danger" onClick={toggle}>
                        Close
                    </Button>
                    {
                        offlinePayment ? (
                            <Link to={{ pathname: `/trainee/offline-payment/${courseList.type}/${courseList.id}/${courseFee}/${passingYear}/${selectedSession}` }} className={`${selectedSession !== '' && agreeState ? 'btn btn-success' : 'btn btn-success disabled'}`} >
                                Proceed for Payment
                            </Link>
                        ) : null
                    }

                    {
                        onlinePayment ? (
                            <a href={`${config.APP_URL}fcps-online-payment/${courseList?.id!}/${store.getState().auth.user.id}/${courseFee}?passing_year=${passingYear}&passing_session=${selectedSession}`} rel='noreferrer' target={'_blank'} className={`${selectedSession !== '' && agreeState ? 'btn btn-success' : 'btn btn-success disabled'}`}>
                                Proceed for Payment
                            </a>
                        ) : null
                    }

                </div>
            </ModalFooter>
        </Modal>
    );
};

export default CourseListApplyModal;
